import { ObjectId } from "@app/utils/generics";
import { IFullQuestion } from "@tests-core/schemas/questions/helper-schemas";
import * as React from "react";
import "react-quill/dist/quill.snow.css";
import Popup, { PopupContentWithClose } from "../../widgets/Popup";
import TextWithQuestions from "./with-text";
import { successfulSaveArg } from "../types";

type IProps = {
	onClose: () => void;
	onSuccessSave?: (args: successfulSaveArg) => void;
	courseId: ObjectId;
} & (
	| { textId: ObjectId; folderId?: ObjectId }
	| { textId?: ObjectId; folderId: ObjectId }
);

interface IState {
	textId?: ObjectId;
}

class TextPopup extends React.PureComponent<IProps, IState> {
	question?: IFullQuestion;
	_isMounted = false;

	onSuccessSave = (args: successfulSaveArg) => {
		if (this.props.onSuccessSave) {
			this.props.onSuccessSave(args);
		}
		this.props.onClose();
	};

	render() {
		return (
			<Popup
				onClose={this.props.onClose}
				disableClosingOnOutsideClick={true}
			>
				<PopupContentWithClose onClose={this.props.onClose}>
					<TextWithQuestions
						folderId={this.props.folderId as ObjectId}
						courseId={this.props.courseId}
						textId={this.props.textId as ObjectId}
						onSuccessSave={this.onSuccessSave}
					/>
				</PopupContentWithClose>
			</Popup>
		);
	}
}

export default TextPopup;
