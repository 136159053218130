import { ItemType } from "@app/api/folders/helper-schemas";
import { MainButton } from "@app/components/users/styles/styledComponents";
import { inject } from "@app/modules";
import { HierarchyItemType } from "@app/services/hierarchy-info/interfaces";
import { IAnyObj, ObjectId } from "@app/utils/generics";
import TaskTypeIcon from "@material-ui/icons/AssignmentRounded";
import TextIcon from "@material-ui/icons/FormatAlignLeft";
import * as React from "react";
import "react-quill/dist/quill.snow.css";
import {
	DeepMultipleSelectWithSearch,
	IDefaultItems,
	IItemsHierarchy,
	IMultipleSelectItem,
	IMSelectWrapperProps,
} from "../deep-multiple-select-wth-search";
import TopicIcon from "../folder/styles/img/topic";
import FolderIcon from "@material-ui/icons/Folder";
import QuestionIcon from "@material-ui/icons/HelpOutline";
import CardIcon from "@material-ui/icons/CreditCard";
import File from "@material-ui/icons/InsertDriveFile";
import TestIcon from "../folder/styles/img/test";
import {
	FileIconSimple,
	TaskTypeIconSimple,
	FolderIconSimple,
	TopicIconSimple,
	TestIconSimple,
} from "../folder/styles";
import { getFormattedMessage } from "@app/utils/locale";
import { FormattedMessage } from "react-intl";

const topicItemIcons: { [itemType: number]: JSX.Element } = {
	[-1]: <TopicIcon className={TopicIconSimple} />,
};
const taskTypeItemIcons: { [itemType: number]: JSX.Element } = {
	[-1]: <TaskTypeIcon className={TaskTypeIconSimple} />,
};

const defaultItemTypesToBeStored: { [itemType: number]: true } = {
	[-1]: true,
};

interface IHierarchyMultipleSelectProps {
	courseId: ObjectId;
	loadLastOptionsAutomatically?: boolean;
	hierarchiesToShow: HierarchyItemType[];
	itemTypesToBeStored: Record<
		HierarchyItemType.folder,
		{ [itemType in ItemType]?: true }
	>;
	placeholders?: { [h in HierarchyItemType]?: string };
	defaultSelections?: IHierarchyMultipleSelectDefaultSelections;
	multipleSelectProps?: {
		[h in HierarchyItemType]?: Partial<IMSelectWrapperProps>;
	};
	multipleSelectComponent?:
		| {
				[h in HierarchyItemType]?: React.ComponentType<
					IMSelectWrapperProps
				>;
		  }
		| React.ComponentType<IMSelectWrapperProps>;
	onChange?: (
		selection: Record<HierarchyItemType, IDefaultItems[]>,
		isInitialCall: boolean
	) => void;
	alwaysHideLoadLastOptionsButton?: boolean;
}

export interface IHierarchyMultipleSelectDefaultSelections {
	[HierarchyItemType.folder]?: IDefaultItems<ObjectId, ItemType>[];
	[HierarchyItemType.taskType]?: IDefaultItems<ObjectId, -1>[];
	[HierarchyItemType.topic]?: IDefaultItems<ObjectId, -1>[];
}

interface IState {
	optionsKey: number;
	selections: Record<HierarchyItemType, IDefaultItems[]>;
	areLastOptionsLoaded: boolean;
	isLoading: boolean;
}

export const getDefaultHierarchySelect = (): Record<
	HierarchyItemType,
	IDefaultItems[]
> => {
	return {
		[HierarchyItemType.folder]: [],
		[HierarchyItemType.taskType]: [],
		[HierarchyItemType.topic]: [],
	};
};

export class HierarchyMultipleSelect extends React.Component<
	IHierarchyMultipleSelectProps,
	IState
> {
	private readonly _LastOptionModel = inject("LastOptionModel");
	private readonly _CourseFetchingController = inject(
		"CourseFetchingController"
	);

	state: IState = {
		optionsKey: 0,
		selections: getDefaultHierarchySelect(),
		areLastOptionsLoaded: false,
		isLoading: true,
	};

	constructor(props: IHierarchyMultipleSelectProps) {
		super(props);
		if (props.defaultSelections) {
			this.state.selections = {
				...this.state.selections,
				...props.defaultSelections,
			};
		}
	}

	componentDidMount() {
		this.loadNecessaryContnet().then(() => {
			this.setState({
				isLoading: false,
			});
		});
		if (this.props.loadLastOptionsAutomatically) {
			this.loadPastOptions();
		} else {
			if (this.props.onChange) {
				this.props.onChange(this.state.selections, true);
			}
		}
	}

	private loadNecessaryContnet() {
		const courseId = this.props.courseId;
		return Promise.all([
			this._CourseFetchingController.loadWholeCourseSkeleton({
				courseId,
			}),
		]);
	}

	private onSelectedItemsChange = (
		selectedItems: IDefaultItems[],
		selectType: HierarchyItemType
	): void => {
		this.setState(
			({ selections }) => ({
				selections: {
					...selections,
					[selectType]: selectedItems,
				},
			}),
			() => {
				if (this.props.onChange) {
					this.props.onChange(this.state.selections, false);
				}
			}
		);
	};

	_FolderHierarchyService = inject("FolderHierarchyService");
	_TopicHierarchyService = inject("TopicHierarchyService");
	_TaskTypeHierarchyService = inject("TaskTypeHierarchyService");

	private getExpandableItemTypeBySelectType = (
		selectType: HierarchyItemType
	): number => {
		if (selectType === HierarchyItemType.folder) {
			return ItemType.folder;
		}
		return -1;
	};

	public getSelectedItemsByHierarchyType = (
		type: HierarchyItemType
	): IDefaultItems[] => {
		return this.state.selections[type];
	};

	public getSelectedItemIdsByHierarchyType = (
		type: HierarchyItemType
	): ObjectId[] => {
		return this.state.selections[type].map(e => e.id);
	};

	private getPlaceholderBySelectType = (
		selectType: HierarchyItemType
	): string => {
		const { placeholders } = this.props;
		const placeholder = placeholders && placeholders[selectType];
		switch (selectType) {
			case HierarchyItemType.folder:
				return placeholder || getFormattedMessage("folders");
			case HierarchyItemType.topic:
				return placeholder || getFormattedMessage("topics");
			case HierarchyItemType.taskType:
				return placeholder || getFormattedMessage("taskTypes");
			default:
				return placeholder || "";
		}
	};

	private getItemIconsObjectBySelectType = (
		selectType: HierarchyItemType
	): { [itemType: number]: JSX.Element } => {
		switch (selectType) {
			case HierarchyItemType.folder:
				return folderItemsIcons;
			case HierarchyItemType.topic:
				return topicItemIcons;
			case HierarchyItemType.taskType:
				return taskTypeItemIcons;
			default:
				return {};
		}
	};

	loadPastOptions = () => {
		const lastOption = this._LastOptionModel.findByIdSync(
			this.props.courseId
		);
		if (!lastOption || !lastOption.selections) {
			if (this.props.onChange) {
				this.props.onChange(this.state.selections, true);
			}
			return;
		}
		this.setState(
			({ selections, optionsKey }) => ({
				selections: {
					...selections,
					...(lastOption.selections as Record<
						HierarchyItemType,
						IDefaultItems[]
					>),
				},
				optionsKey: optionsKey + 1,
				areLastOptionsLoaded: true,
			}),
			() => {
				if (this.props.onChange) {
					this.props.onChange(this.state.selections, true);
				}
			}
		);
	};

	private getHierarchyAndItems = (
		type: HierarchyItemType
	): {
		items: IMultipleSelectItem<string>[];
		itemsHierarchy: IItemsHierarchy;
		rootId: string;
	} => {
		if (type === HierarchyItemType.folder) {
			return this._FolderHierarchyService.getItemsAndHierarchyObject(
				this.props.courseId,
				{
					[ItemType.folder]: true,
					...this.props.itemTypesToBeStored[HierarchyItemType.folder],
				}
			);
		}
		if (type === HierarchyItemType.taskType) {
			return this._TaskTypeHierarchyService.getAllItemsAndHierarchyObj(
				this.props.courseId
			);
		}
		if (type === HierarchyItemType.topic) {
			return this._TopicHierarchyService.getAllItemsAndHierarchyObj(
				this.props.courseId
			);
		}
		throw new Error(`type ${type} is not correct HierarchyItemType`);
	};

	shouldComponentUpdate(
		nextProps: IHierarchyMultipleSelectProps,
		nextState: IState
	) {
		return (
			nextState.optionsKey !== this.state.optionsKey ||
			nextState.isLoading !== this.state.isLoading ||
			!isShallowEqual(this.props, nextProps)
		);
	}

	saveOptions = () => {
		const lastOption = this._LastOptionModel.findByIdSync(
			this.props.courseId
		);
		if (lastOption) {
			lastOption.selections = this.state.selections;
			lastOption.saveSync();
		} else {
			this._LastOptionModel.loadOneSync({
				courseId: this.props.courseId,
				selections: this.state.selections,
			});
		}
	};

	render() {
		if (this.state.isLoading) return null;
		const lastOption = this._LastOptionModel.findByIdSync(
			this.props.courseId
		);
		return (
			<div key={this.state.optionsKey}>
				{this.props.hierarchiesToShow.map(hierarchyItemType => {
					const {
						items,
						itemsHierarchy,
						rootId,
					} = this.getHierarchyAndItems(hierarchyItemType);
					const mcProps =
						this.props.multipleSelectProps &&
						this.props.multipleSelectProps[hierarchyItemType];
					const Component =
						typeof this.props.multipleSelectComponent === "function"
							? this.props.multipleSelectComponent
							: (this.props.multipleSelectComponent &&
									this.props.multipleSelectComponent[
										hierarchyItemType
									]) ||
							  DeepMultipleSelectWithSearch;
					return (
						<div key={hierarchyItemType} style={{ margin: 20 }}>
							<Component
								items={items}
								hierarchy={itemsHierarchy}
								defaultSelectedItems={
									this.state.selections[hierarchyItemType]
								}
								onSelectedItemsChange={items =>
									this.onSelectedItemsChange(
										items,
										hierarchyItemType
									)
								}
								onItemsReorder={items =>
									this.onSelectedItemsChange(
										items,
										hierarchyItemType
									)
								}
								rootId={rootId}
								expandableItemType={this.getExpandableItemTypeBySelectType(
									hierarchyItemType
								)}
								itemTypesToBeStored={
									this.props.itemTypesToBeStored[
										hierarchyItemType
									] || defaultItemTypesToBeStored
								}
								iconsByType={this.getItemIconsObjectBySelectType(
									hierarchyItemType
								)}
								placeholder={this.getPlaceholderBySelectType(
									hierarchyItemType
								)}
								hideSelectedItems={
									hierarchyItemType ===
										HierarchyItemType.topic ||
									hierarchyItemType ===
										HierarchyItemType.taskType
								}
								{...mcProps}
							/>
						</div>
					);
				})}
				{lastOption &&
					lastOption.selections &&
					!this.props.alwaysHideLoadLastOptionsButton &&
					!this.props.loadLastOptionsAutomatically &&
					!this.state.areLastOptionsLoaded && (
						<>
							<MainButton
								onClick={this.loadPastOptions}
								style={{ margin: "0 0 15px 0" }}
							>
								<FormattedMessage id="admin:general.loadLastOptions" />
							</MainButton>
							<br />
						</>
					)}
			</div>
		);
	}
}

function isShallowEqual(a: IAnyObj, b: IAnyObj): boolean {
	const aProps = Object.getOwnPropertyNames(a);
	const bProps = Object.getOwnPropertyNames(b);
	if (aProps.length !== bProps.length) {
		return false;
	}
	for (let i = 0; i < aProps.length; i++) {
		const propName = aProps[i];
		if (a[propName] !== b[propName]) {
			return false;
		}
	}
	return true;
}

const folderItemsIcons: { [itemType: number]: JSX.Element } = {
	[ItemType.folder]: <FolderIcon className={FolderIconSimple} />,
	[ItemType.question]: <QuestionIcon />,
	[ItemType.text]: <TextIcon />,
	[ItemType.card]: <CardIcon />,
	[ItemType.file]: <File className={FileIconSimple} />,
	[ItemType.test]: <TestIcon className={TestIconSimple} />,
};
