import * as React from "react";
import FileCards from "./file-cards";
import LoadingSVG from "@app/components/styles/img/loading";
import styled from "react-emotion";
import { connect } from "react-redux";
import {
	FileItemTypes,
	IFileSingleItem,
	IFile,
} from "@app/api/files/helper-schemas";
import { inject } from "@app/modules";
import { IRootState } from "@app/redux";
import { ObjectId } from "@app/utils/generics";
import { addLoader } from "@app/commonJavascript";
import { FileTabs } from "./file-tabs";

interface IOwnProps {
	id: ObjectId;
	folderId: ObjectId;
	courseId: ObjectId;
	isReadingFirstTime: boolean;
	onFinishReading: (id: ObjectId, parentFolderId: ObjectId) => void;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IReadingFileProps = IOwnProps & IStateProps;

class ReadingFile extends React.PureComponent<IReadingFileProps> {
	_UserFolderProgressService = inject("UserFolderProgressService");
	_FolderItemProgressService = inject("FolderItemProgressService");
	_FilesController = inject("FilesController");

	lastStartedTime?: Date;
	didSubmit = false;

	_isMounted = false;

	removeLoader?: () => void;

	componentDidMount() {
		this._isMounted = true;
		if (!this.props.files[this.props.id]) {
			this._FilesController
				.getById({
					_id: this.props.id,
					courseId: this.props.courseId,
				})
				.then(() => {
					this.lastStartedTime = new Date();
				});
		} else {
			this.lastStartedTime = new Date();
		}
	}

	onSubmit = () => {
		if (this.didSubmit) return;
		this.didSubmit = true;
		if (!this.lastStartedTime) return;

		const currentTime = new Date();
		const readingInMilliseconds =
			currentTime.getTime() - this.lastStartedTime.getTime();

		const removeLoader = addLoader();
		this.removeLoader = removeLoader;

		this._FilesController
			.onReadFile({
				courseId: this.props.courseId,
				folderId: this.props.folderId,
				fileId: this.props.id,
				progress: 1,
				startedReadingAt: this.lastStartedTime,
				timeSpent: Math.round(readingInMilliseconds / 1000),
			})
			.then(() => {
				removeLoader();
				this.props.onFinishReading(this.props.id, this.props.folderId);
			})
			.catch(e => {
				console.log(e);
				this.didSubmit = false;
				removeLoader();
				alert("დაფიქსირდა შეცდომა...");
			});
	};

	componentWillUnmount() {
		this._isMounted = false;
		if (this.removeLoader) {
			this.removeLoader();
		}
		if (!this.lastStartedTime) return;
		const currentTime = new Date();
		const readingInMilliseconds =
			currentTime.getTime() - this.lastStartedTime.getTime();

		if (!this.didSubmit) {
			this.didSubmit = true;
			this._FilesController
				.onReadFile({
					courseId: this.props.courseId,
					folderId: this.props.folderId,
					fileId: this.props.id,
					progress: 0,
					startedReadingAt: this.lastStartedTime,
					timeSpent: Math.round(readingInMilliseconds / 1000),
				})
				.then();
		}
	}

	render() {
		const fileObj = this.props.files[this.props.id];

		if (!fileObj) {
			return (
				<div style={{ textAlign: "center" }}>
					<LoadingSVG />
				</div>
			);
		}
		const file = fileObj.info;

		return (
			<div style={{ fontFamily: "FiraGO" }}>
				<FileItems
					courseId={this.props.courseId}
					items={file.items}
					title={file.title}
				/>
				<Button onClick={this.onSubmit}>შემდეგი</Button>
			</div>
		);
	}
}

export const FileItems = ({
	title,
	items,
	courseId,
}: {
	title: string;
	items: IFile["items"];
	courseId: ObjectId;
}) => {
	return (
		<>
			{title && <Heading>{title}</Heading>}
			{items.map((item, index) => (
				<FileItem key={index} item={item} courseId={courseId} />
			))}
		</>
	);
};

interface IFileItemProps {
	item: IFileSingleItem;
	courseId: ObjectId;
}

export const FileItem: React.FC<IFileItemProps> = props => {
	const item = props.item;
	if (item.type === FileItemTypes.text) {
		return <Body dangerouslySetInnerHTML={{ __html: item.text }} />;
	}
	if (item.type === FileItemTypes.cards) {
		return (
			<Body>
				<FileCards ids={item.ids} courseId={props.courseId} />
			</Body>
		);
	}
	if (item.type === FileItemTypes.tabs) {
		return (
			<Body>
				<FileTabs item={item} courseId={props.courseId} />
			</Body>
		);
	}
	return null;
};

const Heading = styled("div")({
	width: 300,
	margin: "20px auto",
	textAlign: "center",
	fontSize: 18,
	fontWeight: "bold",
});

const Body = styled("div")({
	width: "100%",
	padding: 20,
});

const Button = styled("div")({
	background: "#284ea6",
	color: "white",
	width: 150,
	height: 40,
	padding: "0px 10px",
	borderRadius: 50,
	margin: "0 auto",
	textAlign: "center",
	paddingTop: 12,
	cursor: "pointer",
});

const mapStateToProps = (state: IRootState) => ({
	files: state.files,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	ReadingFile
);
