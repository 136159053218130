import * as React from "react";
import FlagIcon from "@material-ui/icons/Flag";
import styled, { css } from "react-emotion";

interface ITestQuesionsNavigationProps {
	questionsLength: number;
	onQuestionSelect: (qIndex: number) => void;
	indexOfLastQuestion: number;
	indexOfSelectedQuestion: number;
}

export const TestQuestionsNavigation: React.FC<ITestQuesionsNavigationProps> = (
	props: ITestQuesionsNavigationProps
) => {
	return (
		<QuestionNavigationBarContainer>
			{new Array(props.questionsLength).fill(0).map((e, index) => (
				<React.Fragment key={index}>
					<TestQuestion
						index={index}
						onQuestionSelect={props.onQuestionSelect}
						isAccessable={index <= props.indexOfLastQuestion}
						isSelected={index === props.indexOfSelectedQuestion}
					>
						{index + 1}
					</TestQuestion>
				</React.Fragment>
			))}
			<TestQuestion
				index={-1}
				onQuestionSelect={props.onQuestionSelect}
				isAccessable={
					props.questionsLength <= props.indexOfLastQuestion
				}
				isSelected={
					props.questionsLength === props.indexOfSelectedQuestion
				}
			>
				<div className="VM">
					<FlagIcon />
				</div>
			</TestQuestion>
		</QuestionNavigationBarContainer>
	);
};

interface ITestQuestionProps {
	index: number;
	onQuestionSelect: (qIndex: number) => void;
	isAccessable: boolean;
	isSelected: boolean;
}

export const TestQuestion: React.FC<ITestQuestionProps> = props => {
	return (
		<TestQuestionNavDiv
			isAccessable={props.isAccessable}
			isSelected={props.isSelected}
		>
			<div
				className={TestQuestionNumberContainer}
				onClick={() => props.onQuestionSelect(props.index)}
				style={props.isAccessable ? undefined : { cursor: "default" }}
			>
				{props.children}
			</div>
		</TestQuestionNavDiv>
	);
};

const QuestionNavigationBarContainer = styled("div")({
	textAlign: "center",
	padding: 20,
	width: 1150,
	maxWidth: "100%",
	margin: "0 auto",
});

const TestQuestionNumberContainer = css`
	display: inline-block;
	width: 35px;
	height: 35px;
	border-radius: 5px;
	background: white;
	color: #1c97d2;
	font-family: roboto;
	font-weight: bold;
	margin: 0;
	line-height: 35px;
	font-size: 18px;
	cursor: pointer;
	outline: none;
	box-shadow: rgba(0, 0, 0, 0.3) 0 0 7px;
`;

const TestQuestionNavDiv = styled("div")<{
	isSelected: boolean;
	isAccessable: boolean;
}>`
	display: inline-block;
	vertical-align: middle;
	position: relative;
	transition: 0.3s;
	margin: 10px;
	opacity: ${props => (props.isAccessable ? 1 : 0.4)};
	${props =>
		props.isSelected
			? `
		.${TestQuestionNumberContainer} {
			background: #1c97d2;
			color: white;
		}
	`
			: ``}
`;
