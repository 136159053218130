import * as React from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { connect } from "react-redux";
import { Content, ItemComponent } from "./content";
import { History } from "history";
import { IRFolder, ItemType } from "@app/api/folders/helper-schemas";
import { inject } from "@app/modules";
import { IOpenIds, ISelectedItem, ISelectionChangeFunc } from "..";
import { IRootState } from "@app/redux";
import { leftNavigationCSS, NavigationTitleCSS, Scrollable } from "./styles";
import { ObjectId } from "@app/utils/generics";

interface IOwnProps {
	courseId: ObjectId;
	folder: IRFolder;
	openIds: IOpenIds;
	onOpenContentChange: (id: string, isOpening: boolean) => void;
	onSelectionChange: ISelectionChangeFunc;
	selection: ISelectedItem;
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IContentsNavigationProps = IOwnProps & IStateProps;

class ContentsNavigation extends React.PureComponent<IContentsNavigationProps> {
	FolderHierarchyService = inject("FolderHierarchyService");
	_UserFolderProgressService = inject("UserFolderProgressService");

	onBack = () => {
		const parentId = this.FolderHierarchyService.getParentIdSync(
			this.props.courseId,
			this.props.folder._id
		);
		this.props.history.push(
			`/courses/${this.props.courseId}/folders/${parentId!}`
		);
	};

	render() {
		const listOfItems = this.props.folder.items;
		if (!listOfItems) {
			return <div>ცარიელია</div>;
		}
		let displayIndex = -1;

		return (
			<div className={leftNavigationCSS}>
				<NavigationTitle
					folder={this.props.folder}
					courseId={this.props.courseId}
					onClick={this.onBack}
				/>
				<div className={Scrollable}>
					{listOfItems.map((item, index) => {
						if (item.isHidden) return null;
						if (
							item.type !== ItemType.folder &&
							item.type !== ItemType.file &&
							item.type !== ItemType.test
						) {
							return null;
						}
						const itemProgress = this._UserFolderProgressService.getProgressOfItemSync(
							{
								courseId: this.props.courseId,
								folderId: this.props.folder._id,
								userId: this.props.userData.id,
								itemId: item.id,
								itemType: item.type,
							}
						);
						const isLocked = this._UserFolderProgressService.isItemLockedSync(
							{
								courseId: this.props.courseId,
								folderId: this.props.folder._id,
								userId: this.props.userData.id,
								itemId: item.id,
								itemType: item.type,
							}
						);
						if (item.type === ItemType.folder) {
							displayIndex++;
							return (
								<Content
									selection={this.props.selection}
									key={item.id}
									name={item.name}
									id={item.id}
									courseId={this.props.courseId}
									isLocked={isLocked}
									openIds={this.props.openIds}
									onOpenContentChange={
										this.props.onOpenContentChange
									}
									onSelectionChange={
										this.props.onSelectionChange
									}
									userId={this.props.userData.id}
								/>
							);
						}
						if (
							item.type === ItemType.file ||
							item.type === ItemType.test
						) {
							displayIndex++;
							return (
								<ItemComponent
									key={item.id}
									item={item}
									onSelectionChange={
										this.props.onSelectionChange
									}
									folderId={this.props.folder._id}
									num={displayIndex + 1}
									isSelected={
										!!this.props.selection &&
										this.props.selection.id === item.id
									}
									isLocked={isLocked}
									isDone={itemProgress === 1}
								/>
							);
						}
						return null;
					})}
				</div>
			</div>
		);
	}
}

interface INavigationTitleProps {
	folder: IRFolder;
	courseId: ObjectId;
	onClick: () => void;
}
const NavigationTitle: React.FC<INavigationTitleProps> = props => (
	<div className={NavigationTitleCSS} onClick={props.onClick}>
		<div>
			<ChevronLeftIcon />
		</div>
		<div>{props.folder.name}</div>
	</div>
);

const mapStateToProps = (state: IRootState) => ({
	folders: state.folders,
	userData: state.user.userData!,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	ContentsNavigation as React.ComponentType<IOwnProps>
);
