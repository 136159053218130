import { IFolderSingleItem, ItemType } from "@app/api/folders/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export const useItemOnClick = ({
	courseId,
	item,
	parentFolderId,
}: {
	courseId: ObjectId | null;
	parentFolderId: ObjectId | null;
	item: IFolderSingleItem | null;
}) => {
	const history = useHistory();
	return useCallback(() => {
		if (!courseId || !item || !parentFolderId) return;
		if (item.type === ItemType.folder) {
			history.push(`/courses/${courseId}/folders/${item.id}`);
		} else if (item.type === ItemType.test) {
			history.push(
				`/courses/${courseId}/folders/${parentFolderId}/tests/${item.id}`
			);
		} else {
			throw new Error(`item type ${item.type} not supported`);
		}
	}, [courseId, history, item, parentFolderId]);
};

export type FilteredFolderItem<T extends ItemType> = Extract<
	IFolderSingleItem,
	{ type: T }
>;

export function itemTypeFilterFn<T extends ItemType>(...types: T[]) {
	return function(item: IFolderSingleItem): item is FilteredFolderItem<T> {
		if (types.indexOf(item.type as T) === -1) return false;
		return true;
	};
}
