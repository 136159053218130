import * as React from "react";
import TestTypeSettings from "./folder/test-type-settings";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { CheckBoxContainer } from "../styles/checkbox";
import { connect } from "react-redux";
import { History } from "history";
import { InputStyle } from "../styles/inputs";
import { IRGETTestType } from "@app/api/test-types/validators";
import { IRootState } from "@app/redux";
import { match } from "react-router";
import { PrimaryButton } from "../styles/buttons";
import { IRTestType } from "@app/api/test-types/helper-schemas";
import { inject } from "@app/modules";
import { getFormattedMessage } from "@app/utils/locale";
import { FormattedMessage } from "react-intl";

interface IOwnProps {
	match: match<{ testTypeId: string }>;
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IStateProps & IOwnProps;

interface IState {
	testType?: Omit<IRGETTestType, "settings"> & {
		settings: IRGETTestType["settings"];
	};
}

class TestTypes extends React.Component<IProps, IState> {
	state: IState = {};

	testTypeId = this.props.match.params.testTypeId;
	_isMounted: boolean = false;

	_TestTypesController = inject("TestTypesController");

	componentDidMount() {
		this._isMounted = true;
		if (this.testTypeId) {
			if (!this.props.testTypes[this.testTypeId]) {
				this._TestTypesController
					.getById({ _id: this.testTypeId })
					.then(data => {
						this.setState({
							testType: data,
						});
					});
			} else {
				this.setState({
					testType: this.props.testTypes[this.testTypeId]!.info,
				});
			}
		} else {
			const testType: IState["testType"] = {
				name: "",
				description: "",
				public: true,
			} as IRGETTestType;
			this.setState({
				testType,
			});
		}
	}

	handleInputChange = (field: string) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const value = e.target.value as string | number;
		this.setState({
			testType: {
				...this.state.testType!,
				[field]: value,
			},
		});
	};

	handleCheckboxChange = (field: string) => {
		this.setState({
			testType: {
				...this.state.testType!,
				[field]: !this.state.testType![field],
			},
		});
	};

	onSettingsChange = (settings: IRTestType["settings"]) => {
		this.setState(({ testType }) => {
			if (!testType) return null;
			return {
				testType: {
					...testType,
					settings,
				},
			};
		});
	};

	save = () => {
		const { _id, author, ...testType } = this.state.testType!;
		if (!this.testTypeId) {
			this._TestTypesController
				.add(testType)
				.then(data => this.props.history.push(`/admin/test-types`));
			return;
		}
		this._TestTypesController
			.update({ _id: this.testTypeId!, ...testType })
			.then(() => this.props.history.push(`/admin/test-types`));
	};

	render() {
		if (!this.state.testType) return null;
		return (
			<div className="main">
				<div>
					<TextField
						label={getFormattedMessage("inputs.name")}
						value={this.state.testType.name}
						onChange={this.handleInputChange("name")}
						margin="dense"
						variant="outlined"
						className={InputStyle}
					/>
				</div>
				<div>
					<TextField
						label={getFormattedMessage("inputs.description")}
						value={this.state.testType.description}
						onChange={this.handleInputChange("description")}
						margin="dense"
						variant="outlined"
						className={InputStyle}
					/>
				</div>
				<div className={CheckBoxContainer}>
					<FormControlLabel
						control={
							<Checkbox
								checked={this.state.testType.public}
								onChange={() =>
									this.handleCheckboxChange("public")
								}
							/>
						}
						label={getFormattedMessage("public")}
					/>
				</div>
				<TestTypeSettings
					defaultSettings={this.state.testType.settings}
					onChange={this.onSettingsChange}
				/>
				<PrimaryButton onClick={this.save}>
					<FormattedMessage id="save" />
				</PrimaryButton>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	testTypes: state.testTypes,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	TestTypes
);
