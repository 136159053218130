import { css } from "emotion";

export const InputStyle = css`
	width: 400px;
	max-width: 100%;
`;

export const borderedInput = css`
	width: 100%;
	padding: 5px;
	margin: 10px 0;
	border: 1px solid black;
	border-radius:5px;
`;
