import { ITopic } from "@app/api/topics/helper-schemas";
import { IAPOSTCreateTopic } from "@app/api/topics/validators";
import Popup, { PopupContent } from "@app/components/widgets/Popup";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { PrimaryButton } from "../../styles/buttons";
import FancyLoading from "../../widgets/fancy-loading";
import { InputStyle } from "./styles/components";
import { removeKeys } from "@app/utils/common";
import { FormattedMessage } from "react-intl";
import { getFormattedMessage } from "@app/utils/locale";

interface IProps {
	courseId: string;
	id?: ObjectId;
	parentId?: ObjectId;
	onSuccess?: () => void;
}

interface IState {
	isGalleryOpened: boolean;
	taskType?: IAPOSTCreateTopic | ITopic;
	isLoading: boolean;
}

class TopicEdit extends React.PureComponent<IProps, IState> {
	state: IState = {
		isGalleryOpened: false,
		isLoading: true,
	};

	private _TopicsController = inject("TopicsController");

	componentDidMount() {
		if (this.props.id) {
			this._TopicsController
				.getById(
					{
						_id: this.props.id,
						originalCourseId: this.props.courseId,
					},
					true
				)
				.then(taskType => {
					this.setState({ taskType, isLoading: false });
				});
		} else {
			this.setState({
				taskType: {
					name: "",
					originalCourseId: this.props.courseId,
				},
				isLoading: false,
			});
		}
	}

	handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			taskType: {
				...this.state.taskType!,
				name: e.target.value,
			},
		});
	};

	onSave = () => {
		this.setState({ isLoading: true });
		if (!this.props.id) {
			this._TopicsController
				.add({
					...this.state.taskType,
					parentId: this.props.parentId,
				} as IAPOSTCreateTopic)
				.then(data => {
					if (this.props.onSuccess) this.props.onSuccess();
				})
				.catch(e => {
					console.error(e);
					alert("დაფიქსირდა შეცდომა");
					this.setState({ isLoading: false });
				});
		} else {
			this._TopicsController
				.update(removeKeys(this.state.taskType as ITopic, "author"))
				.then(data => {
					if (this.props.onSuccess) this.props.onSuccess();
				})
				.catch(() => {
					alert("დაფიქსირდა შეცდომა");
					this.setState({ isLoading: false });
				});
		}
	};

	render() {
		const { taskType } = this.state;
		if (!taskType || this.state.isLoading) {
			return <FancyLoading />;
		}
		return (
			<div>
				<TextField
					label={getFormattedMessage("inputs.name")}
					defaultValue={taskType.name}
					placeholder={getFormattedMessage("inputs.name")}
					margin="normal"
					variant="outlined"
					className={InputStyle}
					onChange={this.handleNameChange}
				/>
				<PrimaryButton
					onClick={this.onSave}
					style={{ display: "block" }}
				>
					<FormattedMessage id="save" />
				</PrimaryButton>
			</div>
		);
	}
}

export const TopicEditPopup: React.FC<Omit<IProps, "onSuccess"> & {
	onClose: () => void;
}> = props => {
	return (
		<Popup onClose={props.onClose}>
			<PopupContent>
				<TopicEdit {...props} onSuccess={props.onClose} />
			</PopupContent>
		</Popup>
	);
};
