import React from "react";
import { ObjectId } from "@app/utils/generics";
import Popup from "@app/components/widgets/Popup";
import CancelIcon from "@material-ui/icons/CancelRounded";
import { PopupCSS, PopupHeader, Cancel } from "../styles";
import { FileComponent } from "../../files";

interface FilePopupProps {
	fileId?: ObjectId;
	folderId: ObjectId;
	courseId: ObjectId;
	onClose: () => void;
}

export const FilePopup: React.FC<FilePopupProps> = ({
	onClose,
	fileId,
	folderId,
	courseId,
}) => {
	return (
		<Popup onClose={onClose}>
			<div className={PopupCSS}>
				<div className={PopupHeader}>
					<CancelIcon className={Cancel} onClick={onClose} />
				</div>
				<div style={{ padding: 10 }}>
					<FileComponent
						fileId={fileId}
						folderId={folderId}
						courseId={courseId}
						onSuccessfulSave={onClose}
					/>
				</div>
			</div>
		</Popup>
	);
};
