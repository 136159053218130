import {
	IFolderSingleItem,
	IRFolder,
	ItemType,
} from "@app/api/folders/helper-schemas";
import { NotUndefined, ObjectId } from "@app/utils/generics";
import CardIcon from "@material-ui/icons/CreditCard";
import FolderIcon from "@material-ui/icons/Folder";
import TextIcon from "@material-ui/icons/FormatAlignLeft";
import QuestionIcon from "@material-ui/icons/HelpOutline";
import { css } from "emotion";
import * as React from "react";
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
} from "react-beautiful-dnd";
import { ContextMenuTrigger } from "react-contextmenu";
import {
	AllFolders,
	DraggableCSS,
	FileIcon,
	FolderContainer,
	FolderIconCSS,
	FolderItemsPage,
	FolderSingleItemNameCSS,
	TestIconStyles,
} from "./styles";
import "./styles/context-menu.css";
import SvgFile from "./styles/img/file";
import TestIcon from "./styles/img/test";
import { MenuAction, MyMenu, ITriggerAttributes } from "./context-menu";
import { covertASCIIMathToLaTex } from "@tests-core/components/editor/math";
import { normalizeHTML } from "@tests-core/components/editor/html";
import { useQuestionDifficulty } from "@app/hooks/questions";
import { roundNumberDecimalPlaces } from "@app/utils/common";
import { IQuestionStatsCoefficients } from "@app/api/questions/stats/helper-schemas";

interface IFolderItemsContainerProps {
	items: NotUndefined<IRFolder["items"]>;
	onItemSelect: (type: ItemType, _id: string) => void;
	onItemEdit: (type: ItemType, _id: string) => void;
	onItemRemove: (type: ItemType, _id: string) => void;
	onItemsReorder: (
		newItems: NotUndefined<IRFolder["items"]>,
		sourceIndex: number,
		destinationIndex: number
	) => void;
	onItemRelocate: (type: ItemType, _id: string) => void;
	viewUsersOfTest: (testId: string) => void;
	courseId: ObjectId;
}

function collect(props) {
	return props;
}

export class FolderItemsContainer extends React.PureComponent<
	IFolderItemsContainerProps
> {
	onDragEnd = (result: DropResult): void => {
		if (!result.destination) return;
		const newArray = [...this.props.items];
		const removed = newArray[result.source.index];
		newArray.splice(result.source.index, 1);
		newArray.splice(result.destination.index, 0, removed);
		this.props.onItemsReorder(
			newArray,
			result.source.index,
			result.destination.index
		);
	};

	onMenuItemSelect = (
		e,
		data: { action: MenuAction },
		target: HTMLElement
	) => {
		const itemId = target.title;
		const item = this.props.items.find(each => each.id === itemId);
		if (!item) return;
		switch (data.action) {
			case MenuAction.view:
				this.props.onItemSelect(item.type, item.id);
				break;
			case MenuAction.edit:
				this.props.onItemEdit(item.type, item.id);
				break;
			case MenuAction.delete:
				this.props.onItemRemove(item.type, item.id);
				break;
			case MenuAction.relocate:
				this.props.onItemRelocate(item.type, item.id);
				break;
			case MenuAction.vewUsersOfByTest:
				this.props.viewUsersOfTest(item.id);
		}
	};

	render() {
		const { items, onItemSelect } = this.props;
		return (
			<div>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								className={FolderItemsPage}
							>
								<div className={AllFolders}>
									{items &&
										items.map((el, index) => {
											const additional: ITriggerAttributes = {
												itemType: el.type,
											};
											return (
												<Draggable
													key={el.id}
													draggableId={el.id}
													index={index}
												>
													{(provided2, snapshot2) => (
														<ContextMenuTrigger
															id="multi"
															attributes={{
																title: el.id,
															}}
															collect={collect}
															{...(additional as any)}
														>
															<div
																className={
																	DraggableCSS
																}
																ref={
																	provided2.innerRef
																}
																{...provided2.draggableProps}
																{...provided2.dragHandleProps}
															>
																<div
																	className={
																		FolderContainer
																	}
																	onClick={() =>
																		onItemSelect(
																			el.type,
																			el.id
																		)
																	}
																>
																	<FolderItemElement
																		key={
																			el.id
																		}
																		item={
																			el
																		}
																		courseId={
																			this
																				.props
																				.courseId
																		}
																	/>
																</div>
															</div>
														</ContextMenuTrigger>
													)}
												</Draggable>
											);
										})}
								</div>
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				<MyMenu onSelect={this.onMenuItemSelect} />
			</div>
		);
	}
}

interface IFolderItemElementProps {
	item: IFolderSingleItem;
	onClick?: (item: IFolderSingleItem) => void;
	courseId: ObjectId;
}

export const FolderItemElement: React.FC<IFolderItemElementProps> = React.memo(
	function FolderItemElement({ item, onClick, courseId }) {
		const handleClick = React.useCallback(() => {
			if (!onClick) return;
			onClick(item);
		}, [item, onClick]);

		switch (item.type) {
			case ItemType.folder:
				return (
					<div onClick={handleClick}>
						<FolderIcon className={FolderIconCSS} />
						<div className={FolderSingleItemNameCSS}>
							{item.name}
						</div>
					</div>
				);
			case ItemType.file:
				return (
					<div onClick={handleClick}>
						<SvgFile className={FileIcon} />
						<div className={FolderSingleItemNameCSS}>
							{item.name}
						</div>
					</div>
				);
			case ItemType.card:
				return (
					<div onClick={handleClick}>
						<CardIcon className={itemIconCSS} />
						<div className={FolderSingleItemNameCSS}>
							{item.name}
						</div>
					</div>
				);
			case ItemType.question:
				return (
					<div onClick={handleClick} className={qContainerCSS}>
						<QuestionIcon className={itemIconCSS} />
						<div
							className={FolderSingleItemNameCSS}
							dangerouslySetInnerHTML={{
								__html: covertASCIIMathToLaTex(
									normalizeHTML(item.name)
								),
							}}
						/>
						<QDifficulty questionId={item.id} courseId={courseId} />
					</div>
				);
			case ItemType.text:
				return (
					<div onClick={handleClick}>
						<TextIcon className={itemIconCSS} />
						<div className={FolderSingleItemNameCSS}>
							{item.name}
						</div>
					</div>
				);
			case ItemType.test:
				return (
					<div onClick={handleClick}>
						<TestIcon className={TestIconStyles} />
						<div className={FolderSingleItemNameCSS}>
							{item.name}
						</div>
					</div>
				);
			default:
				return null;
		}
	}
);

const itemIconCSS = css`
	vertical-align: middle;
`;

const qContainerCSS = css`
	margin: 5px 0;
`;

export const QDifficulty = React.memo<{
	questionId: ObjectId;
	courseId: ObjectId;
}>(function QDifficulty({ questionId, courseId }) {
	const difficulty = useQuestionDifficulty({ questionId, courseId });
	return (
		<div style={{ float: "right" }}>
			{difficulty.doc && formatCoefficients(difficulty.doc.coefficients)}
			{difficulty.hasFoundError && "--"}
			{!difficulty.isSuccessfullyLoaded &&
				!difficulty.hasFoundError &&
				".."}
		</div>
	);
});

function formatCoefficients(coefficients: IQuestionStatsCoefficients) {
	return Math.round(coefficients.b * 100) + "%";
}
