import {
	QuestionDifficultyStatsSchema,
	IQuestionDifficultyStats,
} from "./helper-schemas";
import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";

export const AGETQuestionDifficultySchema = Joi.object({
	courseId: Joi.objectId().required(),
	questionId: Joi.objectId().required(),
});

export interface IAGETQuestionDifficulty {
	courseId: ObjectId;
	questionId: ObjectId;
}

export const RGETQuestionDifficultySchema = QuestionDifficultyStatsSchema;
export type IRGETQuestionDifficulty = IQuestionDifficultyStats;

///

export const AGETMultipleQuestionDifficultiesSchema = Joi.object({
	courseId: Joi.objectId().required(),
	questionIds: Joi.array()
		.items(Joi.objectId().required())
		.required(),
});

export interface IAGETMultipleQuestionDifficulties {
	courseId: ObjectId;
	questionIds: ObjectId[];
}

export const RGETMultipleQuestionDifficultiesSchema = Joi.array().items(
	QuestionDifficultyStatsSchema
);
export type IRGETMultipleQuestionDifficulties = IQuestionDifficultyStats[];
