import {
	FileItemTypes,
	IFileWithFullContent,
	IFile,
} from "@app/api/files/helper-schemas";
import AllFoldersAndItemsPopup, {
	IItems,
	ISelectedItems,
} from "../items-select-popup";
import { IAPOSTCreateFile } from "@app/api/files/validators";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import React from "react";
import TextField from "@material-ui/core/TextField";
import { getFormattedMessage } from "@app/utils/locale";
import { InputStyle } from "@app/components/styles/inputs";
import { FileItems } from "./items";
import { getDefaultItemByType } from "./defaults";
import { PrimaryButton } from "@app/components/styles/buttons";
import { FormattedMessage } from "react-intl";
import GalleryPopup from "../gallery";
import { ItemType } from "@app/api/folders/helper-schemas";
import { mergeRecursive } from "@app/utils/common";
import { normalizeFileItems } from "./normalize";

interface FileProps {
	fileId?: ObjectId;
	folderId: ObjectId;
	courseId: ObjectId;
	onSuccessfulSave: () => void;
}

export type ContentAddArg = {
	type: FileItemTypes.cards | FileItemTypes.questions;
	defaultIds: ({ questionId: ObjectId } | { cardId: ObjectId })[];
	handleConfirm: (selectedItemIds: ObjectId[]) => void;
};

interface IPopupState {
	file?: IAPOSTCreateFile | IFileWithFullContent["file"];
	itemNames: IFileWithFullContent["itemNames"];
	isGalleryPopupOpen: boolean;
	contentAddPopup?: ContentAddArg;
}

export class FileComponent extends React.PureComponent<FileProps, IPopupState> {
	state: IPopupState = {
		file: this.props.fileId
			? undefined
			: {
					name: "",
					folderId: this.props.folderId,
					courseId: this.props.courseId,
					items: [getDefaultItemByType(FileItemTypes.text)],
			  },
		itemNames: {
			questions: {},
			cards: {},
		},
		isGalleryPopupOpen: false,
	};

	_FilesController = inject("FilesController");

	componentDidMount() {
		if (!this.props.fileId) return;
		this._FilesController
			.getWithFullContent({
				_id: this.props.fileId,
				courseId: this.props.courseId,
			})
			.then(data => {
				this.setState({ file: data.file, itemNames: data.itemNames });
			});
	}

	save = () => {
		let file = this.state.file;
		if (!file) return;
		file = { ...file, items: normalizeFileItems(file.items) };
		if (!this.props.fileId) {
			this._FilesController
				.add(file)
				.then(() => this.props.onSuccessfulSave());
		} else if (this.props.fileId) {
			const { name, folderId, items, courseId } = file;
			this._FilesController
				.update({
					_id: this.props.fileId,
					name,
					folderId,
					courseId,
					items,
				})
				.then(() => {
					this.props.onSuccessfulSave();
				});
		}
	};

	handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			file: {
				...this.state.file!,
				name: e.target.value,
			},
		});
	};

	handleItemsChange = (items: IFile["items"]) => {
		this.setState({
			file: {
				...this.state.file!,
				items,
			},
		});
	};

	onUpload?: (path: string) => void;
	onGalleryOpen = (onSuccess: (path: string) => void) => {
		//
		this.onUpload = onSuccess;
		this.setState({
			isGalleryPopupOpen: true,
		});
	};
	onGalleryClose = () => {
		this.setState({
			isGalleryPopupOpen: false,
		});
	};

	onContentAddOpen = (arg: ContentAddArg) => {
		this.setState({ contentAddPopup: arg });
	};

	onContentAddClose = () => {
		this.setState({ contentAddPopup: undefined });
	};

	addQuestionOrCardItems = (
		selectedItems: NonNullable<ISelectedItems>,
		itemNames: IItems
	) => {
		const { contentAddPopup } = this.state;
		if (!contentAddPopup) return;
		const modifiesItemNames = {} as IPopupState["itemNames"];
		Object.keys(itemNames).forEach(key => {
			modifiesItemNames[key] = {};
			Object.keys(itemNames[key]).forEach(k => {
				modifiesItemNames[key][k] = itemNames[key][k].name;
			});
		});
		const names = mergeRecursive(this.state.itemNames, modifiesItemNames);
		const selectedItemIds: ObjectId[] = [];
		const { type } = contentAddPopup;
		if (type !== FileItemTypes.questions && type !== FileItemTypes.cards)
			return;
		Object.keys(selectedItems).forEach(folderId => {
			const questionIds = selectedItems[folderId].questions;
			const cardIds = selectedItems[folderId].cards;
			if (questionIds && type === FileItemTypes.questions) {
				selectedItemIds.push(
					...Object.keys(questionIds).filter(id => questionIds[id])
				);
			}
			if (cardIds && type === FileItemTypes.cards) {
				selectedItemIds.push(
					...Object.keys(cardIds).filter(id => cardIds[id])
				);
			}
		});
		this.setState(
			{
				itemNames: names,
				contentAddPopup: undefined,
			},
			() => {
				contentAddPopup.handleConfirm(selectedItemIds);
			}
		);
	};

	render() {
		const { file, itemNames } = this.state;
		if (!file || !itemNames) {
			return null;
		}
		return (
			<div>
				<TextField
					label={getFormattedMessage("inputs.name")}
					defaultValue={file.name}
					onChange={this.handleNameChange}
					margin="normal"
					variant="outlined"
					className={InputStyle}
				/>
				<FileItems
					items={file.items}
					onChange={this.handleItemsChange}
					itemNames={itemNames}
					onGalleryOpen={this.onGalleryOpen}
					onContentAddOpen={this.onContentAddOpen}
				/>

				<PrimaryButton onClick={this.save}>
					<FormattedMessage id="save" />
				</PrimaryButton>
				{this.state.isGalleryPopupOpen && (
					<GalleryPopup
						courseId={this.props.courseId}
						onClose={this.onGalleryClose}
						onUpload={this.onUpload!}
					/>
				)}
				{this.state.contentAddPopup && (
					<AllFoldersAndItemsPopup
						courseId={this.props.courseId}
						folderId={this.props.folderId}
						onClose={this.onContentAddClose}
						displayItemTypes={
							this.state.contentAddPopup.type ===
							FileItemTypes.questions
								? [ItemType.question]
								: [ItemType.card]
						}
						onSave={this.addQuestionOrCardItems}
						defaultSelectedItems={
							this.state.contentAddPopup.defaultIds
						}
						getItemNames={true}
					/>
				)}
			</div>
		);
	}
}
