import * as React from "react";
import Popup, { PopupContent } from "../widgets/Popup";
import styled from "@emotion/styled";
import { borderedInput } from "../styles/inputs";
import { ObjectId } from "@app/utils/generics";
import { smallSelect } from "../styles/selects";
import { inject } from "@app/modules";
import { FormattedMessage } from "react-intl";

interface IOwnProps {
	courseId: ObjectId;
	onClose: () => void;
}
type IProps = IOwnProps;

interface IState {
	messagePattern: string;
	lateMessagePattern: string;
	hour: number;
	minute: number;
	action: "add" | "save";
}

class CourseReminder extends React.Component<IProps, IState> {
	state: IState = {
		messagePattern: "",
		lateMessagePattern: "",
		hour: 19,
		minute: 0,
		action: "add",
	};

	_CoursesController = inject("CoursesController");

	hours = new Array(5).fill(0).map((e, i) => i + 15);
	minutes = new Array(12).fill(0).map((e, i) => i * 5);

	changeMessagePattern = (field: "messagePattern" | "lateMessagePattern") => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		if (field === "messagePattern")
			this.setState({ messagePattern: e.target.value });
		else this.setState({ lateMessagePattern: e.target.value });
	};

	saveReminder = () => {
		if (this.state.action === "save") {
			this._CoursesController
				.updateReminder({
					courseId: this.props.courseId,
					messagePattern: this.state.messagePattern,
					lateMessagePattern: this.state.lateMessagePattern,
					hour: this.state.hour,
					minute: this.state.minute,
				})
				.then(() => this.props.onClose());
			return;
		}
		this._CoursesController
			.createReminder({
				courseId: this.props.courseId,
				messagePattern: this.state.messagePattern,
				lateMessagePattern: this.state.lateMessagePattern,
				hour: this.state.hour,
				minute: this.state.minute,
			})
			.then(() => this.props.onClose());
		return;
	};

	onHourChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		this.setState({
			hour: +e.target.value,
		});
	};

	onMinuteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		this.setState({
			minute: +e.target.value,
		});
	};

	render() {
		return (
			<Popup onClose={this.props.onClose}>
				<PopupContent>
					<Container>
						<p>
							*user_name* - ადგილი, სადაც მომხმარებლის სახელი უნდა
							ჩაისვას
						</p>
						<p>
							*topic_name* - ადგილი, სადაც თემის სახელი უნდა
							ჩაისვას
						</p>
						<p>*link* - ადგილი, სადაც თემის ლინკი უნდა ჩაისვას</p>
						<input
							className={borderedInput}
							placeholder="შეიყვანეთ სტანდარტული შეტყობინება"
							value={this.state.messagePattern}
							onChange={this.changeMessagePattern(
								"messagePattern"
							)}
						/>
						<input
							className={borderedInput}
							placeholder="შეიყვანეთ ჩამორჩენის შეტყობინება"
							value={this.state.lateMessagePattern}
							onChange={this.changeMessagePattern(
								"lateMessagePattern"
							)}
						/>
						<select
							className={smallSelect}
							value={this.state.hour}
							onChange={this.onHourChange}
						>
							{this.hours.map((hour, i) => (
								<option key={i} value={hour}>
									{hour} საათი
								</option>
							))}
						</select>
						<select
							className={smallSelect}
							value={this.state.minute}
							onChange={this.onMinuteChange}
						>
							{this.minutes.map((minute, i) => (
								<option key={i} value={minute}>
									{minute} წუთი
								</option>
							))}
						</select>
						<div>
							<ReminderButton onClick={this.saveReminder}>
								<FormattedMessage id="save" />
							</ReminderButton>
						</div>
					</Container>
				</PopupContent>
			</Popup>
		);
	}

	componentDidMount() {
		this._CoursesController
			.getReminder({ courseId: this.props.courseId })
			.then(data => {
				if (!data) return;
				this.setState({
					messagePattern: data.messagePattern,
					lateMessagePattern: data.lateMessagePattern,
					hour: data.hour,
					minute: data.minute,
					action: "save",
				});
			})
			.catch(err => console.log(err));
	}
}

const Container = styled.div`
	max-width: 600px;
	width: calc(100vw - 10px);
	background-color: white;
	border-radius: 10px;
	padding: 10px;
`;

export const ReminderButton = styled.button`
	width: 200px;
	height: 40px;
	font-family: "BPGNinoMtavruli";
	font-size: 18px;
	margin-right: 10px;
	background-color: #ff8500;
	border: 1px solid #ff8500;
	border-radius: 7px;
	color: white;
	transition: all 0.2s;
	cursor: pointer;

	&:hover {
		background-color: white;
		color: #ff8500;
	}
`;

export default CourseReminder;
