import * as React from "react";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import InputForDate from "./date";
import InputForNumber from "./number";
import InputForText from "./text";
import Select from "react-select";
import { AnyComponent } from "../../../utils/generics";
import {
	ICardProperty,
	IEntry,
	IEntryValue,
	IValue,
} from "../../../schemas/cards";
import { PropertyType } from "../../../schemas/cards/templates";
import { removeKeys } from "../../../utils/common";

export function getEmptyValue(type: PropertyType): IValue {
	switch (type) {
		case PropertyType.Number:
			return 0;
		case PropertyType.Text:
			return "";
		case PropertyType.HistDate:
			return { year: 0 };
		default:
			throw new Error(`type: ${type} is not supported`);
	}
}

export function getComponentForType(type: PropertyType) {
	switch (type) {
		case PropertyType.Number:
			return InputForNumber;
		case PropertyType.Text:
		case PropertyType.TextArray:
			return InputForText;
		case PropertyType.HistDate:
			return InputForDate;
		default:
			throw new Error(`type ${type} is not supported yet`);
	}
}

interface IGeneralInput {
	entry: IEntry;
	type: PropertyType;
	component: AnyComponent<{ val: IValue; onChange: (val: IValue) => void }>;
	propId: number;
	onChange: (newProp: ICardProperty) => void;
}

export default class GeneralInput extends React.PureComponent<IGeneralInput> {
	preciseOptions = [
		{ value: 1, label: "Precise" },
		{ value: 2, label: "Interval" },
		{ value: 3, label: "Alternatives" },
	];

	onValueChange = (val: IEntryValue) => {
		this.props.onChange({
			id: this.props.propId,
			entries: {
				...this.props.entry!,
				val,
			} as IEntry,
		});
	};

	onChange = (value: IValue, index?: number) => {
		if (index === undefined) {
			this.onValueChange(value);
			return;
		}
		this.onValueChange(
			(this.props.entry.val as IValue[]).map((val, i) =>
				i === index ? value : val
			)
		);
	};

	onAlternativesAdd = () => {
		this.onValueChange([
			...(this.props.entry.val as IValue[]),
			getEmptyValue(this.props.type),
		]);
	};

	onPrecisenessChange = (select: { value: number; label: string }) => {
		const { entry } = this.props;
		if (!entry || Array.isArray(entry)) return; // TODO:
		if (!entry.isNotPrecise) {
			if (select.value === 2) {
				// interval
				this.props.onChange({
					id: this.props.propId,
					entries: {
						...entry,
						isNotPrecise: true as true,
						isInterval: true as true,
						val: [entry.val, entry.val] as [IValue, IValue],
					} as ICardProperty["entries"],
				});
			} else if (select.value === 3) {
				// alternatives
				this.props.onChange({
					id: this.props.propId,
					entries: {
						...entry,
						isNotPrecise: true as true,
						isInterval: false as false,
						val: [entry.val] as [IValue],
					} as ICardProperty["entries"],
				});
			}
		} else if (select.value === 1) {
			// precise
			this.props.onChange({
				id: this.props.propId,
				entries: {
					...removeKeys(entry, "isNotPrecise", "isInterval"),
					val: (entry.val as IValue[])[0],
				},
			});
		} else if (select.value === 2) {
			// interval
			this.props.onChange({
				id: this.props.propId,
				entries: {
					...entry,
					isNotPrecise: true as true,
					isInterval: true as true,
					val: [entry.val[0], entry.val[1]] as [IValue, IValue],
				} as ICardProperty["entries"],
			});
		} else if (select.value === 3) {
			// alternatives
			this.props.onChange({
				id: this.props.propId,
				entries: {
					...entry,
					isNotPrecise: true,
					isInterval: false,
					val: entry.val,
				} as ICardProperty["entries"],
			});
		}
	};

	render() {
		let selectedPrecisenessValue: undefined | number;

		const { entry } = this.props;
		if (entry === undefined) return null;
		if (!Array.isArray(entry)) {
			if (!entry.isNotPrecise) {
				selectedPrecisenessValue = 1;
			} else if (entry.isInterval) {
				selectedPrecisenessValue = 2;
			} else selectedPrecisenessValue = 3;
		}

		const selectedPreciseness = this.preciseOptions.find(
			e => e.value === selectedPrecisenessValue
		);

		const s = (
			<Select
				value={selectedPreciseness}
				options={this.preciseOptions}
				onChange={this.onPrecisenessChange}
			/>
		);
		const InputComponent = this.props.component;
		if (!this.props.entry.isNotPrecise) {
			return (
				<>
					{s}
					<InputComponent
						val={this.props.entry.val}
						onChange={this.onChange}
					/>
				</>
			);
		}
		if (this.props.entry.isNotPrecise) {
			return (
				<>
					{s}
					{this.props.entry.val.map((x, i) => (
						<InputComponent
							key={i}
							val={this.props.entry.val[i]}
							onChange={v => this.onChange(v, i)}
						/>
					))}
					<div
						style={{
							display: "inline-block",
							verticalAlign: "middle",
							marginTop: 3,
						}}
					>
						{!this.props.entry.isInterval && (
							<AddIcon onClick={this.onAlternativesAdd} />
						)}
					</div>
				</>
			);
		}
		return null;
	}
}
