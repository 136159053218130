import {
	IADELETETaskType,
	IAGETALLCourseTaskTypes,
	IAGETTaskType,
	IAGETTaskTypeLevels,
	IAPOSTCreateTaskType,
	IAPUTTaskType,
	IRGETAllTaskTypes,
	IRGETTaskType,
	IRGETTaskTypeLevels,
	RGETAllTaskTypesSchema,
	IRPOSTCreateTaskType,
	RGETTaskTypeLevelsSchema,
	RGETTaskTypeSchema,
	RPOSTCreateTaskTypeSchema,
	IAGETTaskTypeHierarchy,
	IRGETTaskTypeHierarchy,
	RGETTaskTypeHierarchySchema,
	IAGETTaskTypesByQuestion,
	IRGETTaskTypesByQuestion,
	IAPUTAddItemsToTaskType,
	IRPUTAddItemsToTaskType,
	IAPUTRemoveItemsFromTaskType,
	IRPUTRemoveItemsFromTaskType,
} from "./validators";
import { IRequest } from "../requests";
import { inject } from "@app/modules";
import { generateFakeObjectId } from "@app/utils/common";
import { PromisesKeeperAPI } from "../promises-keeper";
import { ObjectId } from "@app/utils/generics";
import { TaskType } from "@app/models/task-type";
import { UserTaskTypeLevel } from "@app/models/user-task-type-level";
import { TaskTypeHierarchy } from "@app/models/task-type-hierarchy";

export class TaskTypesController {
	private readonly Request: IRequest;

	private readonly _TaskTypeModel = inject("TaskTypeModel");
	private readonly _UserTaskTypeLevelModel = inject("UserTaskTypeLevelModel");
	private readonly _TaskTypeHierarchyModel = inject("TaskTypeHierarchyModel");
	private readonly _TaskTypeHierarchyService = inject(
		"TaskTypeHierarchyService"
	);

	private taskTypePromises = new PromisesKeeperAPI<ObjectId, TaskType>();
	private userTaskTypeLevelPromises = new PromisesKeeperAPI<
		ObjectId,
		UserTaskTypeLevel
	>();

	private readonly assertAndGetCoursesUser = inject(
		"assertAndGetCoursesUser"
	);

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = (args: IAPOSTCreateTaskType): Promise<TaskType> =>
		this.Request.send("POST", "/api/task-types", args, null, {
			responseSchema: RPOSTCreateTaskTypeSchema,
		}).then((data: IRPOSTCreateTaskType) => {
			const taskType = this._TaskTypeModel.loadOneSync(data); // taskType must be loaded first
			if (args.parentId) {
				this._TaskTypeHierarchyService.setItemParentSync(
					args.originalCourseId,
					data._id,
					args.parentId
				);
			}
			return taskType;
		});

	update = (args: IAPUTTaskType): Promise<TaskType | null> =>
		this.Request.send("PUT", "/api/task-types/:_id", args).then(() =>
			this._TaskTypeModel.updateOneSync({ _id: args._id }, args)
		);

	getAllByCourseId = async (
		args: IAGETALLCourseTaskTypes,
		loadFresh?: boolean
	): Promise<TaskType[]> => {
		if (!loadFresh) {
			const taskTypeHierarchy = this._TaskTypeHierarchyModel.findOneByCourseSync(
				args.courseId
			);
			if (taskTypeHierarchy) {
				const taskTypeIds = this._TaskTypeHierarchyService.getAllItemIdsSync(
					args.courseId
				);
				const taskTypes = this._TaskTypeModel.findManyByIdsSync(
					taskTypeIds
				);
				if (taskTypeIds.length === taskTypes.length) {
					return taskTypes;
				}
			}
		}
		return this.taskTypePromises.getOrSetPromise(
			Symbol.for(args.courseId),
			() => {
				return this.Request.send(
					"GET",
					"/api/task-types/by-course-id",
					args,
					null,
					{
						responseSchema: RGETAllTaskTypesSchema,
					}
				).then((data: IRGETAllTaskTypes) => {
					this._TaskTypeModel.meta.updateLoadTime(args.courseId);
					return this._TaskTypeModel.loadManySync(data);
				});
			}
		);
	};

	getByItem = async (
		args: IAGETTaskTypesByQuestion
	): Promise<IRGETTaskTypesByQuestion> =>
		this.Request.send("GET", "/api/task-types/by-content", args).then(
			(data: IRGETTaskTypesByQuestion) => data
		);

	getById = async (
		args: IAGETTaskType,
		loadFresh?: boolean
	): Promise<TaskType> => {
		if (!loadFresh) {
			const taskType = this._TaskTypeModel.findByIdSync(args._id);
			if (taskType) return taskType;
		}
		return this.taskTypePromises.getOrSetPromise(args._id, () =>
			this.Request.send("GET", "/api/task-types/:_id", args, null, {
				responseSchema: RGETTaskTypeSchema,
			}).then((data: IRGETTaskType) => {
				return this._TaskTypeModel.loadOneSync(data);
			})
		);
	};

	getHierarchy = async (
		args: IAGETTaskTypeHierarchy,
		loadFresh?: boolean
	): Promise<TaskTypeHierarchy> => {
		if (!loadFresh) {
			const hierarchy = this._TaskTypeHierarchyModel.findOneByCourseSync(
				args.courseId
			);
			if (hierarchy) return hierarchy;
		}
		return this.Request.send(
			"GET",
			"/api/task-types/hierarchy",
			args,
			null,
			{
				responseSchema: RGETTaskTypeHierarchySchema,
			}
		).then((data: IRGETTaskTypeHierarchy) => {
			return this._TaskTypeHierarchyModel.loadOneSync(data);
		});
	};

	getLevels = async (
		args: IAGETTaskTypeLevels,
		loadFresh?: boolean,
		forceLoadingIfNeedsRecalculation?: boolean
	): Promise<UserTaskTypeLevel> => {
		const user = this.assertAndGetCoursesUser();
		const userId = user.id;
		if (!loadFresh) {
			const levelsDoc = this._UserTaskTypeLevelModel.findOneSync({
				userId,
				courseId: args.courseId,
			});
			if (levelsDoc) {
				if (!levelsDoc.needsRecalculation) return levelsDoc;
				if (forceLoadingIfNeedsRecalculation) {
					return this.getLevels(args, true);
				}
				this.getLevels(args, true).then();
				return levelsDoc;
			}
		}
		return this.userTaskTypeLevelPromises.getOrSetPromise(
			`${userId}-${args.courseId}`,
			() =>
				this.Request.send(
					"GET",
					"/api/task-types/levels",
					args,
					undefined,
					{
						responseSchema: RGETTaskTypeLevelsSchema,
					}
				)
					.then((data: IRGETTaskTypeLevels) => {
						return this._UserTaskTypeLevelModel.loadOneSync(data);
					})
					.catch(e => {
						if (!e.response || e.response.status !== 404) {
							throw e;
						}
						const emptyData: IRGETTaskTypeLevels = {
							_id: generateFakeObjectId(),
							userId,
							courseId: args.courseId,
							levelsByTaskTypeId: {},
						};
						return this._UserTaskTypeLevelModel.loadOneSync(
							emptyData
						);
					})
		);
	};

	deleteById = (args: IADELETETaskType): Promise<void> =>
		this.Request.send("DELETE", "/api/task-types/:_id", args).then(() => {
			this._TaskTypeHierarchyService.onItemDeleteSync(
				args.originalCourseId,
				args._id
			);
		});

	addItems = (
		args: IAPUTAddItemsToTaskType
	): Promise<IRPUTAddItemsToTaskType> =>
		this.Request.send("PUT", "/api/task-types/:labelId/add-items", args);

	removeItems = (
		args: IAPUTRemoveItemsFromTaskType
	): Promise<IRPUTRemoveItemsFromTaskType> =>
		this.Request.send("PUT", "/api/task-types/:labelId/remove-items", args);
}
