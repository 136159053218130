import * as React from "react";
import AdminTable from "@app/components/widgets/admin-table";
import memoizeOne from "memoize-one";
import { connect } from "react-redux";
import { History } from "history";
import { IRootState } from "@app/redux";
import { ObjectId } from "@app/utils/generics";
import { PrimaryButton } from "../styles/buttons";
import { inject } from "@app/modules";
import { IStateCardTemplates } from "@app/models/card-template";

interface IOwnProps {
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IOwnProps;

class AllCardTemplates extends React.Component<IProps> {
	_isMounted: boolean = false;

	getSortedCardTemplates = memoizeOne((templates: IStateCardTemplates) => {
		return Object.keys(this.props.cardTemplates)
			.map(_id => this.props.cardTemplates[_id]!.info)
			.sort((a, b) => b._id.localeCompare(a._id));
	});

	_CardTemplatesController = inject("CardTemplatesController");

	componentDidMount() {
		this._isMounted = true;
		this._CardTemplatesController.getAll();
	}

	addCardTemplate = () =>
		this.props.history.push(`/admin/card-templates/add`);

	redirectTo = (id: ObjectId) =>
		this.props.history.push(`/admin/card-templates/${id}/edit`);

	onDelete = (id: ObjectId) => {
		this._CardTemplatesController.deleteById({ _id: id });
	};

	render() {
		if (!this.props.cardTemplates) {
			return null;
		}

		const cardTemplates = this.getSortedCardTemplates(
			this.props.cardTemplates
		);
		return (
			<div className="main">
				<AdminTable
					rows={cardTemplates}
					redirectTo={this.redirectTo}
					onDelete={this.onDelete}
				/>
				<PrimaryButton onClick={this.addCardTemplate}>
					ბარათის თარგის დამატება
				</PrimaryButton>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	cardTemplates: state.cardTemplates,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	AllCardTemplates
);
