/* eslint-disable no-restricted-globals */
import * as React from "react";
import SvgLoading from "../styles/img/loading";
import TestPage, { IFinishComponentProps } from "./test";
import { connect } from "react-redux";
import { IAnsweredQuestion } from "@app/api/modeling/validators";
import {
	IFullQuestion,
	IShortQuestion,
} from "@tests-core/schemas/questions/helper-schemas";
import { IModelingTest } from "@app/api/modeling/helper-schemas";
import { IText } from "@tests-core/schemas/texts/helper-schemas";
import { IUserAnswer } from "@tests-core/schemas/questions/contnets/ans-schema";
import { match } from "react-router-dom";
import { ObjectId } from "@app/utils/generics";
import { PrimaryButton } from "../styles/buttons";
import { TestQuestion } from "../users/tests/old-test-navigation";
import { getQueryStringParams } from "@app/commonJavascript";
import Axios from "axios";
import { inject } from "@app/modules";

interface IOwnProps {
	match: match<{ modellingId: ObjectId }>;
}

enum Mode {
	beginning,
	test,
	review,
	end,
}

interface IState {
	mode: Mode;
	loading: boolean;
	questions?: IShortQuestion[] | IFullQuestion[];
	texts?: IText[];
	test?: IModelingTest;
	defaultCheckedAnswers?: { id: ObjectId; userAnswer: IUserAnswer }[];
}

class OfflineModelling extends React.Component<IOwnProps, IState> {
	testId = +this.props.match.params.modellingId;

	state: IState = {
		mode: Mode.beginning,
		loading: true,
	};

	beginTestCounter = 0;

	_ModelingTestsController = inject("ModelingController");

	componentDidMount() {
		this._ModelingTestsController
			.getOffline({ testId: this.testId })
			.then(test => {
				this.setState({
					loading: false,
					test,
				});
				this.onBeginTest();
			})
			.catch(e => {
				alert("დაფიქსირდა შეცდომა");
			});
	}

	onBeginTest = () => {
		this.beginTestCounter++;
		if (this.beginTestCounter > 5) return;
		this.setState({
			loading: true,
		});
		this._ModelingTestsController
			.beginOffline({ testId: this.testId })
			.then(data => {
				this.setState({
					mode: Mode.test,
					questions: data.questions as IFullQuestion[],
					texts: data.texts,
					loading: false,
					defaultCheckedAnswers: undefined,
				});
			})
			.catch(e => {
				setTimeout(this.onBeginTest, 100);
			});
	};

	onFinish = (answers: IAnsweredQuestion[], totalScore: number) => {
		this.setState({
			loading: false,
			mode: Mode.review,
		});
		try {
			const { code } = getQueryStringParams(location.search);
			Axios.get(
				`https://murtsku.com/php/tou-offline-modelling-score.php?subj=8&score=${totalScore}&code=${code}`,
				{
					crossdomain: true,
					crossorigin: true,
					headers: {
						"Access-Control-Allow-Origin": "*",
					},
				} as any
			)
				.then(console.log)
				.catch(console.error);
		} catch (e) {
			//
		}
	};

	finishComponent = (props: IFinishComponentProps) => {
		const unAnsweredQuestionIndices: number[] = [];
		for (let i = 0; i < props.questionsLength; i++) {
			if (
				props.userAnswers[i] === undefined ||
				props.userAnswers[i] === null
			) {
				unAnsweredQuestionIndices.push(i);
			}
		}
		return (
			<div style={{ textAlign: "center" }}>
				ნამდვილად გსურთ ტესტის დასრულება?
				<br />
				{unAnsweredQuestionIndices.length >= 1 && (
					<div>
						თქვენ არ გიპასუხიათ შემდეგი{" "}
						{unAnsweredQuestionIndices.length === 1
							? "კითხვისთვის"
							: "კითხვებისთვის"}
						:
						<div style={{ textAlign: "center" }}>
							{unAnsweredQuestionIndices.map(e => (
								<TestQuestion
									key={e}
									index={e}
									isAccessable={true}
									isSelected={false}
									onQuestionSelect={props.onQuestionSelect}
								>
									{e + 1}
								</TestQuestion>
							))}
						</div>
					</div>
				)}
				<PrimaryButton onClick={props.onFinish}>
					დასრულება
				</PrimaryButton>
			</div>
		);
	};

	reviewComponent = (props: IFinishComponentProps) => {
		console.log(props);
		const totalScore = props.totalScore;
		return (
			<div style={{ textAlign: "center" }}>
				თქვენ დაასრულეთ მოდელირების წერა <br />
				ჯამური ქულა: <b>{totalScore}</b> <br />
				შეგიძლიათ, დააკლიკოთ კითხვის ნომერს და ნახოთ პასუხები
			</div>
		);
	};

	modelingIsFinished = () => {
		if (!this.state.test) throw new Error();
		if (this.state.test.finish_time.getTime() < Date.now()) return true;
		return false;
	};

	canReviewNow = () => {
		if (!this.state.test) throw new Error();
		if (!this.state.test.review_start_time) return false;
		if (this.state.test.review_start_time.getTime() > Date.now()) {
			return false;
		}
		if (!this.state.test.review_finish_time) return true;
		return this.state.test.review_finish_time.getTime() > Date.now();
	};

	render() {
		if (this.state.loading || !this.state.test) {
			return (
				<div style={{ textAlign: "center" }}>
					<SvgLoading />
				</div>
			);
		}
		if (this.state.mode === Mode.beginning) {
			if (this.modelingIsFinished()) {
				if (!this.canReviewNow()) {
					return (
						<div style={{ textAlign: "center" }} className="main">
							<h2>მოდელირება დასრულებულია</h2>
						</div>
					);
				}
				return (
					<div
						style={{ textAlign: "center", fontFamily: "FiraGo" }}
						className="main"
					>
						<h2>მოდელირების გადახედვა შეუძლებელია</h2>
					</div>
				);
			}
			return (
				<div style={{ textAlign: "center" }} className="main">
					<h1>{this.state.test.name}</h1>
					გთხოვთ, გაითვალისწინოთ, რომ ონლაინ მოდელირების დროს ღია
					კითხვების პასუხების ზუსტი შემოწმება შეუძლებელია, რის გამოც
					ტესტში არ შეგხვდებათ არგუმენტაციის დავალება, ხოლო 2
					სავარჯიშოში - ისტორიული წყაროს ანალიზი და ისტორიული რუკის
					ანალიზი - კითხვები წარმოდგენილია არასრულად.
					<br />
					შესაბამისად, ტესტის ჯამური ქულა შეადგენს არა 70-ს, არამედ 59
					ქულას.
					<br />
					<PrimaryButton onClick={this.onBeginTest}>
						დაწყება
					</PrimaryButton>
				</div>
			);
		}
		if (this.state.mode === Mode.end) {
			return (
				<div style={{ textAlign: "center" }} className="main">
					<img
						src="https://murtsku.com/css/img/tou/logo-085.jpg"
						style={{
							height: 80,
							display: "block",
							margin: "0 auto",
						}}
						alt="logo"
					/>
					<br />
					ტესტირება დასრულებულია. თქვენს შედეგს მიიღებთ მოკლე ტექსტური
					შეტყობინების სახით 1 კვირის ვადაში.
				</div>
			);
		}
		if (!this.state.questions || !this.state.texts) {
			return (
				<div style={{ textAlign: "center" }}>
					<SvgLoading />
				</div>
			);
		}
		return (
			<>
				<br />
				<img
					src="https://murtsku.com/css/img/tou/logo-085.jpg"
					style={{ height: 80, display: "block", margin: "0 auto" }}
					alt="logo"
				/>
				<br />
				<TestPage
					allowSwitchingBetweenQuestions={true}
					showAnswersAfterAnswering={false}
					onFinish={this.onFinish}
					questions={this.state.questions}
					texts={this.state.texts}
					finishComponent={
						this.state.mode === Mode.test
							? this.finishComponent
							: this.reviewComponent
					}
					hideAnswers={this.state.mode === Mode.test}
					defaultCheckedAnswers={this.state.defaultCheckedAnswers}
					isFinished={this.state.mode === Mode.review}
					generalShuffleKey={324235}
				/>
				<br />
				<br />
				<br />
			</>
		);
		// return null;
	}
}

export default connect<null, null, IOwnProps>(null, null)(OfflineModelling);
