import { JoiMetaInfo } from "m-model-joi";
import { IStorage } from "m-model-common";
import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export class CommonMetaInfo extends JoiMetaInfo<ICommonMetaInfo> {
	constructor(storage: IStorage, storageKey: string) {
		super(emptyMetaData, storage, storageKey, CommonMetaInfoSchema);
	}

	clear() {
		this.replaceData(emptyMetaData);
	}

	updateLoadTime() {
		this.setItem("lastFullLoadTime", new Date());
	}
}

const CommonMetaInfoSchema = Joi.object({
	lastFullLoadTime: Joi.date(),
});

interface ICommonMetaInfo {
	lastFullLoadTime?: Date;
}

const emptyMetaData: ICommonMetaInfo = {};

///

export class CourseCommonMetaInfo extends JoiMetaInfo<ICourseCommonMetaInfo> {
	constructor(storage: IStorage, storageKey: string) {
		super(
			emptyCourseMetaData,
			storage,
			storageKey,
			CourseCommonMetaInfoSchema
		);
	}

	clear() {
		this.replaceData(emptyCourseMetaData);
	}

	updateLoadTime(courseId: ObjectId) {
		this.setItem("loadTimesByCourses", {
			...this.data.loadTimesByCourses,
			[courseId]: new Date(),
		});
	}
}

const CourseCommonMetaInfoSchema = Joi.object({
	loadTimesByCourses: Joi.object()
		.pattern(/[\da-f]+/, Joi.date())
		.required(),
});

interface ICourseCommonMetaInfo {
	loadTimesByCourses: Record<string, Date>;
}
const emptyCourseMetaData: ICourseCommonMetaInfo = {
	loadTimesByCourses: {},
};
