import { ObjectId } from "@app/utils/generics";
import Joi from "@app/utils/joi";
///
import { store } from "index";
import {
	filterByLoadTime,
	getDefaultReducer,
	listenToLocalStorageChange,
	loadFromStorage,
	getDefaultStorageSettings,
} from "m-model-common";
import { createCRUDActionTypes, createModel, RawInstances } from "m-model-core";
import { validateStorage, getJoiObjectKeys } from "m-model-joi";
import { MAX_LOAD_TIME_DIFF, MIN_LOAD_TIME } from "./constants";
import { CourseCommonMetaInfo } from "./meta-info";

const LastOptionSchema = Joi.object({
	courseId: Joi.objectId().required(),
	selections: Joi.object().pattern(
		/\d/,
		Joi.array().items(
			Joi.object({
				id: Joi.objectId().required(),
				type: Joi.number().required(),
			})
		)
	),
	filterShuffleKey: Joi.number().allow(null),
});
interface ILastOption {
	courseId: ObjectId;
	selections?: Record<
		any,
		{
			id: ObjectId;
			type: number;
		}[]
	>;
	filterShuffleKey?: number | null;
}

const keyOfId = "courseId";
type IdKey = typeof keyOfId;
type DOC = ILastOption;
export type IStateLastOptions = RawInstances<IdKey, DOC>;

// ==============Base Model=================

const dockeys = getJoiObjectKeys<DOC>(LastOptionSchema);
const storage = localStorage;
const actionTypes = createCRUDActionTypes("last_option");
const storageSettings = getDefaultStorageSettings("lastOptions");
const metaInformationName = "lastOptionsMetaInformation";

const isLoadedRecentlyEnough = filterByLoadTime(
	MAX_LOAD_TIME_DIFF,
	MIN_LOAD_TIME
);

const Model = createModel<IdKey, DOC>({
	keyOfId,
	getInstances: (() => store.getState().lastOptions) as any,
	dispatch: (action => store.dispatch(action)) as any,
	subscribe: (listener => store.subscribe(listener)) as any,
	actionTypes,
	dockeys,
	loadInstancesFromStorage: () =>
		loadFromStorage({
			storage,
			key: storageSettings.itemName,
			validateWholeData: validateStorage("ObjectId", LastOptionSchema),
			filter: isLoadedRecentlyEnough,
		}),
});

// ==============Main Model=================

export class LastOption extends Model {
	static initialize() {
		const info = super.initialize();
		if (info.loadedAll) this.meta.initialize();
		else this.meta.clear();
		return info;
	}

	static setValue<K extends keyof DOC>(
		courseId: ObjectId,
		key: K,
		value: DOC[K]
	) {
		const doc = this.findByIdSync(courseId);
		if (doc) {
			doc[key] = value as any;
			doc.saveSync();
		} else {
			this.loadOneSync({
				courseId,
				[key]: value,
			});
		}
	}

	static meta = new CourseCommonMetaInfo(storage, metaInformationName);
}

export type ILastOptionInstance = LastOption;
export type ILastOptionModel = typeof LastOption;

// ==============ETC=================

listenToLocalStorageChange(storage, metaInformationName, LastOption.meta);

export const lastOptionsReducer = getDefaultReducer(
	storageSettings,
	() => LastOption
);
