import { inject } from "@app/modules";
import { IItemProgress } from "@app/api/folders/helper-schemas";
import { ObjectId, WithKnownKeyType } from "@app/utils/generics";
import { useModelDocByQuery, useModelDocsByQuery } from "m-model-react";
import { useMemo, useEffect } from "react";
import { getResourceLoadingInfo, IResourceLoadingInfo } from "./fetch";
import { useDynamicRef } from "./general";
import { UserFolderProgress } from "@app/models/user-folder-progress";
import { arrayToObject } from "@app/utils/common";

interface UseFolderInfoArgs {
	coursesUserId: number | null;
	folderId: ObjectId | null;
	courseId: ObjectId | null;
}

export function useFolderProgress({
	coursesUserId,
	folderId,
	courseId,
}: UseFolderInfoArgs): IResourceLoadingInfo<UserFolderProgress, undefined> {
	const query = useMemo(
		() =>
			!coursesUserId || !folderId || !courseId
				? null
				: {
						userId: coursesUserId,
						folderId,
						courseId,
				  },
		[courseId, coursesUserId, folderId]
	);
	const folderProgress = useModelDocByQuery(
		inject("UserFolderProgressModel"),
		query
	);
	const needsRecalculationRef = useDynamicRef(
		folderProgress ? folderProgress.needsRecalculation : null
	);
	useEffect(() => {
		if (!needsRecalculationRef.current) return;
		setTimeout(() => {
			if (
				!needsRecalculationRef.current ||
				!courseId ||
				!folderId ||
				!coursesUserId
			) {
				return;
			}
			const service = inject("UserProgressCalculationService");
			try {
				service.recalculateUserFolderProgressSync(
					courseId,
					folderId,
					coursesUserId
				);
			} catch (e) {
				console.error(e);
			}
		}, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [courseId, coursesUserId, folderId, needsRecalculationRef.current]);
	return useMemo(() => {
		return getResourceLoadingInfo<UserFolderProgress, undefined>({
			resource: folderProgress,
			error: null,
			loadAgain: undefined,
			isIdentificationKnown: !!(coursesUserId && folderId && courseId),
		});
	}, [courseId, coursesUserId, folderId, folderProgress]);
}

interface UseItemProgressArgs<T> {
	coursesUserId: number | null;
	parentFolderId: ObjectId | null;
	courseId: ObjectId | null;
	itemId: IItemProgress["id"] | null;
	itemType: T | null;
}
export function useItemProgress<T extends IItemProgress["type"]>({
	coursesUserId,
	parentFolderId,
	courseId,
	itemId,
	itemType,
}: UseItemProgressArgs<T>):
	| WithKnownKeyType<IItemProgress, "type", T>
	| undefined {
	const parentFolderProgress = useFolderProgress({
		coursesUserId,
		folderId: parentFolderId,
		courseId,
	});
	return useMemo(() => {
		if (!parentFolderProgress.isSuccessfullyLoaded) return undefined;
		if (!itemId || !itemType) return undefined;
		const itemProgress = parentFolderProgress.doc.itemsProgress.find(
			itemProg => itemProg.id === itemId && itemProg.type === itemType
		);
		return itemProgress as
			| WithKnownKeyType<IItemProgress, "type", T>
			| undefined;
	}, [itemId, itemType, parentFolderProgress]);
}

export const useUserCourseFolderProgresses = (
	coursesUserId: number | null,
	courseId: ObjectId | null
) => {
	const query = useMemo(
		() =>
			!coursesUserId || !courseId
				? null
				: {
						userId: coursesUserId,
						courseId,
				  },
		[courseId, coursesUserId]
	);
	const userDocs = useModelDocsByQuery(
		inject("UserFolderProgressModel"),
		query
	);
	const docsByFolderIds = useMemo(
		() => (!userDocs ? null : arrayToObject(userDocs, "folderId")),
		[userDocs]
	);
	return docsByFolderIds;
};
