import * as React from "react";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { css } from "emotion";
import { ObjectId } from "@app/utils/generics";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FormattedMessage } from "react-intl";

interface IRowInfo {
	_id: ObjectId;
	name?: string;
	description?: string;
}

interface IProps {
	rows: IRowInfo[];
	redirectTo?: (id: ObjectId) => void;
	onDelete?: (id: ObjectId) => void;
	onView?: (id: ObjectId) => void;
	additionalComponents?: React.ComponentType<{
		rowId: ObjectId;
		iconClassName: string;
	}>;
}

const AdminTable: React.FC<IProps> = props => (
	<Paper className={AdminTableContainer}>
		<Table>
			<TableHead className={AdminTableHead}>
				<TableRow className={AdminTableRow}>
					<TableCell>
						<FormattedMessage id="inputs.name" />
					</TableCell>
					<TableCell>
						<FormattedMessage id="inputs.description" />
					</TableCell>
					<TableCell />
				</TableRow>
			</TableHead>
			<TableBody>
				{props.rows.map(row => (
					<TableRow key={row._id} className={AdminTableRow}>
						<TableCell>{row.name}</TableCell>
						<TableCell>{row.description}</TableCell>
						<TableCell>
							{props.onView && (
								<VisibilityIcon
									className={SVG}
									onClick={() => props.onView!(row._id)}
								/>
							)}
							{props.redirectTo && (
								<CreateIcon
									className={SVG}
									onClick={() => props.redirectTo!(row._id)}
								/>
							)}
							{props.additionalComponents && (
								<props.additionalComponents
									rowId={row._id}
									iconClassName={SVG}
								/>
							)}
							{props.onDelete && (
								<DeleteIcon
									className={SVG}
									onClick={() => props.onDelete!(row._id)}
								/>
							)}
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</Paper>
);

export const AdminTableHead = css`
	font-size: 30px;
	font-family: BPGNinoMtavruli;
`;

export const AdminTableRow = css`
	font-size: 25px;
	font-family: BPGNinoMtavruli;
`;

export const AdminTableContainer = css`
	margin: 20px;
`;

export const SVG = css`
	color: red;
	margin: 0px 5px;
	cursor: pointer;
	color: grey;
	vertical-align: middle;
	&:hover {
		color: #5f5a5a;
	}
`;

export default AdminTable;
