export const htmlSubstring = (
	htmlString: string,
	count: number,
	additionalString: string = ""
): string => {
	const div = document.createElement("div");
	div.innerHTML = htmlString;

	walk(div, track);

	function track(el) {
		if (count > 0) {
			const len = el.data.length;
			count -= len;
			if (count <= 0) {
				el.data = el.substringData(0, el.data.length + count);
			}
		} else {
			el.data = "";
		}
	}

	function walk(el, fn) {
		let node = el.firstChild;
		do {
			if (node.nodeType === 3) {
				fn(node);
			} else if (
				node.nodeType === 1 &&
				node.childNodes &&
				node.childNodes[0]
			) {
				walk(node, fn);
			}
			node = node.nextSibling;
		} while (node);
	}
	(div.lastChild as HTMLElement).innerText =
		(div.lastChild as HTMLElement).innerText + additionalString;
	return div.innerHTML;
};
