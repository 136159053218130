import * as React from "react";
import CancelIcon from "@material-ui/icons/CancelRounded";
import GalleryPopup from "../../gallery";
import Popup from "../../../widgets/Popup";
import SelectedPhotos from "../../gallery/selected";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { CheckBoxContainer } from "../../../styles/checkbox";
import { IAPOSTCreateFolder, IAPUTFolder } from "@app/api/folders/validators";
import { IRFolder } from "@app/api/folders/helper-schemas";
import { InputStyle } from "../../../styles/inputs";
import { ObjectId } from "@app/utils/generics";
import { PrimaryButton } from "../../../styles/buttons";
import {
	Cancel,
	OuterCheckboxDiv,
	PopupBody,
	PopupCSS,
	PopupHeader,
} from "../styles";
import { inject } from "@app/modules";
import FancyLoading from "@app/components/widgets/fancy-loading";
import { getFormattedMessage } from "@app/utils/locale";
import { FormattedMessage } from "react-intl";

interface IPopupProps {
	courseId: ObjectId;
	parentFolderId?: ObjectId;
	onClose: () => void;
	folderId?: ObjectId;
}
interface IPopupState {
	folderData: IAPOSTCreateFolder | IRFolder | null;
	isGalleryOpened: boolean;
}
class FolderPopup extends React.PureComponent<IPopupProps, IPopupState> {
	state: IPopupState = {
		folderData: this.props.folderId
			? null
			: {
					name: "",
					originalCourseId: this.props.courseId,
					public: true,
					parentId: this.props.parentFolderId,
			  },
		isGalleryOpened: false,
	};

	private _FoldersController = inject("FoldersController");

	componentDidMount() {
		if (this.props.folderId) {
			this._FoldersController
				.getById({
					_id: this.props.folderId,
					courseId: this.props.courseId,
				})
				.then(data => {
					this.setState({
						folderData: data,
					});
				});
		}
	}

	handleInputChange = (fieldName: string) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		this.setState({
			folderData: {
				...this.state.folderData!,
				[fieldName]: e.target.value,
			},
		});
	};

	handleCheckboxChange = () => {
		this.setState({
			folderData: {
				...this.state.folderData!,
				public: !this.state.folderData!.public,
			},
		});
	};

	save = () => {
		if (!this.props.folderId) {
			this._FoldersController
				.add({
					...(this.state.folderData as IAPOSTCreateFolder),
				})
				.then(() => {
					this.props.onClose();
				});
		} else {
			const { name, description, public: publica, photo } = this.state
				.folderData as IAPOSTCreateFolder;
			const obj: IAPUTFolder = {
				name,
				description,
				public: publica,
				photo,
				_id: this.props.folderId,
				courseId: this.props.courseId,
			};
			this._FoldersController.update(obj).then(() => {
				this.props.onClose();
			});
		}
	};

	openGallery = () => this.setState({ isGalleryOpened: true });
	closeGallery = () => this.setState({ isGalleryOpened: false });

	addPhoto = (photo: string) => {
		if (!this.state.folderData) return;
		if (this.state.folderData.photo) {
			this.setState({
				folderData: { ...this.state.folderData, photo: undefined },
			});
		} else {
			this.setState({ folderData: { ...this.state.folderData, photo } });
			this.closeGallery();
		}
	};

	render() {
		return (
			<>
				{this.state.isGalleryOpened && (
					<GalleryPopup
						courseId={this.props.courseId}
						onClose={this.closeGallery}
						onUpload={this.addPhoto}
					/>
				)}
				<Popup onClose={this.props.onClose}>
					<div className={PopupCSS}>
						<div className={PopupHeader}>
							<CancelIcon
								className={Cancel}
								onClick={this.props.onClose}
							/>
						</div>
						{!this.state.folderData ? (
							<FancyLoading />
						) : (
							<>
								<div className={PopupBody}>
									<TextField
										label={getFormattedMessage(
											"inputs.name"
										)}
										defaultValue={
											this.state.folderData.name
										}
										onChange={this.handleInputChange(
											"name"
										)}
										margin="normal"
										variant="outlined"
										className={InputStyle}
									/>
									<TextField
										label={getFormattedMessage(
											"inputs.description"
										)}
										defaultValue={
											this.state.folderData.description
										}
										onChange={this.handleInputChange(
											"description"
										)}
										margin="normal"
										variant="outlined"
										className={InputStyle}
									/>
									<SelectedPhotos
										selectedPhoto={
											this.state.folderData.photo
										}
										onAdd={this.openGallery}
										onDelete={this.addPhoto}
									/>
									<div className={OuterCheckboxDiv}>
										<div className={CheckBoxContainer}>
											<FormControlLabel
												control={
													<Checkbox
														checked={
															this.state
																.folderData
																.public
														}
														onChange={
															this
																.handleCheckboxChange
														}
													/>
												}
												label={getFormattedMessage(
													"public"
												)}
											/>
										</div>
									</div>
								</div>
								<PrimaryButton onClick={this.save}>
									<FormattedMessage id="save" />
								</PrimaryButton>
							</>
						)}
					</div>
				</Popup>
			</>
		);
	}
}

export default FolderPopup;
