import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../styles/buttons";
import Popup, { PopupContent, PopupContentWithClose } from "../widgets/Popup";
import TouLogoSrc from "./imgs/logo-name.png";
import CaUniPoster1 from './imgs/ca_poster1.jpg';

export const TouStartPopup = React.memo<{
	onClose: () => void;
	onStart: () => void;
	secondaryText: string | JSX.Element;
}>(function TouStartPopup({ onStart, onClose, secondaryText }) {
	const [isDisabled, setIsdisabled] = useState(true);
	useEffect(() => {
		const timeout = setTimeout(() => {
			setIsdisabled(false);
		}, 3 * 1000);
		return () => {
			clearTimeout(timeout);
		};
	}, []);
	return (
		<Popup onClose={onClose}>
			<PopupContent
				style={{
					padding: 20,
					width: 600,
					maxWidth: "95%",
					fontFamily: "FiraGo",
					textAlign: "left",
					fontSize: 20,
				}}
				// className={styles.euroUniPopupContent}
			>
				<b>გახსოვდეს:</b>
				<br />
				<br />
				<b>შენ გარანტირებულად გახდები</b> თბილისის ღია უნივერსიტეტის
				სტუდენტი!
				<br />
				<br />
				<b>თუ</b> {secondaryText}
				<br />
				<br />
				<div style={{ textAlign: "center" }}>
					<PrimaryButton
						onClick={onStart}
						isFaded={isDisabled}
						disabled={isDisabled}
					>
						გამოცდის დაწყება
					</PrimaryButton>
				</div>
			</PopupContent>
		</Popup>
	);
});

export const TouDistractingPopup = React.memo<{ onClose: () => void }>(
	function TouDistractingPopup({ onClose }) {
		return (
			<Popup onClose={onClose}>
				<PopupContentWithClose
					style={{
						padding: "10px 0",
						width: 600,
						maxWidth: "95%",
						fontFamily: "FiraGo",
						textAlign: "center",
						fontSize: 19,
					}}
					onClose={onClose}
				>
					<div style={{ textAlign: "center", marginTop: 0 }}>
						<img
							src={TouLogoSrc}
							alt="eu logo"
							style={{ maxHeight: 90, maxWidth: "90%" }}
						/>
					</div>
					<br />
					<br />
					ალბათ ფიქრობ უადგილოა ეს წარწერა, მაგრამ ეროვნულის წერის
					დროსაც ბევრი უადგილო შეკითხვა დაისმება კლასში, არ შეიმჩნიო
					და ტესტზე კონცენტრირდი! <br />
					<br />
					😊 უთხარი შენს თავს : „დღეს შენი დღეა!“{" "}
				</PopupContentWithClose>
			</Popup>
		);
	}
);

export const HistInstructions = React.memo<{}>(function HistInstructions({}) {
	return (
		<span
			style={{ display: "block", textAlign: "left", marginBottom: -20 }}
		>
			<b>ყურადღებით წაიკითხეთ იმიტირებული გამოცდის ინსტრუქცია!</b><br></br><br></br>
			ერთიან ეროვნულ გამოცდაზე <b>ისტორიის ტესტი 60 ქულით ფასდება</b> და ტესტზე სამუშაო <b>დროა 150 წუთი.</b> 
			იმიტირებული გამოცდის ფარგლებში თქვენს წინაშეა ტესტი რომლის მაქსიმალური შეფასება <b>52 ქულაა,</b> ხოლო, სამუშაო დრო <b>120 წუთია.</b>
			<br /><br/>
			<b>იმიტირებული ტესტი შედგება შემდეგი სავარჯიშოებისაგან:</b><br/><br/>
			ტესტური სავარჯიშო <br/>
			რუკის ანალიზი<br/>
			ისტორიული წყაროს ანალიზი<br/><br/>
			• ტესტურ დავალებებში მონიშნეთ თქვენთვის სასურველი პასუხი და ამის შემდეგ დააჭირეთ ღილაკს „შემდეგი“.<br/>
			• მომდევნო საკითხებს აქვს რამდენიმე შეკითხვა. გთხოვთ, ყველა შეკითხვაზე დააფიქსიროთ პასუხი (ვერსია) იმისათვის რომ შეძლოთ შემდეგზე გადასვლა.<br/>
			<br/>
			იმიტირებული გამოცდისას <b>თქვენი ნაწერი სწორდება ელექტრონული პროგრამის მიერ.</b> სწორედ ამიტომ წარმოდგენილი იმიტირებული ტესტი მოიცავს ყველა ტიპის სავარჯიშოს, <b>გარდა არგუმენტაციის სავარჯიშოსი.</b>
			<br/><br/>
			<b>
				<u>ტესტის შედეგის ნახვას შეძლებ წერის დასრულებისთანავე,</u>
			</b>{" "}
			ხოლო საკუთარ ნაშრომსა და სწორ პასუხებზე წვდომა გექნებათ{" "}
			<b>
				ორშაბათს, 10 ივნისს, 12:00-დან 23:59-მდე <u>ამავე გვერდზე!</u>
			</b><br/><br/>
			<b>გისურვებთ წარმატებას !</b>
		</span>
	);
});

export const EngInstructions = React.memo<{}>(function EngInstructions({}) {
	return (
		<span
			style={{ display: "block", textAlign: "left", marginBottom: -20 }}
		>
			<b>ყურადღებით წაიკითხეთ იმიტირებული გამოცდის ინსტრუქცია!</b><br></br><br></br>
			ერთიან ეროვნულ გამოცდაზე <b>ინგლისური ენის ტესტი 70 ქულით ფასდება</b> და ტესტზე სამუშაო <b>დროა 150 წუთი.</b>
			იმიტირებული გამოცდის ფარგლებში თქვენს წინაშეა ინგლისური ენის ტესტი რომლის მაქსიმალური შეფასება <b>48 ქულაა,</b> ხოლო, სამუშაო დრო <b>100 წუთია.</b>
			<br /><br/>
			იმიტირებული გამოცდისას <b>თქვენი ნაწერი სწორდება ელექტრონული პროგრამის მიერ.</b> სწორედ ამიტომ წარმოდგენილი იმიტირებული ტესტი მოიცავს ყველა ტიპის სავარჯიშოს, <b>გარდა წერილისა და ესეს სავარჯიშოებისა.</b>
			<br/><br/>
			ეროვნულ გამოცდებზე <b>22 ქულით</b> ფასდება დიალოგის შევსება და "ესე" სავარჯიშო. იმიტირებული გამოცდის ტესტი, რომელიც არ მოიცავს დიალოგის შევსებასა და ესეს სავარჯიშოს ფასდება <b>48 ქულით.</b>
			<br /><br />
			<b>ტესტის შედეგს გაიგებთ წერის დასრულებისთანავე. </b> 
			ხოლო საკუთარ ნაშრომსა და სწორ პასუხებზე წვდომა გექნება <b>ორშაბათს, 10 ივნისს, 12:00-დან  23:59-მდე ამავე გვერდზე! </b>
			<br />
			<br />
			<b>გისურვებთ წარმატებას !</b>
		</span>
	);
});

export const MathInstructions = React.memo<{}>(function EngInstructions({}) {
	return (
		<span
			style={{ display: "block", textAlign: "left", marginBottom: -20 }}
		>
			შენ იწყებ ეროვნული გამოცდების მოდელირების წერას <b>მათემატიკაში,</b>{" "}
			რომლის მაქსიმალური ქულაა <b>43 ქულა</b> (ნაცვლად 51-სა), ხოლო{" "}
			<b>წერის დრო - 2,5 სთ</b> (ნაცვლად 3 სთ-სა).
			<br />
			<br />
			მოდელირება შედგენილია ეროვნული გამოცდების პროგრამის მიხედვით და
			მაქსიმალურად მიახლოებულია ეროვნული გამოცდის ფორმატთან - ტესტური და
			ღია სავარჯიშოების რაოდენობები ზუსტად ემთხვევა, თუმცა ვინაიდან 3 და 4
			ქულიან ამოცანებში დიდი მნიშვნელობა ენიჭება ამოცანის ამოხსნის გზას
			(რომელსაც ჩვენ ვერ ვამოწმებთ), 8-ვე ღია ტიპის სავარჯიშო იქნება 2
			ქულიანი.
			<br />
			<br />
			გაითვალისწინე, რომ{" "}
			<b>ნაშრომი გასწორდება ელექტონული პროგრამის მიერ,</b> ავტომატურად,
			ამიტომ მნიშვნელოვანია, რომ საკუთარი პასუხებში დააფიქსირო კონკრეტული
			პასუხი დამატებითი სიმბოლოების გარეშე.
			<br />
			<br />
			<b>მოდელირებაში ქულების ნაწილდება შემდეგნაირად:</b>
			<br />
			1 ქულიანი ტესტური (შემოსახაზი) სავარჯიშოები - 27 ტესტი - 27 ქულა
			<br />
			2 ქულიანი ღია ტიპის (ჩასაწერი) სავარჯიშოები - 8 სავარჯიშო - 16 ქულა
			<br />
			<br />
			<b>
				<u>შენი ჯამური შედეგის ნახვას შეძლებ წერის დასრულებისთანავე,</u>
			</b>{" "}
			ხოლო ნაშრომსა და სწორ პასუხებზე წვდომა გექნება{" "}
			<b>
				ხუთშაბათს, 8 ივლისს, 12:00-დან დღის ბოლომდე{" "}
				<u>ამავე გვერდზე.</u>
			</b>
			<br />
			<br />
			<b>ახლა კი, მოდელირებაზე კონცენტრირდი და გვაჩვენე რა შეგიძლია!</b>
			<br />
			<br />
			<b>აბა, შენ იცი, წარმატებები!</b>
		</span>
	);
});

export const CaUniBannerPopup = ({ onClose }: {onClose: () => void}) => {
	return (
		<Popup onClose={onClose}>
			<img src={CaUniPoster1} style={{ maxWidth: "100%", margin: 20 }} />
		</Popup>
	);
} 