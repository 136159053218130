/* eslint-disable max-lines-per-function */
import * as React from "react";
import { IUserAnswer } from "../../schemas/questions/contnets/ans-schema";
import {
	IFullQuestion,
	IShortQuestion,
	IQuestionAssessment,
} from "../../schemas/questions/helper-schemas";
import { newContent } from "../questions/contents/new-content";
import { animateWindowScroll, getHTMLElementCoords } from "../../utils/dom";
import memoizeOne from "memoize-one";
import QuestionContentTestMode, {
	IQContentPassableProps,
} from "../questions/contents";
import { IText } from "../../schemas/texts/helper-schemas";
import {
	removeKeys,
	arrayToObject,
	getOldIfUnchanged,
	deepEqual,
} from "../../utils/common";
import { ITestNavigationProps } from "./navigation";
import { IRawQuestionContent } from "../../schemas/questions/contnets/schemas";
import { ObjectId } from "../../utils/joi";
import { getGradableItemsByEditor } from "../questions/contents/grading";
import { QuestionTiming } from "./timing";
import { TextContainer } from "./text";
import {
	defaultRawAnswerToRichAnswer,
	defaultRichAnswerToRawAnswer,
	IQuestionContentInfo,
} from "./utils";
import { DefaultTestTimer } from "./timer";

export interface IQuestionDisplay {
	type: "question";
	index: number;
}

export interface ITextDisplay {
	type: "text";
	id: ObjectId;
	indexOfQuestionToWhichItIsDisplayedBefore: number;
}

export interface IStartPageDisplay {
	type: "startPage";
}

export enum FinishState {
	none,
	saving,
	saved,
	failed,
}

export interface IFinishPageDisplay {
	type: "finishPage";
}

export interface ISpecialPageDisplay {
	type: "specialPage";
	pageId: string | number;
}

export type display =
	| IQuestionDisplay
	| ITextDisplay
	| IStartPageDisplay
	| IFinishPageDisplay
	| ISpecialPageDisplay;

interface IQuestionDates {
	firstAnsweredAt?: Date;
	lastAnsweredAt?: Date;
	submittedAt?: Date;
	millisecondsSpentBeforeLastAnswer?: number;
	millisecondsSpentBeforeSubmitting?: number;
	millisecondsSpentTotally?: number;
	unceasingSwitchingDateToQuestion?: Date;
}

export interface IUserExtendedAnswer {
	userRichAnswer: any | undefined;
	assessment: IQuestionAssessment | undefined;
	credit?: number;
	maxCredit?: number;
	submitted: boolean;
	dates: IQuestionDates;
	isFullyAnswered: boolean;
}
export interface IUserExtendedRawAnswer
	extends Omit<IUserExtendedAnswer, "userRichAnswer"> {
	userAnswer: IUserAnswer | undefined;
}
export interface IFinishPageProps {
	questions: (IFullQuestion | IShortQuestion)[];
	info: ITestFinishArgs;
	onPageSelect: (display: display) => void;
	finishState: FinishState;
	retryFinishingTest: () => Promise<any>;
}

export interface ITestFinishArgs {
	unassessedCredit: number;
	assessedCredit: number;
	totalCredit: number;
	isFullyAssessed: boolean;
	hasAssessableQuestion: boolean;
	numOfAnsweredUniqueQuestions: number;
	numOfAnsweredQuestions: number;
	numOfKnownUniqueQuestions: number | null;
	questions: (IFullQuestion | IShortQuestion)[];
	userAnswers: (IUserExtendedRawAnswer & { questionId: ObjectId })[];
	hasChangedAtLeastOneAnswer: boolean;
	questionIds: (ObjectId | undefined)[];
}

export interface IStartPageProps {
	questions: (IFullQuestion | IShortQuestion)[];
	info: ITestFinishArgs;
	onPageSelect: (display: display) => void;
}

export interface ISeparateTextPageProps {
	questionInfo: {
		canBeSwitchedTo: boolean;
		userAnswerInfo: IUserExtendedAnswer | undefined;
		realIndex: number;
	}[];
	onQuestionSelect: (qIndex: number) => void;
	testFinishState: FinishState;
	text: IText;
}

export interface ISpecialPageProps {
	pageId: string | number;
	gotoNextPage: () => void;
}

export interface TimerProps {
	timeMs: number;
}

export interface IAGetTestQuestion {
	passedQuestions: (IFullQuestion | IShortQuestion)[];
	passedTexts: IText[];
	selectedQuestionIds: (ObjectId | undefined)[];
	unknownQuestionIds: ObjectId[];
	answers: (IUserExtendedAnswer | undefined)[];
	questionIndexToRequest: number;
}

export type IRGetTestQuestion =
	| { isFinished: false; questionId: ObjectId }
	| { isFinished: true };

export interface INextButtonProps {
	userAnswerInfo: IUserExtendedAnswer;
	isFullContentLoaded: boolean;
	isFinished: boolean;
	questionIndex: number;
	questionId: ObjectId;
	onClick: (args: { submit?: boolean; gotoNext: boolean }) => void;
}

type showPosition = "top" | "bottom" | null;

export interface TestSpecialPage {
	display: display;
	location: "before" | "after";
	pageId: number | string;
}

export interface TestSpecialPagesGetterFn {
	(
		args: {
			questionIds: (ObjectId | undefined)[];
			showTextsOnSeparatePage: boolean;
		} & Pick<ITestComponentProps, "questions" | "texts">
	): TestSpecialPage[];
}

export interface ITestComponentProps {
	allowSwitchingToSubmittedQuestions: boolean;
	knownNumberOfQuestions: number | null;
	allowSwitchingToUnsubmittedQuestions: boolean;
	initialQuestionsSkipStrategy?: "all" | "submitted" | "answered";
	onFinish: (args: ITestFinishArgs) => Promise<any>;
	preFinishHook?: (
		args: ITestFinishArgs
	) => { finish: boolean } | Promise<{ finish: boolean }>;
	preSwitchDisplayHook?: (
		currentDisplay: display | undefined,
		newDisplay: display
	) => void;
	postSwitchDisplayHook?: (
		newDisplay: display,
		oldDisplay: display | undefined
	) => void;
	getQuestion: (args: IAGetTestQuestion) => Promise<IRGetTestQuestion>;
	postUserAnswerChamge?: (args: {
		display: IQuestionDisplay;
		index: number;
		userAnswersInfo: (IUserExtendedAnswer | undefined)[];
	}) => void;
	components: {
		Navigation: React.ComponentType<ITestNavigationProps>;
		StartPage?: React.ComponentType<IStartPageProps>;
		FinishPage: React.ComponentType<IFinishPageProps>;
		NextButton: React.ComponentType<INextButtonProps>;
		SeparateTextPage?: React.ComponentType<ISeparateTextPageProps>;
		SpecialPage?: React.ComponentType<ISpecialPageProps>;
		Loading: React.ComponentType<{}>;
		Timer?: React.ComponentType<TimerProps>;
	};
	NavigationProps?: Partial<ITestNavigationProps>;
	navigatonShowage: {
		onQuestionsPage: showPosition;
		onStartPage: showPosition;
		onFinishPage: showPosition;
		onSeparateTextPage: showPosition;
		onSpecialPage: showPosition;
	};
	showAnswersOfSubmittedQuestions: boolean;
	showAnswersOfUnsubmittedQuestions: boolean;
	questions: (IFullQuestion | IShortQuestion)[];
	showTextsOnSeparatePage: boolean;
	disableShufflingAnswers?: boolean;
	disableEditingAnswer?: boolean;
	texts: IText[];
	specialPagesGetterFn?: TestSpecialPagesGetterFn;
	questionsCustomizedProps?: IQContentPassableProps;
	hideFinishButtonWhenNotFinished?: boolean;
	startedAt?: Date;
	durationMs?: number;
	onTimerEnd?: () => void;
	initialQuestionsInfo: (
		| ({
				questionId: ObjectId;
				userAnswer: IUserAnswer | undefined;
				assessment?: IQuestionAssessment;
				submitted: boolean;
		  } & Pick<
				IQuestionDates,
				| "firstAnsweredAt"
				| "lastAnsweredAt"
				| "submittedAt"
				| "millisecondsSpentBeforeLastAnswer"
				| "millisecondsSpentBeforeSubmitting"
				| "millisecondsSpentTotally"
		  >)
		| undefined
	)[];
	rawAnswerToRichAnswer?: (
		rawAnswer: IUserAnswer | undefined,
		contentInfo: IQuestionContentInfo
	) => any;
	richAnswerToRawAnswer?: (
		richAnswer: any,
		contentInfo: IQuestionContentInfo
	) => IUserAnswer | undefined;
}

interface IState {
	questionIds: (ObjectId | undefined)[];
	shuffleKeys: (number | undefined)[];
	finishState: FinishState;
	currentDisplay?: display;
	userAnswersInfo: (IUserExtendedAnswer | undefined)[];
	time?: number;
	specialPages?: TestSpecialPage[];
}

export class TestComponent extends React.PureComponent<
	ITestComponentProps,
	IState
> {
	static defaultProps: Pick<
		ITestComponentProps,
		| "allowSwitchingToSubmittedQuestions"
		| "allowSwitchingToUnsubmittedQuestions"
		| "showAnswersOfSubmittedQuestions"
		| "showAnswersOfUnsubmittedQuestions"
		| "showTextsOnSeparatePage"
		| "navigatonShowage"
		| "disableShufflingAnswers"
		| "hideFinishButtonWhenNotFinished"
	> = {
		allowSwitchingToSubmittedQuestions: true,
		allowSwitchingToUnsubmittedQuestions: false,
		showAnswersOfSubmittedQuestions: true,
		showAnswersOfUnsubmittedQuestions: false,
		showTextsOnSeparatePage: false,
		navigatonShowage: {
			onFinishPage: "top",
			onQuestionsPage: "top",
			onSeparateTextPage: "top",
			onStartPage: null,
			onSpecialPage: "top",
		},
		disableShufflingAnswers: false,
		hideFinishButtonWhenNotFinished: true,
	};

	rawAnswerToRichAnswer =
		this.props.rawAnswerToRichAnswer || defaultRawAnswerToRichAnswer;
	richAnswerToRawAnswer =
		this.props.richAnswerToRawAnswer || defaultRichAnswerToRawAnswer;

	hasChangedAtLeastOneAnswer = false;
	visitedPages: {
		[key in IStartPageDisplay["type"]]?: true;
	} &
		{
			[key in IFinishPageDisplay["type"]]?: true;
		} & {
			questions: Record<string | number, true | undefined>;
			texts: Record<string | number, true | undefined>;
		} = {
		questions: {},
		texts: {},
	};

	state: IState = {
		questionIds: [],
		shuffleKeys: [],
		finishState: FinishState.none,
		userAnswersInfo: [],
	};

	constructor(props: ITestComponentProps) {
		super(props);
		const qIdToQuestion = this.getQuestionsByIdsObj(props.questions);
		let indexOfFirstDesiredQuestion;
		const initialQuestionsSkipStrategy =
			props.initialQuestionsSkipStrategy || "submitted";
		for (
			let i = 0, index = 0;
			i < props.initialQuestionsInfo.length;
			++i, ++index
		) {
			const qAnsInfo = props.initialQuestionsInfo[i];
			if (!qAnsInfo) {
				if (indexOfFirstDesiredQuestion === undefined) {
					indexOfFirstDesiredQuestion = index;
				}
				continue;
			}
			if (
				initialQuestionsSkipStrategy === "submitted" &&
				!qAnsInfo.submitted &&
				indexOfFirstDesiredQuestion === undefined
			) {
				indexOfFirstDesiredQuestion = index;
			}

			this.state.questionIds[index] = qAnsInfo.questionId;
			this.state.shuffleKeys[index] = Math.floor(Math.random() * 1e8);
			const question = qIdToQuestion[qAnsInfo.questionId];
			if (!question) {
				index--;
				continue;
			}

			const qContentInfo = this.getQuestionContnetInfo(question);
			const userRichAnswer = this.rawAnswerToRichAnswer(
				qAnsInfo.userAnswer,
				qContentInfo
			);
			const additional = this.getRichAnswerInfo(
				userRichAnswer,
				qContentInfo
			);
			if (
				props.initialQuestionsSkipStrategy === "answered" &&
				(qAnsInfo.userAnswer === undefined ||
					additional.isFullyAnswered === false) &&
				indexOfFirstDesiredQuestion === undefined
			) {
				indexOfFirstDesiredQuestion = index;
			}

			this.state.userAnswersInfo[index] = {
				submitted: qAnsInfo.submitted,
				userRichAnswer,
				isFullyAnswered: false,
				assessment: qAnsInfo.assessment,
				dates: {
					firstAnsweredAt: qAnsInfo.firstAnsweredAt,
					lastAnsweredAt: qAnsInfo.lastAnsweredAt,
					submittedAt: qAnsInfo.submittedAt,
					millisecondsSpentTotally:
						qAnsInfo.millisecondsSpentTotally ??
						qAnsInfo.millisecondsSpentBeforeSubmitting ??
						qAnsInfo.millisecondsSpentBeforeLastAnswer,
					millisecondsSpentBeforeSubmitting:
						qAnsInfo.millisecondsSpentBeforeSubmitting ??
						qAnsInfo.millisecondsSpentBeforeLastAnswer ??
						qAnsInfo.millisecondsSpentTotally,
					millisecondsSpentBeforeLastAnswer:
						qAnsInfo.millisecondsSpentBeforeLastAnswer ??
						qAnsInfo.millisecondsSpentBeforeSubmitting ??
						qAnsInfo.millisecondsSpentTotally,
				},
				...additional,
			};
		}
		const questionIndex =
			indexOfFirstDesiredQuestion === undefined
				? props.initialQuestionsInfo.length
				: indexOfFirstDesiredQuestion;

		this.state.currentDisplay = {
			type: "question",
			index: questionIndex - 1,
		};
		this.markAsVisited(this.state.currentDisplay);
	}

	bodyContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
	containerRef: React.RefObject<HTMLDivElement> = React.createRef();
	questionBodyRef: React.RefObject<HTMLDivElement> = React.createRef();

	private _isMounted = false;
	private intervalID: NodeJS.Timeout;

	componentDidMount() {
		this._isMounted = true;
		this.gotoNextPage();
		if (this.props.startedAt && this.props.durationMs !== undefined) {
			const difference =
				this.props.durationMs +
				new Date(this.props.startedAt).getTime() -
				Date.now();
			this.setState({ time: difference });
			if (difference <= 0 && this.props.onTimerEnd) {
				this.props.onTimerEnd();
			}
			this.intervalID = setInterval(() => {
				if (
					!this.props.startedAt ||
					this.props.durationMs === undefined
				) {
					return;
				}
				const difference =
					this.props.durationMs! +
					new Date(this.props.startedAt!).getTime() -
					Date.now();
				if (difference <= 0) {
					clearInterval(this.intervalID);
					this.props.onTimerEnd?.();
				}

				this.setState({ time: difference });
			}, 1000);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		clearInterval(this.intervalID);
	}

	public dangerouslySetFinishState = () => {
		this.setState({ finishState: FinishState.saved });
	};

	lastText: IText | null = null;

	gotoNextPage = async () => {
		const oldDisplay = this.state.currentDisplay;
		this.oldDisplay = oldDisplay;
		const newDisplay = this.getNextPage(oldDisplay);
		const initialScrollTop =
			window.pageYOffset || document.documentElement!.scrollTop;
		this.setNewDisplayHelper(newDisplay, oldDisplay);
		if (
			newDisplay.type === "question" &&
			!this.state.questionIds[newDisplay.index]
		) {
			await this.getQuestion(newDisplay.index);
		}
		this.handleDisplayChangeSideffects(
			oldDisplay,
			newDisplay,
			initialScrollTop
		);
	};

	private handleDisplayChangeSideffects = (
		oldDisplay: display | undefined,
		newDisplay: display,
		initialScrollTop?: number
	) => {
		this.handleScroll(oldDisplay, newDisplay, initialScrollTop);
		this.handleQuestionTimingSideffects(oldDisplay, newDisplay);
		const curentQuestion =
			newDisplay.type === "question"
				? this.getQuestionByDisplayIndex(newDisplay.index)
				: null;
		const text = this.getTextOfQuestion(curentQuestion);
		try {
			this.props.postSwitchDisplayHook?.(newDisplay, oldDisplay);
		} catch (e) {}
		if (curentQuestion) {
			this.lastText = text;
		} else {
			setTimeout(() => {
				this.lastText = text;
			}, 10);
		}
	};

	private handleQuestionTimingSideffects = (
		oldDisplay: display | undefined,
		newDisplay: display
	) => {
		this.setState(({ userAnswersInfo: userAnswers }) => {
			if (
				(!oldDisplay || oldDisplay.type !== "question") &&
				newDisplay.type !== "question"
			) {
				return null;
			}
			const newUserAnswers = [...userAnswers];
			if (newDisplay.type === "question") {
				newUserAnswers[
					newDisplay.index
				] = QuestionTiming.getUserExtendedAnswerAfterSwitchingTo(
					newUserAnswers[newDisplay.index]
				);
			}
			if (oldDisplay && oldDisplay.type === "question") {
				newUserAnswers[
					oldDisplay.index
				] = QuestionTiming.getUserExtendedAnswerAfterLeaving(
					newUserAnswers[oldDisplay.index]
				);
			}
			return {
				userAnswersInfo: newUserAnswers,
			};
		});
	};

	safeFinish = async (submitAnswersEvenIfErrorOccured = false) => {
		return new Promise(async (resolve, reject) => {
			const { finish } = await this.preFinish();
			if (!finish) {
				reject(new Error("cancelled"));
				return;
			}
			let userAnswersInfo: IState["userAnswersInfo"] = [];
			this.setState(
				({ userAnswersInfo: userAnswers }) => {
					userAnswersInfo = userAnswers;
					return {
						userAnswersInfo: userAnswers.map((e, i) => {
							if (!e || e.submitted) return e;
							const qContentInfo = this.getQuestionContentInfoByIndex(
								i
							);
							if (!qContentInfo) {
								console.error(
									`qContentInfo not found for index: ${i}`
								);
							}
							const normalizedAns = this.normalizeUserAnswerInfo(
								e,
								i
							);
							return QuestionTiming.getUserExtendedAnswerAfterSubmitting(
								normalizedAns
							);
						}),
					};
				},
				() =>
					this.onFinish()
						.catch(e => {
							if (!submitAnswersEvenIfErrorOccured) {
								this.setState({
									userAnswersInfo,
								});
							}
							reject(e);
						})
						.then(resolve)
			);
		});
	};

	private getQuestion = async (
		questionIndexToRequest: number
	): Promise<void> => {
		return this.getQuestionHelper(questionIndexToRequest).then(data => {
			if (data.isFinished) {
				this.setState({
					currentDisplay: this.oldDisplay,
				});
				this.safeFinish().catch(() => {
					// no need to action
				});
				return;
			}
			return new Promise<void>(resolve => {
				this.setState(
					({
						userAnswersInfo: userAnswers,
						questionIds,
						shuffleKeys,
					}) => {
						const newUserAnswers =
							userAnswers[questionIndexToRequest] === undefined
								? [...userAnswers]
								: userAnswers;
						if (
							newUserAnswers[questionIndexToRequest] === undefined
						) {
							const newUserAnswerInfo: IUserExtendedAnswer = {
								userRichAnswer: undefined,
								assessment: undefined,
								isFullyAnswered: false,
								submitted: false,
								dates: {},
							};
							newUserAnswers[
								questionIndexToRequest
							] = newUserAnswerInfo;
						}
						const newQuestionIds = [...questionIds];
						newQuestionIds[questionIndexToRequest] =
							data.questionId;
						const newShuffleKeys = [...shuffleKeys];
						newShuffleKeys[questionIndexToRequest] = Math.floor(
							Math.random() * 1e8
						);
						return {
							userAnswersInfo: newUserAnswers,
							questionIds: newQuestionIds,
							shuffleKeys: newShuffleKeys,
						};
					},
					resolve
				);
			});
		});
	};

	private getNextPageHelper = (
		currentDisplay: display | undefined,
		specialPages: TestSpecialPage[] | undefined
	): display => {
		if (!currentDisplay && this.props.components.StartPage) {
			return { type: "startPage" };
		}
		if (!currentDisplay || currentDisplay.type === "startPage") {
			currentDisplay = {
				type: "question",
				index: -1,
			};
		}

		if (currentDisplay.type === "specialPage") {
			const pageId = currentDisplay.pageId;
			const page = specialPages?.find(e => e.pageId === pageId);
			if (page) {
				if (page.location === "before") {
					return page.display;
				} else {
					return this.getNextPageHelper(page.display, specialPages);
				}
			}
		}

		if (currentDisplay.type === "finishPage") {
			return currentDisplay;
		}

		if (currentDisplay.type === "text") {
			return {
				type: "question",
				index: currentDisplay.indexOfQuestionToWhichItIsDisplayedBefore,
			};
		}

		if (currentDisplay.type === "question") {
			if (
				this.state.finishState &&
				currentDisplay.index + 1 === this.state.questionIds.length
			) {
				return {
					type: "finishPage",
				};
			}
			const oldIndex = currentDisplay.index;
			const nextQIndex = oldIndex + 1;
			if (this.state.questionIds[nextQIndex] === undefined) {
				const c = this.state.questionIds.findIndex(
					e => e === undefined
				);
				if (c > -1) {
					return {
						type: "question",
						index: c,
					};
				}
			}
			if (
				this.state.questionIds[nextQIndex] === undefined ||
				!this.props.showTextsOnSeparatePage
			) {
				return {
					type: "question",
					index: nextQIndex,
				};
			}
			const newQuestion = this.props.questions.find(
				e => e._id === this.state.questionIds[nextQIndex]
			);
			if (!currentDisplay || !newQuestion || !newQuestion.textId) {
				return {
					type: "question",
					index: nextQIndex,
				};
			}
			const oldQuestion = this.props.questions.find(
				e => e._id === this.state.questionIds[oldIndex]
			);
			const newQuestionTextId = newQuestion.textId;
			if (oldQuestion && oldQuestion.textId === newQuestionTextId) {
				return {
					type: "question",
					index: nextQIndex,
				};
			}
			return {
				type: "text",
				id: newQuestionTextId,
				indexOfQuestionToWhichItIsDisplayedBefore: nextQIndex,
			};
		}

		throw new Error("nothing returned");
	};

	private getNextPage = (currentDisplay: display | undefined): display => {
		let specialPages: TestSpecialPage[] | undefined = undefined;
		if (this.props.specialPagesGetterFn) {
			specialPages = this.props.specialPagesGetterFn({
				questionIds: this.state.questionIds,
				questions: this.props.questions,
				texts: this.props.texts,
				showTextsOnSeparatePage: !!this.props.showTextsOnSeparatePage,
			});
			this.setState({
				specialPages,
			});
		}
		let newDisplay = this.getNextPageHelper(currentDisplay, specialPages);
		if (!this.props.allowSwitchingToSubmittedQuestions) {
			while (newDisplay.type === "question") {
				const ansInfo = this.state.userAnswersInfo[newDisplay.index];
				if (ansInfo === undefined || !ansInfo.submitted) break;
				newDisplay = this.getNextPageHelper(newDisplay, specialPages);
			}
		}
		if (specialPages) {
			const nextSpecialPage = getSpecialPageBefore(
				currentDisplay,
				newDisplay,
				specialPages
			);
			if (nextSpecialPage) {
				const modifiedNewDisplay: display = {
					type: "specialPage",
					pageId: nextSpecialPage.pageId,
				};
				if (!deepEqual(currentDisplay, modifiedNewDisplay)) {
					return modifiedNewDisplay;
				}
			}
		}
		return newDisplay;
	};

	private getQuestionHelper = async (
		questionIndexToRequest: number
	): Promise<IRGetTestQuestion> => {
		const knownQuestionIdsObj: { [qId: string]: true } = {};
		for (const qId of this.state.questionIds) {
			if (qId === undefined) continue;
			knownQuestionIdsObj[qId] = true;
		}
		const unknownQuestionIds = this.props.questions
			.filter(q => !knownQuestionIdsObj[q._id])
			.map(e => e._id);
		return this.props.getQuestion({
			passedQuestions: this.props.questions,
			passedTexts: this.props.texts,
			selectedQuestionIds: this.state.questionIds,
			unknownQuestionIds,
			answers: this.state.userAnswersInfo,
			questionIndexToRequest,
		});
	};

	private getQuestionContnetInfo = (
		question: IFullQuestion | IShortQuestion
	): IQuestionContentInfo => {
		if ((question as IFullQuestion).content) {
			return {
				contnet: newContent((question as IFullQuestion).content),
				isFullContent: true,
				qId: question._id,
				gradableItems: getGradableItemsByEditor(
					(question as IFullQuestion).content
				),
			};
		}
		return {
			contnet: newContent(
				(question as IShortQuestion).shortContent as IRawQuestionContent
			),
			isFullContent: false,
			qId: question._id,
			gradableItems: getGradableItemsByEditor(
				(question as IShortQuestion).shortContent!
			),
		};
	};

	private getQuestionContentInfos = memoizeOne(
		(
			questions: (IFullQuestion | IShortQuestion)[]
		): IQuestionContentInfo[] => {
			const contents: IQuestionContentInfo[] = [];
			for (const question of questions) {
				contents.push(this.getQuestionContnetInfo(question));
			}
			return contents;
		}
	);

	getQuestionContentInfoByIndex = (
		index: number
	): IQuestionContentInfo | null => {
		const indices = this.stateQuestionIdIndexToPropQuestionIdIndices(
			this.state.questionIds,
			this.props.questions
		);
		const contents = this.getQuestionContentInfos(this.props.questions);
		const propsIndex = indices[index];
		if (propsIndex === undefined) return null;
		return contents[propsIndex];
	};

	private getQIdToQuestionContentInfo = memoizeOne(
		(questions: ITestComponentProps["questions"]) =>
			arrayToObject(this.getQuestionContentInfos(questions), "qId")
	);

	getFinishArgs = (): ITestFinishArgs => {
		let numOfAnsweredQuestions = 0;
		let numOfAnsweredUniqueQuestions = 0;
		const qs: Record<string, true | undefined> = {};
		const qIdToQuestionContentInfo = this.getQIdToQuestionContentInfo(
			this.props.questions
		);
		const userAnswers = this.getUserRawAnswersWithQuestionIds(
			this.state.questionIds,
			this.state.userAnswersInfo,
			qIdToQuestionContentInfo
		);
		let unassessedCredit = 0;
		let assessedCredit = 0;
		let isFullyAssessed = true;
		let hasAssessableQuestion = false;
		for (const ans of userAnswers) {
			if (ans.userAnswer !== null && ans.userAnswer !== undefined) {
				numOfAnsweredQuestions++;
			}
			if (!qs[ans.questionId]) {
				numOfAnsweredUniqueQuestions++;
			}
			if (ans.credit !== undefined) {
				unassessedCredit += ans.credit;
			}
			qs[ans.questionId] = true;
			const qContent = qIdToQuestionContentInfo[ans.questionId];
			if (qContent) {
				if (qContent.gradableItems.length > 0) {
					hasAssessableQuestion = true;
					if (!ans.assessment) {
						isFullyAssessed = false;
					} else {
						const itemKeys = Object.keys(ans.assessment.items);
						let gradedItems = 0;
						for (const itemId of itemKeys) {
							const item = ans.assessment.items[+itemId];
							if (!item) continue;
							gradedItems++;
							assessedCredit += item.credit;
						}
						if (gradedItems < qContent.gradableItems.length) {
							isFullyAssessed = false;
						}
					}
				}
			}
		}
		return {
			questions: this.props.questions,
			userAnswers,
			hasChangedAtLeastOneAnswer: this.hasChangedAtLeastOneAnswer,
			numOfAnsweredQuestions,
			numOfAnsweredUniqueQuestions,
			unassessedCredit,
			assessedCredit,
			isFullyAssessed,
			hasAssessableQuestion,
			totalCredit: assessedCredit + unassessedCredit,
			numOfKnownUniqueQuestions:
				this.props.knownNumberOfQuestions !== null
					? this.props.knownNumberOfQuestions
					: this.state.finishState
					? this.state.questionIds.length
					: null,
			questionIds: this.state.questionIds,
		};
	};

	private preFinish = async () => {
		if (this.props.preFinishHook) {
			return this.props.preFinishHook(this.getFinishArgs());
		}
		return {
			finish: true,
		};
	};

	onFinish = async () => {
		return new Promise<void>((resolve, reject) => {
			if (this.state.finishState === FinishState.saving) {
				reject(
					new Error("cannot save while previous save is not finished")
				);
				return;
			}
			this.setState({ finishState: FinishState.saving }, resolve);
		})
			.then(() => {
				this.onPageSelect({
					type: "finishPage",
				});
				return this.props.onFinish(this.getFinishArgs());
			})
			.then(data => {
				return new Promise(resolve => {
					this.setState(
						{
							finishState: FinishState.saved,
						},
						() => resolve(data)
					);
				});
			})
			.catch(e => {
				return new Promise((resolve, reject) => {
					this.setState(
						{
							finishState: FinishState.failed,
						},
						() => reject(e)
					);
				});
			});
	};

	private getRichAnswerInfo = (
		userRichAnswer: any,
		qContentInfo: IQuestionContentInfo
	): Partial<IUserExtendedAnswer> => {
		const userRawAnswer = this.richAnswerToRawAnswer(
			userRichAnswer,
			qContentInfo
		);
		if (userRawAnswer === null || userRawAnswer === undefined) {
			return {
				isFullyAnswered: false,
			};
		}
		const additional: Partial<IUserExtendedAnswer> = {};
		additional.isFullyAnswered = !!qContentInfo.contnet.hasAnsweredFully(
			userRawAnswer as any
		);
		if (qContentInfo.isFullContent) {
			additional.maxCredit = qContentInfo.contnet.getMaxCredit();
			additional.credit =
				qContentInfo.contnet.getCreditShare(userRawAnswer) *
				additional.maxCredit;
		}
		return additional;
	};

	private onUserAnswerChange = (userAnswer: IUserAnswer) => {
		if (this.props.disableEditingAnswer) return;
		const { currentDisplay } = this.state;
		if (!currentDisplay || currentDisplay.type !== "question") {
			return;
		}
		this.hasChangedAtLeastOneAnswer = true;
		this.setState(
			({ userAnswersInfo }) => {
				const newAnswers = [...userAnswersInfo];
				const qContentInfo = this.getQuestionContentInfoByIndex(
					currentDisplay.index
				);
				const additional = qContentInfo
					? this.getRichAnswerInfo(userAnswer, qContentInfo)
					: {};
				newAnswers[
					currentDisplay.index
				] = QuestionTiming.getUserExtendedAnswerAfterUserAnswerChange({
					...newAnswers[currentDisplay.index]!,
					userRichAnswer: userAnswer,
					isFullyAnswered: false,
					...additional,
				});
				return {
					userAnswersInfo: newAnswers,
				};
			},
			() => {
				this.props.postUserAnswerChamge?.({
					display: currentDisplay,
					index: currentDisplay.index,
					userAnswersInfo: this.state.userAnswersInfo,
				});
			}
		);
	};

	private handleScroll = (
		oldDisplay: display | undefined,
		newDisplay: display,
		initialScrollTop?: number
	) => {
		this.setState(
			() => null,
			() => {
				if (
					!oldDisplay ||
					oldDisplay.type !== newDisplay.type ||
					newDisplay.type !== "question"
				) {
					if (this.containerRef.current) {
						const coordinates = getHTMLElementCoords(
							this.containerRef.current
						);
						animateWindowScroll(
							coordinates.top - 100,
							300,
							initialScrollTop
						);
					}
				} else if (
					oldDisplay.type === "question" &&
					newDisplay.type === "question"
				) {
					const qIndex = newDisplay.index;
					if (!this.state.questionIds[qIndex]) return;
					const questionsInfo = this.getQuestionsArrayForNavigation();
					const qInfo = questionsInfo[qIndex];
					let shouldScrollUpJustToQuestionPart = false;
					if (
						!this.props.showTextsOnSeparatePage &&
						oldDisplay.type === newDisplay.type
					) {
						const oldQ = questionsInfo[oldDisplay.index];
						if (oldQ && !oldQ.isUnknown && !qInfo.isUnknown) {
							if (
								oldQ.textId === qInfo.textId &&
								qInfo.textId !== undefined
							) {
								shouldScrollUpJustToQuestionPart = true;
							}
						}
					}
					if (shouldScrollUpJustToQuestionPart) {
						if (this.questionBodyRef.current) {
							const coordinates = getHTMLElementCoords(
								this.questionBodyRef.current
							);
							animateWindowScroll(
								coordinates.top - 100,
								300,
								initialScrollTop
							);
						}
					} else {
						if (this.bodyContainerRef.current) {
							const coordinates = getHTMLElementCoords(
								this.bodyContainerRef.current
							);
							animateWindowScroll(
								coordinates.top - 100,
								300,
								initialScrollTop
							);
						}
					}
				}
			}
		);
	};

	oldDisplay: display | undefined = undefined;

	public onPageSelect = async (display: display, force?: boolean) => {
		const oldDisplay = this.state.currentDisplay;
		this.oldDisplay = oldDisplay;
		if (display.type !== "question") {
			if (
				this.state.finishState === FinishState.none &&
				display.type === "finishPage" &&
				!force
			) {
				return;
			}
		} else {
			const qIndex = display.index;
			if (!this.state.questionIds[qIndex]) {
				if (this.props.allowSwitchingToUnsubmittedQuestions) {
					await this.getQuestion(qIndex);
				}
			} else {
				const questionsInfo = this.getQuestionsArrayForNavigation();
				const qInfo = questionsInfo[qIndex];
				if ((!qInfo || !qInfo.canBeSwitchedTo) && !force) {
					return;
				}
			}
		}
		this.setNewDisplayHelper(display, oldDisplay);
		this.handleDisplayChangeSideffects(oldDisplay, display);
	};

	private setNewDisplayHelper(
		display: display,
		oldDisplay: display | undefined
	) {
		try {
			this.props.preSwitchDisplayHook?.(oldDisplay, display);
		} catch (e) {}
		this.setState({
			currentDisplay: display,
		});
		this.markAsVisited(display);
	}

	markAsVisited = (display?: display) => {
		if (!display) return;
		if (display.type === "startPage") {
			this.visitedPages[display.type] = true;
		}
		if (display.type === "finishPage") {
			this.visitedPages[display.type] = true;
		}
		if (display.type === "question") {
			this.visitedPages.questions[display.index] = true;
		}
		if (display.type === "text") {
			this.visitedPages.texts[
				display.id +
					"#-#" +
					display.indexOfQuestionToWhichItIsDisplayedBefore
			] = true;
		}
	};

	private normalizeUserAnswerInfo = <
		T extends IUserExtendedAnswer | undefined
	>(
		ans: T,
		index: number
	): T => {
		if (!ans) return ans;
		const qContentInfo = this.getQuestionContentInfoByIndex(index);
		if (!qContentInfo) {
			console.error(`qContentInfo not found for index ${index}`);
			return ans;
		}
		return {
			...ans,
			userRichAnswer: this.rawAnswerToRichAnswer(
				this.richAnswerToRawAnswer(ans.userRichAnswer, qContentInfo),
				qContentInfo!
			),
		};
	};

	private onQuestionNextButtonClick = ({
		submit,
		gotoNext,
	}: {
		submit?: boolean;
		gotoNext: boolean;
	}) => {
		const display = this.state.currentDisplay;
		if (!display || display.type !== "question") return;
		this.setState({
			userAnswersInfo: this.state.userAnswersInfo.map((ans, index):
				| IUserExtendedAnswer
				| undefined => {
				if (index !== display.index) {
					return ans;
				}
				const normalizedAns = this.normalizeUserAnswerInfo(ans, index);
				if (normalizedAns && submit) {
					return QuestionTiming.getUserExtendedAnswerAfterSubmitting(
						normalizedAns
					);
				}
				return normalizedAns;
			}),
		});
		if (gotoNext) {
			this.gotoNextPage().then();
		}
	};

	private onQuestionSelect = (index: number) => {
		this.onPageSelect({
			type: "question",
			index,
		});
	};

	private getQuestionsByIdsObj = memoizeOne(
		(questions: ITestComponentProps["questions"]) => {
			return arrayToObject(questions, "_id");
		}
	);

	private stateQuestionIdIndexToPropQuestionIdIndices = memoizeOne(
		(
			questionIds: IState["questionIds"],
			questions: ITestComponentProps["questions"]
		) => {
			const indices: Record<string, number | undefined> = {};
			let index = -1;
			for (const question of questions) {
				index++;
				indices[question._id] = index;
			}
			return questionIds.map(qId =>
				qId === undefined ? undefined : indices[qId]
			);
		}
	);

	private oldQuestionsInfo: ITestNavigationProps["questionsInfo"] = [];

	private getQuestionsArrayForNavigation = (): ITestNavigationProps["questionsInfo"] => {
		const questionsInfo: ITestNavigationProps["questionsInfo"] = [];
		const qsObj = this.getQuestionsByIdsObj(this.props.questions);
		const qsContentInfo = arrayToObject(
			this.getQuestionContentInfos(this.props.questions),
			"qId"
		);
		const { currentDisplay } = this.state;
		let index = -1;
		for (const qId of this.state.questionIds) {
			index++;
			const question = qId === undefined ? undefined : qsObj[qId];
			const questionContent =
				qId === undefined ? undefined : qsContentInfo[qId];
			let obj: ITestNavigationProps["questionsInfo"][number];
			if (!question) {
				obj = {
					isUnknown: true,
					canBeSwitchedTo: this.props
						.allowSwitchingToUnsubmittedQuestions,
				};
			} else {
				const userAnswerInfo = this.state.userAnswersInfo[index]!;
				const hasAnswered =
					userAnswerInfo.userRichAnswer !== undefined &&
					userAnswerInfo.userRichAnswer !== null;
				let canBeSwitchedTo = false;
				if (
					currentDisplay &&
					currentDisplay.type === "question" &&
					currentDisplay.index === index
				) {
					canBeSwitchedTo = true;
				} else if (
					userAnswerInfo.submitted &&
					this.props.allowSwitchingToSubmittedQuestions
				) {
					canBeSwitchedTo = true;
				} else if (
					!userAnswerInfo.submitted &&
					(this.props.allowSwitchingToUnsubmittedQuestions ||
						hasAnswered)
				) {
					canBeSwitchedTo = true;
				} else if (
					!userAnswerInfo.submitted &&
					currentDisplay &&
					currentDisplay.type === "text" &&
					currentDisplay.id === question.textId
				) {
					canBeSwitchedTo = true;
				}
				if (this.visitedPages.questions[index] === true) {
					canBeSwitchedTo = true;
				}
				obj = {
					isUnknown: false,
					textId: question.textId,
					canBeSwitchedTo,
					userAnswerInfo: {
						...removeKeys(userAnswerInfo, "userRichAnswer"),
						hasAnswered,
					},
					numOfGradableItemsByEditor:
						questionContent === undefined
							? 0
							: questionContent.gradableItems.length,
				};
			}
			questionsInfo.push(
				getOldIfUnchanged(obj, this.oldQuestionsInfo[index])
			);
		}
		if (this.props.knownNumberOfQuestions) {
			for (
				let index = this.state.questionIds.length;
				index < this.props.knownNumberOfQuestions;
				++index
			) {
				const obj: ITestNavigationProps["questionsInfo"][number] = {
					isUnknown: true,
					canBeSwitchedTo: this.props
						.allowSwitchingToUnsubmittedQuestions,
				};
				questionsInfo.push(
					getOldIfUnchanged(obj, this.oldQuestionsInfo[index])
				);
			}
		}
		this.oldQuestionsInfo = getOldIfUnchanged(
			questionsInfo,
			this.oldQuestionsInfo
		);
		return this.oldQuestionsInfo;
	};

	private getTextsInfo = memoizeOne(
		(
			questionsInfo: ITestNavigationProps["questionsInfo"]
		): ITestNavigationProps["textsInfo"] => {
			const textsInfo: ITestNavigationProps["textsInfo"] = [];
			let index = -1;
			let lastTextId: undefined | string;
			for (const qInfo of questionsInfo) {
				index++;
				if (
					!qInfo.isUnknown &&
					qInfo.textId !== undefined &&
					qInfo.textId !== lastTextId
				) {
					let canBeSwitchedTo = false;
					for (let i = index; i < questionsInfo.length; ++i) {
						const q = questionsInfo[i];
						if (q.isUnknown || q.textId !== qInfo.textId) break;
						if (q.canBeSwitchedTo) {
							canBeSwitchedTo = true;
							break;
						}
					}
					textsInfo.push({
						canBeSwitchedTo,
						id: qInfo.textId,
						indexOfQuestionToWhichItIsDisplayedBefore: index,
					});
				}
				lastTextId =
					!qInfo.isUnknown && qInfo.textId !== undefined
						? qInfo.textId
						: undefined;
			}
			return textsInfo;
		}
	);

	private getNavigation = () => {
		const questionsInfo = this.getQuestionsArrayForNavigation();
		let showFinishPageIcon = !!this.props.components.FinishPage;
		if (showFinishPageIcon && this.props.hideFinishButtonWhenNotFinished) {
			if (
				!this.state.currentDisplay ||
				this.state.currentDisplay.type !== "finishPage"
			) {
				if (this.state.finishState === FinishState.none) {
					showFinishPageIcon = false;
				}
			}
		}
		const Component = this.props.components.Navigation;
		return (
			<Component
				onPageSelect={this.onPageSelect}
				currentDisplay={this.state.currentDisplay!}
				questionsInfo={questionsInfo}
				textsInfo={this.getTextsInfo(questionsInfo)}
				showStartPageIcon={!!this.props.components.StartPage}
				showFinishPageIcon={showFinishPageIcon}
				showTextsAsSeparate={this.props.showTextsOnSeparatePage}
				canClickStartPage={!!this.props.components.StartPage}
				canClickFinishPage={this.state.finishState !== FinishState.none}
				testFinishState={this.state.finishState}
				showAnswersOfSubmittedQuestions={
					this.props.showAnswersOfSubmittedQuestions
				}
				showAnswersOfUnsubmittedQuestions={
					this.props.showAnswersOfUnsubmittedQuestions
				}
				specialPages={this.state.specialPages}
				{...this.props.NavigationProps}
			/>
		);
	};

	private getUserRawAnswersWithQuestionIds = memoizeOne(
		(
			questionIds: IState["questionIds"],
			userAnswers: IState["userAnswersInfo"],
			qIdToQuestionContentInfo: {
				[qId: string]: IQuestionContentInfo | undefined;
			}
		): (IUserExtendedRawAnswer & { questionId: ObjectId })[] => {
			const userAnswersWithQuestionIds: (IUserExtendedRawAnswer & {
				questionId: ObjectId;
			})[] = [];
			for (let i = 0; i < userAnswers.length; ++i) {
				const ans = userAnswers[i];
				if (ans === undefined) continue;
				const qId = questionIds[i];
				if (qId === undefined) continue;
				const qContent = qIdToQuestionContentInfo[qId];
				if (qContent === undefined) continue;
				const { userRichAnswer, ...restAnswer } = ans;
				userAnswersWithQuestionIds.push({
					...restAnswer,
					userAnswer: this.richAnswerToRawAnswer(
						userRichAnswer,
						qContent
					),
					questionId: qId,
				});
			}
			return userAnswersWithQuestionIds;
		}
	);

	getQuestionByDisplayIndex = (
		index: number
	): IFullQuestion | IShortQuestion | null => {
		const questionId = this.state.questionIds[index];
		return questionId
			? this.props.questions.find(e => e._id === questionId) || null
			: null;
	};

	private getTextOfQuestion = (
		question: { textId?: ObjectId | null } | null
	): IText | null => {
		if (!question || !question.textId) return null;
		const textId = question.textId;
		return this.props.texts.find(t => t._id === textId) || null;
	};

	private getTextOfCurrentQuestion = () => {
		const { currentDisplay } = this.state;
		if (!currentDisplay || currentDisplay.type !== "question") return null;
		const currentQuestion = this.getQuestionByDisplayIndex(
			currentDisplay.index
		);
		if (currentQuestion) {
			return this.getTextOfQuestion(currentQuestion);
		}
		return this.lastText;
	};

	render() {
		const { currentDisplay, finishState } = this.state;
		const Loading = this.props.components.Loading;
		const StartPage = this.props.components.StartPage;
		if (!currentDisplay) {
			return <Loading />;
		}

		if (currentDisplay.type === "startPage") {
			if (!StartPage) {
				return null;
			}
			const navigation =
				this.props.navigatonShowage.onStartPage === null
					? null
					: this.getNavigation();
			return (
				<div ref={this.containerRef}>
					{this.props.navigatonShowage.onStartPage === "top" &&
						navigation}
					<div ref={this.bodyContainerRef}>
						<StartPage
							questions={this.props.questions}
							info={this.getFinishArgs()}
							onPageSelect={this.onPageSelect}
						/>
					</div>
					{this.props.navigatonShowage.onStartPage === "bottom" &&
						navigation}
				</div>
			);
		}
		if (currentDisplay.type === "finishPage") {
			const navigation =
				this.props.navigatonShowage.onFinishPage === null
					? null
					: this.getNavigation();
			const FinishPage = this.props.components.FinishPage;
			return (
				<div ref={this.containerRef}>
					{this.props.navigatonShowage.onFinishPage === "top" &&
						navigation}
					<div ref={this.bodyContainerRef}>
						<FinishPage
							questions={this.props.questions}
							info={this.getFinishArgs()}
							onPageSelect={this.onPageSelect}
							finishState={finishState}
							retryFinishingTest={this.safeFinish}
						/>
					</div>
					{this.props.navigatonShowage.onFinishPage === "bottom" &&
						navigation}
				</div>
			);
		}

		if (currentDisplay.type === "text") {
			if (!this.props.showTextsOnSeparatePage) {
				return null;
			}
			const text = this.props.texts.find(
				e => e._id === currentDisplay.id
			);
			if (!text) {
				return null;
			}
			const navigation =
				this.props.navigatonShowage.onSeparateTextPage === null
					? null
					: this.getNavigation();
			const SeparateTextComponent = this.props.components.SeparateTextPage
				? this.props.components.SeparateTextPage
				: TextPage;
			return (
				<div ref={this.containerRef}>
					{this.props.navigatonShowage.onSeparateTextPage === "top" &&
						navigation}
					<div ref={this.bodyContainerRef}>
						<SeparateTextComponent
							onQuestionSelect={this.onQuestionSelect}
							questionInfo={[]}
							text={text}
							testFinishState={finishState}
						/>
					</div>
					{this.props.navigatonShowage.onSeparateTextPage ===
						"bottom" && navigation}
				</div>
			);
		}

		if (currentDisplay.type === "specialPage") {
			const page = this.state.specialPages?.find(
				e => e.pageId === currentDisplay.pageId
			);
			if (!page) {
				return null;
			}
			const navigation =
				this.props.navigatonShowage.onSpecialPage === null
					? null
					: this.getNavigation();
			const SpecialPageComp = this.props.components.SpecialPage
				? this.props.components.SpecialPage
				: SpecialPageComponent;
			return (
				<div ref={this.containerRef}>
					{this.props.navigatonShowage.onSpecialPage === "top" &&
						navigation}
					<div ref={this.bodyContainerRef}>
						<SpecialPageComp
							pageId={currentDisplay.pageId}
							gotoNextPage={this.gotoNextPage}
						/>
					</div>
					{this.props.navigatonShowage.onSpecialPage === "bottom" &&
						navigation}
				</div>
			);
		}

		if (currentDisplay.type === "question") {
			const navigation =
				this.props.navigatonShowage.onQuestionsPage === null
					? null
					: this.getNavigation();

			const currentQuestion = this.getQuestionByDisplayIndex(
				currentDisplay.index
			);
			const isFullContentLoaded =
				!!currentQuestion &&
				!!(currentQuestion as IFullQuestion).content;

			const userAnswerInfo = this.state.userAnswersInfo[
				currentDisplay.index
			];
			const userAnswer = userAnswerInfo && userAnswerInfo.userRichAnswer;
			const text = this.getTextOfCurrentQuestion();

			let shuffleKey = this.state.shuffleKeys[currentDisplay.index] || 0;
			if (this.props.disableShufflingAnswers) {
				shuffleKey = 0;
			}
			let displayAnswer = false;
			if (userAnswerInfo) {
				displayAnswer =
					((userAnswerInfo.submitted &&
						this.props.showAnswersOfSubmittedQuestions) ||
						!!this.props.showAnswersOfUnsubmittedQuestions) &&
					isFullContentLoaded;
			}
			let disableEditingAnswer = false;
			if (displayAnswer) {
				disableEditingAnswer = true;
			} else if (userAnswerInfo && userAnswerInfo.submitted) {
				disableEditingAnswer = true;
			}
			if (this.props.disableEditingAnswer) {
				disableEditingAnswer = true;
			}

			const Loading = this.props.components.Loading;
			const NextButton = this.props.components.NextButton;
			const Timer = this.props.components.Timer || DefaultTestTimer;
			return (
				<div ref={this.containerRef}>
					{this.props.navigatonShowage.onQuestionsPage === "top" &&
						navigation}
					{!!this.state.time && <Timer timeMs={this.state.time} />}
					<div ref={this.bodyContainerRef}>
						{text && !this.props.showTextsOnSeparatePage && (
							<div>
								<TextContainer key={text._id} text={text} />
							</div>
						)}
						{!currentQuestion || !userAnswerInfo ? (
							<Loading />
						) : (
							<div>
								<QuestionContentTestMode
									key={currentQuestion._id}
									content={
										(currentQuestion as IFullQuestion)
											.content ||
										(currentQuestion as IShortQuestion)
											.shortContent
									}
									onUserAnswerChange={this.onUserAnswerChange}
									displayAnswer={displayAnswer}
									userAnswer={userAnswer}
									customized={
										this.props.questionsCustomizedProps
									}
									questionBodyRef={this.questionBodyRef}
									shuffleKey={shuffleKey}
									disableEditingAnswer={disableEditingAnswer}
									itemsAssessments={
										userAnswerInfo.assessment &&
										userAnswerInfo.assessment.items
									}
									displayItemAssessments={
										userAnswerInfo.submitted &&
										displayAnswer
									}
								/>
								<NextButton
									onClick={this.onQuestionNextButtonClick}
									userAnswerInfo={userAnswerInfo}
									isFullContentLoaded={isFullContentLoaded}
									isFinished={
										finishState === FinishState.saved
									}
									questionIndex={currentDisplay.index}
									questionId={currentQuestion._id}
								/>
							</div>
						)}
					</div>
					{this.props.navigatonShowage.onQuestionsPage === "bottom" &&
						navigation}
				</div>
			);
		}
		return null;
	}
}

const TextPage: React.FC<ISeparateTextPageProps> = props => {
	return (
		<div>
			<TextContainer text={props.text} />
		</div>
	);
};

const SpecialPageComponent: React.FC<ISpecialPageProps> = props => {
	return <div>Special page #{props.pageId}</div>;
};

const getSpecialPageBefore = (
	oldDisplay: display | undefined,
	newDisplay: display,
	specialPages: TestSpecialPage[]
): TestSpecialPage | null => {
	let page =
		oldDisplay &&
		specialPages.find(specialPage => {
			if (specialPage.location === "after") {
				return areDisplaysEqual(oldDisplay, specialPage.display);
			}
			return false;
		});
	if (!page) {
		page = specialPages.find(specialPage => {
			if (specialPage.location === "before") {
				return areDisplaysEqual(newDisplay, specialPage.display);
			}
			return false;
		});
	}
	if (page && (!oldDisplay || oldDisplay.type !== "specialPage")) {
		const pageBetweenThem = getSpecialPageBefore(
			newDisplay,
			{ type: "specialPage", pageId: page.pageId },
			specialPages
		);
		if (pageBetweenThem) return pageBetweenThem;
	}
	return page ?? null;
};

const areDisplaysEqual = (display1: display, display2: display): boolean => {
	if (display1.type !== display2.type) return false;
	return deepEqual(display1, display2);
};
