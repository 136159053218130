import * as React from "react";
import CheckIcon from "@material-ui/icons/CheckCircleRounded";
import GlowingBar from "../widgets/glowing-bar";
import FancyLoading from "../widgets/fancy-loading";
import Lock from "@material-ui/icons/Lock";
import { css } from "react-emotion";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { History } from "history";
import { inject } from "@app/modules";
import { IRootState } from "@app/redux";
import { match } from "react-router-dom";
import { round } from "@app/commonJavascript";
import {
	IFolderSingleItem,
	ItemType,
	ITestItem,
} from "@app/api/folders/helper-schemas";
import { CourseBackground } from "./background";
import { SponsorLogo } from "./sponsor-logo";
import styles from "./guide/universal.module.scss";
import classNames from "classnames";
import { useCoursesUserId } from "@app/hooks/users";
import { useEffect } from "react";

interface IOwnProps {
	match: match<{ courseId: string; folderId: string; chapter: string }>;
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type ISubTopicsProps = IOwnProps & IStateProps;

const SubTopicsNew: React.FC<ISubTopicsProps> = props => {
	const courseId = props.match.params.courseId;
	const folderId = props.match.params.folderId;
	const chapter = +props.match.params.chapter;

	const _Folder = inject("FolderModel");
	const _UserFolderProgressService = inject("UserFolderProgressService");
	const _CourseFetchingController = inject("CourseFetchingController");
	const _TestTypesController = inject("TestTypesController");
	const FolderHierarchyService = inject("FolderHierarchyService");

	useEffect(() => {
		_CourseFetchingController
			.loadWholeCourseSkeleton({
				courseId: courseId,
				folderId: folderId,
			})
			.then(data => {
				//
			});
		_TestTypesController.getAll();
	});

	const redirectToTestPage = (testTypeId: string, isLocked: boolean) => {
		if (isLocked) return null;
		return props.history.push(
			`/courses/${courseId}/folders/${folderId}/tests/${testTypeId}`
		);
	};

	const redirect = (item: IFolderSingleItem, isLocked: boolean) => {
		if (isLocked) return null;
		if (item.type === ItemType.folder) {
			return props.history.push(
				`/courses/${courseId}/folders/${item.id}/content`
			);
		}
		redirectToTestPage(item.id, isLocked);
	};

	const returnToCourse = () => {
		props.history.push(`/courses/${courseId}/guide`);
	};

	const folder = _Folder.findByIdSync(folderId);
	const userId = useCoursesUserId()!;

	if (!folder) {
		return <FancyLoading />;
	}

	if (!folder.items) {
		return <div>ცარიელია</div>;
	}

	const progressDoc = _UserFolderProgressService.getProgressDocSync({
		courseId: courseId,
		folderId: folderId,
		userId: props.userData.id,
	});
	const folderProgress = !progressDoc ? 0 : progressDoc.progress;
	const hasFinished = folderProgress >= 1;
	const parentId =
		FolderHierarchyService.getParentIdSync(courseId, folderId) || "root";

	const isLocked = inject("UserFolderProgressService").isItemLockedSync({
		courseId: courseId,
		itemId: folderId,
		itemType: ItemType.folder,
		folderId: parentId,
		userId,
	});

	return (
		<Center>
			<CourseBackground />
			<SponsorLogo />
			<Container>
				<Header>
					<div>
						<div
							className={classNames(
								styles.photo,
								isLocked
									? styles.locked
									: hasFinished
									? ""
									: styles.current
							)}
							style={{ width: 100, height: 100 }}
						>
							<img
								alt=""
								src={folder.photo || "/img/default-lesson.svg"}
							/>
						</div>
					</div>
					<div className="space" />
					<div className="box" style={{ width: 240 }}>
						თავი {chapter}
					</div>
					<div className="space" />
					<div className="box" style={{ width: 600 }}>
						{folder.name}
					</div>
				</Header>
				<ItemsContainer>
					{folder.items.map(item => {
						const itemProgress = _UserFolderProgressService.getProgressOfItemSync(
							{
								courseId: courseId,
								itemId: item.id,
								itemType: item.type,
								folderId: folder._id,
								userId: props.userData.id,
							}
						);
						const isLocked = _UserFolderProgressService.isItemLockedSync(
							{
								courseId: courseId,
								itemId: item.id,
								itemType: item.type,
								folderId: folder._id,
								userId: props.userData.id,
							}
						);
						if (
							item.type !== ItemType.folder &&
							item.type !== ItemType.test
						) {
							return null;
						}
						return (
							<EachSubtopic
								onClick={() => redirect(item, isLocked)}
								className={
									isLocked
										? "locked"
										: Math.round(itemProgress * 100) < 1
										? "current"
										: "finished"
								}
							>
								{item.name}
							</EachSubtopic>
						);
					})}
				</ItemsContainer>
			</Container>
		</Center>
	);
};

const Center = styled.div`
	text-align: center;
`;

const Container = styled.div`
	display: inline-grid;
`;

const Header = styled.div`
	display: inline-block;
	font-family: "RobotoUpperCase";
	color: white;
	font-weight: bold;
	font-size: 20px;
	letter-spacing: 1.2px;
	margin-bottom: 25px;
	& > div {
		display: table-cell;
		vertical-align: middle;
	}
	& > .box {
		background-color: #192d4a;
		padding: 20px;
		border-radius: 5px;
	}
	.space {
		width: 1.5%;
	}

	@media screen and (max-width: 500px) {
		font-size: 16px;
	}
`;

const EachSubtopic = styled.div`
	padding: 13px 0px;
	max-width: 100%;
	border-radius: 5px;
	color: white;
	font-family: "RobotoLowerCase";
	margin: 10px 0px;
	@media screen and (max-width: 500px) {
		font-size: 14px;
	}
`;

const ItemsContainer = styled.div`
	background: #e63946;
	border-radius: 5px;
	padding: 18px;

	.finished {
		background-color: #36c99d;
		cursor: pointer;
	}

	.current {
		background-color: rgba(255, 255, 255, 0.1);
		cursor: pointer;
	}

	.locked {
		background-color: rgba(255, 255, 255, 0.2);
		cursor: not-allowed;
	}
`;

const mapStateToProps = (state: IRootState) => ({
	courses: state.courses,
	folders: state.folders,
	folderProgresses: state.userFolderProgresses,
	testTypes: state.testTypes,
	userData: state.user.userData!,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	SubTopicsNew
);
