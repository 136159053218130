import { ItemType } from "@app/api/folders/helper-schemas";
import CloseIcon from "@material-ui/icons/Close";
import { reorder } from "@tests-core/utils";
import * as React from "react";
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
} from "react-beautiful-dnd";
import multipleSelectStyles from "./styles/multiple-select.module.css";

interface ISelectedItemWithName {
	id: string;
	name: string;
	type: ItemType.question | ItemType.card;
}

interface IItemElementProps {
	item: ISelectedItemWithName;
	onCheckedItemsChange: (item: ISelectedItemWithName) => void;
	onWholeLine?: boolean;
	iconsByType?: { [itemType: number]: JSX.Element };
}

const fullItemStyle: React.CSSProperties = {
	width: "100%",
	margin: "3px 0",
};

export const ItemElement: React.FC<IItemElementProps> = (
	props: IItemElementProps
) => (
	<div
		className={multipleSelectStyles.checkedItems}
		style={props.onWholeLine ? fullItemStyle : undefined}
	>
		{props.iconsByType && props.iconsByType[props.item.type]}
		<div style={{ flex: 1, padding: 5 }}>{props.item.name}</div>
		<div
			className={multipleSelectStyles.deleteButton}
			onClick={() => props.onCheckedItemsChange(props.item)}
		>
			<CloseIcon />
		</div>
	</div>
);

interface IItemsContainerProps {
	items: ISelectedItemWithName[];
	onItemsReorder: (items: IItemsContainerProps["items"]) => void;
	onCheckedItemsChange: (item: ISelectedItemWithName) => void;
	iconsByType?: { [itemType: number]: JSX.Element };
	singleItemPerLine?: boolean;
}

export class ItemsContainer extends React.PureComponent<IItemsContainerProps> {
	onDragEnd = (result: DropResult): void => {
		if (!result.destination) return;
		const newArray = reorder(
			this.props.items,
			result.source.index,
			result.destination.index
		);
		this.props.onItemsReorder(newArray);
	};

	render() {
		return (
			<div>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div ref={provided.innerRef}>
								{this.props.items.map((el, index) => (
									<div key={index}>
										<Draggable
											key={index}
											draggableId={el.id}
											index={index}
										>
											{(provided2, snapshot2) => (
												<div
													ref={provided2.innerRef}
													{...provided2.draggableProps}
													{...provided2.dragHandleProps}
												>
													<ItemElement
														key={index}
														item={el}
														onCheckedItemsChange={
															this.props
																.onCheckedItemsChange
														}
														onWholeLine={
															this.props
																.singleItemPerLine ===
															undefined
																? true
																: this.props
																		.singleItemPerLine
														}
														iconsByType={
															this.props
																.iconsByType
														}
													/>
												</div>
											)}
										</Draggable>
									</div>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
		);
	}
}
