import * as React from "react";

const SvgAddFile = props => (
	<svg viewBox="0 0 264 264" {...props}>
		<path
			d="M209.1 33h-32.267V2.383L209.1 33zm-18.933 147c23.159 0 42 18.841 42 42s-18.841 42-42 42-42-18.841-42-42 18.84-42 42-42zm-.834 18a7.499 7.499 0 0 0-7.5 7.5v9.5h-8.167c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h8.167v8.5c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5V230h9.833c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-9.833v-9.5c0-4.143-3.357-7.5-7.5-7.5zm-50.532 48.701l-99.324-.451c-4.134-.013-7.644-3.617-7.644-7.75V7.5c0-4.143 3.69-7.5 7.833-7.5h122.167v40.5c0 4.143 3.69 7.5 7.833 7.5h42.167v121.421c-6-2.845-14.039-4.421-21.834-4.421-31.43 0-56.917 25.57-56.917 57 .001 8.844 2.109 17.224 5.719 24.701zM63.666 76.5c0 4.143 3.357 7.5 7.5 7.5h99c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-99a7.5 7.5 0 0 0-7.5 7.5zm0 31c0 4.143 3.357 7.5 7.5 7.5h99c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-99a7.5 7.5 0 0 0-7.5 7.5zm7.5 40.5h99c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-99c-4.143 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgAddFile;
