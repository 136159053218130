import { IRHierarchy } from "@app/api/courses/helper-schemas";
import { IRFolder, ItemType } from "@app/api/folders/helper-schemas";
import { arrayToObject } from "@app/utils/common";
import { getUnionOfSets } from "@app/utils/set";
import { IChildrenInfo } from "../interfaces";
import { ObjectId } from "@app/utils/generics";

export class HierarchyToFoldersComparer {
	static areFoldersWithSync({
		hierarchy,
		folders,
	}: {
		hierarchy: IRHierarchy;
		folders: IRFolder[];
	}): boolean {
		const foldersByIds = arrayToObject(folders, "_id");
		const childrenInfoObject = this.createChildrenInfo(
			hierarchy.parentInfo
		);
		for (const parentId in childrenInfoObject) {
			const children = childrenInfoObject[parentId]!;
			const parentFolder = foldersByIds[parentId];
			if (!parentFolder) continue;
			const items = parentFolder.items || [];
			const itemFoldersSet = new Set<string>();
			for (const item of items) {
				if (item.type !== ItemType.folder) continue;
				if (item.isHidden) continue;
				itemFoldersSet.add(item.id);
			}

			if (
				getUnionOfSets<string>(new Set(children), itemFoldersSet)
					.size !== itemFoldersSet.size
			) {
				console.log(new Set(children), itemFoldersSet);
				return false;
			}
		}
		return true;
	}

	static createChildrenInfo(parentInfoObject: IRHierarchy["parentInfo"]) {
		const childrenInfoObject: IChildrenInfo = {};

		for (const childId in parentInfoObject) {
			if (parentInfoObject.hasOwnProperty(childId)) {
				const parentId = parentInfoObject[childId]!;
				const strigifiedParentId = parentId;
				const childObjectId: ObjectId = childId;

				if (childrenInfoObject[strigifiedParentId]) {
					childrenInfoObject[strigifiedParentId].push(childObjectId);
				} else {
					childrenInfoObject[strigifiedParentId] = [childObjectId];
				}
				if (!childrenInfoObject[childObjectId]) {
					childrenInfoObject[childObjectId] = [];
				}
			}
		}
		return childrenInfoObject;
	}
}
