import * as React from "react";
import styled, { css } from "react-emotion";

interface IProps {
	className?: string;
	percentage?: number;
}

const GlowingBar: React.FC<IProps> = React.memo(props => {
	const percentage = props.percentage === undefined ? 100 : props.percentage;
	return (
		<Glowing className={props.className}>
			<IdkLevel percentage={percentage}>
				<UpperLevel percentage={percentage} />
			</IdkLevel>
			<div className={glowCSS} />
		</Glowing>
	);
});

const UpperLevel = styled("div")`
	position: absolute;
	background: linear-gradient(to right, #6503f1, #d106f6);
	height: 30px;
	top: 0px;
	left: 0px;
	border-radius: 30px;
	box-shadow: rgba(255, 255, 255, 0.9) 0 0 15px inset;
	width: ${(props: { percentage: number }) =>
		(100 * 100) / props.percentage + "%"};
`;

const IdkLevel = styled("div")`
	width: ${(props: { percentage: number }) => props.percentage + "%"};
	height: 30px;
	overflow: hidden;
	position: absolute;
	top: 0px;
	left: 0px;
`;

const Glowing = styled("div")`
	padding: 2px 8px 0 8px;
	border-radius: 200px;
	height: 30px;
	position: relative;
	box-shadow: rgba(255, 255, 255, 0.9) 0 0 15px inset;
`;

const glowCSS = css`
	border-radius: 200px;
	height: 13px;
	background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0.25),
			rgba(255, 255, 255, 0.5),
			rgba(255, 255, 255, 0.25)
		),
		linear-gradient(
			to right,
			rgba(255, 255, 255, 0) 30%,
			rgba(255, 255, 255, 0.7),
			rgba(255, 255, 255, 0) 70%
		);
	position: relative;
	z-index: 2;
`;

export default GlowingBar;
