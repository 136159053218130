import * as React from "react";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CardTemplateRow from "./row";
import Label from "./label";
import styled from "react-emotion";
import TextField from "@material-ui/core/TextField";
import {
	ICardTemplate,
	ICardTemplateProperty,
	ILabel,
	PropertyType,
} from "../../../schemas/cards/templates";
import { IIdGenerators } from "../../questions/contents/interfaces";
import { OptionalKeys } from "../../../utils/generics";

interface IProps {
	defaultTemplate?: ICardTemplate;
	generateUniqueIds: IIdGenerators["generateUniqueIds"];
}

interface IState {
	template: OptionalKeys<ICardTemplate, "_id" | "authorId">;
}

class CardTemplate extends React.Component<IProps, IState> {
	state: IState = {
		template: this.props.defaultTemplate || {
			name: "",
			properties: [],
			labels: [],
		},
	};

	getData = () => {
		let template = this.state.template;
		if (
			template.properties.every(e => e.isPrimary === undefined) &&
			template.properties.length > 0
		) {
			// if no primary property is seelcted,
			template = {
				// make the first propery primary
				...template,
				properties: template.properties.map((prop, index) =>
					index > 0 ? prop : { ...prop, isPrimary: true }
				),
			};
		}
		return template;
	};

	getCurrentIds = (): ReturnType<IIdGenerators["getCurrentIds"]> => {
		return [
			...this.state.template.properties.map(e => e.id),
			...this.state.template.labels.map(e => e.id),
		];
	};

	onNameChange = e => {
		this.setState({
			template: {
				...this.state.template,
				name: e.target.value,
			},
		});
	};

	onPropertyChange = (newProp: ICardTemplateProperty) => {
		this.setState({
			template: {
				...this.state.template,
				properties: this.state.template.properties.map(e =>
					e.id === newProp.id ? newProp : e
				),
			},
		});
	};

	onPrimaryChange = (propId: number, isAdding: boolean) => {
		if (isAdding) {
			this.setState({
				template: {
					...this.state.template,
					properties: this.state.template.properties.map(e => {
						if (e.id !== propId) {
							if (e.isPrimary === undefined) return e;
							// make sure that only i will be selected as primary
							const { isPrimary, ...rest } = e;
							return rest;
						}
						return { ...e, isPrimary: true };
					}),
				},
			});
		} else {
			this.setState({
				template: {
					...this.state.template,
					properties: this.state.template.properties.map(e => {
						if (e.id !== propId) return e;
						const { isPrimary, ...rest } = e;
						return rest;
					}),
				},
			});
		}
	};

	onPropertyAdd = () => {
		this.setState({
			template: {
				...this.state.template,
				properties: [
					...this.state.template.properties,
					{
						id: this.props.generateUniqueIds()[0],
						name: "",
						type: PropertyType.Text,
					},
				],
			},
		});
	};

	onPropertyDelete = (id: number) => {
		this.setState({
			template: {
				...this.state.template,
				properties: this.state.template.properties.filter(
					e => e.id !== id
				),
			},
		});
	};

	onLabelsadd = () => {
		this.setState({
			template: {
				...this.state.template,
				labels: [
					...this.state.template.labels,
					{
						id: this.props.generateUniqueIds()[0],
						name: "",
					},
				],
			},
		});
	};

	onLabelChange = (newLabel: ILabel) => {
		this.setState({
			template: {
				...this.state.template,
				labels: this.state.template.labels.map(e =>
					e.id === newLabel.id ? newLabel : e
				),
			},
		});
	};

	onLabelDelete = (id: number) => {
		this.setState({
			template: {
				...this.state.template,
				labels: this.state.template.labels.filter(e => e.id !== id),
			},
		});
	};

	render() {
		return (
			<Container>
				<div style={{ marginLeft: 25, marginTop: 20 }}>
					<div>Template Name: </div>
					<TextField
						label="Name"
						value={this.state.template.name}
						onChange={this.onNameChange}
						margin="dense"
						variant="outlined"
					/>
				</div>

				<LabelsContainer>
					<div>labels:</div>
					{this.state.template.labels.map(e => (
						<Label
							key={e.id}
							onChange={this.onLabelChange}
							onDelete={this.onLabelDelete}
							label={e}
						/>
					))}
					<div
						onClick={this.onLabelsadd}
						style={{ marginTop: 10, color: "#518fc5" }}
					>
						<div
							style={{
								cursor: "pointer",
								width: 50,
								marginLeft: "auto",
								marginRight: "auto",
								marginBottom: 5,
							}}
						>
							<AddIcon />
						</div>
					</div>
				</LabelsContainer>

				<PropertiesContainer>
					<div>Properties:</div>
					{this.state.template.properties.map(e => (
						<CardTemplateRow
							key={e.id}
							property={e}
							onChange={this.onPropertyChange}
							onDelete={this.onPropertyDelete}
							onPrimaryChange={this.onPrimaryChange}
						/>
					))}
					<div onClick={this.onPropertyAdd} style={{ marginTop: 10 }}>
						<div
							style={{
								cursor: "pointer",
								width: 50,
								marginLeft: "auto",
								marginRight: "auto",
								marginBottom: 5,
							}}
						>
							<AddIcon />
						</div>
					</div>
				</PropertiesContainer>
			</Container>
		);
	}
}

const Container = styled("div")({
	width: 600,
	maxWidth: "100%",
	border: "#ccc solid 1px",
	borderRadius: 5,
	padding: 10,
});

const LabelsContainer = styled("div")({
	width: 600,
	maxWidth: "100%",
	border: "#ccc solid 1px",
	borderRadius: 5,
	padding: 10,
});

const PropertiesContainer = styled("div")({
	width: 600,
	maxWidth: "100%",
	border: "#ccc solid 1px",
	borderRadius: 5,
	padding: 10,
	marginTop: 5,
});

export default CardTemplate;
