import React, { useState, useEffect } from "react";
import {
	IRGETAppealCounts,
	IRGETAppealsByCourse,
} from "@app/api/appeals/validators";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";

export const useAppealCounts = () => {
	const [data, setData] = useState<IRGETAppealCounts>();

	useEffect(() => {
		const controller = inject("AppealsController");
		let isCancelled = false;
		controller.getAppealCounts().then(data => {
			if (isCancelled) return;
			setData(data);
		});
		return () => {
			isCancelled = true;
		};
	}, []);

	return data;
};

export const useCourseAppeals = (courseId: ObjectId | null) => {
	const [data, setData] = useState<IRGETAppealsByCourse>();

	useEffect(() => {
		if (!courseId) {
			setData(undefined);
			return;
		}
		const controller = inject("AppealsController");
		let isCancelled = false;
		controller.getCourseAppeals({ courseId }).then(data => {
			if (isCancelled) return;
			setData(data);
		});
		return () => {
			isCancelled = true;
		};
	}, [courseId]);

	return data;
};
