import { ObjectId } from "@app/utils/generics";
import { ModelInstance, ModelClass } from "m-model-core";

class AdditionalStaticMethodsOfParentInfoModel {
	static findOneByCourseSync(
		courseId: ObjectId
	): IParentInfoInstance | undefined {
		return 5 as any;
	}
}

export enum HierarchyItemType {
	folder = 0,
	topic = 1,
	taskType = 2,
}

export interface IParentInfo {
	[itemId: string]: ObjectId;
}

export interface IChildrenInfo {
	[itemId: string]: ObjectId[];
}

export interface IPlainParentInfoInstance {
	courseId: ObjectId;
	rootId: ObjectId;
	parentInfo: IParentInfo;
}
export type IParentInfoInstance = ModelInstance<IPlainParentInfoInstance>;

export type IParentInfoModel = ModelClass<
	ObjectId,
	IPlainParentInfoInstance,
	any
> &
	typeof AdditionalStaticMethodsOfParentInfoModel;

export interface IPlainItemInstance {
	_id: ObjectId;
	originalId?: ObjectId;
	originalCourseId: ObjectId;
	name: string;
}
export type IItemInstance = ModelInstance<IPlainItemInstance>;

class AdditionalStaticMethodsOfClonnableItemModel {
	static findClonnablesSync(
		courseId: ObjectId,
		ids: ObjectId[]
	): IItemInstance[] {
		return 5 as any;
	}
}

export type IItemModel = ModelClass<ObjectId, IPlainItemInstance, any>;

export type IClonnableItemModel = ModelClass<
	ObjectId,
	IPlainItemInstance,
	any
> &
	typeof AdditionalStaticMethodsOfClonnableItemModel;

export interface IHierarchyInfo {
	parentInfo: IParentInfo;
	childrenInfo: IChildrenInfo;
	rootId: ObjectId;
}
