export const tryPromiseMultipleTimes = async <T extends any>({
	getPromise,
	maxTrials,
	delayBetweenTrials = 0,
}: {
	getPromise: () => Promise<T>;
	maxTrials: number;
	delayBetweenTrials: number;
}): Promise<T> => {
	if (maxTrials <= 0) {
		throw new Error("maxTrials must be positive");
	}
	let lastError: any = null;
	for (let i = 0; i < maxTrials; i++) {
		try {
			const data = await getPromise();
			return data;
		} catch (e) {
			lastError = e;
			await delayPromise(delayBetweenTrials);
		}
	}
	throw lastError;
};

export const delayPromise = (milliseconds: number) =>
	new Promise(resolve => setTimeout(resolve, milliseconds));
