import { Store } from "redux";
import { CardTemplatesController } from "./api/card-templates/controllers";
import { CardsController } from "./api/cards/controllers";
import { CoursesController } from "./api/courses/controllers";
import CourseFetchingController from "./api/courses/fetch-controllers";
import { FilesController } from "./api/files/controllers";
import { FoldersController } from "./api/folders/controllers";
import { ModelingController } from "./api/modeling/controllers";
import { QuestionsController } from "./api/questions/controllers";
import { IRequest } from "./api/requests";
import { TaskTypesController } from "./api/task-types/controller";
import { TestTypesController } from "./api/test-types/controllers";
import { TestsController } from "./api/tests/controller";
import { TopicsController } from "./api/topics/controllers";
import { CardTemplate } from "./models/card-template";
import { Course } from "./models/course";
import { Folder } from "./models/folder";
import { FolderHierarchy } from "./models/folder-hierarchy";
import { LastOption } from "./models/last-option";
import { TaskType } from "./models/task-type";
import { TaskTypeHierarchy } from "./models/task-type-hierarchy";
import { Test } from "./models/test";
import { TestType } from "./models/test-type";
import { Topic } from "./models/topic";
import { TopicHierarchy } from "./models/topic-hierarchy";
import { UserFolderLevel } from "./models/user-folder-level";
import { UserFolderProgress } from "./models/user-folder-progress";
import { UserTaskTypeLevel } from "./models/user-task-type-level";
import { UserTopicLevel } from "./models/user-topic-level";
import { IRootActions, IRootState } from "./redux";
import { IModel } from "m-model-core";
import FolderItemsService from "./services/folder-items";
import UserFolderProgressService from "./services/folder-progress";
import UserProgressCalculationService from "./services/folder-progress/calculation";
import FolderItemProgressService from "./services/folder-progress/items";
import UserFolderProgressResetService from "./services/folder-progress/reset";
import FolderHierarchyService from "./services/hierarchy-info/folders";
import TaskTypeHierarchyService from "./services/hierarchy-info/task-types";
import TopicHierarchyService from "./services/hierarchy-info/topics";
import { Card } from "./models/card";
import { File } from "./models/file";
import { User } from "./user";
import TestItemsService from "./services/tests/items";
import { Appeal } from "./models/appeal";
import { AppealsController } from "./api/appeals/controller";

const emeptyFunc = (...args: any[]): any => {
	//
};

const Container = {
	// --------------------------- MODELS ---------------------------
	CourseModel: Course,
	CardTemplateModel: CardTemplate,
	CardModel: Card,
	FileModel: File,
	FolderModel: Folder,
	TopicModel: Topic,
	TestTypeModel: TestType,
	UserFolderProgressModel: UserFolderProgress,
	FolderHierarchyModel: FolderHierarchy,
	TopicHierarchyModel: TopicHierarchy,
	TaskTypeModel: TaskType,
	TaskTypeHierarchyModel: TaskTypeHierarchy,
	TestModel: Test,
	UserFolderLevelModel: UserFolderLevel,
	UserTaskTypeLevelModel: UserTaskTypeLevel,
	UserTopicLevelModel: UserTopicLevel,
	LastOptionModel: LastOption,
	AppealModel: Appeal,
	// --------------------------- SERVICES ---------------------------
	FolderItemsService: {} as FolderItemsService,
	UserFolderProgressService: {} as UserFolderProgressService,
	UserProgressCalculationService: {} as UserProgressCalculationService,
	FolderHierarchyService: {} as FolderHierarchyService,
	FolderItemProgressService: {} as FolderItemProgressService,
	UserFolderProgressResetService: {} as UserFolderProgressResetService,
	TopicHierarchyService: {} as TopicHierarchyService,
	TaskTypeHierarchyService: {} as TaskTypeHierarchyService,
	TestItemsService: {} as TestItemsService,
	// --------------------------- CONTROLLERS ---------------------------
	CardTemplatesController: {} as CardTemplatesController,
	CardsController: {} as CardsController,
	CoursesController: {} as CoursesController,
	FilesController: {} as FilesController,
	FoldersController: {} as FoldersController,
	ModelingController: {} as ModelingController,
	TestTypesController: {} as TestTypesController,
	QuestionsController: {} as QuestionsController,
	TestsController: {} as TestsController,
	TopicsController: {} as TopicsController,
	TaskTypesController: {} as TaskTypesController,
	CourseFetchingController: {} as CourseFetchingController,
	AppealsController: {} as AppealsController,
	// --------------------------- SPECIALS ---------------------------
	assertAndGetCoursesUser: emeptyFunc as () => User,
	rootDispatch: emeptyFunc as (action: IRootActions) => void,
	getAllModels: (): IModel[] => {
		const keys = Object.keys(Container);
		const models: IModel[] = [];
		const modelLength = "Model".length;
		for (const key of keys) {
			const lastIndex = key.lastIndexOf("Model");
			if (lastIndex === key.length - modelLength) {
				models.push((Container[key as any] as any) as IModel);
			}
		}
		return models;
	},
};

export function createModules({
	store,
	coursesRequest,
}: {
	store: Store<IRootState, IRootActions>;
	coursesRequest: IRequest;
}) {
	// --------------------------- SPECIALS ---------------------------
	Container.assertAndGetCoursesUser = () => {
		const user = store.getState()["user"];
		if (!user.loggedIn) {
			throw new Error("user must be logged in");
		}
		return User.createUserInstance(user.userData);
	};
	Container.rootDispatch = store.dispatch;
	// --------------------------- MODELS ---------------------------
	// Container.CourseModel.initialize();
	// Container.CardModel.initialize();
	// Container.CardTemplateModel.initialize();
	// Container.FileModel.initialize();
	Container.FolderHierarchyModel.initialize();
	Container.TestTypeModel.initialize();
	// Container.TestModel.initialize();
	Container.TopicModel.initialize();
	Container.TopicHierarchyModel.initialize();
	// Container.UserFolderProgressModel.initialize();
	Container.TaskTypeModel.initialize();
	Container.TaskTypeHierarchyModel.initialize();
	// Container.UserFolderLevelModel.initialize();
	// Container.UserTaskTypeLevelModel.initialize();
	// Container.UserTopicLevelModel.initialize();
	Container.LastOptionModel.initialize();
	Container.AppealModel.initialize();

	// --------------------------- SERVICES ---------------------------
	Container.FolderHierarchyService = new FolderHierarchyService();
	Container.TopicHierarchyService = new TopicHierarchyService();
	Container.TaskTypeHierarchyService = new TaskTypeHierarchyService();
	Container.FolderItemsService = new FolderItemsService();
	Container.UserFolderProgressService = new UserFolderProgressService();
	Container.FolderItemsService._UserFolderProgressService =
		Container.UserFolderProgressService;
	Container.UserProgressCalculationService = new UserProgressCalculationService();
	Container.UserFolderProgressService._UserProgressCalculation =
		Container.UserProgressCalculationService;
	Container.FolderItemProgressService = new FolderItemProgressService();
	Container.UserFolderProgressResetService = new UserFolderProgressResetService();
	Container.TestItemsService = new TestItemsService();

	// --------------------------- CONTROLLERS ---------------------------
	Container.CardTemplatesController = new CardTemplatesController(
		coursesRequest
	);
	Container.CardsController = new CardsController(coursesRequest);
	Container.CoursesController = new CoursesController(coursesRequest);
	Container.FilesController = new FilesController(coursesRequest);
	Container.FoldersController = new FoldersController(coursesRequest);
	Container.ModelingController = new ModelingController(coursesRequest);
	Container.TestTypesController = new TestTypesController(coursesRequest);
	Container.QuestionsController = new QuestionsController(coursesRequest);
	Container.TestsController = new TestsController(coursesRequest);
	Container.TopicsController = new TopicsController(coursesRequest);
	Container.TaskTypesController = new TaskTypesController(coursesRequest);
	Container.CourseFetchingController = new CourseFetchingController(
		coursesRequest
	);
	Container.AppealsController = new AppealsController(coursesRequest);
}

export function inject<T extends keyof typeof Container>(
	key: T
): typeof Container[T] {
	return Container[key];
}
