import * as React from "react";
import Select from "react-select";
import { IMilleniumDate } from "../../../../schemas/cards/templates";

interface IMilleniumDateProps {
	date: IMilleniumDate;
	onChange: (newDate: IMilleniumDate) => void;
}

export default class MilleniumDate extends React.PureComponent<
	IMilleniumDateProps
> {
	whichHalf = [
		{
			value: -1,
			label: "None",
		},
		...["first", "second"].map((e, i) => ({
			value: i,
			label: "" + e,
		})),
	];

	half = this.whichHalf.find(e => e.value === this.props.date.half);

	onMilleniumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onChange({
			...this.props.date,
			millenium: +e.target.value,
		});
	};

	onMilleniumHalfChange = (selection: { value: number; label: string }) => {
		if (selection.value === -1) {
			// none
			this.props.onChange({ millenium: this.props.date.millenium });
			return;
		}
		this.props.onChange({
			...this.props.date,
			half: selection.value,
		});
	};
	render() {
		return (
			<>
				{" "}
				<div style={{ margin: "3px 0px" }}>
					<div style={{ display: "inline-block", minWidth: 70 }}>
						millenium
					</div>
					<input
						defaultValue={"" + this.props.date.millenium}
						type="text"
						onChange={this.onMilleniumChange}
					/>
				</div>
				<div>
					<div style={{ display: "inline-block", minWidth: 70 }}>
						half
					</div>
					<div
						style={{
							display: "inline-block",
							minWidth: "calc(100% - 70px)",
						}}
					>
						<Select
							value={this.half}
							options={this.whichHalf}
							onChange={this.onMilleniumHalfChange}
							placeholder="Half"
						/>
					</div>
				</div>
			</>
		);
	}
}
