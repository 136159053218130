import React, { useCallback, useMemo } from "react";
import { useStoreProperty } from "./store";
import { IRUser } from "@app/api/users/helper-schemas";
import { User } from "@app/user";

export const useMurtskuUserId = (): number | undefined | null => {
	const user = useStoreProperty("user");
	if (!user || !user.userData) return undefined;
	return user.userData.murtskuId;
};

export const useCoursesUser = (): User | undefined => {
	const user = useStoreProperty("user");
	const getUserInstance = useCallback((userObj: IRUser) => {
		return User.createUserInstance(userObj);
	}, []);
	const userInfo = useMemo(() => {
		if (!user || !user.userData) return undefined;
		return getUserInstance(user.userData);
	}, [getUserInstance, user]);
	return userInfo;
};

export const useCoursesUserId = (): number | undefined => {
	const user = useStoreProperty("user");
	if (!user || !user.userData) return undefined;
	return user.userData.id;
};
