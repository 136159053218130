import React, { useState, useCallback, useRef } from "react";
import { IFileTabs, IFileTabSingleItem } from "@app/api/files/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { FileItem } from "./reading-files";
import styled from "react-emotion";
import LeftIcon from "@material-ui/icons/ChevronLeft";
import RightIcon from "@material-ui/icons/ChevronRight";
import {
	getHTMLElementCoords,
	animateWindowScroll,
} from "@app/commonJavascript";

export const FileTabs: React.FC<{ item: IFileTabs; courseId: ObjectId }> = ({
	item,
	courseId,
}) => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const selectedTab = item.items[selectedTabIndex];
	const divRef = useRef<HTMLDivElement>(null);
	const onTabChange = useCallback((index: number) => {
		setSelectedTabIndex(index);
		if (divRef.current) {
			const { top } = getHTMLElementCoords(divRef.current);
			animateWindowScroll(top - 200, 300);
		}
	}, []);

	return (
		<div ref={divRef}>
			<TabHeader
				selectedTabIndex={selectedTabIndex}
				items={item.items}
				onSelectedTabChange={setSelectedTabIndex}
			/>
			{selectedTab && (
				<FileItem courseId={courseId} item={selectedTab.item} />
			)}
			{selectedTab && (
				<FileFooter
					currentIndex={selectedTabIndex}
					previousItem={item.items[selectedTabIndex - 1] || null}
					nextItem={item.items[selectedTabIndex + 1] || null}
					onItemSwitch={onTabChange}
				/>
			)}
		</div>
	);
};

const TabHeader: React.FC<{
	onSelectedTabChange: (selectedTabIndex: number) => void;
	items: IFileTabSingleItem[];
	selectedTabIndex: number;
}> = React.memo(({ items, selectedTabIndex, onSelectedTabChange }) => {
	return (
		<TabsContainer>
			{items.map(({ title }, i) => {
				return (
					<SingleTab
						key={i}
						isSelected={selectedTabIndex === i}
						onClick={() => onSelectedTabChange(i)}
					>
						{title}
					</SingleTab>
				);
			})}
		</TabsContainer>
	);
});

const FileFooter: React.FC<{
	currentIndex: number;
	onItemSwitch: (newTabIndex: number) => void;
	previousItem: IFileTabSingleItem | null;
	nextItem: IFileTabSingleItem | null;
}> = React.memo(({ currentIndex, onItemSwitch, previousItem, nextItem }) => {
	const swicthToPrevious = useCallback(() => {
		onItemSwitch(currentIndex - 1);
	}, [currentIndex, onItemSwitch]);
	const swicthToNext = useCallback(() => {
		onItemSwitch(currentIndex + 1);
	}, [currentIndex, onItemSwitch]);

	return (
		<FileFooterContainer>
			<FileFooterButtonContainer align="left">
				{previousItem && (
					<TabBottomButton onClick={swicthToPrevious}>
						<LeftIcon />
						<div>{previousItem.title}</div>
					</TabBottomButton>
				)}
			</FileFooterButtonContainer>
			<div style={{ width: 10 }} />
			<FileFooterButtonContainer align="right">
				{nextItem && (
					<TabBottomButton onClick={swicthToNext}>
						<div>{nextItem.title}</div>
						<RightIcon />
					</TabBottomButton>
				)}
			</FileFooterButtonContainer>
		</FileFooterContainer>
	);
});

const TabsContainer = styled("div")({
	display: "flex",
	"overflow-x": "auto",
	width: "100%",
	"border-bottom": "1px solid #ccc",
});

const SingleTab = styled("div")(
	{
		padding: "5px 15px",
		whiteSpace: "nowrap",
		cursor: "pointer",
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
	},
	({ isSelected }: { isSelected: boolean }) => ({
		background: isSelected ? "#ccc" : "white",
		padding: isSelected ? "5px 15px" : "5px 10px",
		fontSize: isSelected ? "18px" : "16px",
	})
);

const FileFooterContainer = styled("div")({
	display: "flex",
	width: "100%",
});

const FileFooterButtonContainer = styled("div")(
	{
		flex: 1,
		display: "flex",
		width: "100%",
	},
	({ align }: { align: "left" | "right" }) => ({
		textAlign: align,
		justifyContent: align === "left" ? "flex-start" : "flex-end",
	})
);

const TabBottomButton = styled("button")({
	display: "flex",
	fontFamily: "FiraGo",
	border: "none",
	alignItems: "center",
	outline: "none",
	justifyContent: "center",
	padding: "7px 12px",
	background: "#2a6dc0",
	color: "white",
	borderRadius: 6,
	boxShadow: "rgba(0, 0, 0, 0.6) 0 2px 10px -3px",
	cursor: "pointer",
	transition: "0.7s",
	"&:hover": {
		boxShadow: "rgba(0, 0, 0, 0.6) 0 2px 10px 2px",
		background: "#1d4e8c",
	},
});
