import React, { useContext, useLayoutEffect, useMemo, useState } from "react";

const ParamsParentContext = React.createContext<{
	value: Record<any, any>;
	setValue: (newVal: Record<any, any>) => void;
}>({} as any);

export const useGlobalParams = <
	T extends Record<any, any> = Record<any, any>
>(): T => useContext(ParamsParentContext).value;

export const useGlobalParamProperty = (key: string): string | undefined =>
	useContext(ParamsParentContext).value[key];

export const ParamsContainer = React.memo<{ children: React.ReactNode }>(
	function ParamsContainer({ children }) {
		const [params, setParams] = useState<Record<any, any>>({});
		// TODO: merge params
		return (
			<ParamsParentContext.Provider
				value={useMemo(() => ({ value: params, setValue: setParams }), [
					params,
				])}
			>
				{children}
			</ParamsParentContext.Provider>
		);
	}
);

export const ParamsObserver = React.memo<{ params: Record<any, any> }>(
	function ParamsObserver({ params }) {
		const setParams = useContext(ParamsParentContext).setValue;
		useLayoutEffect(() => {
			setParams(params);
		}, [setParams, params]);
		return null;
	}
);
