import { IFileSingleItem, FileItemTypes } from "@app/api/files/helper-schemas";

export const normalizeFileItems = (
	items: IFileSingleItem[]
): IFileSingleItem[] => {
	return items.map(normalizeFileItem);
};

const normalizeFileItem = (item: IFileSingleItem): IFileSingleItem => {
	if (item.type === FileItemTypes.text) {
		return {
			...item,
			text: normalizeText(item.text),
		};
	}
	if (item.type === FileItemTypes.tabs) {
		return {
			...item,
			items: item.items.map(tabItem => ({
				...tabItem,
				item: normalizeFileItem(tabItem.item),
			})),
		};
	}
	return item;
};

const normalizeText = (text: string): string => {
	return text.replace(/cursor: (.+?){0,5}resize;/g, "");
};
