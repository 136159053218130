import { ItemType } from "@app/api/folders/helper-schemas";
import { IAPOSTCreateQuestion } from "@app/api/questions/validators";
import { inject } from "@app/modules";
import { mergeRecursive, removeKeys } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import { IQContentEditPassableProps } from "@tests-core/components/questions/contents/edit";
import QuestionEditwrapper from "@tests-core/components/questions/contents/edit-wrapper";
import { IFullQuestion } from "@tests-core/schemas/questions/helper-schemas";
import memoizeOne from "memoize-one";
import * as React from "react";
import "react-quill/dist/quill.snow.css";
import FancyLoading from "../../widgets/fancy-loading";
import GallerySelect from "../gallery";
import {
	HierarchyMultipleSelect,
	IHierarchyMultipleSelectDefaultSelections,
} from "../hierarchy-multiple-select";
import { getQuestionsEditCustomizedProps } from "./custom-props";
import { HierarchyItemType } from "@app/services/hierarchy-info/interfaces";
import { getLocale } from "@app/hooks/intl";
import { getFormattedMessage } from "@app/utils/locale";
import { MainButton } from "@app/components/users/styles/styledComponents";
import { FormattedMessage } from "react-intl";
import { successfulSaveArg } from "../types";
import { ContentType } from "@tests-core/schemas/questions/contnets/common-schemas";
import { ContentError } from "@tests-core/components/questions/contents/interfaces";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { css } from "emotion";

type IProps = {
	onSuccessSave: (args: successfulSaveArg) => void;
	handleQuestionWithText: (textId: ObjectId) => void;
	courseId: ObjectId;
} & (
	| { id: ObjectId; folderId?: ObjectId }
	| { id?: ObjectId; folderId: ObjectId }
);

interface IState {
	defaultSelectedTestIds?: ObjectId[];
	defaultSelectedTaskTypeIds?: ObjectId[];
	defaultSelectedTopicIds?: ObjectId[];
	defaultSelectedFolderIds?: ObjectId[];
}
class SingleQuestionForm extends React.PureComponent<IProps, IState> {
	private initialQuestion?: IFullQuestion;
	private id = this.props.id;
	private _isMounted = false;

	private hierarchyMultipleSelectRef = React.createRef<
		HierarchyMultipleSelect
	>();
	private foldersMultipleSelectRef = React.createRef<
		HierarchyMultipleSelect
	>();

	private _TestsController = inject("TestsController");
	private _QuestionsController = inject("QuestionsController");
	private _TopicsController = inject("TopicsController");
	private _TaskTypesController = inject("TaskTypesController");
	private _FoldersController = inject("FoldersController");

	state: IState = {};

	componentDidMount() {
		this._isMounted = true;
		if (this.id) {
			this._QuestionsController
				.getById({
					_id: this.id,
					getAnswer: true,
					courseId: this.props.courseId,
				})
				.then(question => {
					if (!this._isMounted) return;
					this.initialQuestion = question as IFullQuestion;
					if (this.initialQuestion.textId) {
						this.props.handleQuestionWithText(
							this.initialQuestion.textId
						);
					} else {
						this.forceUpdate();
					}
				});
			this._TestsController
				.getTestsByItem({
					courseId: this.props.courseId,
					itemId: this.id,
					itemType: ItemType.question,
				})
				.then(data => {
					this.setState({ defaultSelectedTestIds: data });
				});
			this._TaskTypesController
				.getByItem({
					courseId: this.props.courseId,
					itemId: this.id,
					itemType: ItemType.question,
				})
				.then(data => {
					this.setState({
						defaultSelectedTaskTypeIds: data,
					});
				});
			this._TopicsController
				.getByItem({
					courseId: this.props.courseId,
					itemId: this.id,
					itemType: ItemType.question,
				})
				.then(data => {
					this.setState({
						defaultSelectedTopicIds: data,
					});
				});
			this._FoldersController
				.getByItem({
					courseId: this.props.courseId,
					itemId: this.id,
					itemType: ItemType.question,
				})
				.then(data => {
					this.setState({
						defaultSelectedFolderIds: data,
					});
				});
		} else {
			this.setState({
				defaultSelectedTaskTypeIds: [],
				defaultSelectedTestIds: [],
				defaultSelectedTopicIds: [],
				defaultSelectedFolderIds: this.props.folderId
					? [this.props.folderId]
					: [],
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getSelections = (): Pick<
		IAPOSTCreateQuestion,
		"taskTypeIds" | "testIds" | "topicIds" | "folderIds"
	> => {
		const ref = this.hierarchyMultipleSelectRef.current!;
		const foldersRef = this.foldersMultipleSelectRef.current!;
		return {
			testIds: ref
				.getSelectedItemsByHierarchyType(HierarchyItemType.folder)
				.map(e => e.id),
			taskTypeIds: ref.getSelectedItemIdsByHierarchyType(
				HierarchyItemType.taskType
			),
			topicIds: ref.getSelectedItemIdsByHierarchyType(
				HierarchyItemType.topic
			),
			folderIds: foldersRef
				.getSelectedItemsByHierarchyType(HierarchyItemType.folder)
				.map(e => e.id),
		};
	};

	private handleErrors = (errors: ContentError[]) => {
		const mainError = getFormattedMessage("errorAlert");
		openConfirmationPopup({
			text: (
				<div>
					{mainError}
					<br />
					<br />
					{errors.map((error, i) => {
						const text = getFormattedMessage(
							`contentErrors.${error.errorToken}`
						);
						return <div key={i}>{text}</div>;
					})}
				</div>
			),
		});
	};

	onSave = (
		errors: ContentError[] | null,
		newQuestion: IFullQuestion,
		{ forcefullyEditGlobally }: { forcefullyEditGlobally: boolean } = {
			forcefullyEditGlobally: false,
		}
	) => {
		if (!newQuestion || !this.hierarchyMultipleSelectRef.current) return;
		if (errors) {
			this.handleErrors(errors);
			return;
		}
		const selectedItems = this.getSelections();
		this.hierarchyMultipleSelectRef.current.saveOptions();
		if (!this.id) {
			if (!this.props.folderId) {
				throw new Error(
					"folderId is required when adding new question"
				);
			}
			this._QuestionsController
				.add({
					courseId: this.props.courseId,
					question: newQuestion,
					...selectedItems,
				})
				.then(data => {
					this.props.onSuccessSave({
						folders: selectedItems.folderIds,
						topics: selectedItems.topicIds,
						taskTypes: selectedItems.taskTypeIds,
						item: {
							type: ItemType.question,
							id: data._id,
							name: data.shortStat,
						},
					});
				});
			return;
		}
		const removedKeysQuestion = removeKeys(
			newQuestion,
			"authorId",
			"domain",
			"createdAt",
			"updatedAt"
		);
		this._QuestionsController
			.update({
				_id: removedKeysQuestion._id,
				courseId: this.props.courseId,
				question: removedKeysQuestion,
				...selectedItems,
				forcefullyEditGlobally,
			})
			.then(data => {
				this.props.onSuccessSave({
					folders: selectedItems.folderIds,
					topics: selectedItems.topicIds,
					taskTypes: selectedItems.taskTypeIds,
					item: {
						id: removedKeysQuestion._id,
						type: ItemType.question,
						name: data.shortStat,
					},
				});
			});
		return;
	};

	private getDefaultSelections = memoizeOne(
		(): IHierarchyMultipleSelectDefaultSelections => {
			return {
				[HierarchyItemType.folder]: (
					this.state.defaultSelectedTestIds || []
				).map(e => ({ type: ItemType.test, id: e })),
				[HierarchyItemType.taskType]: (
					this.state.defaultSelectedTaskTypeIds || []
				).map(e => ({ type: -1, id: e })),
				[HierarchyItemType.topic]: (
					this.state.defaultSelectedTopicIds || []
				).map(e => ({ type: -1, id: e })),
			};
		}
	);

	private getDefaultFolders = memoizeOne(
		(): IHierarchyMultipleSelectDefaultSelections => {
			return {
				[HierarchyItemType.folder]: (
					this.state.defaultSelectedFolderIds || []
				).map(e => ({ type: ItemType.folder, id: e })),
			};
		}
	);

	private selectHierarchyItems = React.memo(() => {
		return (
			<HierarchyMultipleSelect
				ref={this.hierarchyMultipleSelectRef}
				courseId={this.props.courseId}
				hierarchiesToShow={[
					HierarchyItemType.folder,
					HierarchyItemType.taskType,
					HierarchyItemType.topic,
				]}
				itemTypesToBeStored={{
					[HierarchyItemType.folder]: {
						[ItemType.test]: true,
					},
				}}
				placeholders={{
					[HierarchyItemType.folder]: getFormattedMessage("tests"),
				}}
				defaultSelections={this.getDefaultSelections()}
				loadLastOptionsAutomatically={!this.props.id}
				alwaysHideLoadLastOptionsButton={true}
			/>
		);
	});

	private selectFolders = React.memo(() => {
		return (
			<HierarchyMultipleSelect
				ref={this.foldersMultipleSelectRef}
				courseId={this.props.courseId}
				hierarchiesToShow={[HierarchyItemType.folder]}
				itemTypesToBeStored={{
					[HierarchyItemType.folder]: {
						[ItemType.folder]: true,
					},
				}}
				multipleSelectProps={{
					[HierarchyItemType.folder]: {
						disjoinParentFromChildren: true,
					},
				}}
				placeholders={{
					[HierarchyItemType.folder]: getFormattedMessage("folders"),
				}}
				defaultSelections={this.getDefaultFolders()}
				loadLastOptionsAutomatically={
					!this.props.id && !this.props.folderId
				}
				alwaysHideLoadLastOptionsButton={true}
			/>
		);
	});

	loadPastOptions = () => {
		const ref = this.hierarchyMultipleSelectRef.current!;
		ref.loadPastOptions();
		// const foldersRef = this.foldersMultipleSelectRef.current!;
		// foldersRef.loadPastOptions();
	};

	beforeSaveButton = () => {
		const [areLastOptionsLoaded, setAreLastOptionsLoaded] = React.useState(
			!this.props.id && !this.props.folderId
		);
		const onLoad = React.useCallback(() => {
			this.loadPastOptions();
			setAreLastOptionsLoaded(true);
		}, []);

		const SelectHierarchyItems = this.selectHierarchyItems;
		const SelectFolders = this.selectFolders;
		const isEdit = !!this.id;

		return (
			<div>
				<div>
					<SelectHierarchyItems />
					<SelectFolders />
					{!areLastOptionsLoaded && (
						<MainButton
							onClick={onLoad}
							style={{ margin: "0 0 15px 0" }}
						>
							<FormattedMessage id="admin:general.loadLastOptions" />
						</MainButton>
					)}
				</div>
				{isEdit && (
					<button
						onClick={() => {
							const inst = this.questionEditwrapperRef.current!;
							const data = inst.getData();
							if (!data) return;
							this.onSave(inst.getErrors(), data, {
								forcefullyEditGlobally: true,
							});
						}}
						className={globallyEditButtonClassName}
					>
						გლობალურად შეცვლა ყველგან
					</button>
				)}
			</div>
		);
	};

	customizedProps = memoizeOne(
		(locale: string): IQContentEditPassableProps =>
			mergeRecursive(
				{
					commonProps: {
						galleryComponent: props => (
							<GallerySelect
								courseId={this.props.courseId}
								{...props}
							/>
						),
						commonComponents: {
							beforeSaveButton: this.beforeSaveButton,
						},
					} as IQContentEditPassableProps["commonProps"],
				},
				getQuestionsEditCustomizedProps(locale)
			)
	);

	questionEditwrapperRef = React.createRef<QuestionEditwrapper>();

	render() {
		if (
			(this.props.id && !this.initialQuestion) ||
			!this.state.defaultSelectedTaskTypeIds ||
			!this.state.defaultSelectedTestIds ||
			!this.state.defaultSelectedTopicIds ||
			!this.state.defaultSelectedFolderIds
		) {
			return <FancyLoading />;
		}
		return (
			<React.Fragment>
				<QuestionEditwrapper
					ref={this.questionEditwrapperRef}
					onSave={this.onSave}
					defaultQuestion={this.initialQuestion}
					defaultContentType={ContentType.MultipleChoice}
					customized={this.customizedProps(getLocale())}
				/>
			</React.Fragment>
		);
	}
}

export default SingleQuestionForm;

const globallyEditButtonClassName = css`
	height: 40px;
	border: none;
	border-radius: 20px;
	color: white;
	background: #673ab7;
	font-size: 18px;
	box-shadow: 0 0 0 1px inset #673ab7, 0 0 0 3px inset white;
	font-family: FiraGO;
	padding: 0 15px;
	margin-bottom: 5px;
	cursor: pointer;
`;
