import Joi from "@app/utils/joi";
import { IAnyObj } from "@app/utils/generics";

export const ModelingTestSchema = Joi.object({
	id: Joi.number()
		.integer()
		.required(),
	name: Joi.string().required(),
	course_id: Joi.string().required(),
	folder_id: Joi.string().required(),
	test_id: Joi.string().required(),
	subject_id: Joi.number()
		.integer()
		.required(),
	ordinal: Joi.number()
		.integer()
		.required(),
	start_time: Joi.date().required(),
	finish_time: Joi.date().required(),
	max_duration: Joi.number().required(),
	extra_info: Joi.object().allow(null),
	stats: Joi.object().allow(null),
	review_start_time: Joi.date()
		.required()
		.allow(null),
	review_finish_time: Joi.date()
		.required()
		.allow(null),
	created_at: Joi.date().required(),
	updated_at: Joi.date().required(),
});
export interface IModelingTest {
	id: number;
	name: string;
	course_id: string;
	folder_id: string;
	test_id: string;
	subject_id: number;
	ordinal: number;
	start_time: Date;
	finish_time: Date;
	max_duration: number;
	extra_info: IAnyObj | null;
	stats: IAnyObj | null;
	review_start_time: Date | null;
	review_finish_time: Date | null;
	created_at: Date;
	updated_at: Date;
}
