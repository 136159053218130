import { ItemType } from "@app/api/folders/helper-schemas";
import {
	ITestTypeSettings,
	TestTypeAnswersShowTime,
} from "@app/api/test-types/helper-schemas";
import {
	IEditorAssessments,
	IRTest,
	IUserTestQuestionInfo,
} from "@app/api/tests/helper-schemas";
import { IRGETTestContents } from "@app/api/tests/validators";
import { getQuestionsCustomizedProps } from "@app/components/admin/questions/custom-props";
import FancyLoading from "@app/components/widgets/fancy-loading";
import { getLocale } from "@app/hooks/intl";
import { ObjectId } from "@app/utils/generics";
import { css } from "emotion";
import memoizeOne from "memoize-one";
import * as React from "react";
import {
	IAGetTestQuestion,
	IFinishPageProps,
	INextButtonProps,
	IRGetTestQuestion,
	ITestComponentProps,
	ITestFinishArgs,
	TestComponent,
} from "../../../../@tests-core/components/tests";
import { TestNavigation } from "../../../../@tests-core/components/tests/navigation";
import { MainButton } from "../styles/styledComponents";
import FeedbackPage from "./feedback-page";
import { getDecomposedContentIds } from "./functions/decompose";
import { getQuestionIdsOfFirstNotFullyAskedText } from "./functions/text-questions";
import { ModellingFinishComponent, StandardFinishComponent } from "./standard";

interface IProps {
	test: IRTest;
	courseId: ObjectId;
	folderId: ObjectId;
	testTypeSettings: ITestTypeSettings;
	currentAttempt: number;
	onFinish: (args: ITestFinishArgs) => Promise<any>;
	onSave: (args: ITestFinishArgs) => Promise<any>;
	onGotoNext: () => void;
	onResetTestProgress: () => void;
	onResetFolders: (progressResetFolderIds: ObjectId[]) => void;
	redirectoToFolder: () => void;
	content: IRGETTestContents;
	defaultUserAnswers: IUserTestQuestionInfo[];
	editorAssessments?: IEditorAssessments;
}

interface IState {
	isLoading: boolean;
	isFinished: boolean;
}

export class UniversalTestWrapper extends React.PureComponent<IProps, IState> {
	state: IState = {
		isLoading: false,
		isFinished: false,
	};

	testComponentRef = React.createRef<TestComponent>();

	componentWillUnmount() {
		if (this.testComponentRef.current && !this.state.isFinished) {
			this.props.onSave(this.testComponentRef.current.getFinishArgs());
		}
	}

	getNextQuestion = async (
		args: IAGetTestQuestion
	): Promise<IRGetTestQuestion> => {
		const { test } = this.props;
		if (args.unknownQuestionIds.length === 0) {
			return {
				isFinished: true,
			};
		}
		if (
			test.settings &&
			test.settings.contentIds &&
			test.settings.isContentSorted
		) {
			const decomposedContentIds = getDecomposedContentIds(
				test.settings.contentIds,
				this.props.content.texts
			);
			const q = decomposedContentIds[args.questionIndexToRequest];
			if (
				q !== undefined &&
				q.type === ItemType.question &&
				args.selectedQuestionIds.indexOf(q.id) === -1
			) {
				return {
					isFinished: false,
					questionId: q.id,
				};
			}
		}
		if (
			this.props.testTypeSettings.maxNumOfQuestions &&
			args.answers.filter(e => e !== null && e !== undefined).length >=
				this.props.testTypeSettings.maxNumOfQuestions
		) {
			return {
				isFinished: true,
			};
		}
		const textQuestionIds = getQuestionIdsOfFirstNotFullyAskedText(args);
		if (textQuestionIds.length > 0) {
			return {
				isFinished: false,
				questionId: textQuestionIds[0],
			};
		}
		const index = Math.floor(
			Math.random() * args.unknownQuestionIds.length
		);
		return {
			isFinished: false,
			questionId: args.unknownQuestionIds[index],
		};
	};

	nextButton: React.FC<INextButtonProps> = props => {
		const warned = (): boolean => {
			if (props.isFinished) return false;
			if (props.userAnswerInfo.isFullyAnswered) return false;
			alert("შემოხაზეთ პასუხი");
			return true;
		};
		const isAlreadySubmitted = props.userAnswerInfo.submitted;
		if (
			!props.userAnswerInfo.submitted &&
			this.props.testTypeSettings.submitAnswerAfterAnswering &&
			this.props.testTypeSettings.showAnswersAt ===
				TestTypeAnswersShowTime.immediately
		) {
			return (
				<MainButton
					onClick={() => {
						if (warned()) return;
						props.onClick({
							gotoNext: false,
							submit: isAlreadySubmitted
								? undefined
								: !!this.props.testTypeSettings
										.submitAnswerAfterAnswering,
						});
					}}
					isFaded={
						!props.isFinished &&
						!props.userAnswerInfo.isFullyAnswered
					}
				>
					დასტური
				</MainButton>
			);
		}
		return (
			<MainButton
				onClick={() => {
					if (warned()) return;
					props.onClick({
						gotoNext: true,
						submit: isAlreadySubmitted
							? undefined
							: !!this.props.testTypeSettings
									.submitAnswerAfterAnswering,
					});
				}}
				isFaded={
					!props.isFinished && !props.userAnswerInfo.isFullyAnswered
				}
			>
				{!props.userAnswerInfo.submitted &&
				this.props.testTypeSettings.submitAnswerAfterAnswering
					? "დასტური"
					: "შემდეგი"}
			</MainButton>
		);
	};

	onFinish = (args: ITestFinishArgs) => {
		this.setState({
			isFinished: true,
		});
		return this.props.onFinish(args);
	};

	FinishPage = (props: IFinishPageProps) => {
		if (this.props.testTypeSettings.showStatsAfterEnd) {
			return (
				<FeedbackPage
					{...props}
					courseId={this.props.courseId}
					folderId={this.props.folderId}
					onGotoNext={this.props.onGotoNext}
					onResetTestProgress={this.props.onResetTestProgress}
					onResetFolders={this.props.onResetFolders}
					redirectoToFolder={this.props.redirectoToFolder}
					testTypeSettings={this.props.testTypeSettings}
					currentAttempt={this.props.currentAttempt}
				/>
			);
		}
		let FinishComponent = StandardFinishComponent;
		if (this.props.test.name === "მოდელირება") {
			FinishComponent = ModellingFinishComponent;
		}
		return (
			<FinishComponent
				{...props}
				test={this.props.test}
				testTypeSettings={this.props.testTypeSettings}
				onGotoNext={this.props.onGotoNext}
				onResetTestProgress={this.props.onResetTestProgress}
				currentAttempt={this.props.currentAttempt}
			/>
		);
	};

	components: ITestComponentProps["components"] = {
		FinishPage: this.FinishPage,
		Navigation: TestNavigation,
		NextButton: this.nextButton,
		Loading: FancyLoading,
	};

	getInitialQuestionsInfo = memoizeOne(
		(): ITestComponentProps["initialQuestionsInfo"] => {
			const uniqueAnswersLength = [
				...new Set(this.props.defaultUserAnswers.map(q => q.id)),
			].length;
			const questionsLength = this.props.content.questions.length;
			const isFinished = uniqueAnswersLength >= questionsLength;
			const submitInitialAnswers = !isFinished
				? !!this.props.testTypeSettings.submitAnswerAfterAnswering
				: true;
			const editorAssessmentsOfQuestions = this.props.editorAssessments
				? this.props.editorAssessments.questions
				: {};
			const userAnswers: ITestComponentProps["initialQuestionsInfo"] = [];
			const leavOnlyUniqueAnswers = true;
			const answeredQuestionsObj: Record<string, true | undefined> = {};
			for (const ans of this.props.defaultUserAnswers) {
				if (leavOnlyUniqueAnswers && answeredQuestionsObj[ans.id]) {
					continue;
				}
				answeredQuestionsObj[ans.id] = true;
				userAnswers.push({
					questionId: ans.id,
					userAnswer: ans.userAnswer,
					submitted:
						ans.userAnswer !== undefined
							? submitInitialAnswers
							: false,
					assessment: editorAssessmentsOfQuestions[ans.id],
				});
			}
			const { test } = this.props;
			if (
				test.settings &&
				test.settings.contentIds &&
				test.settings.isContentSorted
			) {
				const sortedQuestionInfos = getDecomposedContentIds(
					test.settings.contentIds,
					this.props.content.texts
				);
				const sortedUserAnswers: ITestComponentProps["initialQuestionsInfo"] = [];
				for (let i = 0; i < sortedQuestionInfos.length; ++i) {
					const questionId = sortedQuestionInfos[i].id;
					const ans = userAnswers.find(
						e => e && e.questionId === questionId
					);
					if (ans) {
						sortedUserAnswers[i] = ans;
					}
				}
				return sortedUserAnswers;
			}
			return userAnswers;
		}
	);

	getQuestionsCustomizedProps = memoizeOne((locale: string) => {
		return getQuestionsCustomizedProps(locale, true);
	});

	render() {
		if (this.state.isLoading) {
			return <FancyLoading />;
		}
		const {
			allowSwitchingToSubmittedQuestions,
			allowSwitchingToUnsubmittedQuestions,
		} = this.props.testTypeSettings;
		let showAnswersAfterAnswering = true;
		if (
			this.props.testTypeSettings.showAnswersAt ===
				TestTypeAnswersShowTime.afterFinish &&
			!this.state.isFinished
		) {
			showAnswersAfterAnswering = false;
		}
		if (
			this.props.testTypeSettings.showAnswersAt ===
			TestTypeAnswersShowTime.afterDeadline
		) {
			showAnswersAfterAnswering = false;
		}

		return (
			<div className="main">
				<div className={containerCSS}>
					<TestComponent
						ref={this.testComponentRef}
						key={this.props.test._id}
						allowSwitchingToSubmittedQuestions={
							this.state.isFinished
								? true
								: allowSwitchingToSubmittedQuestions
						}
						allowSwitchingToUnsubmittedQuestions={
							this.state.isFinished
								? true
								: allowSwitchingToUnsubmittedQuestions
						}
						showAnswersOfSubmittedQuestions={
							showAnswersAfterAnswering
						}
						questions={this.props.content.questions}
						onFinish={this.onFinish}
						getQuestion={this.getNextQuestion}
						components={this.components}
						texts={this.props.content.texts}
						knownNumberOfQuestions={
							this.props.testTypeSettings.maxNumOfQuestions
								? Math.min(
										this.props.testTypeSettings
											.maxNumOfQuestions,
										this.props.content.questions.length
								  )
								: this.props.content.questions.length
						}
						showTextsOnSeparatePage={false}
						initialQuestionsInfo={this.getInitialQuestionsInfo()}
						questionsCustomizedProps={this.getQuestionsCustomizedProps(
							getLocale()
						)}
					/>
				</div>
			</div>
		);
	}
}

const containerCSS = css`
	font-family: FiraGo;
	padding: 20px 20px 70px 20px;
`;
