import React from "react";
import Helmet from "react-helmet";
import { designDistributor } from "./distributor";

const CreateBGComp = (color: string) =>
	React.memo(() => (
		<Helmet
			bodyAttributes={{
				style: ("background-color: " + color) as any,
			}}
		/>
	));

export const CourseBackground = designDistributor({
	pillars: CreateBGComp("#152958"),
	universal: CreateBGComp("#1D3557"),
});
