import { TransformPropertiesToOtherType } from "@app/utils/generics";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";

export function useForceUpdate() {
	const [, set] = useState(0);
	const forceUpdate = useCallback(() => {
		set(v => v + 1);
	}, []);
	return forceUpdate;
}

export function useBoolean(defaultValue?: boolean) {
	const [value, setValue] = useState(defaultValue || false);
	const setTrue = useCallback(() => {
		setValue(true);
	}, []);
	const setFalse = useCallback(() => {
		setValue(false);
	}, []);
	const switchValue = useCallback(() => {
		setValue(c => !c);
	}, []);
	return { value, setValue, setTrue, setFalse, switchValue } as const;
}

export const useErrors = <T extends {}>(
	defaultErrors?: Partial<TransformPropertiesToOtherType<T, string>> | null
) => {
	type ErrorType = Partial<TransformPropertiesToOtherType<T, string>>;
	const [errors, setErrors] = useState<ErrorType | null>(
		defaultErrors || null
	);
	type ErrorKeys = keyof NonNullable<typeof errors>;
	const areErrorsEmpty = useRef(!errors);
	areErrorsEmpty.current = !errors;

	const removeErrorMessage = useCallback((keyName: ErrorKeys) => {
		if (!areErrorsEmpty.current) {
			setErrors(e => (!e ? null : { ...e, [keyName]: undefined }));
		}
	}, []);

	const setErrorMessage = useCallback((keyName: ErrorKeys, value: string) => {
		setErrors(e =>
			!e
				? ({ [keyName]: value } as ErrorType)
				: ({ ...e, [keyName]: value } as ErrorType)
		);
	}, []);

	return {
		errors,
		removeErrorMessage,
		setErrorMessage,
		setErrors,
	};
};

export const useDynamicRef = <T extends any>(
	value: T
): { readonly current: T } => {
	const ref = useRef(value);
	ref.current = value;
	return ref;
};

export interface IMountingInfo {
	isMounted: boolean;
	isFirstMounting: boolean;
	hasFinishedFirstMountingCycle: boolean;
}
export const useMountingInfo = (): IMountingInfo => {
	const isMounted = useState({
		isMounted: true,
		isFirstMounting: true,
		hasFinishedFirstMountingCycle: false,
	})[0];
	useEffect(() => {
		if (!isMounted.isFirstMounting) {
			isMounted.hasFinishedFirstMountingCycle = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMounted.isFirstMounting]);
	useEffect(() => {
		isMounted.isMounted = true;
		isMounted.isFirstMounting = false;
		setTimeout(() => {
			isMounted.hasFinishedFirstMountingCycle = true;
		}, 0);
		return () => {
			isMounted.isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return isMounted;
};

export const useDynamicValueChange = <T extends {} | null | undefined>(
	onChange: (newVal: (oldValue: T) => T) => void
): (<K extends keyof NonNullable<T>>(
	key: K
) => (
	value:
		| ((oldValue: NonNullable<T>[K]) => NonNullable<T>[K])
		| NonNullable<T>[K]
) => void) => {
	return useCallback(
		<K extends keyof NonNullable<T>>(key: K) => (
			value:
				| NonNullable<T>[K]
				| ((oldValue: NonNullable<T>[K]) => NonNullable<T>[K])
		) => {
			if (typeof value !== "function") {
				onChange(old =>
					old
						? {
								...old,
								[key]: value,
						  }
						: old
				);
			} else {
				onChange(old => {
					if (!old) return old;
					const newVal = (value as any)(
						old[key as any]
					) as NonNullable<T>[K];
					return {
						...old,
						[key]: newVal,
					};
				});
			}
		},
		[onChange]
	);
};

export const useValueChangeWithRef = <T extends {}>(
	onChange: (newVal: T) => void,
	ref: React.MutableRefObject<T>
): (<K extends keyof T>(key: K) => (value: T[K]) => void) => {
	return useCallback(
		<K extends keyof T>(key: K) => (value: T[K]) => {
			onChange({
				...ref.current,
				[key]: value,
			});
		},
		[onChange, ref]
	);
};

export const useFuncCall = <A extends any[], R>(
	func: (...args: A) => R,
	...args: A
): R => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	return useMemo(() => func(...args), [JSON.stringify(args), func]);
};
