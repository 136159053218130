type IsReservedFn = (key: string) => boolean;

export const clearAllKeysExcept = (
	storage: Storage,
	/** array of (keys that must not be deleted, or functions which return true if key must not be deleted) */
	keys: (string | IsReservedFn)[]
) => {
	const reservedKeys = new Set(
		keys.filter((key): key is string => typeof key === "string")
	);
	const reservedKeyFns = keys.filter(
		(key): key is (key: string) => boolean => typeof key === "function"
	);
	const keysThatShouldBeDeleted = new Set<string>();
	for (let i = 0; i < storage.length; i++) {
		const key = storage.key(i);
		if (key === null) continue;
		if (
			!reservedKeys.has(key) &&
			!reservedKeyFns.some(shouldLeave => shouldLeave(key))
		) {
			keysThatShouldBeDeleted.add(key);
		}
	}
	for (const key of keysThatShouldBeDeleted) {
		storage.removeItem(key);
	}
};
