import { PropertyType } from "../schemas/cards/templates";

export function isPropertyTypeArray(type: PropertyType): boolean {
	return type === PropertyType.TextArray;
}

export function arrayTypeToSingle(type: PropertyType) {
	// tslint:disable-next-line:no-collapsible-if
	if (isPropertyTypeArray(type)) {
		if (type === PropertyType.TextArray) {
			return PropertyType.Text;
		}
	}
	return type;
}
