import * as React from "react";
import Arrow from "@material-ui/icons/KeyboardArrowDown";
import List from "@material-ui/icons/List";
import Lock from "@material-ui/icons/Lock";
import {
	AnglePath,
	ArrowStyleOpen,
	ArrowStyles,
	BlueBase,
	CellStyles,
	FilesTextStyles,
	FolderNameStyles,
	LockStyles,
	StraightPath,
} from "./styles";
import { connect } from "react-redux";
import {
	IFileItem,
	ItemType,
	ITestItem,
} from "@app/api/folders/helper-schemas";
import { inject } from "@app/modules";
import { IOpenIds, ISelectedItem, ISelectionChangeFunc } from "..";
import { IRootState } from "@app/redux";
import { ObjectId } from "@app/utils/generics";
import FancyLoading from "@app/components/widgets/fancy-loading";

interface IOwnProps {
	name: string;
	id: string;
	courseId: string;
	openIds: IOpenIds;
	isLocked: boolean;
	onOpenContentChange: (id: string, isOpening: boolean) => void;
	onSelectionChange: ISelectionChangeFunc;
	selection: ISelectedItem;
	userId: number;
}

export class Content extends React.PureComponent<IOwnProps> {
	itemStyles: React.CSSProperties = {
		marginTop: 30,
	};

	_UserFolderProgressService = inject("UserFolderProgressService");
	FolderHierarchyService = inject("FolderHierarchyService");
	FolderModel = inject("FolderModel");

	onContentOpen = () => {
		this.props.onOpenContentChange(this.props.id, true);
	};

	onContentClose = () => {
		this.props.onOpenContentChange(this.props.id, false);
	};

	render() {
		const myFolder = this.FolderModel.findByIdSync(this.props.id);
		if (!myFolder) return <FancyLoading />;
		const parentId = this.FolderHierarchyService.getParentIdSync(
			this.props.courseId,
			myFolder._id
		);
		const isOpen = !!this.props.openIds[this.props.id];
		const arrowOnClick = isOpen ? this.onContentClose : this.onContentOpen;
		const isLocked = this.props.isLocked;
		let isFirst = true;
		let prevIsFirst = true;
		const myProgress = this._UserFolderProgressService.getProgressOfItemSync(
			{
				courseId: this.props.courseId,
				folderId: parentId!,
				itemId: myFolder._id,
				itemType: ItemType.folder,
				userId: this.props.userId,
			}
		);
		let displayIndex = -1;
		return (
			<div>
				<FolderComponent
					name={this.props.name}
					isLocked={isLocked}
					isOpen={isOpen}
					onClick={arrowOnClick}
					isDone={myProgress === 1}
				/>
				{isOpen && !isLocked && myFolder && (
					<div style={{ paddingLeft: 40 }}>
						{myFolder.items &&
							myFolder.items.map((item, index) => {
								if (
									item.type !== ItemType.folder &&
									item.type !== ItemType.file &&
									item.type !== ItemType.test
								) {
									return null;
								}
								if (item.isHidden) return null;
								const itemProgress = this._UserFolderProgressService.getProgressOfItemSync(
									{
										courseId: this.props.courseId,
										folderId: myFolder!._id,
										userId: this.props.userId,
										itemId: item.id,
										itemType: item.type,
									}
								);
								const isItemLocked = this._UserFolderProgressService.isItemLockedSync(
									{
										courseId: this.props.courseId,
										folderId: myFolder!._id,
										userId: this.props.userId,
										itemId: item.id,
										itemType: item.type,
									}
								);
								prevIsFirst = isFirst;
								isFirst = false;
								if (
									item.type === ItemType.file ||
									item.type === ItemType.test
								) {
									displayIndex++;
									const isSelected =
										!!this.props.selection &&
										this.props.selection.id === item.id &&
										this.props.selection.folderId ===
											myFolder!._id;
									return (
										<ItemComponent
											key={item.id}
											item={item}
											onSelectionChange={
												this.props.onSelectionChange
											}
											folderId={myFolder!._id}
											num={displayIndex + 1}
											isSelected={isSelected}
											style={this.itemStyles}
											isDone={itemProgress === 1}
											isLocked={isItemLocked}
										>
											{prevIsFirst ? (
												<AnglePath />
											) : (
												<StraightPath />
											)}
										</ItemComponent>
									);
								}
								if (item.type === ItemType.folder) {
									displayIndex++;
									return (
										<div
											style={{ marginTop: 20 }}
											key={item.id}
										>
											<Content
												{...this.props}
												key={item.id}
												isLocked={isItemLocked}
												id={item.id}
												name={item.name}
											/>
										</div>
									);
								}
								return null;
							})}
					</div>
				)}
			</div>
		);
	}
}

interface IItemComponentProps {
	isLocked: boolean;
	item: IFileItem | ITestItem;
	onSelectionChange: ISelectionChangeFunc;
	folderId: ObjectId;
	num: number;
	isSelected: boolean;
	style?: React.CSSProperties;
	isDone: boolean;
}

export const ItemComponent: React.FC<IItemComponentProps> = props => {
	return (
		<div
			key={props.item.id}
			style={{
				cursor: "pointer",
				position: "relative",
				margin: "5px 0px",
				display: "flex",
				...props.style,
			}}
			onClick={() =>
				props.onSelectionChange(
					props.item.id,
					props.item.type,
					props.folderId
				)
			}
		>
			{props.children}
			<BlueBase
				style={{ paddingLeft: 9, paddingTop: 3 }}
				isDone={props.isDone}
			>
				{props.num}
			</BlueBase>
			<FilesTextStyles
				isSelected={props.isSelected}
				isLocked={props.isLocked}
			>
				<div>{props.item.name}</div>
			</FilesTextStyles>

			{props.isLocked && (
				<LockStyles>
					<Lock />
				</LockStyles>
			)}
		</div>
	);
};

interface IFolderComponentProps {
	name: string;
	isOpen: boolean;
	isLocked: boolean;
	onClick: () => void;
	isDone: boolean;
}

const FolderComponent: React.FC<IFolderComponentProps> = props => (
	<CellStyles onClick={props.onClick}>
		<BlueList isDone={props.isDone} />
		<FolderNameStyles isOpen={props.isOpen} isLocked={props.isLocked}>
			{props.name}
		</FolderNameStyles>
		{props.isLocked ? (
			<LockStyles>
				<Lock />
			</LockStyles>
		) : (
			<ArrowStyles className={props.isOpen ? ArrowStyleOpen : undefined}>
				<Arrow />
			</ArrowStyles>
		)}
	</CellStyles>
);

interface IBlueBaseProps {
	isDone: boolean;
}

const BlueList: React.FC<IBlueBaseProps> = props => {
	return (
		<div style={{ flex: 1, verticalAlign: "middle" }}>
			<BlueBase
				style={{ paddingLeft: 3, paddingTop: 3 }}
				isDone={props.isDone}
			>
				<List />
			</BlueBase>
		</div>
	);
};
