import * as queryString from "query-string";
import * as React from "react";
import ContentsNavigation from "./navigation";
import FancyLoading from "@app/components/widgets/fancy-loading";
import Popup, { PopupContent } from "@app/components/widgets/Popup";
import ReadingFile from "./reading-files";
import {
	animateWindowScroll,
	getHTMLElementCoords,
	addLoader,
} from "@app/commonJavascript";
import { connect } from "react-redux";
import { css } from "react-emotion";
import { History } from "history";
import { IFolderItem, ItemType } from "@app/api/folders/helper-schemas";
import { inject } from "@app/modules";
import { IRootState } from "@app/redux";
import { match } from "react-router-dom";
import { ObjectId } from "@app/utils/generics";
import { TestLoader } from "../tests/test-loader";

interface IOwnProps {
	match: match<{ courseId: string; folderId: string }>;
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type ISubTopicsContainerProps = IOwnProps & IStateProps;

export type ISelectedItem =
	| {
			type: ItemType;
			id: ObjectId;
			folderId: ObjectId;
	  }
	| undefined;

export type ISelectionChangeFunc = (
	id: ObjectId,
	type: ItemType,
	folderId: ObjectId
) => void;

interface ISubTopicsContentState {
	openIds: IOpenIds;
	selection: ISelectedItem;
	isLoaded: boolean;
	lastTestPopupInfo?: {
		itemId: ObjectId;
		itemType: ItemType;
		folderId: ObjectId;
	};
}

export interface IOpenIds {
	[itemId: string]: true | undefined;
}

class SubTopicsContent extends React.Component<
	ISubTopicsContainerProps,
	ISubTopicsContentState
> {
	courseId = this.props.match.params.courseId;
	folderId = this.props.match.params.folderId;

	_Folder = inject("FolderModel");
	_Test = inject("TestModel");
	_TestsController = inject("TestsController");
	_TestTypesController = inject("TestTypesController");
	_UserFolderProgressService = inject("UserFolderProgressService");
	_CourseFetchingController = inject("CourseFetchingController");
	_FolderHierarchyService = inject("FolderHierarchyService");

	state: ISubTopicsContentState = {
		openIds: {},
		selection: undefined,
		isLoaded: false,
	};

	rightNavRef: React.RefObject<HTMLDivElement> = React.createRef();

	componentDidMount() {
		const promises: Promise<any>[] = [];
		promises.push(
			this._CourseFetchingController.loadWholeCourseSkeleton({
				courseId: this.courseId,
			})
		);
		promises.push(this._TestTypesController.getAll());
		Promise.all(promises).then(() => {
			this.setState({
				isLoaded: true,
			});
			const folder = this._Folder.findByIdSync(this.folderId);
			if (!folder) return;
			const parentId = this._FolderHierarchyService.getParentIdSync(
				this.courseId,
				folder._id
			);
			const myProgress = this._UserFolderProgressService.getProgressOfItemSync(
				{
					itemId: folder._id,
					itemType: ItemType.folder,
					folderId: parentId!,
					courseId: this.courseId,
					userId: this.props.userData.id,
				}
			);

			if (myProgress < 1) {
				this.openFirstUndoneItemOfFolder(this.folderId);
				return;
			}

			let hasFoundItemFromQuery = false;
			const searchQuery = this.props.history.location.search;
			try {
				if (searchQuery) {
					// check whether url tells us to open certain item
					const query = queryString.parse(searchQuery);
					if (
						query.itemType &&
						query.itemId &&
						query.itemParentFolder &&
						typeof query.itemId === "string" &&
						typeof query.itemParentFolder === "string"
					) {
						const itemType = +query.itemType as ItemType;
						hasFoundItemFromQuery = true;
						this.onSelectionChange(
							query.itemId,
							itemType,
							query.itemParentFolder
						);
					}
				}
			} catch (e) {
				hasFoundItemFromQuery = false;
			}

			if (!hasFoundItemFromQuery && myProgress === 1 && folder.items) {
				this.openFirstItem(folder._id);
			}
		});
	}

	onOpenContentChange = (id: string, isOpening: boolean) => {
		if (isOpening) {
			this.setState(({ openIds }) => ({
				openIds: { ...openIds, [id]: true },
			}));
		} else {
			this.setState(({ openIds }) => {
				const newOpenIds = { ...openIds };
				delete newOpenIds[id];
				return { openIds: newOpenIds };
			});
		}
	};

	moveToFolderContents = (
		folderId: ObjectId,
		itemType: ItemType,
		itemId: ObjectId,
		itemParentFolder: ObjectId
	) => {
		this.props.history.push(
			`/courses/${this.courseId}/folders/${folderId}/content?itemType=${itemType}&itemId=${itemId}&itemParentFolder=${itemParentFolder}`
		);
	};

	moveToTestPage = (testId: ObjectId, parentFolderId: ObjectId) => {
		this.props.history.push(
			`/courses/${this.courseId}/folders/${parentFolderId}/tests/${testId}`
		);
	};

	getSiblingWhichHasDescendantFolderOfId = (
		folderId: ObjectId
	): null | ObjectId => {
		const folder = this._Folder.findByIdSync(folderId);
		const myFolder = this._Folder.findByIdSync(this.folderId);
		if (!folder || !myFolder) return null;
		const myParentId = this._FolderHierarchyService.getParentIdSync(
			this.courseId,
			myFolder._id
		);
		if (myParentId === folder._id) return folder._id;
		while (folderId) {
			const nextFolder = this._Folder.findByIdSync(folderId);
			if (!nextFolder) return null;
			const nextParentId = this._FolderHierarchyService.getParentIdSync(
				this.courseId,
				nextFolder._id
			);
			if (nextParentId === myParentId) return folderId;
			folderId = nextParentId!;
		}
		return null;
	};

	isDescendant = (
		itemId: ObjectId,
		itemType: ItemType,
		folderId: ObjectId
	): boolean => {
		const folderItems = this._Folder.getItemsByFolderIdSync(folderId);
		if (!folderItems) return false;
		const isMyChild =
			folderItems.findIndex(
				item => item.id === itemId && item.type === itemType
			) > -1;
		if (isMyChild) return true;
		for (let i = 0; i < folderItems.length; ++i) {
			if (
				folderItems[i].type === ItemType.folder &&
				this.isDescendant(itemId, itemType, folderItems[i].id)
			) {
				return true;
			}
		}
		return false;
	};

	checkIfShouldGoToAnotherPage = (
		id: ObjectId,
		type: ItemType,
		folderId: ObjectId
	) => {
		if (!this.isDescendant(id, type, this.folderId)) {
			const comAncestor = this.getSiblingWhichHasDescendantFolderOfId(
				folderId
			);
			if (!comAncestor) {
				this.props.history.push(`/courses/${this.courseId}/guide`);
				return true;
			}
			this.moveToFolderContents(comAncestor, type, id, folderId);
			return true;
		}
		return false;
	};

	onSelectionChange: ISelectionChangeFunc = (
		id: ObjectId,
		type: ItemType,
		folderId: ObjectId
	) => {
		if (type === ItemType.test) {
			const removeLoader = addLoader();
			this._TestsController
				.loadTest({ testId: id })
				.then(({ test, testTypeSettings }) => {
					removeLoader();
					if (
						testTypeSettings &&
						testTypeSettings.showStatsAfterEnd
					) {
						const progress = this._UserFolderProgressService.getProgressOfItemSync(
							{
								itemId: id,
								itemType: type,
								folderId,
								courseId: this.courseId,
								userId: this.props.userData.id,
							}
						);
						if (progress > 0) {
							this.moveToTestPage(id, folderId);
						} else {
							this.showPopupOfLastTest(id, type, folderId);
						}
						return;
					}
					this.checkIfShouldGoToAnotherPage(id, type, folderId);
				})
				.catch(e => {
					removeLoader();
					console.log(e);
					alert("დაფიქსირდა შეცდომა");
				});
		} else if (this.checkIfShouldGoToAnotherPage(id, type, folderId)) {
			return;
		}
		const isLocked = this._UserFolderProgressService.isItemLockedSync({
			courseId: this.courseId,
			itemId: id,
			itemType: type,
			folderId,
			userId: this.props.userData.id,
		});
		if (!isLocked) {
			this.setState({
				selection: {
					type,
					id,
					folderId,
				},
			});
			if (this.rightNavRef.current) {
				const coordinates = getHTMLElementCoords(
					this.rightNavRef.current
				);
				animateWindowScroll(coordinates.top - 100, 300);
			}
			this.openFoldersRecursively(folderId);
		}
	};

	showPopupOfLastTest = (
		id: ObjectId,
		type: ItemType,
		folderId: ObjectId
	) => {
		this.setState({
			lastTestPopupInfo: { itemId: id, itemType: type, folderId },
		});
	};
	// opens parent folders
	openFoldersRecursively = (folderId: ObjectId) => {
		this.onOpenContentChange(folderId, true);
		const folder = this._Folder.findByIdSync(folderId);
		const parentId = this._FolderHierarchyService.getParentIdSync(
			this.courseId,
			folderId
		);
		if (folder && parentId && parentId !== folderId) {
			this.openFoldersRecursively(parentId);
		}
	};

	onItemTypeFolder = (folder: IFolderItem): boolean => {
		const items = this._Folder.getItemsByFolderIdSync(folder.id);
		if (!items) return false;
		for (let i = 0; i < items.length; i++) {
			const item = items[i];
			if (item.type === ItemType.file) {
				this.onSelectionChange(item.id, ItemType.file, folder.id);
				return true;
			}
			if (item.type === ItemType.test) {
				this.onSelectionChange(item.id, ItemType.test, folder.id);
				return true;
			}
			if (item.type === ItemType.folder) {
				const hasFound = this.onItemTypeFolder(item);
				if (hasFound) return true;
			}
		}
		return false;
	};

	onFinishReading = (id: ObjectId, parentFolderId: ObjectId) => {
		this.gotoNextItem(id, ItemType.file, parentFolderId);
	};

	gotoNextItem = (
		itemId: ObjectId,
		itemType: ItemType,
		parentFolderId: ObjectId
	) => {
		const folder = this._Folder.findByIdSync(parentFolderId);
		if (!folder) return;
		const items = folder.items;
		if (!items) return;
		const myIndex = items.findIndex(
			e => e.id === itemId && e.type === itemType
		);
		if (myIndex === -1) return;
		for (let i = myIndex + 1; i < items.length; i++) {
			const item = items[i];
			if (item.type === ItemType.file) {
				this.onSelectionChange(item.id, item.type, folder._id);
				return;
			}
			if (item.type === ItemType.folder) {
				const hasFoundNextItem = this.onItemTypeFolder(item);
				if (hasFoundNextItem) return;
			}
			if (item.type === ItemType.test) {
				this.onSelectionChange(item.id, item.type, folder._id);
				return;
			}
		}
		this.openNextItemOfParentFolder(parentFolderId);
	};

	openNextItemOfParentFolder = (folderId: ObjectId) => {
		const folder = this._Folder.findByIdSync(folderId);
		if (!folder) return;
		const parentId = this._FolderHierarchyService.getParentIdSync(
			this.courseId,
			folderId
		);
		if (!parentId) return;
		const parentItems = this._Folder.getItemsByFolderIdSync(parentId);
		if (parentItems) {
			const myIndex = parentItems.findIndex(item => item.id === folderId);
			for (let i = myIndex + 1; i < parentItems.length; ++i) {
				const item = parentItems[i];
				if (item.type === ItemType.folder) {
					const hasFound = this.onItemTypeFolder(item);
					if (hasFound) return;
				}
				if (item.type === ItemType.file) {
					this.onSelectionChange(item.id, ItemType.file, parentId);

					return;
				}
				if (item.type === ItemType.test) {
					this.onSelectionChange(item.id, ItemType.test, parentId);
					return;
				}
			}
		}
		this.gotoNextItem(folderId, ItemType.folder, parentId);
	};

	onFinishTest = (id: ObjectId, parentFolderId: ObjectId) => {
		this.gotoNextItem(id, ItemType.test, parentFolderId);
	};

	openFirstUndoneItemOfFolder = (id: ObjectId) => {
		const folderItems = this._Folder.getItemsByFolderIdSync(id);
		if (!folderItems) return;
		const item = folderItems.find(eachItem => {
			if (
				eachItem.type !== ItemType.file &&
				eachItem.type !== ItemType.folder &&
				eachItem.type !== ItemType.test
			) {
				return false;
			}
			return (
				this._UserFolderProgressService.getProgressOfItemSync({
					itemId: eachItem.id,
					itemType: eachItem.type,
					folderId: id,
					courseId: this.courseId,
					userId: this.props.userData.id,
				}) < 1
			);
		});
		if (!item) return;
		if (item.type !== ItemType.folder)
			return this.onSelectionChange(item.id, item.type, id);
		return this.openFirstUndoneItemOfFolder(item.id);
	};

	openFirstItem = (folderId: ObjectId) => {
		const folder = this._Folder.findByIdSync(folderId);
		if (!folder) return;
		if (folder.items) {
			folder.items.find(item => {
				if (!item.isHidden) {
					if (
						item.type === ItemType.file ||
						item.type === ItemType.test
					) {
						this.onSelectionChange(item.id, item.type, folderId);
						return true;
					}
					if (item.type === ItemType.folder) {
						this.onItemTypeFolder(item);
						return true;
					}
				}
				return false;
			});
		}
		return;
	};

	onMoveToTestPageFromPopup = () => {
		const { lastTestPopupInfo } = this.state;
		if (!lastTestPopupInfo) return;
		this.moveToTestPage(
			lastTestPopupInfo.itemId,
			lastTestPopupInfo.folderId
		);
	};

	onLastTestPopupClose = () => {
		this.setState({
			lastTestPopupInfo: undefined,
		});
	};

	render() {
		if (!this.state.isLoaded || !this.props.userData)
			return <FancyLoading />;
		const folder = this._Folder.findByIdSync(this.folderId);
		if (!folder) return <FancyLoading />;

		if (!folder.items) {
			return (
				<div style={{ textAlign: "center", margin: 20 }}>ცარიელია!</div>
			);
		}

		return (
			<div className="main">
				<div className={Container}>
					<div className={ListOfContentsContainer}>
						<ContentsNavigation
							selection={this.state.selection}
							openIds={this.state.openIds}
							folder={folder}
							courseId={this.courseId}
							onOpenContentChange={this.onOpenContentChange}
							onSelectionChange={this.onSelectionChange}
							history={this.props.history}
						/>
					</div>
					<div className={ContentInfo} ref={this.rightNavRef}>
						{this.state.selection &&
							this.state.selection.type === ItemType.file && (
								<ReadingFile
									courseId={this.courseId}
									folderId={this.state.selection.folderId}
									id={this.state.selection.id}
									isReadingFirstTime={true}
									key={`${this.state.selection.folderId}-${this.state.selection.type}-${this.state.selection.id}`}
									onFinishReading={this.onFinishReading}
								/>
							)}
						{this.state.selection &&
							this.state.selection.type === ItemType.test && (
								<TestLoader
									courseId={this.courseId}
									folderId={this.state.selection.folderId}
									testId={this.state.selection.id}
									onGotoNext={this.onFinishTest}
									key={`${this.state.selection.folderId}-${this.state.selection.type}-${this.state.selection.id}`}
									userId={this.props.userData.id}
									history={this.props.history}
								/>
							)}
					</div>

					{this.state.lastTestPopupInfo && (
						<BluePopup
							onClose={this.onLastTestPopupClose}
							name={folder.name}
							onClick={this.onMoveToTestPageFromPopup}
						/>
					)}
				</div>
			</div>
		);
	}
}

interface IBluePopupProps {
	onClose: () => void;
	onClick: () => void;
	name: string;
}

const BluePopup: React.FC<IBluePopupProps> = props => {
	return (
		<Popup onClose={props.onClose}>
			<PopupContent
				style={{
					background: "transparent",
					padding: 0,
					width: 556,
					maxWidth: "90%",
				}}
			>
				<div className={PopupStyles}>
					<div>
						შენ დაასრულე თემა - {props.name}! ახლა შეგიძლია დაწერო
						შემაჯამებელი ტესტი.
					</div>
					<button className={popupButton} onClick={props.onClick}>
						დაწყება
					</button>
				</div>
			</PopupContent>
		</Popup>
	);
};

const PopupStyles = css`
	color: white;
	font-size: 20px;
	background: linear-gradient(to top right, #08257d, #1f7cf5);
	padding: 50px 18px;
	border-radius: 7px;
	text-align: center;
`;

const popupButton = css`
	font-size: 20px;
	width: 40%;
	margin-top: 20px;
	color: white;
	border: 2px solid white;
	border-radius: 7px;
	background: transparent;
	padding: 5px;
	cursor: pointer;
	&:hover {
		background: white;
		color: #08257d;
	}
`;

const Container = css`
	display: flex;
	height: 1000;

	@media (max-width: 960px) {
		display: block;
	}
`;

const ListOfContentsContainer = css`
	flex: 1;
	border-right: 1px double #bfbfbf;

	@media (max-width: 960px) {
		max-width: 100%;
		margin: 5px;
		border: 1px double #bfbfbf66;
		box-shadow: 0 0 5px #bfbfbf;
		border-radius: 4px;
		padding: 5px;
	}
`;

const ContentInfo = css`
	flex: 3;
	margin-bottom: 120px;
	max-width: 70%;

	@media (max-width: 960px) {
		width: 100%;
		max-width: 100%;
	}
`;

const mapStateToProps = (state: IRootState) => ({
	courses: state.courses,
	folders: state.folders,
	folderProgresses: state.userFolderProgresses,
	userData: state.user.userData!,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	SubTopicsContent
);
