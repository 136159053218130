import {
	IAPOSTLogin,
	IAPOSTLogout,
	IAPUTAccessTokens,
	IAPUTLoginByAccessToken,
	IRPOSTLogin,
	IRPOSTLogout,
	IRPUTAccessTokens,
	IRPUTLoginByAccessToken,
	RPOSTLoginSchema,
} from "./validators";
import { Requests } from "@app/api";

const auth = {
	login: (data: IAPOSTLogin): Promise<IRPOSTLogin> =>
		Requests.send("POST", "/api/auth/login", data, null, {
			responseSchema: RPOSTLoginSchema,
		}),
	loginByToken: (
		data: IAPUTLoginByAccessToken
	): Promise<IRPUTLoginByAccessToken> =>
		Requests.send("POST", "/api/auth/login/:token", data, {
			headers: {
				access_token: undefined,
			},
		}),
	logout: (data: IAPOSTLogout): Promise<IRPOSTLogout> =>
		Requests.send("POST", "/api/auth/logout", data).then(data => {
			localStorage.clear();
			return data;
		}),
	updateAccessToken: (data: IAPUTAccessTokens): Promise<IRPUTAccessTokens> =>
		Requests.send("PUT", "/api/auth/access-tokens", data),
};
export default auth;
