import * as React from "react";
import styled from "@emotion/styled";
import SvgLoading from "@app/components/styles/img/loading";
import Add from "@material-ui/icons/Add";
import { ObjectId } from "@app/utils/generics";
import { inject } from "@app/modules";
import { FormattedMessage } from "react-intl";
import { IRPOSTGetCourseMedia } from "@app/api/courses/validators";
import AudioICONUrl from "./imgs/musical-note.svg";

const Background = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 2000;
`;

const Container = styled.div`
	position: fixed;
	width: calc(100vw - 10px);
	max-width: 800px;
	min-width: 300px;
	min-height: 200px;
	max-height: 500px;
	overflow: auto;
	padding: 15px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	border-radius: 15px;
	z-index: 2000;
`;

const OptionTab = styled.div`
	display: inline;
	padding: 5px;
	cursor: pointer;
	border-bottom: ${(props: any) =>
		props.selected ? "2px solid black" : "1px solid grey"};
`;

export const PhotoPreview = styled.div`
	position: relative;
	vertical-align: top;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 170px;
	height: 170px;
	border: 2px
		${(props: any) =>
			props.select
				? "dashed black"
				: "solid" + (props.selected ? " blue" : " grey")};
	border-radius: 10px;
	margin: 10px;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
`;

interface IOwnProps {
	courseId: ObjectId;
	selectedPhotos?: string[];
	onClose: () => void;
	onUpload: (path: string, mimetype: string | null) => void;
}
type IProps = IOwnProps;

enum Tab {
	OWN_MEDIA,
	OTHER_MEDIA,
}
interface IState {
	selectedTab: Tab;
	photos: IRPOSTGetCourseMedia["files"];
	isUploading: boolean;
}

// tslint:disable:no-console

class GalleryPopup extends React.Component<IProps, IState> {
	state: IState = {
		selectedTab: Tab.OWN_MEDIA,
		photos: [],
		isUploading: false,
	};

	fileRef = React.createRef<HTMLInputElement>();

	_isMounted = false;
	_CoursesController = inject("CoursesController");

	changeTab = (selectedTab: Tab) => () => {
		this.setState({ selectedTab });
		this.loadCourseMedia(selectedTab);
	};

	handleFileClick = () => {
		if (this.fileRef.current === null) return;
		this.fileRef.current.click();
	};

	uploadMedia = event => {
		this.setState({
			isUploading: true,
		});
		const formData = new FormData();
		formData.append("files", event.target.files[0]);
		formData.append("courseId", this.props.courseId);
		this._CoursesController
			.uploadPhotos(formData as any)
			.then(data => {
				if (!this._isMounted) return;
				const file = data.files[0];
				const photoUrl = this.getFullUrl(file.fileFullUrl);
				this.setState({
					photos: [
						{ path: photoUrl, mimetype: file.mimetype },
						...this.state.photos,
					],
				});
				this.props.onUpload(photoUrl, file.mimetype);
			})
			.catch(err => {
				console.log(err);
				if (!this._isMounted) return;
				this.setState({
					isUploading: false,
				});
			});
	};

	loadCourseMedia = (selectedTab?: Tab) => {
		const newTab = selectedTab ? selectedTab : this.state.selectedTab;
		if (newTab === Tab.OWN_MEDIA) {
			this._CoursesController
				.getMedia({ courseId: this.props.courseId })
				.then(data => {
					if (!this._isMounted) return;
					this.setState({ photos: data.files });
				})
				.catch(err => {
					console.log(err);
					if (!this._isMounted) return;
				});
		} else if (newTab === Tab.OTHER_MEDIA) {
			this._CoursesController
				.getAllMedia({ courseId: this.props.courseId })
				.then(data => {
					if (!this._isMounted) return;
					this.setState({ photos: data.files });
				})
				.catch(err => {
					console.log(err);
					if (!this._isMounted) return;
				});
		}
	};

	getFullUrl = (path: string) => {
		if (path.length === 0) return path;
		if (path.substr(0, 1) === "/") {
			return (
				(process.env.REACT_APP_BACKEND_SERVER ||
					"http://localhost:8093") + path
			);
		}
		return path;
	};

	render() {
		return (
			<React.Fragment>
				<Background onClick={this.props.onClose} />
				<Container>
					<div>
						<OptionTab
							selected={this.state.selectedTab === Tab.OWN_MEDIA}
							onClick={this.changeTab(Tab.OWN_MEDIA)}
						>
							ჩემი ფაილები
						</OptionTab>
						<OptionTab
							selected={
								this.state.selectedTab === Tab.OTHER_MEDIA
							}
							onClick={this.changeTab(Tab.OTHER_MEDIA)}
						>
							სხვა ფაილები
						</OptionTab>
					</div>
					<div style={{ marginTop: "10px", textAlign: "center" }}>
						{this.state.selectedTab === Tab.OWN_MEDIA && (
							<React.Fragment>
								<input
									type="file"
									ref={this.fileRef}
									style={{ display: "none" }}
									onChange={this.uploadMedia}
									accept=".jpg,.png,.mp3"
								/>
								<PhotoPreview
									select={true}
									onClick={this.handleFileClick}
								>
									<Add style={{ fontSize: "85px" }} />
									<p>
										<FormattedMessage id="upload" />
									</p>
								</PhotoPreview>
							</React.Fragment>
						)}
						{this.state.isUploading && (
							<PhotoPreview>
								<SvgLoading />
							</PhotoPreview>
						)}
						{this.state.photos.map(photo => (
							<PhotoPreview
								key={photo.path}
								style={{
									backgroundImage:
										photo.mimetype === "audio/mp3" ||
										photo.mimetype === "audio/mpeg"
											? `url(${AudioICONUrl})`
											: `url(${photo.path})`,
									backgroundSize:
										photo.mimetype === "audio/mp3" ||
										photo.mimetype === "audio/mpeg"
											? "70%"
											: "cover",
								}}
								onClick={() =>
									this.props.onUpload(
										this.getFullUrl(photo.path),
										photo.mimetype
									)
								}
								selected={
									this.props.selectedPhotos &&
									this.props.selectedPhotos.indexOf(
										photo.path
									) >= 0
								}
							/>
						))}
					</div>
				</Container>
			</React.Fragment>
		);
	}

	componentDidMount() {
		this.loadCourseMedia();
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}
}

export default GalleryPopup;
