import * as React from "react";

interface IInputForText {
	val: string;
	onChange: (val: string) => void;
}

const InputForText: React.FC<IInputForText> = props => (
	<input
		defaultValue={(props.val as string) || ""}
		type="text"
		onChange={e => props.onChange(e.target.value)}
	/>
);

export default InputForText;
