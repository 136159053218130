import * as React from "react";
import Header from "@app/components/Header";
import LoginPopup from "@app/components/login-popup";
import { connect } from "react-redux";
import { History, Path } from "history";
import { IRootState } from "@app/redux";
import { Redirect, Route } from "react-router-dom";
import { removeKeys } from "@app/utils/common";

interface IWonProps {
	component: any;
	location: History["location"];
	path: Path;
	exact: boolean;
	excludedParamsForKey?: string | string[];
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IWonProps;

export const paramsToKey = (
	params,
	excludedParamsForKey?: IWonProps["excludedParamsForKey"]
) => {
	const exclKeys = Array.isArray(excludedParamsForKey)
		? excludedParamsForKey
		: typeof excludedParamsForKey !== "undefined"
		? [excludedParamsForKey]
		: undefined;
	return JSON.stringify(exclKeys ? removeKeys(params, ...exclKeys) : params);
};

const AdminRoute = ({
	component: Component,
	isAuthenticated,
	location,
	user,
	excludedParamsForKey,
	...rest
}: IProps) => {
	return (
		<Route
			location={location}
			{...rest}
			render={props =>
				user.userData &&
				user.userData.permissions &&
				user.userData.permissions.isAdmin ? (
					<div
						key={paramsToKey(
							props.match.params,
							excludedParamsForKey
						)}
					>
						<div className="user-page">
							<Header {...rest} {...props} />
							<div className="user-page-without-header">
								<Component
									{...rest}
									{...props}
									{...props.match.params}
									userData={user.userData}
								/>
							</div>
						</div>
					</div>
				) : !isAuthenticated ? (
					<LoginPopup onClose={() => window.location.reload()} />
				) : (
					<Redirect to="/" />
				)
			}
		/>
	);
};

const mapStateToProps = (state: IRootState) => ({
	isAuthenticated: !!state.user.userId,
	user: state.user,
});

export default connect<IStateProps, null, IWonProps>(mapStateToProps)(
	AdminRoute
);
