import styled from "@emotion/styled";
import { css } from "emotion";

export const SpecificTaskType = styled.div`
	display: table-cell;
	font-size: 19px;
	vertical-align: middle;
`;
export const TaskTypeAddButton = styled.div`
    display: inline-block;
    color: #17a094;
    font-size: 16px;
    cursor: pointer;
    &:hover {
        border-color: #17a094;
        background-color: #17a094 !important;
        color: white;
    }
}`;

export const TaskTypeEditButton = styled.div`
    color: #0f78a2;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    &:hover {
        border-color: #0f78a2;
        background-color: #0f78a2 !important;
        color: white;
    }
}`;

export const TaskTypeDeleteButton = styled.div`
    color: #de2900;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    &:hover {
        border-color: #de2900;
        background-color: #de2900 !important;
        color: white;
    }
}`;

export const TaskTypeButtonLabel = styled.div`
	display: inline-block;
	vertical-align: middle;
	margin: 5px;
	padding-top: 5px;
`;

export const MiddleAligned = css`
	vertical-align: middle;
`;

export const TaskTypeHeader = styled.div`
	font-size: 19px;
	display: inline-block;
	vertical-align: middle;
	padding-top: 6px;
	padding-left: 6px;
`;

export const TaskTypeButtonsContainer = styled.div`
	float: right;
	margin-right: 10px;
`;

export const InputStyle = css`
	width: 400px;
	max-width: 100%;
`;

export const SubTaskTypeContainer = styled.div`
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.2) 0 5px 9px -4px;
	margin-bottom: 10px;
	margin-left: 0;
	padding: 10px;
	margin-left: ${(props: { marginLeft: number }) => props.marginLeft}px;
`;
