import { useCurrentCourseId } from "@app/hooks/courses";
import { createComponentDistributor } from "@pckgs/component-distributor";
type Design = "pillars" | "universal";

const historyCourseIds = new Set([
	"5cb8e49ce94fdf1ab0defcf7",
	"5d6e3596bc7fc46bbd1f95a3",
	"5d6e40c4bc7fc46bbd1f95aa",
	"5e1441ce6cec7666fa914f58",
]);

export const designDistributor = createComponentDistributor(
	(): Design => {
		const courseId = useCurrentCourseId();
		if (courseId && historyCourseIds.has(courseId)) return "pillars";
		return "universal";
	}
);
