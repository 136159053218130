import * as React from "react";
import ChooseCardTemplate from "./templates/choose-template";
import EditCard from ".";
import { getUniqueId } from "../../utils/common";
import { ICard } from "../../schemas/cards";
import { ICardTemplate } from "../../schemas/cards/templates";

interface IProps {
	templates?: ICardTemplate[];
	defaultCard?: ICard;
	onSave: (data: ReturnType<EditCard["getData"]>) => void;
}

interface IState {
	selectedId?: ICardTemplate["_id"];
}

class CardWrapper extends React.PureComponent<IProps, IState> {
	cardRef: React.RefObject<EditCard> = React.createRef();

	state: IState = {
		selectedId: this.props.defaultCard
			? this.props.defaultCard.templateId
			: undefined,
	};

	generateUniqueIds = (numOfIds?: number) => {
		if (this.cardRef.current) {
			const component = this.cardRef.current;
			return getUniqueId(component.getCurrentIds(), numOfIds);
		}
		return getUniqueId([], numOfIds);
	};

	onSave = () => {
		if (!this.cardRef.current) return;
		this.props.onSave(this.cardRef.current.getData());
	};

	onTemplateChange = _id => {
		this.setState({
			selectedId: _id,
		});
	};

	render() {
		if (!this.props.templates || this.props.templates.length === 0) {
			return null;
		}
		const template = this.props.templates.find(
			e => e._id === this.state.selectedId
		);
		return (
			<div>
				<ChooseCardTemplate
					onChange={this.onTemplateChange}
					selectedId={this.state.selectedId}
					templates={this.props.templates}
				/>
				{template && (
					<>
						<EditCard
							key={template._id}
							ref={this.cardRef}
							defaultTemplate={template}
							defaultCard={this.props.defaultCard}
							generateUniqueIds={this.generateUniqueIds}
						/>
						<div>
							<button onClick={this.onSave}>Save</button>
						</div>
					</>
				)}
			</div>
		);
	}
}

export default CardWrapper;
