import Joi from "@app/utils/joi";
import {
	IAdminPermissions,
	ICourseAdminPermissions,
	IMainAdminPermissions,
	IRUser,
	IStudentPermissions,
	IUserPermissions,
	RUserSchema,
} from "@app/api/users/helper-schemas";
import { ObjectId } from "@app/utils/generics";

export abstract class User implements IRUser {
	public static createUserInstance(user: IRUser): any {
		const validationResult = this.validateUserObject(user);
		if (validationResult.error) {
			throw validationResult.error;
		}
		const { permissions } = validationResult.value!;
		if (permissions) {
			if ((permissions as IAdminPermissions).isAdmin) {
				const adminPermissions = permissions as IAdminPermissions;
				if (adminPermissions.isMainAdmin) {
					return new MainAdmin(user);
				}
				if (
					adminPermissions.isAdmin &&
					adminPermissions.courseIds !== undefined
				) {
					return new CourseAdmin(user);
				}
			}
			return new Student(user);
		}
		return new Student(user);
	}

	private static validateUserObject(userObject: IRUser) {
		return RUserSchema.keys({
			iat: Joi.number()
				.integer()
				.optional(),
			exp: Joi.number()
				.integer()
				.optional(),
		}).validate(userObject);
	}

	id: number;
	murtskuId: number | null;
	mobile: string | null;
	mail: string | null;
	username?: string;
	firstname?: string | null;
	lastname?: string | null;
	permissions?: IUserPermissions;
	validatedPermission: any;

	constructor(user: IRUser) {
		const validationResult = User.validateUserObject(user);
		if (validationResult.error) {
			throw validationResult.error;
		}
		user = validationResult.value!;

		this.id = user.id;
		this.murtskuId = user.murtskuId;
		this.mobile = user.mobile;
		this.mail = user.mail || null;
		this.username = user.username;
		this.firstname = user.firstname;
		this.lastname = user.lastname;
		this.permissions = user.permissions;
		this.validatedPermission = user.permissions;
	}

	abstract canAccessAllCourses(): boolean;
	abstract canAccessCourse(courseId: ObjectId): boolean;
	abstract canStudyCourse(courseId: ObjectId): boolean;
}

export abstract class Admin extends User {
	permissions: IAdminPermissions;

	constructor(user: IRUser) {
		super(user);
		this.permissions = this.validatedPermission;
		if (!this.permissions) {
			throw new Error(
				"cannot create an admin without permissions; expected permissions, got: " +
					this.permissions
			);
		}
	}
}

export class MainAdmin extends Admin {
	permissions: IMainAdminPermissions;

	constructor(user: IRUser) {
		super(user);
		this.permissions = this.validatedPermission;
		if (!this.permissions || !this.permissions.isMainAdmin) {
			throw new Error(
				`cannot create main admin with permissions ${JSON.stringify(
					user.permissions
				)}`
			);
		}
	}

	canAccessAllCourses(): boolean {
		return true;
	}

	canAccessCourse(courseId: ObjectId): boolean {
		return true;
	}

	canStudyCourse(courseId: ObjectId): boolean {
		return true;
	}
}

export class CourseAdmin extends Admin {
	permissions: ICourseAdminPermissions;

	constructor(user: IRUser) {
		super(user);
		this.permissions = this.validatedPermission;
		if (
			!this.permissions ||
			!this.permissions.isAdmin ||
			this.permissions.courseIds === undefined
		) {
			throw new Error(
				`cannot create course admin with permissions ${JSON.stringify(
					user.permissions
				)}`
			);
		}
	}

	getCourseIds(): ObjectId[] {
		return this.permissions.courseIds;
	}

	canAccessAllCourses(): boolean {
		return false;
	}

	canAccessCourse(courseId: ObjectId): boolean {
		return this.permissions.courseIds.some(id => id === courseId);
	}

	canStudyCourse(courseId: ObjectId): boolean {
		return this.canAccessCourse(courseId);
	}
}

export class Student extends User {
	permissions: IStudentPermissions;

	constructor(user: IRUser) {
		super(user);
		this.permissions = this.validatedPermission;
	}

	canAccessAllCourses(): boolean {
		return false;
	}

	canAccessCourse(courseId: ObjectId): boolean {
		return false;
	}

	canStudyCourse(courseId: ObjectId): boolean {
		return (
			!!this.permissions &&
			this.permissions.availableCourses.some(id => id === courseId)
		);
	}
}

export class Developer extends MainAdmin {}
