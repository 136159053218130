import {
	ICardItem,
	IFileItem,
	IFolderItem,
	IFolderSingleItem,
	IQuestionItem,
	IRFolder,
	ItemType,
} from "@app/api/folders/helper-schemas";
import { IRTestType } from "@app/api/test-types/helper-schemas";
import { IRTest } from "@app/api/tests/helper-schemas";
import { PrimaryButton } from "@app/components/styles/buttons";
import Popup, { PopupContent } from "@app/components/widgets/Popup";
import { inject } from "@app/modules";
import { IRootState } from "@app/redux";
import {
	IChildrenInfo,
	IHierarchyInfo,
} from "@app/services/hierarchy-info/interfaces";
import { NotUndefined, ObjectId } from "@app/utils/generics";
import styled from "@emotion/styled";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { css } from "emotion";
import memoizeOne from "memoize-one";
import * as React from "react";
import { connect } from "react-redux";
import TestIcon from "@app/components/admin/folder/styles/img/test";
import {
	FileIconSimple,
	FolderIconSimple,
	TestIconSimple,
} from "@app/components/admin/folder/styles";
import CardIcon from "@material-ui/icons/CreditCard";
import Folder from "@material-ui/icons/Folder";
import QuestionIcon from "@material-ui/icons/HelpOutline";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import TextIcon from "@material-ui/icons/FormatAlignLeft";
import { FormattedMessage } from "react-intl";

export const getIconByItemType = (itemType: ItemType) => {
	switch (itemType) {
		case ItemType.folder:
			return <Folder className={FolderIconSimple} />;
		case ItemType.file:
			return <InsertDriveFile className={FileIconSimple} />;
		case ItemType.question:
			return <QuestionIcon />;
		case ItemType.text:
			return <TextIcon />;
		case ItemType.card:
			return <CardIcon />;
		case ItemType.test:
			return <TestIcon className={TestIconSimple} />;
		default:
			return null;
	}
};

export interface IItems {
	folders?: { [id: string]: IFolderItem };
	files?: { [id: string]: IFileItem };
	questions?: { [id: string]: IQuestionItem };
	cards?: { [id: string]: ICardItem };
	testTypes?: { [id: string]: IRTestType };
	tests?: { [id: string]: IRTest };
}

interface IOwnProps {
	courseId: ObjectId;
	onClose: () => void;
	folderId: ObjectId;
	displayItemTypes: ItemType[];
	onSave: (
		selectedItems: IState["selectedItems"],
		itemNames?: IItems
	) => void;
	defaultSelectedItems?: (
		| { questionId: ObjectId }
		| { cardId: ObjectId }
		| { testId: ObjectId }
	)[];
	getItemNames?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IStateProps & IOwnProps;

interface IState {
	rootFolderId?: ObjectId;
	expandedFolderIds: { [id: string]: boolean };
	selectedItems: {
		[folderId: string]: {
			folders?: { [id: string]: boolean };
			files?: { [id: string]: boolean };
			questions?: { [id: string]: boolean };
			cards?: { [id: string]: boolean };
			testTypes?: { [id: string]: boolean };
			tests?: { [id: string]: boolean };
		};
	};
	hierarchyInfo: IHierarchyInfo;
}

export type ISelectedItems = IState["selectedItems"];

function typeToKey(type: ItemType) {
	switch (type) {
		case ItemType.folder:
			return "folders";
		case ItemType.file:
			return "files";
		case ItemType.question:
			return "questions";
		case ItemType.card:
			return "cards";
		case ItemType.test:
			return "tests";
		default:
			throw new Error(`item type ${type} is not supported`);
	}
}

class AllFoldersAndItemsPopup extends React.Component<IProps, IState> {
	state: IState = {
		rootFolderId: undefined,
		expandedFolderIds: {},
		selectedItems: {
			root: {
				folders: {},
				files: {},
				questions: {},
				cards: {},
				testTypes: {},
				tests: {},
			},
		},
		hierarchyInfo: {} as IHierarchyInfo,
	};

	getRootItem = memoizeOne(
		(id: ObjectId, name: string): IFolderSingleItem => ({
			id,
			type: ItemType.folder,
			name,
		})
	);

	FolderHierarchyService = inject("FolderHierarchyService");
	_CoursesController = inject("CoursesController");
	FolderModel = inject("FolderModel");

	componentDidMount() {
		this._CoursesController
			.getAllFolders({ courseId: this.props.courseId })
			.then(data => {
				const expandedFolderIds = {};
				const hierarchyInfo: IHierarchyInfo = this.FolderHierarchyService.getHierarchyInfoObjectSync(
					this.props.courseId
				);
				let currFolderId = this.props.folderId;
				while (currFolderId) {
					expandedFolderIds[currFolderId] = true;
					const parentId = hierarchyInfo.parentInfo[currFolderId];
					if (!parentId) break;
					currFolderId = parentId;
				}
				if (this.props.folderId !== data.rootFolderId) {
					this.expandSubFolders(
						this.props.folderId,
						hierarchyInfo.childrenInfo,
						expandedFolderIds
					);
				}
				this.setState(
					{
						rootFolderId: data.rootFolderId,
						expandedFolderIds,
					},
					() => {
						if (this.props.defaultSelectedItems) {
							for (const item of this.props
								.defaultSelectedItems) {
								const obj = item as any;
								let type: any;
								let id: any;
								if (obj.questionId) {
									type = ItemType.question;
									id = obj.questionId;
								} else if (obj.cardId) {
									type = ItemType.card;
									id = obj.cardId;
								} else if (obj.testId) {
									type = ItemType.test;
									id = obj.testId;
								}
								if (!type || !id) return;
								for (const folder of data.allFolders) {
									if (!folder.items) return;
									for (const item of folder.items) {
										console.log(
											item.type,
											item.id,
											type,
											id
										);
										if (
											item.type !== type ||
											item.id !== id
										) {
											return;
										}
										this.onCheckBoxTick(
											id,
											type,
											folder._id,
											true
										);
									}
								}
							}
						}
					}
				);
			});
	}

	onSaveItems = (selectedItems: IState["selectedItems"]) => {
		if (this.props.getItemNames) {
			const folderItems: IItems = {
				questions: {},
				cards: {},
			};
			for (const folderId of Object.keys(selectedItems)) {
				if (selectedItems[folderId].questions) {
					for (const id of Object.keys(
						selectedItems[folderId].questions!
					)) {
						if (
							this.props.folders[folderId] &&
							this.props.folders[folderId]!.info.items
						) {
							for (const item of this.props.folders[folderId]!
								.info.items!) {
								if (
									item.id === id &&
									item.type === ItemType.question
								) {
									folderItems!.questions![item.id] = item;
								}
							}
						}
					}
				}
				if (selectedItems[folderId].cards) {
					for (const id of Object.keys(
						selectedItems[folderId].cards!
					)) {
						if (
							this.props.folders[folderId] &&
							this.props.folders[folderId]!.info.items
						) {
							for (const item of this.props.folders[folderId]!
								.info.items!) {
								if (
									item.id === id &&
									item.type === ItemType.card
								) {
									folderItems!.cards![item.id] = item;
								}
							}
						}
					}
				}
			}
			this.props.onSave(this.state.selectedItems, folderItems);
		} else {
			this.props.onSave(this.state.selectedItems);
		}
	};

	onFolderExpand = (folderId: ObjectId) => {
		this.setState({
			expandedFolderIds: {
				...this.state.expandedFolderIds,
				[folderId]: !this.state.expandedFolderIds[folderId],
			},
		});
	};

	expandSubFolders = (
		folderId: ObjectId,
		childrenInfo: IChildrenInfo,
		expandedFolderIds: IState["expandedFolderIds"]
	) => {
		const childrenArray = childrenInfo[folderId];
		if (!childrenArray || !childrenArray.length) return;
		for (let i = 0; i < childrenArray.length; i++) {
			expandedFolderIds[childrenArray[i]] = true;
			const grandChildren = childrenInfo[childrenArray[i]];
			if (grandChildren) childrenArray.push(...grandChildren);
		}
	};

	onCheckBoxTick = (
		id: ObjectId,
		itemType: ItemType,
		parentFolderId: ObjectId,
		checked: boolean
	) => {
		const key = typeToKey(itemType);

		console.log("key", id);

		this.setState(({ selectedItems, expandedFolderIds }) => {
			const newSelectedItems = {
				...selectedItems,
				[parentFolderId]: {
					...selectedItems[parentFolderId],
					[key]: {
						...(selectedItems[parentFolderId] || {})[key],
						[id]: checked,
					},
				},
			};

			const newExpandedFolderIds = { ...expandedFolderIds };
			newExpandedFolderIds[parentFolderId] = true;

			if (checked) {
				this.checkParentFolders(
					parentFolderId,
					this.props.folders,
					newSelectedItems,
					newExpandedFolderIds,
					this.props.displayItemTypes
				);
			} else {
				this.unCheckParentFolders(
					parentFolderId,
					this.props.folders,
					newSelectedItems
				);
			}
			if (itemType === ItemType.folder) {
				this.checkSubItems(
					id,
					this.props.folders,
					newSelectedItems,
					checked
				);
			}

			return {
				selectedItems: { ...newSelectedItems },
				expandedFolderIds: newExpandedFolderIds,
			};
		});
	};

	checkParentFolders = (
		folderId: ObjectId,
		folders: IRootState["folders"],
		selectedItems: IState["selectedItems"],
		expandedFolderIds: IState["expandedFolderIds"],
		displayItemTypes: ItemType[]
	) => {
		if (!folders[folderId]) return;
		let current = folderId;
		while (current && current !== "root") {
			if (!folders[current]) break;
			const amIFull =
				!!folders[current]!.info.items &&
				// eslint-disable-next-line no-loop-func
				!folders[current]!.info.items!.some(el => {
					return (
						(displayItemTypes.indexOf(el.type) >= 0 ||
							el.type === ItemType.folder) &&
						(!selectedItems[current] ||
							!selectedItems[current][typeToKey(el.type)] ||
							!selectedItems[current][typeToKey(el.type)]![el.id])
					);
				});
			if (!amIFull) break;

			const parent =
				this.FolderHierarchyService.getParentIdSync(
					this.props.courseId,
					current
				) || "root";
			selectedItems[parent] = {
				...selectedItems[parent],
				folders: {
					...(selectedItems[parent] || {}).folders,
					[current]: true,
				},
			};
			current = parent;
		}
		current = folderId;
		while (current && current !== "root") {
			if (!folders[current]) break;
			const parent =
				this.FolderHierarchyService.getParentIdSync(
					this.props.courseId,
					current
				) || "root";
			expandedFolderIds[parent] = true;
			current = parent;
		}
	};

	unCheckParentFolders = (
		folderId: ObjectId,
		folders: IRootState["folders"],
		selectedItems: IState["selectedItems"]
	) => {
		if (!folders[folderId]) return;
		let current = folderId;
		while (current && current !== "root") {
			if (!folders[current]) break;
			const parent =
				this.FolderHierarchyService.getParentIdSync(
					this.props.courseId,
					current
				) || "root";
			if (
				selectedItems[parent] &&
				selectedItems[parent].folders &&
				selectedItems[parent].folders![current]
			) {
				selectedItems[parent].folders = {
					...selectedItems[parent].folders,
					[current]: false,
				};
			}
			current = parent;
		}
	};

	checkSubItems = (
		folderId: ObjectId,
		folders: IRootState["folders"],
		selectedItems: IState["selectedItems"],
		value: boolean
	) => {
		if (!folders[folderId] || !folders[folderId]!.info.items) return;
		const items: IFolderItem[] = folders[folderId]!.info.items!;
		for (let i = 0; items.length; i++) {
			if (!selectedItems[folderId]) {
				selectedItems[folderId] = {};
			}
			const itemId = items[i].id;
			const itemType = items[i].type;
			const key = typeToKey(itemType);
			selectedItems[folderId] = {
				...selectedItems[folderId],
				[key]: {
					...selectedItems[folderId][key],
					[itemId]: value,
				},
			};
			if (
				itemType === ItemType.folder &&
				folders[itemId] &&
				folders[itemId]!.info.items
			) {
				items.push(...folders[itemId]!.info.items);
			}
		}
	};

	render() {
		if (
			!this.state.rootFolderId ||
			!this.props.folders[this.state.rootFolderId]
		) {
			return null;
		}
		const rootItem = this.getRootItem(
			this.state.rootFolderId,
			this.props.folders[this.state.rootFolderId]!.info.name
		);
		return (
			<Popup onClose={this.props.onClose}>
				<PopupContent>
					<Container style={{ textAlign: "left" }}>
						<FolderItem
							depth={0}
							expandedFolderIds={this.state.expandedFolderIds}
							item={rootItem}
							onCheckBoxTick={this.onCheckBoxTick}
							onFolderExpand={this.onFolderExpand}
							parentFolderId="root"
							selectedItems={this.state.selectedItems}
						/>
						{this.props.folders[this.state.rootFolderId] &&
							this.state.expandedFolderIds[
								this.state.rootFolderId
							] && (
								<DisplayFoldersAndItems
									items={
										this.props.folders[
											this.state.rootFolderId
										]!.info.items
									}
									depth={1}
									displayItemTypes={
										this.props.displayItemTypes
									}
									folders={this.props.folders}
									expandedFolderIds={
										this.state.expandedFolderIds
									}
									onFolderExpand={this.onFolderExpand}
									selectedItems={this.state.selectedItems}
									onCheckBoxTick={this.onCheckBoxTick}
									parentFolderId={this.state.rootFolderId}
								/>
							)}
						<PrimaryButton
							onClick={() =>
								this.onSaveItems(this.state.selectedItems)
							}
						>
							<FormattedMessage id="save" />
						</PrimaryButton>
					</Container>
				</PopupContent>
			</Popup>
		);
	}
}

interface IDisplayFolderProps {
	items: IRFolder["items"];
	depth: number;
	displayItemTypes: ItemType[];
	folders: IRootState["folders"];
	expandedFolderIds: IState["expandedFolderIds"];
	onFolderExpand: (id: ObjectId) => void;
	selectedItems: IState["selectedItems"];
	onCheckBoxTick: (
		id: ObjectId,
		itemType: ItemType,
		parentFolderId,
		checked: boolean
	) => void;
	parentFolderId: ObjectId;
}

const DisplayFoldersAndItems: React.FC<IDisplayFolderProps> = props => {
	return (
		<React.Fragment>
			{!!props.items &&
				props.items.map((item, index) => {
					if (
						item.type !== ItemType.folder &&
						props.displayItemTypes.indexOf(item.type) < 0
					) {
						return null;
					}
					return (
						<React.Fragment key={index}>
							<FolderItem
								depth={props.depth}
								expandedFolderIds={props.expandedFolderIds}
								item={item}
								onCheckBoxTick={props.onCheckBoxTick}
								onFolderExpand={props.onFolderExpand}
								parentFolderId={props.parentFolderId}
								selectedItems={props.selectedItems}
							/>
							{item.type === ItemType.folder &&
								!!props.folders[item.id] &&
								!!props.folders[item.id]!.info.items &&
								props.expandedFolderIds[item.id] && (
									<DisplayFoldersAndItems
										items={
											props.folders[item.id]!.info.items
										}
										depth={props.depth + 1}
										displayItemTypes={
											props.displayItemTypes
										}
										folders={props.folders}
										expandedFolderIds={
											props.expandedFolderIds
										}
										onFolderExpand={props.onFolderExpand}
										selectedItems={props.selectedItems}
										onCheckBoxTick={props.onCheckBoxTick}
										parentFolderId={item.id}
									/>
								)}
						</React.Fragment>
					);
				})}
		</React.Fragment>
	);
};

interface IFolderItemProps {
	item: NotUndefined<IRFolder["items"]>[number];
	selectedItems: IState["selectedItems"];
	expandedFolderIds: IState["expandedFolderIds"];
	onFolderExpand: (id: ObjectId) => void;
	onCheckBoxTick: (
		id: ObjectId,
		itemType: ItemType,
		parentFolderId,
		checked: boolean
	) => void;
	parentFolderId: ObjectId;
	depth: number;
}

class FolderItem extends React.PureComponent<IFolderItemProps> {
	expRef: React.RefObject<HTMLDivElement> = React.createRef();

	onCheck = (e: React.ChangeEvent<HTMLInputElement> | undefined, checked) => {
		if (e) e.stopPropagation();
		this.props.onCheckBoxTick(
			this.props.item.id,
			this.props.item.type,
			this.props.parentFolderId,
			checked
		);
	};

	isChecked = () => {
		const key = typeToKey(this.props.item.type);
		return (
			!!this.props.selectedItems[this.props.parentFolderId] &&
			!!this.props.selectedItems[this.props.parentFolderId][key] &&
			!!this.props.selectedItems[this.props.parentFolderId][key]![
				this.props.item.id
			]
		);
	};

	onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const checked = this.isChecked();
		this.onCheck(undefined, !checked);
	};

	onFolderExpand = e => {
		if (e) e.stopPropagation();
		this.props.onFolderExpand(this.props.item.id);
	};

	render() {
		const { item } = this.props;
		const icon = getIconByItemType(item.type);
		return (
			<DisplayFolderOrItemContainer
				depth={this.props.depth}
				onClick={this.onClick}
			>
				<FormControlLabel
					control={
						<Checkbox
							checked={this.isChecked()}
							onChange={this.onCheck}
						/>
					}
					label={""}
				/>
				<div className={c}>
					{icon}
					<p>{item.name}</p>
				</div>
				<div
					style={{ height: 50, width: 40, paddingLeft: 10 }}
					className="VM"
				>
					{item.type === ItemType.folder &&
						(this.props.expandedFolderIds[item.id] ? (
							<ExpandLess onClick={this.onFolderExpand} />
						) : (
							<ExpandMore onClick={this.onFolderExpand} />
						))}
				</div>
			</DisplayFolderOrItemContainer>
		);
	}
}

const Container = styled.div`
	width: calc(100vw - 20px);
	max-width: 800px;
	background-color: white;
	border-radius: 15px;
	padding: 15px;
`;

const DisplayFolderOrItemContainer = styled.div`
	background-color: white;
	padding: 2px 1px;
	cursor: pointer;
	margin: 0 ${(props: any) => props.depth * 15}px 0
		${(props: any) => props.depth * 15}px;
	display: flex;
	align-items: center;

	svg {
		vertical-align: middle;
		margin-right: 10px;
	}
	&:hover {
		background-color: rgba(0, 0, 255, 0.3);
	}
`;

const c = css`
	width: 100%;
	display: flex;
	align-items: center;
`;

const mapStateToProps = (state: IRootState) => ({
	courses: state.courses,
	folders: state.folders,
	folderHierarchies: state.folderHierarchies,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	AllFoldersAndItemsPopup
);
