import * as React from "react";
import Select from "react-select";
import { ICenturyDate } from "../../../../schemas/cards/templates";

interface ICenturyDateProps {
	date: ICenturyDate;
	onChange: (newDate: ICenturyDate) => void;
}

export default class CenturyDate extends React.PureComponent<
	ICenturyDateProps
> {
	whichHalf = [
		{
			value: -1,
			label: "None",
		},
		...["first", "second"].map((e, i) => ({
			value: i,
			label: "" + e,
		})),
	];

	whichDecade = [
		{
			value: -1,
			label: "None",
		},
		...[0, 10, 20, 30, 40, 50, 60, 70, 80, 90].map(e => ({
			value: e,
			label: "" + e,
		})),
	];

	half = this.whichHalf.find(e => e.value === this.props.date.half);
	decade = this.whichDecade.find(e => e.value === this.props.date.decade);

	onCenturyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onChange({
			...this.props.date,
			century: +e.target.value,
		});
	};

	onCenturyHalfChange = (selection: { value: number; label: string }) => {
		if (selection.value === -1) {
			// none
			this.props.onChange({ century: this.props.date.century });
			return;
		}
		this.props.onChange({
			...this.props.date,
			half: selection.value,
		});
	};

	onCenturyDecadeChange = (selection: { value: number; label: string }) => {
		if (selection.value === -1) {
			// none
			this.props.onChange({
				century: this.props.date.century,
				half: this.props.date.half,
			});
			return;
		}
		this.props.onChange({
			...this.props.date,
			decade: selection.value,
		});
	};
	render() {
		return (
			<>
				<div style={{ margin: "3px 0px" }}>
					<div style={{ display: "inline-block", minWidth: 50 }}>
						century
					</div>
					<input
						defaultValue={"" + this.props.date.century}
						type="text"
						onChange={this.onCenturyChange}
					/>
				</div>
				{this.props.date.decade === undefined && (
					<div style={{ margin: "3px 0px" }}>
						<div style={{ display: "inline-block", minWidth: 50 }}>
							half
						</div>
						<div
							style={{
								display: "inline-block",
								minWidth: "calc(100% - 50px)",
							}}
						>
							<Select
								value={this.half}
								options={this.whichHalf}
								onChange={this.onCenturyHalfChange}
								placeholder="Half"
							/>
						</div>
					</div>
				)}
				{this.props.date.half === undefined && (
					<>
						<div style={{ display: "inline-block", minWidth: 50 }}>
							decade
						</div>
						<div
							style={{
								display: "inline-block",
								minWidth: "calc(100% - 50px)",
							}}
						>
							<Select
								value={this.decade}
								options={this.whichDecade}
								onChange={this.onCenturyDecadeChange}
								placeholder="Decade"
							/>
						</div>
					</>
				)}
			</>
		);
	}
}
