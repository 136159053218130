import { ITopic, ITopicHierarchy } from "@app/api/topics/helper-schemas";
import {
	AdminTableContainer,
	AdminTableRow,
} from "@app/components/widgets/admin-table";
import { inject } from "@app/modules";
import { IRootState } from "@app/redux";
import {
	IChildrenInfo,
	IHierarchyInfo,
} from "@app/services/hierarchy-info/interfaces";
import { ObjectId } from "@app/utils/generics";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/AddRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import memoizeOne from "memoize-one";
import * as React from "react";
import { connect } from "react-redux";
import { match } from "react-router";
import AllTaskTypes from "../task-types";
import {
	MiddleAligned,
	SubTopicContainer,
	TopicAddButton,
	TopicButtonLabel,
	TopicButtonsContainer,
	TopicDeleteButton,
	TopicEditButton,
	TopicHeader,
} from "./styles/components";
import { TopicEditPopup } from "./edit";
import { FormattedMessage } from "react-intl";
import { getFormattedMessage } from "@app/utils/locale";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";

interface IWrapperProps {
	match: match<{ courseId: string }>;
}

const Wrapper: React.FC<IWrapperProps> = props => {
	const { courseId } = props.match.params;
	React.useEffect(() => {
		const TopicsController = inject("TopicsController");
		TopicsController.getHierarchy({ courseId }, true).then();
		const TaskTypesController = inject("TaskTypesController");
		TaskTypesController.getHierarchy({ courseId }, true).then();
	}, [courseId]);

	return (
		<Paper
			className={AdminTableContainer}
			style={{ maxWidth: 1200, margin: "0 auto" }}
		>
			<ConnectedAllTopics {...props} courseId={courseId} />
			<br />
			<AllTaskTypes {...props} courseId={courseId} />
		</Paper>
	);
};

interface IOwnProps {
	courseId: string;
}
type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IStateProps & IOwnProps;

interface IState {
	loading: boolean;
	topicPopup?:
		| { type: "add"; parentId: ObjectId }
		| { type: "edit"; id: ObjectId };
}

type IAllTopicsProps = IProps;

class AllTopics extends React.Component<IAllTopicsProps, IState> {
	state: IState = {
		loading: true,
	};

	courseId = this.props.courseId;
	TopicHierarchyModel = inject("TopicHierarchyModel");
	TopicHierarchyService = inject("TopicHierarchyService");
	_TopicsController = inject("TopicsController");

	getHierarchyInfo = memoizeOne((topicHierarchy: ITopicHierarchy | null):
		| IHierarchyInfo
		| undefined => {
		if (!topicHierarchy) return undefined;
		const hierarchyInfo = this.TopicHierarchyService.getHierarchyInfoObjectSync(
			this.courseId
		);
		return hierarchyInfo;
	});

	componentDidMount() {
		Promise.all([
			this._TopicsController.getHierarchy(
				{ courseId: this.courseId },
				true
			),
			this._TopicsController.getAllByCourseId({
				courseId: this.courseId,
			}),
		]).then(data => {
			this.setState({
				loading: false,
			});
		});
	}

	onClose = () => {
		this.setState({
			topicPopup: undefined,
		});
	};

	onDelete = (_id: ObjectId, originalCourseId: ObjectId) => {
		openConfirmationPopup({
			approveTitle: getFormattedMessage("yes"),
			rejectTitle: getFormattedMessage("no"),
			onApprove: () => {
				return this._TopicsController
					.deleteById({ _id, originalCourseId })
					.then(data => this.onClose());
			},
			text: getFormattedMessage("deleteConfirmation"),
			defaultErrorText: getFormattedMessage("errorAlert"),
			displayRejectButtonAsPrimary: true,
		});
	};

	onAddTopic = (parentId: ObjectId) => {
		this.setState({
			topicPopup: {
				type: "add",
				parentId,
			},
		});
	};

	onUpdateTopic = (id: ObjectId) => {
		this.setState({
			topicPopup: {
				type: "edit",
				id,
			},
		});
	};

	render() {
		const hierarchyInfo: IHierarchyInfo =
			this.getHierarchyInfo(
				this.TopicHierarchyModel.findOneByCourseSync(this.courseId)
			) || ({} as IHierarchyInfo);

		return (
			<div className="main">
				<h2 style={{ textAlign: "center" }}>
					<FormattedMessage id="topics" />
				</h2>
				{this.state.topicPopup && (
					<TopicEditPopup
						onClose={this.onClose}
						courseId={this.props.courseId}
						id={(this.state.topicPopup as { id: ObjectId }).id}
						parentId={
							(this.state.topicPopup as { parentId: ObjectId })
								.parentId
						}
					/>
				)}
				<AllTopicsTable
					rootTopicId={hierarchyInfo.rootId}
					topicsObj={this.props.topics}
					childrenHierarchyInfo={hierarchyInfo.childrenInfo}
					onAddTopic={this.onAddTopic}
					onUpdateTopic={this.onUpdateTopic}
					onDelete={this.onDelete}
				/>
			</div>
		);
	}
}

interface ITableProps {
	rootTopicId: ObjectId;
	topicsObj: IRootState["topics"];
	childrenHierarchyInfo: IChildrenInfo;
	onAddTopic: (parentId: ObjectId) => void;
	onUpdateTopic: (_id: ObjectId) => void;
	onDelete: (_id: ObjectId, originalCourseId: ObjectId) => void;
}

const AllTopicsTable: React.FC<ITableProps> = (props: ITableProps) => (
	<div className={AdminTableContainer}>
		<Table>
			<TableHead>
				<TableRow className={AdminTableRow}>
					<TableCell>
						<RootTopic
							id={props.rootTopicId}
							name={
								props.topicsObj &&
								props.topicsObj![props.rootTopicId]
									? props.topicsObj[props.rootTopicId]!.info
											.name
									: ""
							}
							onAddTopic={props.onAddTopic}
						/>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{!!props.childrenHierarchyInfo &&
					(props.childrenHierarchyInfo[props.rootTopicId] || []).map(
						topicId =>
							props.topicsObj &&
							props.topicsObj[topicId] && (
								<TableRow
									key={topicId}
									className={AdminTableRow}
								>
									<TableCell>
										<RecursiveTopic
											currentTopic={
												props.topicsObj[topicId]!.info
											}
											onAddTopic={props.onAddTopic}
											onUpdateTopic={props.onUpdateTopic}
											onDelete={props.onDelete}
											depth={0}
											topics={props.topicsObj}
											childrenHierarchyInfo={
												props.childrenHierarchyInfo
											}
										/>
									</TableCell>
								</TableRow>
							)
					)}
			</TableBody>
		</Table>
	</div>
);

interface ITopicRecursiveProps {
	currentTopic: ITopic;
	onAddTopic: (id: ObjectId) => void;
	onUpdateTopic: (id: ObjectId) => void;
	onDelete: (_id: ObjectId, originalCourseId: ObjectId) => void;
	depth: number;
	topics: IRootState["topics"];
	childrenHierarchyInfo: { [id: string]: string[] };
}

class RecursiveTopic extends React.PureComponent<ITopicRecursiveProps> {
	render() {
		if (!this.props.currentTopic) return null;
		return (
			<React.Fragment>
				<Topic
					id={this.props.currentTopic._id}
					name={this.props.currentTopic.name}
					onAddTopic={this.props.onAddTopic}
					onUpdateTopic={this.props.onUpdateTopic}
					onDelete={this.props.onDelete}
					depth={this.props.depth}
					originalCourseId={this.props.currentTopic.originalCourseId}
				/>
				{(
					this.props.childrenHierarchyInfo[
						this.props.currentTopic._id
					] || []
				).map(
					id =>
						this.props.topics[id] && (
							<RecursiveTopic
								key={id}
								currentTopic={this.props.topics[id]!.info}
								onAddTopic={this.props.onAddTopic}
								onUpdateTopic={this.props.onUpdateTopic}
								onDelete={this.props.onDelete}
								depth={this.props.depth + 20}
								topics={this.props.topics}
								childrenHierarchyInfo={
									this.props.childrenHierarchyInfo
								}
							/>
						)
				)}
			</React.Fragment>
		);
	}
}

interface ITopicProps {
	id: ObjectId;
	name: string;
	originalCourseId: ObjectId;
	onAddTopic: (id: ObjectId) => void;
	onUpdateTopic: (id: ObjectId) => void;
	onDelete: (_id: ObjectId, originalCourseId: ObjectId) => void;
	depth: number;
}

const Topic: React.FC<ITopicProps> = (props: ITopicProps) => (
	<SubTopicContainer marginLeft={props.depth}>
		<TopicHeader>{props.name}</TopicHeader>
		<TopicButtonsContainer>
			<TopicAddButton onClick={() => props.onAddTopic(props.id)}>
				<TopicButtonLabel>
					<FormattedMessage id="admin:general.addSubtopic" />
				</TopicButtonLabel>
				<AddIcon className={MiddleAligned} />
			</TopicAddButton>
			<TopicEditButton onClick={() => props.onUpdateTopic(props.id)}>
				<TopicButtonLabel>
					<FormattedMessage id="admin:general.changeName" />
				</TopicButtonLabel>
				<EditIcon className={MiddleAligned} />
			</TopicEditButton>
			<TopicDeleteButton
				onClick={() => props.onDelete(props.id, props.originalCourseId)}
			>
				<TopicButtonLabel>
					<FormattedMessage id="delete" />
				</TopicButtonLabel>
				<DeleteIcon className={MiddleAligned} />
			</TopicDeleteButton>
		</TopicButtonsContainer>
		<div style={{ clear: "both" }} />
	</SubTopicContainer>
);

interface IRootTopicProps {
	id: ObjectId;
	name: string;
	onAddTopic: (id: ObjectId) => void;
}

const RootTopic: React.FC<IRootTopicProps> = (props: IRootTopicProps) => (
	<SubTopicContainer marginLeft={0} style={{ textAlign: "center" }}>
		<TopicHeader style={{ fontWeight: "bold" }}>{props.name}</TopicHeader>
		<TopicButtonsContainer>
			<TopicAddButton onClick={() => props.onAddTopic(props.id)}>
				<TopicButtonLabel>
					<FormattedMessage id="admin:general.addSubtopic" />
				</TopicButtonLabel>
				<AddIcon className={MiddleAligned} />
			</TopicAddButton>
		</TopicButtonsContainer>
		<div style={{ clear: "both" }} />
	</SubTopicContainer>
);

const mapStateToProps = (state: IRootState) => ({
	topics: state.topics,
	topicsHierarchies: state.topicHierarchies,
});

const ConnectedAllTopics = connect<IStateProps, null, IOwnProps>(
	mapStateToProps
)(AllTopics);

export default Wrapper;
