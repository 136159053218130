/* eslint-disable no-restricted-globals */
import { IModelingTest } from "@app/api/modeling/helper-schemas";
import {
	ITestTypeSettings,
	TestTypeAnswersShowTime,
} from "@app/api/test-types/helper-schemas";
import { IRTest, IUserTestQuestionInfo } from "@app/api/tests/helper-schemas";
import { inject } from "@app/modules";
import { isNonNullable } from "@app/utils/common";
import triggerEvent from "@app/utils/events";
import { ObjectId } from "@app/utils/generics";
import { getFormattedMessage } from "@app/utils/locale";
import { tryPromiseMultipleTimes } from "@app/utils/promises";
import { newContent } from "@tests-core/components/questions/contents/new-content";
import {
	IFinishPageProps,
	ITestComponentProps,
	ITestFinishArgs,
	TestComponent,
} from "@tests-core/components/tests";
import {
	IFullQuestion,
	IShortQuestion,
} from "@tests-core/schemas/questions/helper-schemas";
import { IText } from "@tests-core/schemas/texts/helper-schemas";
import { History } from "history";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { match } from "react-router-dom";
import { PrimaryButton } from "../styles/buttons";
import SvgLoading from "../styles/img/loading";
import { UniversalTestWrapper2 } from "../users/tests/wrapper-2";
import { openConfirmationPopup } from "../widgets/confirmation-popup";
import {
	getLocalModellingUserAnswers,
	hasStartedModelling,
	locallySaveModellingUserAnswers,
} from "./utils";
import CaucasusIntUniLogo from "./imgs/Caucasus_International_University.jpg";
import { CaUniBannerPopup, EngInstructions, HistInstructions } from "./popups";
import { testDesign } from "./public";
import styles from "./styles.module.css";
import Loading from "../widgets/raw-loading";
import { useCoursesUser } from "@app/hooks/users";

interface IOwnProps {
	match: match<{ modellingId: ObjectId }>;
	history: History;
}

enum Mode {
	beginning,
	test,
	review,
	end,
}

interface IState {
	mode: Mode;
	loading: boolean;
	questions?: (IShortQuestion | IFullQuestion)[];
	texts?: IText[];
	test?: IRTest;
	modellingTest?: IModelingTest;
	defaultCheckedAnswers?: IUserTestQuestionInfo[];
	startedAt?: Date;
	duration?: number;
	hasWritten: boolean;
}

class Modelling extends React.Component<IOwnProps, IState> {
	modellingId = +this.props.match.params.modellingId;

	state: IState = {
		mode: Mode.beginning,
		loading: true,
		hasWritten: false,
	};

	resultsPage = this.props.history.location.search.indexOf("results") > -1;

	_ModelingTestsController = inject("ModelingController");

	componentDidMount() {
		this._ModelingTestsController
			.get({ testId: this.modellingId })
			.then(data => {
				this.setState(
					{
						loading: false,
						modellingTest: data.test,
						hasWritten: data.attempt > 0,
					},
					() => {
						const inReviewMode =
							this.modelingIsFinished() && this.canReviewNow();
						if (!inReviewMode && this.state.hasWritten) {
							this.setState({
								loading: true,
							});
							this.onBeginTest();
						}
					}
				);
				if (this.resultsPage) {
					localStorage.setItem("has-clicked-survey", "true");
					this.onStartReviewing().then(() => {
						setTimeout(() => {
							this.forceFullyFinish();
						}, 100);
					});
				}
			})
			.catch(e => {
				console.log(e);
				alert("დაფიქსირდა შეცდომა");
			});
	}

	isTImerFinished = (maxDurationInSec: number, startedAt: Date): boolean => {
		console.log("maxDurationInSec", maxDurationInSec, startedAt);
		const difference =
			maxDurationInSec * 1000 +
			new Date(startedAt).getTime() -
			Date.now();
		return difference <= 0;
	};

	disableSend = false;

	onBeginTest = () => {
		this.setState({
			loading: true,
		});
		tryPromiseMultipleTimes({
			delayBetweenTrials: 100,
			getPromise: () =>
				this._ModelingTestsController
					.begin({ testId: this.modellingId })
					.then(data => {
						const timerUp = this.isTImerFinished(
							this.state.modellingTest!.max_duration,
							data.startedAt
						);
						const finished = timerUp || this.state.hasWritten;
						if (timerUp) {
							this.timerAutamitacallyFinishedInThisSession = true;
						}
						if (finished) {
							this.disableSend = true;
						}
						console.log(
							"this.timerAutamitacallyFinishedInThisSession",
							this.timerAutamitacallyFinishedInThisSession,
							getLocalModellingUserAnswers(this.modellingId)
						);
						this.setState(
							{
								mode: !finished ? Mode.test : Mode.end,
								questions: data.questions,
								texts: data.texts,
								test: data.test,
								loading: false,
								startedAt: data.startedAt,
								defaultCheckedAnswers:
									getLocalModellingUserAnswers(
										this.modellingId
									) ||
									(!data.answeredQuestionsInfo
										? undefined
										: data.answeredQuestionsInfo.map(e => ({
												id: e.id,
												userAnswer: e.userAnswer,
												answeredAt: e.answeredAt,
												numAttempts: e.numAttempts,
												credit: e.credit || 0,
										  }))),
							},
							() => {
								if (finished) {
									this.forceFullyFinish();
								}
							}
						);

						if (!finished) {
							triggerEvent(
								{
									category: "Test",
									action: "Modelling test",
									label: "start",
								},
								{
									modellingId: +this.modellingId,
								}
							);
						}
					}),
			maxTrials: 2,
		});
	};

	onStartReviewing = () => {
		this.testTypeSettings = {
			...this.testTypeSettings,
			showAnswersAt: TestTypeAnswersShowTime.afterFinish,
		};
		this.setState({
			loading: true,
		});
		return this._ModelingTestsController
			.review({ testId: this.modellingId })
			.then(data => {
				this.setState({
					mode: Mode.review,
					questions: data.questions as IShortQuestion[],
					texts: data.texts,
					loading: false,
					test: data.test,
					defaultCheckedAnswers: !data.answeredQuestionsInfo
						? []
						: data.answeredQuestionsInfo.map(e => ({
								id: e.id,
								userAnswer: e.userAnswer,
								answeredAt: e.answeredAt,
								numAttempts: e.numAttempts,
								credit: e.credit || 0,
						  })),
				});
			});
	};

	save = async (args: ITestFinishArgs) => {
		if (this.state.mode === Mode.review || this.disableSend) {
			return Promise.resolve();
		}
		const after = () => {
			this.setState({
				loading: false,
				mode: Mode.end,
			});
		};
		if (this.resultsPage) {
			return Promise.resolve().then(after);
		}
		return this._ModelingTestsController
			.save({
				testId: this.modellingId,
				answers: args.userAnswers
					.filter(
						e =>
							!!e &&
							e.userAnswer !== null &&
							e.userAnswer !== undefined
					)
					.map(e => ({
						id: e.questionId,
						userAnswer: e.userAnswer!,
						answeredAt: e.dates.lastAnsweredAt || new Date(),
					})),
			})
			.then(after);
	};

	handleTestError = (e: any) => {
		openConfirmationPopup({
			text: getFormattedMessage(
				"დაფიქსირდა შეცდომა. თქვენი ნაწერი ვერ შეინახა. სცადეთ თავიდან დაწერა"
			),
		});
		throw e;
	};

	onFinish = async (args: ITestFinishArgs) => {
		return tryPromiseMultipleTimes({
			getPromise: () => this.save(args),
			maxTrials: 6,
			delayBetweenTrials: 100,
		})
			.then(() => {
				triggerEvent(
					{
						category: "Test",
						action: "Modelling test",
						label: "finish",
					},
					{
						modellingId: +this.modellingId,
					}
				);
				this.forceUpdate();
			})
			.catch(this.handleTestError);
	};
	onSave = async (args: ITestFinishArgs) => {
		return tryPromiseMultipleTimes({
			getPromise: () => this.save(args).then(() => this.forceUpdate()),
			maxTrials: 6,
			delayBetweenTrials: 100,
		}).catch(this.handleTestError);
	};

	modelingIsFinished = () => {
		if (!this.state.modellingTest) throw new Error();
		if (this.state.modellingTest.finish_time.getTime() < Date.now()) {
			return true;
		}
		return false;
	};

	canReviewNow = () => {
		if (!this.state.modellingTest) throw new Error();
		if (!this.state.modellingTest.review_start_time) return false;
		if (this.state.modellingTest.review_start_time.getTime() > Date.now()) {
			return false;
		}
		if (!this.state.modellingTest.review_finish_time) return true;
		return (
			this.state.modellingTest.review_finish_time.getTime() > Date.now()
		);
	};

	WrapperRef = React.createRef<UniversalTestWrapper2>();

	preFinishHook = (): Promise<{ finish: boolean }> => {
		if (
			this.resultsPage ||
			this.timerAutamitacallyFinishedInThisSession ||
			this.disableSend ||
			this.state.mode === Mode.review
		) {
			return Promise.resolve({ finish: true });
		}
		return new Promise((resolve, reject) => {
			openConfirmationPopup({
				text: "ნამდვილად გსურთ იმიტირებული გამოცდის დასრულება?",
				approveTitle: getFormattedMessage("yes"),
				rejectTitle: getFormattedMessage("no"),
				onApprove: () => {
					resolve({ finish: true });
				},
				onReject: () => {
					resolve({ finish: false });
				},
			});
		});
	};

	forceFullyFinish = async () => {
		const test = this.WrapperRef.current?.testComponentRef.current;
		if (!test) return;
		test.safeFinish().catch(() => {
			//
		});
	};

	private timerAutamitacallyFinishedInThisSession = false;
	onTimerEnd = () => {
		this.timerAutamitacallyFinishedInThisSession = true;
		this.forceFullyFinish();
	};

	onGotoNext = () => {
		//
	};
	redirectoToFolder = () => {
		//
	};

	testTypeSettings: ITestTypeSettings = {
		allowSwitchingToSubmittedQuestions: true,
		allowSwitchingToUnsubmittedQuestions: true,
		checkInBackEnd: false,
		maxNumOfWritingTests: 1,
		showAnswersAt: TestTypeAnswersShowTime.afterDeadline,
		submitAnswerAfterAnswering: false,
	};

	postUserAnswerChamge: NonNullable<
		ITestComponentProps["postUserAnswerChamge"]
	> = ({ userAnswersInfo }) => {
		const test = this.WrapperRef.current!.testComponentRef.current!;
		const userAnswers = userAnswersInfo
			.map((e, index): IUserTestQuestionInfo | null => {
				if (!e) return null;
				const question = test.getQuestionByDisplayIndex(index);
				const contentInfo = test.getQuestionContentInfoByIndex(index);
				if (!question || !contentInfo) return null;
				return {
					answeredAt: e!.dates.lastAnsweredAt || new Date(),
					credit: e!.credit!,
					id: question._id,
					numAttempts: 1,
					userAnswer: test.richAnswerToRawAnswer(
						e.userRichAnswer,
						contentInfo
					)!,
				};
			})
			.filter(isNonNullable);
		locallySaveModellingUserAnswers({
			answers: userAnswers,
			modellingId: this.modellingId!,
		});
	};

	render() {
		const header = (
			<div style={{ textAlign: "center", marginTop: 20 }}>
				<img
					src={CaucasusIntUniLogo}
					alt="caucasus int uni logo"
					style={{ maxHeight: 110, maxWidth: "90%" }}
				/>
			</div>
		);
		if (this.state.loading || !this.state.modellingTest) {
			return (
				<div style={{ textAlign: "center" }}>
					<SvgLoading />
				</div>
			);
		}
		if (this.state.mode === Mode.beginning) {
			if (this.modelingIsFinished()) {
				if (!this.canReviewNow()) {
					return (
						<div>
							{header}

							<div
								style={{
									textAlign: "center",
									fontFamily: "FiraGo",
								}}
								className="main"
							>
								<h2>მოდელირება დასრულებულია</h2>
							</div>
						</div>
					);
				}
				return (
					<div
						style={{ textAlign: "center", fontFamily: "FiraGo" }}
						className="main"
					>
						{header}
						<h2>იმიტირებული გამოცდის გადახედვა</h2>
						<PrimaryButton onClick={this.onStartReviewing}>
							დაწყება
						</PrimaryButton>
					</div>
				);
			}
			return (
				<div
					style={{
						textAlign: "center",
						fontFamily: "FiraGO",
					}}
					className="main"
				>
					<div
						className={
							styles.largeTextContainer /* styles.smallContainer */
						}
					>
						{header}
						<h1>{this.state.modellingTest.name}</h1>
						{this.state.modellingTest.subject_id === 8 && ( // კავკასია ინგილსური
							<EngInstructions />
						)}
						{this.state.modellingTest.subject_id === 17 && ( // კავკასია ისტორია
							<HistInstructions />
						)}
						<br />
						<br />
						<PrimaryButton onClick={this.onBeginTest}>
							{!hasStartedModelling(this.modellingId)
								? "გამოცდის დაწყება"
								: "გაგრძელება"}
						</PrimaryButton>
						{header}
					</div>
				</div>
			);
		}
		if (!this.state.questions || !this.state.texts || !this.state.test) {
			return (
				<div style={{ textAlign: "center" }}>
					<SvgLoading />
				</div>
			);
		}

		console.log("xxxxxx", this.state.mode, this.state.mode !== Mode.test);
		const testComponentProps: Partial<ITestComponentProps> = {};
		if (this.state.mode === Mode.end) {
			testComponentProps.navigatonShowage = {
				...TestComponent.defaultProps.navigatonShowage,
				onFinishPage: null,
			};
		}
		testComponentProps.initialQuestionsSkipStrategy = "answered";
		testComponentProps.postUserAnswerChamge = this.postUserAnswerChamge;
		return (
			<div
				style={{
					fontFamily: "FiraGO",
				}}
			>
				<ModellingTestContext.Provider
					value={{
						modelling: this.state.modellingTest,
						mode: this.state.mode,
					}}
				>
					{header}
					<UniversalTestWrapper2
						ref={this.WrapperRef}
						test={this.state.test}
						testTypeSettings={this.testTypeSettings}
						content={{
							cards: [],
							questions: this.state.questions,
							texts: this.state.texts,
						}}
						isFinishedByDefault={this.state.mode !== Mode.test}
						defaultUserAnswers={
							this.state.defaultCheckedAnswers || []
						}
						onFinish={this.onFinish}
						onSave={this.onSave}
						onGotoNext={this.onGotoNext}
						currentAttempt={1}
						FinishPageComponent={FinishPageComponent}
						testNavigationProps={{
							styles: testDesign,
							showFinishPageIcon: true,
						}}
						preFinishHook={this.preFinishHook}
						startedAt={this.state.startedAt}
						durationMs={
							this.state.mode === Mode.test
								? this.state.modellingTest.max_duration * 1000
								: undefined
						}
						onTimerEnd={this.onTimerEnd}
						testComponentProps={testComponentProps}
					/>
					<div
						className="main"
						style={{
							padding: "0 20px",
							marginTop: "-20px",
						}}
					>
						{this.state.mode !== Mode.end &&
							this.state.mode !== Mode.review && (
								<PrimaryButton
									onClick={this.forceFullyFinish}
									style={{ backgroundColor: "#a375ff" }}
								>
									გამოცდის დასრულება
								</PrimaryButton>
							)}
					</div>
					<br />
					<br />
					<br />
				</ModellingTestContext.Provider>
			</div>
		);
	}
}

const ModellingTestContext = React.createContext<{
	modelling: IModelingTest;
	mode: Mode;
}>({} as any);

export default connect<null, null, IOwnProps>(null, null)(Modelling);

const FinishPageComponent: React.FC<IFinishPageProps> = props => {
	const { modelling, mode } = useContext(ModellingTestContext);
	const [scores, setScores] = useState({
		userScore: 0,
		isFinished: false,
		isLoaded: false,
	});
	const [fetchAttempt, setFetchAttempt] = useState(0);
	useEffect(() => {
		const _ModelingTestsController = inject("ModelingController");
		setTimeout(() => {
			_ModelingTestsController
				.getScore({ testId: modelling.test_id })
				.then(data => {
					if (
						data.isFinished ||
						data.userScore > 0 ||
						fetchAttempt > 2 // if test is not finished and userscore is not fetch, probably we fetch it before the test was submitted
					) {
						setScores({ ...data, isLoaded: true });
					} else {
						setFetchAttempt(x => x + 1);
					}
				});
		}, 2000);
	}, [modelling.test_id, fetchAttempt]);

	const [popupOpen, setPopupOpen] = useState(false);
	// const maxScore = useMemo(() => {
	// 	try {
	// 		let sum = 0;
	// 		for (const q of props.questions) {
	// 			sum += newContent((q as IFullQuestion).content).getMaxCredit();
	// 		}
	// 		return sum;
	// 	} catch (e) {
	// 		console.warn(e);
	// 	}
	// 	return 0;
	// }, [props.questions]);

	const score = props.info.totalCredit;

	// const normalizedScore = Math.round(score * 100) / 100;

	const statsByModellingId: {
		[id: number]: {
			maxErovnuliScore: number;
			maxModellingScore: number;
			minErovnuliScore: number;
			subjName: string;
		};
	} = {
		21: {
			maxErovnuliScore: 80,
			maxModellingScore: 58,
			minErovnuliScore: 16,
			subjName: "ინგლისური ენის",
		},
		24: {
			maxErovnuliScore: 60,
			maxModellingScore: 52,
			minErovnuliScore: 15,
			subjName: "ისტორიის",
		},
	};

	const modellingStats = statsByModellingId[modelling.id];

	const user = useCoursesUser();
	const addressing = user ? user.firstname + ", " : "";
	if (!scores.isLoaded) return <Loading />;

	return (
		<div>
			<div
				style={{
					fontFamily: "FiraGo",
					textAlign: "center",
					color: "black",
					fontSize: 22,
				}}
			>
				<span
					style={{ fontSize: 24, marginBottom: 10, display: "block" }}
				>
					იმიტირებული გამოცდა დასრულებულია! <br/><br/> თქვენ დააგროვეთ{" "}
					<b>
						{" "}
						{scores.userScore.toFixed(2)} ქულა{" "}
						{modellingStats.maxModellingScore}-დან.
					</b>
					<br />
					<br />
				</span>
				<p style={{ fontSize: 18, textAlign: "left" }}>
					<b>შეგახსენებთ,</b> იმიტირებული გამოცდისას <b>თქვენი ნაწერი სწორდება ელექტრონული პროგრამის მიერ.</b> 
					შესაბამისად არსებობს ალბათობა რომ იყოს მცირე უზუსტობა ელექტრონული პროგრამის მიერ ტესტის გასწორებისას! 
					<br/><br/>
					<b>ამიტომ, მნიშვნელოვანია აუცილებლად შეამოწმო საკუთარი ნაშრომი! </b>
					<br/><br/>
					საკუთარი ნაშრომისა და ტესტის სწორი პასუხების ნახვას შეძლებ ამავე გვერდზე <br/><br/>
					<b>10 ივნისს 12:00-დან 23:59-მდე და თქვენივე ექაუნთით!</b>
					<br/><br/>
					გახსოვდეს, მთავარია ეროვნულები წარმატებით ჩააბარო,<br/>
					შენთვის კავკასიის საერთაშორისო უნივერსიტეტის კარი ღიაა!<br/>
					<br /><br />
					<img src="/static/media/finish.png" style={{ width: "50%", margin: "auto", display: "block" }}></img>
				</p>
				{popupOpen && (
					<CaUniBannerPopup onClose={() => setPopupOpen(false)} />
				)}
			</div>
		</div>
	);
};
