import * as React from "react";
import { match } from "react-router";
import { ObjectId } from "@app/utils/generics";
import memoizeOne from "memoize-one";
import { inject } from "@app/modules";

interface IProps {
	match: match<{ courseId: ObjectId }>;
}

export class CourseEssentialData extends React.PureComponent<IProps> {
	_CourseFetchingController = inject("CourseFetchingController");
	_CoursesController = inject("CoursesController");

	loadCourse = memoizeOne((courseId: ObjectId) => {
		if (courseId.length === 24) {
			this._CourseFetchingController
				.loadWholeCourseSkeleton({
					courseId,
					depth: 0,
				})
				.then();
			this._CoursesController
				.getHierarchies({ _id: courseId }, true)
				.then();
		}
	});

	render() {
		this.loadCourse(this.props.match.params.courseId);
		return null;
	}
}
