import ReactQuill, { Quill } from "react-quill";
import { getFormattedMessage } from "@app/utils/locale";
import { css } from "emotion";
import { IFileText } from "@app/api/files/helper-schemas";
import React from "react";
import { StringMap } from "quill";
import ImageResize from "quill-image-resize-module-react";
import { ImageDrop } from "quill-image-drop-module";

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDrop", ImageDrop);

interface FileTextProps {
	item: IFileText;
	onChange: (item: IFileText) => void;
	onGalleryOpen: (onSuccess: (path: string) => void) => void;
}

export class FileText extends React.PureComponent<FileTextProps> {
	modules: StringMap = {
		toolbar: {
			container: [
				[{ header: "1" }, { size: [] }],
				["bold", "italic", "underline", "strike", "blockquote"],
				[{ list: "ordered" }, { list: "bullet" }],
				["link", "image", "video"],
				["clean"],
			],
			handlers: {
				image: () => {
					this.props.onGalleryOpen(this.onUpload);
				},
			},
		},
		imageResize: {
			parchment: Quill.import("parchment"),
			handleStyles: {
				cursor: "initial",
			},
		},
		// imageDrop: true,
	};

	quillRef: React.RefObject<ReactQuill> = React.createRef();

	handleTextChange = (text: string) => {
		this.props.onChange({
			...this.props.item,
			text,
		});
	};

	onUpload = (path: string) => {
		if (!this.quillRef.current) return;
		const editor = this.quillRef.current.getEditor();
		const range = editor.getSelection(true);
		editor.insertEmbed(range.index, "image", path);
	};

	render() {
		const { item } = this.props;
		return (
			<ReactQuill
				className={TextArea}
				placeholder={getFormattedMessage("admin:general.typeText")}
				defaultValue={item.text}
				onChange={this.handleTextChange}
				modules={this.modules}
				ref={this.quillRef}
			/>
		);
	}
}

const TextArea = css`
	margin-top: 35px;
`;
