import * as React from "react";
import CardTemplate from "@tests-core/components/cards/templates";
import CardTemplateWrapper from "@tests-core/components/cards/templates/wrapper";
import { connect } from "react-redux";
import { History } from "history";
import { ICardTemplate } from "@tests-core/schemas/cards/templates";
import { IRootState } from "@app/redux";
import { match } from "react-router";
import { inject } from "@app/modules";

interface IOwnProps {
	match: match<{ cardTemplateId?: string }>;
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IOwnProps;

class CardTemplatePage extends React.Component<IProps> {
	template?: ICardTemplate;
	id = this.props.match.params.cardTemplateId;
	_isMounted = false;

	_CardTemplatesController = inject("CardTemplatesController");

	componentDidMount() {
		this._isMounted = true;
		if (this.id) {
			this._CardTemplatesController
				.getById({ _id: this.id }, true)
				.then(template => {
					this.template = template;
					this.forceUpdate();
				});
		}
	}

	save = (newTemplate: ReturnType<CardTemplate["getData"]>) => {
		const { name, properties, labels } = newTemplate;
		if (!this.id) {
			this._CardTemplatesController
				.add({ name, properties, labels })
				.then(data =>
					this.props.history.replace(`/admin/card-templates`)
				);
			return;
		}
		this._CardTemplatesController
			.update({ _id: this.id, name, properties, labels })
			.then(() => this.props.history.push(`/admin/card-templates`));
	};

	render() {
		if (this.id && !this.template) return null;
		return (
			<div className="main">
				<CardTemplateWrapper
					onSave={this.save}
					template={this.template}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	cardTemplates: state.cardTemplates,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	CardTemplatePage
);
