import * as React from "react";
import Select from "react-select";
import { IYearDate } from "../../../../schemas/cards/templates";

interface IYearDateProps {
	date: IYearDate;
	onChange: (newDate: IYearDate) => void;
}

export default class YearDate extends React.PureComponent<IYearDateProps> {
	months = [
		{
			value: -1,
			label: "None",
		},
		...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(e => ({
			value: e,
			label: "" + e,
		})),
	];
	onYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onChange({
			...this.props.date,
			year: +e.target.value,
		});
	};
	onMonthChange = (selection: { value: number; label: string }) => {
		if (selection.value === -1) {
			// none
			this.props.onChange({ year: this.props.date.year });
			return;
		}
		this.props.onChange({
			...this.props.date,
			month: selection.value,
		});
	};
	onDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (+e.target.value > 31) {
			// TODO: check more strictly
			return;
		}
		this.props.onChange({
			...this.props.date,
			day: +e.target.value,
		});
	};
	render() {
		const selectedMonth = this.months.find(
			e => e.value === this.props.date.month
		);
		return (
			<>
				<div style={{ margin: "3px 0px" }}>
					<div style={{ display: "inline-block", minWidth: 50 }}>
						year
					</div>
					<input
						defaultValue={"" + this.props.date.year}
						type="number"
						onChange={this.onYearChange}
					/>
				</div>
				<div style={{ display: "inline-block", minWidth: 50 }}>
					month
				</div>
				<div
					style={{
						display: "inline-block",
						minWidth: "calc(100% - 50px)",
					}}
				>
					<Select
						value={selectedMonth}
						options={this.months}
						onChange={this.onMonthChange}
						placeholder="Month"
					/>
				</div>
				{this.props.date.month !== undefined && (
					<div style={{ margin: "3px 0px" }}>
						<div style={{ display: "inline-block", minWidth: 50 }}>
							day
						</div>
						<input
							defaultValue={"" + this.props.date.day}
							type="number"
							onChange={this.onDayChange}
						/>
					</div>
				)}
			</>
		);
	}
}
