import Joi from "@app/utils/joi";
import {
	CourseSchema,
	RHierarchySchema,
	CourseReminderSchema,
	IRHierarchy,
	ICourse,
	ICourseReminder,
} from "./helper-schemas";
import {
	RFolderSchema,
	itemTypes,
	ItemType,
	IRFolder,
} from "../folders/helper-schemas";
import {
	TopicSchema,
	TopicHierarchySchema,
	ITopic,
} from "../topics/helper-schemas";
import { TaskTypeSchema, ITaskType } from "../task-types/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { TopicHierarchy } from "@app/models/topic-hierarchy";
import {
	insertStripKeys,
	markKeysForbidden,
	ToInsertKeys,
	updateStripKeys,
	markKeysOptional,
	ToUpdateKeys,
} from "../helper-schemas";

///

export const AGETCourseHierarchySchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETCourseHierarchy {
	_id: ObjectId;
}

export const RGETCourseHierarchySchema = Joi.object({
	folderHierarchy: RHierarchySchema.required(),
	topicHierarchy: TopicHierarchySchema.required(),
	taskTypeHierarchy: RHierarchySchema.required(),
});
export interface IRGETCourseHierarchy {
	folderHierarchy: IRHierarchy;
	topicHierarchy: TopicHierarchy;
	taskTypeHierarchy: IRHierarchy;
}

///

const CourseCreatingForbiddenKeys = [
	"slug",
	"rootFolder",
	"rootTopic",
	"rootTaskType",
] as const;

export const APOSTCreateCourseSchema = CourseSchema.keys(insertStripKeys).fork(
	CourseCreatingForbiddenKeys,
	markKeysForbidden
);

export type IAPOSTCreateCourse = ToInsertKeys<
	ICourse,
	never,
	typeof CourseCreatingForbiddenKeys[number]
>;

export const RPOSTCreateCourseSchema = Joi.object({
	course: CourseSchema.required(),
	rootFolder: RFolderSchema.required(),
	rootTopic: TopicSchema.required(),
	rootTaskType: TaskTypeSchema.required(),
	hierarchies: RGETCourseHierarchySchema.required(),
});
export interface IRPOSTCreateCourse {
	course: ICourse;
	rootFolder: IRFolder;
	rootTopic: ITopic;
	rootTaskType: ITaskType;
	hierarchies: IRGETCourseHierarchy;
}

///

export const AGETCourseSchema = Joi.object({
	_id: Joi.objectId().required(),
});
export interface IAGETCourse {
	_id: ObjectId;
}

export const RGETCourseSchema = CourseSchema;
export type IRGETCourse = ICourse;

///

const courseEditingOptionalKeys = [
	"name",
	"description",
	"price",
	"imageUrl",
	"visibility",
] as const;

const courseEditingForbiddenKeys = [
	"slug",
	"rootFolder",
	"rootTopic",
	"rootTaskType",
] as const;

export const APUTCourseSchema = CourseSchema.keys(updateStripKeys)
	.fork(courseEditingOptionalKeys, markKeysOptional)
	.fork(courseEditingForbiddenKeys, markKeysForbidden);
export type IAPUTCourse = ToUpdateKeys<
	ICourse,
	typeof courseEditingOptionalKeys[number],
	typeof courseEditingForbiddenKeys[number]
>;

///

export const ADELETECourseSchema = AGETCourseSchema;
export type IADELETECourse = IAGETCourse;

///

export const RGETAllCoursesSchema = Joi.array().items(CourseSchema);
export type IRGETAllCourses = ICourse[];

///

export const AGETManyCoursesByIdsSchema = Joi.object({
	ids: Joi.array()
		.items(Joi.objectId())
		.required(),
});
export interface IAGETManyCoursesByIds {
	ids: ObjectId[];
}

export const RGETManyCoursesByIdsSchema = Joi.array().items(CourseSchema);
export type IRGETManyCoursesByIds = ICourse[];

///

export const APOSTUploadCourseMediaSchema = Joi.object({
	courseId: Joi.objectId().required(),
});
export interface IAPOSTUploadCourseMedia {
	courseId: ObjectId;
}

export const RPOSTUploadCourseMediaSchema = Joi.object({
	files: Joi.array()
		.items({
			fileName: Joi.string().required(),
			fileFullUrl: Joi.string().required(),
			mimetype: Joi.string().required(),
		})
		.required(),
});
export interface IRPOSTUploadCourseMedia {
	files: {
		fileName: string;
		fileFullUrl: string;
		mimetype: string;
	}[];
}

///

export const RPOSTGetCourseMediaSchema = Joi.object({
	files: Joi.array()
		.items(
			Joi.object({
				path: Joi.string().required(),
				mimetype: Joi.string()
					.allow(null)
					.required(),
			})
		)
		.required(),
});
export interface IRPOSTGetCourseMedia {
	files: {
		mimetype: string | null;
		path: string;
	}[];
}

///

export const AGETGetCourseFoldersSchema = Joi.object({
	courseId: Joi.objectId().required(),
});
export interface IAGETGetCourseFolders {
	courseId: ObjectId;
}

///

export const RGETCourseFoldersSchema = Joi.object({
	rootFolderId: Joi.objectId().required(),
	allFolders: Joi.array()
		.items(RFolderSchema)
		.required(),
});
export interface IRGETCourseFolders {
	rootFolderId: ObjectId;
	allFolders: IRFolder[];
}

export const APOSTCourseReminderSchema = Joi.object({
	courseId: Joi.objectId().required(),
	messagePattern: Joi.string().required(),
	lateMessagePattern: Joi.string().required(),
	hour: Joi.number()
		.integer()
		.required(),
	minute: Joi.number()
		.integer()
		.required(),
});
export interface IAPOSTCourseReminder {
	courseId: ObjectId;
	messagePattern: string;
	lateMessagePattern: string;
	hour: number;
	minute: number;
}

export const APUTCourseReminderSchema = APOSTCourseReminderSchema;
export type IAPUTCourseReminder = IAPOSTCourseReminder;

export const AGETCourseReminderSchema = Joi.object({
	courseId: Joi.objectId().required(),
});
export interface IAGETCourseReminder {
	courseId: ObjectId;
}

export const RGETCourseReminderSchema = CourseReminderSchema;
export type IRGETCourseReminder = ICourseReminder | null;

///

export const AGETCourseFilteredContentSchema = Joi.object({
	courseId: Joi.objectId().required(),
	folders: Joi.alternatives(
		Joi.array().items(Joi.objectId()),
		Joi.string().valid("none")
	).allow(null),
	topics: Joi.alternatives(
		Joi.array().items(Joi.objectId()),
		Joi.string().valid("none")
	).allow(null),
	taskTypes: Joi.alternatives(
		Joi.array().items(Joi.objectId()),
		Joi.string().valid("none")
	).allow(null),
	limit: Joi.number().required(),
	offset: Joi.number().required(),
	randomSeed: Joi.number().allow(null),
});
export interface IAGETCourseFilteredContent {
	courseId: ObjectId;
	limit: number;
	offset: number;
	folders?: ObjectId[] | "none" | null;
	topics?: ObjectId[] | "none" | null;
	taskTypes?: ObjectId[] | "none" | null;
	randomSeed?: number | null;
}

export const FilteredContent = Joi.object({
	type: Joi.string()
		.valid(...itemTypes)
		.required(),
	id: Joi.objectId().required(),
	name: Joi.string()
		.allow("")
		.required(),
	folders: Joi.array()
		.items(Joi.objectId())
		.required(),
	topics: Joi.array()
		.items(Joi.objectId())
		.required(),
	taskTypes: Joi.array()
		.items(Joi.objectId())
		.required(),
});
export interface IFilteredContent {
	type: ItemType;
	id: ObjectId;
	name: string;
	folders: ObjectId[];
	topics: ObjectId[];
	taskTypes: ObjectId[];
}

export const RGETCourseFilteredContentSchema = Joi.object({
	items: Joi.array()
		.items(FilteredContent)
		.required(),
	totalCount: Joi.number()
		.integer()
		.required(),
});
export interface IRGETCourseFilteredContent {
	items: IFilteredContent[];
	totalCount: number;
}
