import { ObjectId } from "@app/utils/generics";
import React, { useState, useMemo, useEffect } from "react";
import { ItemType } from "@app/api/folders/helper-schemas";
import AllFolders from "./all-folders";
import { useStoreProperty } from "@app/hooks/store";
import { useCoursesUser } from "@app/hooks/users";
import Loading from "@app/components/widgets/raw-loading";
import Popup, { PopupContent } from "@app/components/widgets/Popup";
import AdminTable from "@app/components/widgets/admin-table";
import { inject } from "@app/modules";
import { useWholeCourseSkeleton } from "@app/hooks/folders";

export const AllCourses: React.FC<{
	currentCourse: ObjectId;
	currentFolder: ObjectId;
	onFolderSelect: (args: { courseId: ObjectId; folderId: ObjectId }) => void;
	onClose: () => void;
}> = ({ currentCourse, currentFolder, onFolderSelect, onClose }) => {
	const [selectedCourse, setSetectedCourse] = useState(
		sessionStorage.getItem("copyingCourse")
	);

	const skeleton = useWholeCourseSkeleton({ courseId: selectedCourse });

	useEffect(() => {
		const CoursesController = inject("CoursesController");
		CoursesController.getAll().then();
	}, []);

	const courses = useStoreProperty("courses");
	const user = useCoursesUser()!;

	const sortedCourses = useMemo(() => {
		if (!courses) return null;
		return Object.keys(courses)
			.map(_id => courses[_id]!.info)
			.filter(course => user.canAccessCourse(course._id))
			.sort((a, b) =>
				b.createdAt instanceof Date && a.createdAt instanceof Date
					? b.createdAt.getTime() - a.createdAt.getTime()
					: 0
			);
	}, [courses, user]);

	if (!sortedCourses || (selectedCourse && !skeleton)) {
		return (
			<Popup onClose={onClose}>
				<PopupContent>
					<Loading />
				</PopupContent>
			</Popup>
		);
	}

	return (
		<div>
			{selectedCourse && (
				<AllFolders
					courseId={selectedCourse}
					onClose={() => setSetectedCourse(null)}
					onFolderChange={folderId =>
						onFolderSelect({ courseId: selectedCourse!, folderId })
					}
					itemType={ItemType.folder}
					itemId={currentFolder}
				/>
			)}
			{!selectedCourse && (
				<Popup onClose={onClose}>
					<PopupContent>
						<AdminTable
							rows={sortedCourses}
							onView={courseId => setSetectedCourse(courseId)}
						/>
					</PopupContent>
				</Popup>
			)}
		</div>
	);
};
