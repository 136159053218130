import { IAppeal } from "@app/api/appeals/helper-schemas";
import { IRGETAppealsByCourse } from "@app/api/appeals/validators";
import { ItemType } from "@app/api/folders/helper-schemas";
import { addLoader } from "@app/commonJavascript";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import FancyLoading from "@app/components/widgets/fancy-loading";
import { useCourseAppeals } from "@app/hooks/appeals";
import { useBoolean } from "@app/hooks/general";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import { getFormattedMessage } from "@app/utils/locale";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { History } from "history";
import React, { useCallback, useState, useMemo } from "react";
import QuestionPopup from "../questions/popup";
import styles from "./styles/index.module.css";
import { covertASCIIMathToLaTex } from "@tests-core/components/editor/math";
import { normalizeHTML } from "@tests-core/components/editor/html";

export const CourseAppeals: React.FC<{
	history: History;
	courseId: ObjectId;
}> = React.memo(({ history, courseId }) => {
	const [deletedAppeals, setDeletedAppeals] = useState<
		Record<any, true | undefined>
	>({});
	const itemAppeals = useCourseAppeals(courseId);

	const normalizedItemAppeals = useMemo(() => {
		if (!itemAppeals) return itemAppeals;
		return [...itemAppeals]
			.sort((a, b) => {
				return b.appeals.length - a.appeals.length;
			})
			.map(e => {
				return {
					...e,
					appeals: e.appeals.filter(a => !deletedAppeals[a._id]),
				};
			});
	}, [itemAppeals, deletedAppeals]);

	const onDelete = useCallback(async (appealId: ObjectId) => {
		const controller = inject("AppealsController");
		const removeLoader = addLoader();
		try {
			await controller.delete({ _id: appealId });
			setDeletedAppeals(e => ({ ...e, [appealId]: true }));
		} catch (e) {
			openConfirmationPopup({
				text: getFormattedMessage("errorAlert"),
			});
		} finally {
			removeLoader();
		}
	}, []);

	const onDeleteItemAppeals = useCallback(
		async (itemId: ObjectId, itemType: ItemType) => {
			console.log("normalizedItemAppeals", normalizedItemAppeals);
			if (!normalizedItemAppeals) return;
			const itemAppeals = normalizedItemAppeals.find(
				e => e.itemId === itemId && e.itemType === itemType
			);
			console.log(itemId, itemType, itemAppeals);
			if (!itemAppeals) return;
			const controller = inject("AppealsController");
			const removeLoader = addLoader();
			try {
				const appealIds = itemAppeals.appeals.map(e => e._id);
				console.log(appealIds);
				await controller.deleteMany({ ids: appealIds });
				setDeletedAppeals(e => {
					const newObj = { ...e };
					for (const appealId of appealIds) {
						newObj[appealId] = true;
					}
					return newObj;
				});
			} catch (e) {
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			} finally {
				removeLoader();
			}
		},
		[normalizedItemAppeals]
	);

	if (!normalizedItemAppeals) return <FancyLoading />;

	return (
		<div className="main main2">
			{normalizedItemAppeals.map(e => (
				<ItemWithAppeals
					data={e}
					key={e.itemId + e.itemType}
					courseId={courseId}
					onDelete={onDelete}
					onDeleteItemAppeals={onDeleteItemAppeals}
				/>
			))}
		</div>
	);
});

const ItemWithAppeals: React.FC<{
	data: IRGETAppealsByCourse[number];
	courseId: ObjectId;
	onDelete: (appealId: ObjectId) => void;
	onDeleteItemAppeals: (itemId: ObjectId, itemType: ItemType) => void;
}> = ({ data, courseId, onDelete, onDeleteItemAppeals }) => {
	return (
		<div className={styles.itemWithAppealsContainer}>
			<ItemHeader
				itemId={data.itemId}
				itemType={data.itemType}
				name={data.name}
				courseId={courseId}
				onDeleteItemAppeals={onDeleteItemAppeals}
				numOfAppeals={data.appeals.length}
			/>
			<Appeals appeals={data.appeals} onDelete={onDelete} />
		</div>
	);
};

const ItemHeader: React.FC<{
	itemId: string;
	itemType: ItemType;
	name: string;
	courseId: ObjectId;
	numOfAppeals: number;
	onDeleteItemAppeals: (itemId: ObjectId, itemType: ItemType) => void;
}> = ({
	itemId,
	itemType,
	name,
	courseId,
	numOfAppeals,
	onDeleteItemAppeals,
}) => {
	const {
		value: isPopupOpen,
		setTrue: openPopup,
		setFalse: closePopup,
	} = useBoolean();

	if (itemType !== ItemType.question) return null;
	return (
		<React.Fragment>
			<div className={styles.itemheader}>
				<span
					dangerouslySetInnerHTML={{
						__html: covertASCIIMathToLaTex(normalizeHTML(name)),
					}}
				/>
				{numOfAppeals > 0 && (
					<DeleteIcon
						onClick={() => onDeleteItemAppeals(itemId, itemType)}
						className={styles.deleteIcon}
						style={{ marginRight: 10 }}
					/>
				)}
				<EditIcon onClick={openPopup} />
			</div>
			{isPopupOpen && (
				<QuestionPopup
					onClose={closePopup}
					courseId={courseId}
					id={itemId}
					onSuccessSave={closePopup}
				/>
			)}
		</React.Fragment>
	);
};

const Appeals: React.FC<{
	appeals: IAppeal[];
	onDelete: (appealId: ObjectId) => void;
}> = React.memo(({ appeals, onDelete }) => {
	return (
		<div className={styles.appeals}>
			{appeals.map(appeal => (
				<SingleAppeal
					key={appeal._id}
					appeal={appeal}
					onDelete={onDelete}
				/>
			))}
		</div>
	);
});

const SingleAppeal: React.FC<{
	appeal: IAppeal;
	onDelete: (appealId: ObjectId) => void;
}> = React.memo(({ appeal, onDelete }) => {
	return (
		<div className={styles.appeal}>
			<span>{appeal.message}</span>{" "}
			<DeleteIcon
				onClick={() => onDelete(appeal._id)}
				className={styles.deleteIcon}
			/>
		</div>
	);
});
