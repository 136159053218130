import * as React from "react";
import memoizeOne from "memoize-one";
import MiniChatHeader, { IHeaderStyleProps } from "./header";
import MiniChatMessages from "./messages";
import MiniChatTextArea, {
	ITextAreaContainerStyle,
	ITextAreaStyle,
} from "./text-area";
import styled from "@emotion/styled";
import uuid from "uuid";
import {
	addSentMessageInStorage,
	markMessageAsSeenInStorage,
} from "../../actions/chatrooms";
import { connect } from "react-redux";
import { IMessageContainerStyle } from "./message-wrappers";
import { IChatRootState, ToDispatchType } from "../../rootReducer";
import { IStateChatroom } from "@chat-app/reducers/chatrooms";
import { startDialogue } from "@chat-app/socket-functions";

interface IStyle {
	borderRadius?: string;
}

interface IOwnProps {
	destinationId: string;
	destinationName: string;
	userId: string;
	userName: string;
	uniPicture: string;
	greetingMessage?: string;
	style?: IStyle;
	headerStyle?: IHeaderStyleProps;
	messageStyle?: { containerStyle?: IMessageContainerStyle };
	textareaStyle?: {
		containerStyle?: ITextAreaContainerStyle;
		textareaStyle?: ITextAreaStyle;
	};
	onClose: () => void;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IDispatchProps = ToDispatchType<typeof mapDIspatchToProps>;

type IProps = IStateProps & IDispatchProps & IOwnProps;

const Container = styled.div`
	display: inline-flex;
	flex-direction: column;
	position: fixed;
	left: calc(100vw - 25px);
	top: calc(100vh - 5px);
	width: calc(100vw - 10px);
	height: calc(100vh - 10px);
	max-width: 360px;
	max-height: 360px;
	transform: translate(-100%, -100%);
	border-radius: ${(props: any) =>
		props.borderRadius ? props.borderRadius : "20px"};
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
	z-index: 99;
`;

const MessageContainer = styled.div`
	display: inline-flex;
	flex: 1;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	max-height: calc(100% - 45px);
`;

class MiniChat extends React.Component<IProps> {
	findChatroom = memoizeOne(
		(userId: string, destinationId: string, chatrooms): string | null => {
			if (!chatrooms) return null;
			const chatroomKeys = Object.keys(chatrooms);
			if (chatroomKeys.length <= 0) return null;

			for (let i = 0; i < chatroomKeys.length; i++) {
				if (
					chatrooms[chatroomKeys[i]].participantIds.indexOf(userId) >=
						0 &&
					chatrooms[chatroomKeys[i]].participantIds.indexOf(
						destinationId
					) >= 0
				) {
					return chatrooms[chatroomKeys[i]]._id;
				}
			}
			return null;
		}
	);

	sendMessage = (chatroomId: string | null) => (messageText: string) => {
		if (!chatroomId) {
			startDialogue(this.props.userId, this.props.userName, {
				destinationId: this.props.destinationId,
				destinationName: this.props.destinationName,
				destinationType: "course",
				tempId: uuid(),
				firstMessage: messageText,
			});
			return;
		}
		this.props.dispatchMessage(this.props.userId, chatroomId, messageText);
	};

	markUnseen = (chatroom: IStateChatroom[string], userId: string): void => {
		const { messages } = chatroom;
		const unseenMessages: string[] = [];

		for (let i = 0; i < messages.length; i++) {
			if (messages[i].seenBy.indexOf(userId) < 0)
				unseenMessages.push(messages[i]._id);
		}

		if (unseenMessages.length > 0) {
			this.props.markAsSeen(unseenMessages, userId);
		}
	};

	render() {
		const chatroomId = this.findChatroom(
			this.props.userId,
			this.props.destinationId,
			this.props.chatroomsInfo
		);
		let currentChatroom: any = chatroomId
			? this.props.chatroomsInfo![chatroomId]
			: null;

		let chatroomName;
		let currentMessages;
		if (currentChatroom !== null) {
			this.markUnseen(currentChatroom, this.props.userId);
			currentMessages = [...currentChatroom.messages];

			if (currentChatroom.name !== undefined) {
				chatroomName = currentChatroom.name;
			} else if (currentChatroom.participantNames.length > 2) {
				chatroomName = currentChatroom.participantNames.join(", ");
			} else {
				const userIndex = currentChatroom.participantIds.indexOf(
					this.props.userId
				);
				chatroomName =
					currentChatroom.participantNames[userIndex === 0 ? 1 : 0];
			}

			if (this.props.greetingMessage) {
				currentMessages = [
					{
						_id: uuid(),
						seenBy: [this.props.userId],
						senderId: this.props.destinationId,
						message: this.props.greetingMessage,
					},
					...currentMessages,
				];
			}
		} else {
			chatroomName = this.props.destinationName;
			currentChatroom = {
				messages: this.props.greetingMessage
					? [
							{
								_id: uuid(),
								seenBy: [this.props.userId],
								senderId: this.props.destinationId,
								message: this.props.greetingMessage,
							},
					  ]
					: [],
				participantIds: [this.props.userId, this.props.destinationId],
				participantNames: [
					this.props.userName,
					this.props.destinationName,
				],
			};
			currentMessages = currentChatroom.messages;
		}

		const { borderRadius } = this.props.style
			? this.props.style
			: { borderRadius: undefined };

		return (
			<Container borderRadius={borderRadius}>
				<MiniChatHeader
					chatroomName={chatroomName}
					style={this.props.headerStyle}
					onClose={this.props.onClose}
				/>
				<MessageContainer>
					<MiniChatMessages
						userId={this.props.userId}
						uniPicture={this.props.uniPicture}
						messages={currentMessages}
						participantIds={currentChatroom.participantIds}
						participantNames={currentChatroom.participantNames}
						style={
							this.props.messageStyle
								? {
										messageContainer: this.props
											.messageStyle.containerStyle,
								  }
								: undefined
						}
					/>
					<MiniChatTextArea
						containerStyle={
							this.props.textareaStyle
								? this.props.textareaStyle.containerStyle
								: undefined
						}
						textareaStyle={
							this.props.textareaStyle
								? this.props.textareaStyle.textareaStyle
								: undefined
						}
						onSend={this.sendMessage(chatroomId)}
					/>
				</MessageContainer>
			</Container>
		);
	}
}

const mapStateToProps = (state: IChatRootState) => ({
	chatroomsInfo: state.chatrooms.info,
});

const mapDIspatchToProps = {
	dispatchMessage: addSentMessageInStorage,
	markAsSeen: markMessageAsSeenInStorage,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDIspatchToProps
)(MiniChat);
