import * as React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Popup, { PopupContentWithClose } from "../../widgets/Popup";
import SingleQuestionForm from "./single";
import TextWithQuestions from "./with-text";
import { IFullQuestion } from "@tests-core/schemas/questions/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import "react-quill/dist/quill.snow.css";
import { getFormattedMessage } from "@app/utils/locale";
import { successfulSaveArg } from "../types";

type IProps = {
	onClose: () => void;
	onSuccessSave?: (args: successfulSaveArg) => void;
	courseId: ObjectId;
} & (
	| { id: ObjectId; folderId?: ObjectId }
	| { id?: ObjectId; folderId: ObjectId }
);

interface IState {
	isWithoutText: boolean;
	textId?: ObjectId;
}

class QuestionPopup extends React.PureComponent<IProps, IState> {
	question?: IFullQuestion;
	id = this.props.id;
	_isMounted = false;

	state: IState = {
		isWithoutText: true,
		textId: undefined,
	};

	handleChange = (e, checked: boolean) => {
		this.setState({
			isWithoutText: !checked,
		});
	};

	onTextIdLoad = (textId: ObjectId) => {
		this.setState({
			isWithoutText: false,
			textId,
		});
	};

	onSuccessSave = (args: successfulSaveArg) => {
		if (this.props.onSuccessSave) {
			this.props.onSuccessSave(args);
		}
		this.props.onClose();
	};

	render() {
		return (
			<Popup
				onClose={this.props.onClose}
				disableClosingOnOutsideClick={true}
			>
				<PopupContentWithClose onClose={this.props.onClose}>
					{!this.id && (
						<div>
							<FormControlLabel
								control={
									<Checkbox
										checked={!this.state.isWithoutText}
										onChange={this.handleChange}
										value="checkedA"
									/>
								}
								label={getFormattedMessage(
									"admin:general.commonTextForQuestions"
								)}
							/>
						</div>
					)}
					{this.state.isWithoutText && (
						<SingleQuestionForm
							folderId={this.props.folderId as ObjectId}
							courseId={this.props.courseId}
							id={this.props.id as ObjectId}
							onSuccessSave={this.onSuccessSave}
							handleQuestionWithText={this.onTextIdLoad}
						/>
					)}
					{!this.state.isWithoutText && (
						<TextWithQuestions
							folderId={this.props.folderId as ObjectId}
							courseId={this.props.courseId}
							textId={this.state.textId as ObjectId}
							onSuccessSave={this.onSuccessSave}
						/>
					)}
				</PopupContentWithClose>
			</Popup>
		);
	}
}

export default QuestionPopup;
