import * as React from "react";
import styled, { css } from "react-emotion";

export const AnglePath = styled("div")({
	position: "absolute",
	width: 24,
	height: 39,
	borderLeft: "1px solid #c8cdd2",
	borderBottom: "1px solid #c8cdd2",
	left: -27,
	top: -23,
});

export const StraightPath = styled("div")({
	position: "absolute",
	height: 27,
	borderLeft: "1px solid #c8cdd2",
	left: 14,
	top: -29,
});

export const CellStyles = styled("div")({
	borderTop: "2px solid #edeef0",
	paddingTop: 5,
	display: "flex",
	cursor: "pointer",
});

export const BlueBase = styled("div")(
	{
		width: 30,
		height: 30,
		backgroundColor: "#284ea6",
		borderRadius: 50,
		fontFamily: "arial",
		color: "white",
		fontSize: 21,
		display: "inline-block",
	},
	(props: { isDone: boolean }) => {
		if (props.isDone) return { backgroundColor: "green" };
		return {};
	}
);

export const textColor = "#242424";

export const FilesTextStyles = styled("div")(
	{
		color: textColor,
		display: "inline-flex",
		alignSelf: "center",
		flex: 8,
		padding: "0px 5px",
		verticalAlign: "middle",
		fontSize: 18,
		fontFamily: "FiraGO",
	},
	(props: { isSelected: boolean; isLocked: boolean }) => {
		if (props.isSelected) return { fontWeight: "bold" };
		if (props.isLocked) return { color: "#bfbfbf", fontWeight: "normal" };
		return {};
	}
);

export const FolderNameStyles = styled("div")`
	color: ${textColor};
	display: inline-flex;
	align-self: center;
	flex: 8;
	padding: 0px 5px;
	vertical-align: middle;
	font-size: 18px;
	font-family: "FiraGO";
	font-weight: ${(props: { isOpen: boolean; isLocked: boolean }) =>
		props.isOpen ? (props.isLocked ? "normal" : "bold") : "normal"};
	color: ${(props: { isOpen: boolean; isLocked: boolean }) => {
		return props.isLocked ? "#bfbfbf" : textColor;
	}};
`;

export const LockStyles = styled("div")({
	color: "#bfbfbf",
	display: "inline-Block",
	flex: 1,
	verticalAlign: "middle",
});

export const ArrowStyles = styled("div")({
	color: textColor,
	display: "inline-Block",
	flex: 1,
	verticalAlign: "middle",
	svg: {
		transition: "0.3s",
	},
});

export const ArrowStyleOpen = css`
	svg {
		transform: rotate(180deg);
	}
	cursor: pointer;
`;

export const NavigationTitleCSS = css`
	display: flex;
	font-family: "FiraGO";
	align-items: center;
	font-size: 17px;
	padding: 10px 10px 10px 0;
	cursor: pointer;
`;

export const Scrollable = css`
	word-break: break-word;

	@media (max-width: 960px) {
		border: 1px double #bfbfbf66;
		border-radius: 4px;
		overflow-y: auto;
		max-height: calc(100vh - 193px);
		::-webkit-scrollbar {
			width: 3px;
		}

		::-webkit-scrollbar-track {
			background: #f1f1f1;
		}

		::-webkit-scrollbar-thumb {
			background: #284ea6;
		}

		::-webkit-scrollbar-thumb:hover {
			background: #173a8c;
		}
	}
`;

export const leftNavigationCSS = css``;
