import { IFileWithFullContent } from "@app/api/files/helper-schemas";
import { FileItems } from "@app/components/users/contents/reading-files";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import CancelIcon from "@material-ui/icons/CancelRounded";
import * as React from "react";
import "react-quill/dist/quill.snow.css";
import Popup from "../../../widgets/Popup";
import { Cancel, PopupBody, PopupCSS, PopupHeader } from "../styles";

interface IProps {
	onClose: () => void;
	courseId: ObjectId;
	folderId: ObjectId;
	fileId: ObjectId;
}

interface IState {
	file?: IFileWithFullContent["file"];
	itemNames: IFileWithFullContent["itemNames"];
}

class FileContentPopup extends React.PureComponent<IProps> {
	state: IState = {
		itemNames: {},
	};

	_FilesController = inject("FilesController");

	componentDidMount() {
		this._FilesController
			.getWithFullContent({
				_id: this.props.fileId,
				courseId: this.props.courseId,
			})
			.then(data => {
				this.setState({ file: data.file, itemNames: data.itemNames });
			});
	}

	render() {
		if (!this.state.file || !this.state.itemNames) return null;
		return (
			<Popup onClose={this.props.onClose}>
				<div className={PopupCSS}>
					<div className={PopupHeader}>
						<CancelIcon
							className={Cancel}
							onClick={this.props.onClose}
						/>
					</div>
					<div className={PopupBody} style={{ textAlign: "left" }}>
						<FileItems
							items={this.state.file.items}
							title={this.state.file.name}
							courseId={this.props.courseId}
						/>
					</div>
				</div>
			</Popup>
		);
	}
}

export default FileContentPopup;
