import * as React from "react";
import styled from "@emotion/styled";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import { PhotoPreview } from ".";
import { FormattedMessage } from "react-intl";

const Container = styled.div`
	width: 100%;
	background-color: white;
	padding: 10px;
	margin: 10px 0;
	border: 1px solid grey;
	border-radius: 15px;
	max-height: 212px;
	overflow-x: auto;
`;

interface IProps {
	onAdd: () => void;
	onDelete: (photo: string) => void;
	selectedPhoto?: string;
}

const SelectedPhotos: React.SFC<IProps> = props => (
	<Container>
		{!props.selectedPhoto && (
			<PhotoPreview select={true} onClick={props.onAdd}>
				<Add style={{ fontSize: "85px" }} />
				<p>
					<FormattedMessage id="admin:general.addPhoto" />
				</p>
			</PhotoPreview>
		)}
		{props.selectedPhoto && (
			<PhotoPreview
				key={props.selectedPhoto}
				style={{ backgroundImage: props.selectedPhoto }}
			>
				<Delete
					onClick={() => props.onDelete(props.selectedPhoto!)}
					style={{
						color: "red",
						position: "absolute",
						top: "0",
						right: "0",
					}}
				/>
			</PhotoPreview>
		)}
	</Container>
);

export default SelectedPhotos;
