import FolderHierarchyService from "../hierarchy-info/folders";
import UserFolderProgressService from ".";
import { MError } from "../../utils/errors";
import { ObjectId } from "@app/utils/generics";
import { inject } from "@app/modules";
import { ItemType, IItemProgress } from "@app/api/folders/helper-schemas";
import { IFolderModel } from "@app/models/folder";
import { IUserFolderProgressInstance } from "@app/models/user-folder-progress";

interface IResetProgressArgs {
	courseId: ObjectId;
	parentFolderId: ObjectId;
	itemId: ObjectId;
	itemType: ItemType;
}

export default class UserFolderProgressResetService {
	private readonly _Folder: IFolderModel = inject("FolderModel");

	private readonly _FolderHierarchy: FolderHierarchyService = inject(
		"FolderHierarchyService"
	);

	private readonly _UserProgress: UserFolderProgressService = inject(
		"UserFolderProgressService"
	);

	resetProgressManySync = (
		courseId: ObjectId,
		folderIds: ObjectId[],
		userId: number
	) => {
		const docs = this._Folder.findManyByIdsSync(folderIds);
		const hierarchyDoc = this._FolderHierarchy.getHierarchyInfoObjectSync(
			courseId
		);
		if (!hierarchyDoc)
			throw new MError(400, "hierarchy doc for the course id not found");
		const parentInfo = hierarchyDoc.parentInfo;
		for (let i = 0; i < docs.length; ++i) {
			if (!docs[i]) continue;
			const parentId = parentInfo[docs[i]._id];
			if (!parentId)
				throw new MError(400, "cannot reset root folder progress");
			this.resetProgressOneSync(
				{
					courseId,
					parentFolderId: parentId,
					itemId: docs[i]._id,
					itemType: ItemType.folder,
				},
				userId
			);
		}
	};

	resetProgressOneSync = (args: IResetProgressArgs, userId: number) => {
		if (args.itemType === ItemType.folder) {
			const doc = this._UserProgress.getProgressDocSync({
				courseId: args.courseId,
				folderId: args.itemId,
				userId,
			});
			if (!doc)
				throw new Error(
					`Folder progress document not found for ${args.itemId}`
				);
			this.resetFolderItemsProgressRecursivelySync(
				doc,
				args.courseId,
				userId
			);
		} else {
			const doc = this._UserProgress.getProgressDocSync({
				courseId: args.courseId,
				folderId: args.parentFolderId,
				userId,
			});
			if (!doc)
				throw new Error(
					`Folder progress document not found for ${args.parentFolderId}`
				);
			if (doc.itemsProgress) {
				doc.itemsProgress = [...doc.itemsProgress];
				doc.totalProgressByItemTypes = {
					...doc.totalProgressByItemTypes,
				};
				const itemProgress = doc.itemsProgress.find(
					el => el.type === args.itemType && el.id === args.itemId
				);
				if (!itemProgress) {
					throw new Error("Item progress not found");
				}

				this.resetSingleItemProgressSync(itemProgress);

				if (doc.totalProgressByItemTypes[args.itemType]) {
					doc.totalProgressByItemTypes[args.itemType].totalDone -= 1;
				}

				doc.saveSync();
			}
		}
		this._UserProgress.polluteParentFoldersSync(
			args.courseId,
			args.parentFolderId,
			userId
		);
	};

	private resetFolderItemsProgressRecursivelySync = (
		folderProgressInfo: IUserFolderProgressInstance,
		courseId: ObjectId,
		userId: number
	) => {
		// TODO: remove recursion
		if (!folderProgressInfo) return;
		const { itemsProgress, totalProgressByItemTypes } = folderProgressInfo;

		folderProgressInfo.progress = 0;
		totalProgressByItemTypes[ItemType.folder] = {
			...totalProgressByItemTypes[ItemType.folder],
			totalDone: 0,
		};
		totalProgressByItemTypes[ItemType.file] = {
			...totalProgressByItemTypes[ItemType.file],
			totalDone: 0,
		};
		totalProgressByItemTypes[ItemType.test] = {
			...totalProgressByItemTypes[ItemType.test],
			totalDone: 0,
		};

		const subFoldersProgressIds: ObjectId[] = [];
		for (let i = 0; i < itemsProgress.length; ++i) {
			const itemProgress = itemsProgress[i].progress;
			if (itemProgress === 0) continue;
			itemsProgress[i] = { ...itemsProgress[i] };
			this.resetSingleItemProgressSync(itemsProgress[i]);
			if (itemsProgress[i].type === ItemType.folder) {
				subFoldersProgressIds.push(itemsProgress[i].id);
			}
		}

		folderProgressInfo.itemsProgress = [
			...folderProgressInfo.itemsProgress,
		];
		folderProgressInfo.totalProgressByItemTypes = {
			...folderProgressInfo.totalProgressByItemTypes,
		};
		folderProgressInfo.saveSync();
		const subFoldersProgresses = this._UserProgress.getManyProgressDocByFolderIdsSync(
			{
				folderIds: subFoldersProgressIds,
				courseId,
				userId,
			}
		);
		for (let i = 0; i < subFoldersProgresses.length; ++i) {
			this.resetFolderItemsProgressRecursivelySync(
				subFoldersProgresses[i],
				courseId,
				userId
			);
		}
	};

	private resetSingleItemProgressSync = (itemProgress: IItemProgress) => {
		const { type: itemType } = itemProgress;
		if (itemProgress.type === ItemType.test) {
			itemProgress.attempts = [
				...(itemProgress.attempts || []),
				{
					progress: itemProgress.progress,
					timeSpent: itemProgress.timeSpent || 0,
					score: itemProgress.score,
					resetAt: new Date(),
				},
			];
			itemProgress.progress = 0;
			itemProgress.score = 0;
			itemProgress.timeSpent = 0;
		} else if (itemProgress.type === ItemType.file) {
			itemProgress.attempts = [
				...(itemProgress.attempts || []),
				{
					progress: itemProgress.progress,
					firstTimeSpent: itemProgress.firstTimeSpent,
					totalTimeSpent: itemProgress.totalTimeSpent,
					resetAt: new Date(),
				},
			];
			itemProgress.progress = 0;
			itemProgress.firstTimeSpent = 0;
		} else if (itemProgress.type === ItemType.folder) {
			itemProgress.attempts = [
				...(itemProgress.attempts || []),
				{
					progress: itemProgress.progress,
					resetAt: new Date(),
				},
			];
			itemProgress.progress = 0;
		} else {
			throw new Error(
				`reseting item type ${itemType} is not supported yet`
			);
		}
	};
}
