import * as React from "react";
import Close from "@material-ui/icons/Close";
import Select from "react-select";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import styled from "react-emotion";
import TextField from "@material-ui/core/TextField";
import {
	ICardTemplateProperty,
	PropertyType,
} from "../../../schemas/cards/templates";

const RowStyle = styled("div")({
	border: "#ccc solid 1px",
	margin: "10px auto",
	padding: 10,
	borderRadius: 5,
});

interface ICardTemplateRowProps {
	property: ICardTemplateProperty;
	onChange: (newProperty: ICardTemplateProperty) => void;
	onDelete: (id: number) => void;
	onPrimaryChange: (propId: number, isAdding: boolean) => void;
}

class CardTemplateRow extends React.PureComponent<ICardTemplateRowProps> {
	options = [
		{ value: PropertyType.Number, label: "Number" },
		{ value: PropertyType.Text, label: "Text" },
		{ value: PropertyType.TextArray, label: "List of texts" },
		{ value: PropertyType.HistDate, label: "Date" },
	];

	onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onChange({
			...this.props.property,
			name: event.target.value,
		});
	};

	onPrimaryAdd = () => {
		this.props.onPrimaryChange(this.props.property.id, true);
	};
	onPrimaryRemove = () => {
		this.props.onPrimaryChange(this.props.property.id, false);
	};

	onSelectChange = selectedOption => {
		this.props.onChange({
			...this.props.property,
			type: selectedOption.value,
		});
	};

	onDelete = () => {
		this.props.onDelete(this.props.property.id);
	};

	render() {
		const selectedOption = this.options.find(
			e => e.value === this.props.property.type
		);
		return (
			<RowStyle style={{ position: "relative" }}>
				<div style={{ display: "flex", alignItems: "center" }}>
					<TextField
						label="Name"
						value={this.props.property.name}
						onChange={this.onNameChange}
						margin="dense"
						variant="outlined"
					/>
					<div
						style={{
							marginLeft: 5,
							display: "inline-block",
							width: 200,
						}}
					>
						<Select
							value={selectedOption}
							onChange={this.onSelectChange}
							options={this.options}
						/>
					</div>
					{this.props.property.isPrimary ? (
						<StarIcon onClick={this.onPrimaryRemove} />
					) : (
						<StarBorderIcon onClick={this.onPrimaryAdd} />
					)}
				</div>
				<Close
					onClick={this.onDelete}
					style={{
						cursor: "pointer",
						position: "absolute",
						right: 5,
						top: 5,
					}}
				/>
			</RowStyle>
		);
	}
}

export default CardTemplateRow;
