import React, { useCallback } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export const CheckboxWithLabel = React.memo(
	(props: {
		label: string;
		value: boolean;
		onChange: (value: boolean) => void;
	}) => {
		const { onChange } = props;
		const handleChange = useCallback(
			(e, checked: boolean) => {
				onChange(checked);
			},
			[onChange]
		);

		return (
			<FormControlLabel
				control={
					<Checkbox checked={props.value} onChange={handleChange} />
				}
				label={props.label}
			/>
		);
	}
);

export const SwitchWithLabel = React.memo(
	(props: {
		label: string;
		value: boolean;
		onChange: (value: boolean) => void;
	}) => {
		const { onChange } = props;
		const handleChange = useCallback(
			(e, checked: boolean) => {
				onChange(checked);
			},
			[onChange]
		);

		return (
			<FormControlLabel
				control={
					<Switch checked={props.value} onChange={handleChange} />
				}
				label={props.label}
			/>
		);
	}
);
