import {
	getRandomColor,
	monthNamesGeo,
	romanize,
	toDecadeNames,
	toGeorgianOrdinalNumber,
} from "@app/commonJavascript";
import LoadingSVG from "@app/components/styles/img/loading";
import { inject } from "@app/modules";
import { IRootState } from "@app/redux";
import { ObjectId } from "@app/utils/generics";
import Carousel from "@brainhubeu/react-carousel";
import LeftArrow from "@material-ui/icons/ArrowBackIos";
import RightArrow from "@material-ui/icons/ArrowForwardIos";
import {
	ICard,
	ICardProperty,
	IEntry,
	IValue,
} from "@tests-core/schemas/cards";
import {
	ICardTemplate,
	ICenturyDate,
	ILabel,
	IMilleniumDate,
	IMillionDate,
	IYearDate,
	PropertyType,
} from "@tests-core/schemas/cards/templates";
import {
	arrayTypeToSingle,
	isPropertyTypeArray,
} from "@tests-core/utils/template";
import memoizeOne from "memoize-one";
import * as React from "react";
import styled, { css } from "react-emotion";
import { connect } from "react-redux";

interface IFileCardsState {
	cards?: ICard[];
	displayIndex: number;
	carouselIndex: number;
	singleCard: boolean;
}

interface IOwnProps {
	ids: ObjectId[];
	courseId: ObjectId;
}
type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IOwnProps;

class FileCards extends React.Component<IProps, IFileCardsState> {
	state: IFileCardsState = {
		displayIndex: 0,
		carouselIndex: 0,
		singleCard: true,
	};

	_CardTemplatesController = inject("CardTemplatesController");
	_CardsController = inject("CardsController");

	componentDidMount() {
		this._CardTemplatesController.getAll().then();
		this._CardsController
			.getBulk({
				ids: this.props.ids,
				courseId: this.props.courseId,
			})
			.then(cards => {
				this.setState({
					cards,
				});
			});
	}

	letsShuffle = () => {
		this.setState({
			cards: this.shuffle(this.state.cards!),
		});
	};

	shuffle = (a: ICard[]) => {
		const arr = [...a];
		for (let i = 0; i < arr.length; i++) {
			const j = Math.floor(Math.random() * arr.length);
			const temp = arr[i];
			arr[i] = arr[j];
			arr[j] = temp;
		}
		return arr;
	};

	seeArrayVersion = () => {
		this.setState({
			singleCard: false,
		});
	};

	onDisplayIndexChange = index => {
		this.setState({
			displayIndex:
				((index % this.state.cards!.length) +
					this.state.cards!.length) %
				this.state.cards!.length,
			carouselIndex: index,
		});
	};

	onClick = index => {
		this.setState({
			displayIndex: index,
			carouselIndex: index,
			singleCard: true,
		});
	};
	render() {
		if (!this.state.cards) {
			return (
				<div style={{ textAlign: "center" }}>
					<LoadingSVG />
				</div>
			);
		}
		if (!this.state.singleCard) {
			return (
				<div>
					<button className={cardButton} onClick={this.letsShuffle}>
						ბარათების არევა
					</button>
					<div>
						{!this.state.singleCard &&
							this.state.cards.map(
								(eachCard, i) =>
									this.props.templates[
										eachCard.templateId
									] && (
										<CardsArrayVersion
											key={eachCard._id}
											item={eachCard}
											template={
												this.props.templates[
													eachCard.templateId
												]!.info
											}
											index={i}
											onClick={this.onClick}
										/>
									)
							)}
					</div>
				</div>
			);
		}
		return (
			<div>
				<button className={cardButton} onClick={this.seeArrayVersion}>
					ყველას გამოჩენა
				</button>
				<button className={cardButton} onClick={this.letsShuffle}>
					ბარათების არევა
				</button>
				<Carousel
					arrows={true}
					arrowLeft={<LeftArrow className={iconExample} />}
					arrowRight={<RightArrow className={iconExample} />}
					addArrowClickHandler={true}
					infinite={true}
					onChange={this.onDisplayIndexChange}
					value={this.state.carouselIndex}
				>
					{this.state.cards!.map(
						(card, index) =>
							this.props.templates[card.templateId] && (
								<div key={card._id}>
									<Card
										key={card._id}
										item={card}
										template={
											this.props.templates[
												card.templateId
											]!.info
										}
										isSelected={
											index === this.state.displayIndex
										}
									/>
								</div>
							)
					)}
				</Carousel>
			</div>
		);
	}
}

interface ICardProps {
	item: ICard;
	template: ICardTemplate;
	isSelected: boolean;
}

interface ICardState {
	isRotated: boolean;
	shorterVersion: boolean;
}
class Card extends React.Component<ICardProps, ICardState> {
	state: ICardState = {
		isRotated: false,
		shorterVersion: false,
	};

	reactOnSelectionChange = memoizeOne((isSelected: boolean) => {
		if (!isSelected && this.state.isRotated) {
			setTimeout(() => {
				this.setState({
					isRotated: false,
				});
			}, 0);
		}
	});

	onSideChange = () => {
		this.setState({
			isRotated: !this.state.isRotated,
		});
	};

	seeShorterVersion = () => {
		this.setState({
			shorterVersion: !this.state.shorterVersion,
		});
	};

	render() {
		this.reactOnSelectionChange(this.props.isSelected);
		const isRotated = this.props.isSelected ? this.state.isRotated : false;
		return (
			<>
				<FlipCard side={isRotated} id={this.props.item._id}>
					<div className={flipCardInner} onClick={this.onSideChange}>
						<div className={flipCardFront}>
							<div className="VM">{this.props.item.name}</div>
						</div>
						<div className={flipCardBack}>
							{this.props.item.properties.map(
								(prop: ICardProperty) => {
									const templateProp = this.props.template.properties.find(
										e => e.id === prop.id
									);
									if (!templateProp) return null;
									return (
										<div
											key={prop.id}
											className={onePropStyles}
										>
											<div
												style={{
													backgroundColor: getRandomColor(
														prop.id
													),
													width: 2,
													height: 30,
													display: "inline-block",
													verticalAlign: "middle",
													margin: 5,
												}}
											/>
											{!this.state.shorterVersion && (
												<div
													style={{
														display: "inline-block",
														padding: "0px 5px",
														fontWeight: "bold",
													}}
												>
													{templateProp.name}:
												</div>
											)}
											<CardPropInfo
												cardEntry={prop.entries}
												type={templateProp.type}
												labels={
													this.props.template.labels
												}
											/>
										</div>
									);
								}
							)}
						</div>
					</div>
				</FlipCard>
			</>
		);
	}
}

interface ICardsArrayVersionProps {
	item: ICard;
	template: ICardTemplate;
	index: number;
	onClick: (number) => void;
}

class CardsArrayVersion extends React.Component<ICardsArrayVersionProps> {
	onClick = () => {
		this.props.onClick(this.props.index);
	};
	render() {
		return (
			<div className={flipArrayVersion} onClick={this.onClick}>
				<div className={flipCardInner}>
					<div className={flipCardArrayVersion}>
						<div className="VM">{this.props.item.name}</div>
					</div>
				</div>
			</div>
		);
	}
}

interface ICardPropInfoProps {
	cardEntry: ICardProperty["entries"];
	type: PropertyType;
	labels: ILabel[];
}

class CardPropInfo extends React.PureComponent<ICardPropInfoProps> {
	valToString = (val: IValue): string => {
		if (typeof val === "number") return val.toString();
		if (typeof val === "string") return val;
		// IDateProperty

		if ((val as IMillionDate).million !== undefined) {
			return Math.abs((val as IMillionDate).million) + " მლნ. წლის წინ";
		}

		if ((val as IMilleniumDate).millenium !== undefined) {
			let myMilleniumDate = "";
			if ((val as IMilleniumDate).millenium < 0) {
				myMilleniumDate = "ძვ.წ. ";
			}
			if ((val as IMilleniumDate).half === undefined) {
				myMilleniumDate =
					myMilleniumDate +
					toGeorgianOrdinalNumber(
						Math.abs((val as IMilleniumDate).millenium),
						0
					) +
					" ათასწლეული";
			}
			if ((val as IMilleniumDate).half === 1) {
				myMilleniumDate =
					myMilleniumDate +
					toGeorgianOrdinalNumber(
						Math.abs((val as IMilleniumDate).millenium),
						0
					) +
					" ათასწლეულის პირველი ნახევარი";
			}
			if ((val as IMilleniumDate).half === 2) {
				myMilleniumDate =
					myMilleniumDate +
					toGeorgianOrdinalNumber(
						Math.abs((val as IMilleniumDate).millenium),
						0
					) +
					" ათასწლეულის მეორე ნახევარი";
			}
			return myMilleniumDate;
		}

		if ((val as ICenturyDate).century !== undefined) {
			let myCenturyDate = "";
			if ((val as ICenturyDate).century < 0) {
				myCenturyDate = "ძვ.წ. ";
			}
			if (
				(val as ICenturyDate).half === undefined &&
				(val as ICenturyDate).decade === undefined
			) {
				myCenturyDate =
					myCenturyDate +
					romanize(Math.abs((val as ICenturyDate).century)) +
					" საუკუნე";
			}
			if ((val as ICenturyDate).half === 1) {
				myCenturyDate =
					myCenturyDate +
					romanize(Math.abs((val as ICenturyDate).century)) +
					" საუკუნის პირველი ნახევარი";
			}
			if ((val as ICenturyDate).half === 2) {
				myCenturyDate =
					myCenturyDate +
					romanize(Math.abs((val as ICenturyDate).century)) +
					" საუკუნის მეორე ნახევარი";
			}
			if ((val as ICenturyDate).decade !== undefined) {
				myCenturyDate =
					myCenturyDate +
					romanize(Math.abs((val as ICenturyDate).century)) +
					" საუკუნის " +
					toDecadeNames(
						(val as ICenturyDate).decade!,
						(val as ICenturyDate).century
					) +
					"წლები";
			}
			return myCenturyDate;
		}
		let minus = "";
		if ((val as IYearDate).year < 0) minus = "ძვ.წ ";
		if (
			(val as IYearDate).year !== undefined &&
			(val as IYearDate).month !== undefined &&
			(val as IYearDate).day !== undefined
		) {
			return (
				minus +
				Math.abs((val as IYearDate).year) +
				" წლის" +
				(val as IYearDate).day +
				monthNamesGeo[(val as IYearDate).month! - 1]
			);
		}
		if (
			(val as IYearDate).year !== undefined &&
			(val as IYearDate).month !== undefined
		) {
			return (
				minus +
				Math.abs((val as IYearDate).year) +
				" წლის" +
				monthNamesGeo[(val as IYearDate).month! - 1]
			);
		}
		if ((val as IYearDate).year !== undefined) {
			return minus + Math.abs((val as IYearDate).year) + " წელი";
		}
		return "";
	};

	entryToString = (entry: IEntry): string => {
		if (!entry.isNotPrecise) {
			return this.valToString(entry.val);
		}
		if (entry.isInterval) {
			return (
				this.valToString(entry.val[0]) +
				" - " +
				this.valToString(entry.val[1])
			);
		}
		return entry.val.map(this.valToString).join(", ");
	};

	render() {
		if (isPropertyTypeArray(this.props.type)) {
			return (
				<div style={{ display: "inline" }}>
					{(this.props.cardEntry as IEntry[]).map(cardEntry => (
						<CardPropInfo
							key={cardEntry.id}
							type={arrayTypeToSingle(this.props.type)}
							cardEntry={cardEntry}
							labels={this.props.labels}
						/>
					))}
				</div>
			);
		}
		const entry = this.props.cardEntry as IEntry;
		return (
			<div style={{ display: "inline-block", padding: "0px 5px" }}>
				<SingleEntry labelsId={entry.labels} labels={this.props.labels}>
					{this.entryToString(entry)}
				</SingleEntry>
			</div>
		);
	}
}

interface ISingleEntryProps {
	labels: ILabel[];
	labelsId?: number[];
}
const SingleEntry: React.FC<ISingleEntryProps> = props => (
	<div>
		{props.children}
		{props.labelsId &&
			props.labelsId.map(labelId => {
				const label = props.labels.find(lbl => lbl.id === labelId);
				if (!label) return null;
				const myColor = getRandomColor(label.id);
				return (
					<div
						key={label.id}
						style={{
							display: "inline-block",
							fontSize: 12,
							verticalAlign: "super",
							border: "1px solid " + myColor,
							padding: 3,
							color: myColor,
						}}
					>
						{label.name}
					</div>
				);
			})}
	</div>
);

const flipCardInner = css`
	position: relative;
	width: 100%;
	height: 100%;
	min-width: 300px;
	min-height: 300px;
	transition: transform 0.6s;
	transform-style: preserve-3d;
	border: 1px solid #3054a9;
`;

const FlipCard = styled("div")`
	background: transparent;
	font-family: FiraGO;
	perspective: 1000px;
	margin: 0 auto;
	padding: 50px;
	.${flipCardInner} {
		transform: ${(props: { side: boolean }) =>
			props.side ? "rotateY(180deg)" : ""};
	}
`;

const flipArrayVersion = css`
	background: transparent;
	width: 250px;
	height: 250px;
	font-family: FiraGO;
	margin: 0 auto;
	display: inline-block;
	margin: 10px;
`;

const cardButton = css`
	background: #284ea6;
	width: 200px;
	height: 40px;
	color: white;
	box-shadow: 0 2px 0 #0d2d79;
	border: none;
	border-radius: 5px;
	margin: 10px;
	font-size: 18px;
	font-family: FiraGO;
	cursor: pointer;
`;

const iconExample = css`
	position: relative;
	border: none;
	overflow: hidden;
	outline: 0;
	font-size: 0;
	line-height: 0;
`;

const flipCardFront = css`
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	color: black;
	z-index: 2;
	font-size: 25px;
	text-align: center;
	font-weight: bold;
`;

const flipCardArrayVersion = css`
	position: relative;
	width: 100%;
	height: 100%;
	font-size: 18px;
	text-align: center;
	font-weight: bold;
	padding: 10px;
`;

const flipCardBack = css`
	position: relative;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	transform: rotateY(180deg);
	z-index: 1;
`;

const onePropStyles = css`
	padding: 20px 0px;
	border-bottom: 1px solid #ccd5ea;
	&:last-child {
		border-bottom-color: transparent;
	}
`;

const mapStateToProps = (state: IRootState) => ({
	templates: state.cardTemplates,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	FileCards
);
