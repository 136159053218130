import * as React from "react";
import GalleryPopup from "../gallery";
import TextWithQuestionsEditWrapper, {
	TextContentError,
} from "@tests-core/components/questions/contents/edit-text-with-questions-wrapper";
import { IFullTextWithQuestions } from "@tests-core/schemas/texts/helper-schemas";
import { IQContentEditPassableProps } from "@tests-core/components/questions/contents/edit";
import { ObjectId } from "@app/utils/generics";
import { getQuestionsEditCustomizedProps } from "./custom-props";
import { removeKeys } from "@tests-core/utils/common";
import "react-quill/dist/quill.snow.css";
import { mergeRecursive } from "@app/utils/common";
import { inject } from "@app/modules";
import FancyLoading from "@app/components/widgets/fancy-loading";
import { ItemType } from "@app/api/folders/helper-schemas";
import {
	HierarchyMultipleSelect,
	IHierarchyMultipleSelectDefaultSelections,
} from "../hierarchy-multiple-select";
import { HierarchyItemType } from "@app/services/hierarchy-info/interfaces";
import memoizeOne from "memoize-one";
import { IAPOSTCreateTextWithQuestions } from "@app/api/questions/texts/validators";
import { getLocale } from "@app/hooks/intl";
import { getFormattedMessage } from "@app/utils/locale";
import { MainButton } from "@app/components/users/styles/styledComponents";
import { FormattedMessage } from "react-intl";
import { successfulSaveArg } from "../types";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";

type IProps = {
	onSuccessSave: (args: successfulSaveArg) => void;
	courseId: ObjectId;
} & (
	| { textId: ObjectId; folderId?: ObjectId }
	| { textId?: ObjectId; folderId: ObjectId }
);

interface IState {
	defaultSelectedTestIds?: ObjectId[];
	defaultSelectedTaskTypeIds?: ObjectId[];
	defaultSelectedTopicIds?: ObjectId[];
	defaultSelectedFolderIds?: ObjectId[];
}

class TextWithQuestions extends React.PureComponent<IProps, IState> {
	private textWithQuestions?: IFullTextWithQuestions;
	private textId = this.props.textId;
	private _isMounted = false;

	private hierarchyMultipleSelectRef = React.createRef<
		HierarchyMultipleSelect
	>();
	private foldersMultipleSelectRef = React.createRef<
		HierarchyMultipleSelect
	>();

	private _QuestionsController = inject("QuestionsController");
	private _TestsController = inject("TestsController");
	private _TopicsController = inject("TopicsController");
	private _TaskTypesController = inject("TaskTypesController");
	private _FoldersController = inject("FoldersController");

	state: IState = {};

	componentDidMount() {
		this._isMounted = true;
		if (this.textId) {
			this._QuestionsController
				.getTextQuestions({
					textId: this.textId,
					getAnswers: true,
					courseId: this.props.courseId,
				})
				.then(textWithQuestions => {
					if (!this._isMounted) return;
					this.textWithQuestions = textWithQuestions as IFullTextWithQuestions;
					this.forceUpdate();
				});

			this._TestsController
				.getTestsByItem({
					courseId: this.props.courseId,
					itemId: this.textId,
					itemType: ItemType.text,
				})
				.then(data => {
					this.setState({ defaultSelectedTestIds: data });
				});
			this._TaskTypesController
				.getByItem({
					courseId: this.props.courseId,
					itemId: this.textId,
					itemType: ItemType.text,
				})
				.then(data => {
					this.setState({
						defaultSelectedTaskTypeIds: data,
					});
				});
			this._TopicsController
				.getByItem({
					courseId: this.props.courseId,
					itemId: this.textId,
					itemType: ItemType.text,
				})
				.then(data => {
					this.setState({
						defaultSelectedTopicIds: data,
					});
				});
			this._FoldersController
				.getByItem({
					courseId: this.props.courseId,
					itemId: this.textId,
					itemType: ItemType.text,
				})
				.then(data => {
					this.setState({
						defaultSelectedFolderIds: data,
					});
				});
		} else {
			this.setState({
				defaultSelectedTaskTypeIds: [],
				defaultSelectedTestIds: [],
				defaultSelectedTopicIds: [],
				defaultSelectedFolderIds: this.props.folderId
					? [this.props.folderId]
					: [],
			});
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	private getDefaultSelections = memoizeOne(
		(): IHierarchyMultipleSelectDefaultSelections => {
			return {
				[HierarchyItemType.folder]: (
					this.state.defaultSelectedTestIds || []
				).map(e => ({ type: ItemType.test, id: e })),
				[HierarchyItemType.taskType]: (
					this.state.defaultSelectedTaskTypeIds || []
				).map(e => ({ type: -1, id: e })),
				[HierarchyItemType.topic]: (
					this.state.defaultSelectedTopicIds || []
				).map(e => ({ type: -1, id: e })),
			};
		}
	);

	private getDefaultFolders = memoizeOne(
		(): IHierarchyMultipleSelectDefaultSelections => {
			return {
				[HierarchyItemType.folder]: (
					this.state.defaultSelectedFolderIds || []
				).map(e => ({ type: ItemType.folder, id: e })),
			};
		}
	);

	private selectHierarchyItems = () => {
		return (
			<HierarchyMultipleSelect
				ref={this.hierarchyMultipleSelectRef}
				courseId={this.props.courseId}
				hierarchiesToShow={[
					HierarchyItemType.folder,
					HierarchyItemType.taskType,
					HierarchyItemType.topic,
				]}
				itemTypesToBeStored={{
					[HierarchyItemType.folder]: {
						[ItemType.test]: true,
					},
				}}
				placeholders={{
					[HierarchyItemType.folder]: getFormattedMessage("tests"),
				}}
				defaultSelections={this.getDefaultSelections()}
				loadLastOptionsAutomatically={!this.props.textId}
				alwaysHideLoadLastOptionsButton={true}
			/>
		);
	};

	private selectFolders = React.memo(() => {
		return (
			<HierarchyMultipleSelect
				ref={this.foldersMultipleSelectRef}
				courseId={this.props.courseId}
				hierarchiesToShow={[HierarchyItemType.folder]}
				itemTypesToBeStored={{
					[HierarchyItemType.folder]: {
						[ItemType.folder]: true,
					},
				}}
				multipleSelectProps={{
					[HierarchyItemType.folder]: {
						disjoinParentFromChildren: true,
					},
				}}
				placeholders={{
					[HierarchyItemType.folder]: getFormattedMessage("folders"),
				}}
				defaultSelections={this.getDefaultFolders()}
				loadLastOptionsAutomatically={
					!this.props.textId && !this.props.folderId
				}
				alwaysHideLoadLastOptionsButton={true}
			/>
		);
	});

	loadPastOptions = () => {
		const ref = this.hierarchyMultipleSelectRef.current!;
		const foldersRef = this.foldersMultipleSelectRef.current!;
		ref.loadPastOptions();
		foldersRef.loadPastOptions();
	};

	beforeSaveButton = () => {
		const [areLastOptionsLoaded, setAreLastOptionsLoaded] = React.useState(
			!this.props.textId && !this.props.folderId
		);
		const onLoad = React.useCallback(() => {
			this.loadPastOptions();
			setAreLastOptionsLoaded(true);
		}, []);

		const SelectHierarchyItems = this.selectHierarchyItems;
		const SelectFolders = this.selectFolders;

		return (
			<div>
				<SelectHierarchyItems />
				<SelectFolders />
				{!areLastOptionsLoaded && (
					<MainButton
						onClick={onLoad}
						style={{ margin: "0 0 15px 0" }}
					>
						<FormattedMessage id="admin:general.loadLastOptions" />
					</MainButton>
				)}
			</div>
		);
	};

	private getCustomizedProps = memoizeOne(
		(locale: string): IQContentEditPassableProps =>
			mergeRecursive(
				{
					commonProps: {
						galleryComponent: props => (
							<GalleryPopup
								courseId={this.props.courseId}
								{...props}
							/>
						),
						commonComponents: {
							beforeSaveButtonOfText: this.beforeSaveButton,
						},
					},
				} as IQContentEditPassableProps,
				getQuestionsEditCustomizedProps(locale)
			)
	);

	getSelections = (): Pick<
		IAPOSTCreateTextWithQuestions,
		"taskTypeIds" | "testIds" | "topicIds" | "folderIds"
	> => {
		const ref = this.hierarchyMultipleSelectRef.current!;
		const foldersRef = this.foldersMultipleSelectRef.current!;
		return {
			testIds: ref
				.getSelectedItemsByHierarchyType(HierarchyItemType.folder)
				.map(e => e.id),
			taskTypeIds: ref.getSelectedItemIdsByHierarchyType(
				HierarchyItemType.taskType
			),
			topicIds: ref.getSelectedItemIdsByHierarchyType(
				HierarchyItemType.topic
			),
			folderIds: foldersRef
				.getSelectedItemsByHierarchyType(HierarchyItemType.folder)
				.map(e => e.id),
		};
	};

	private handleContentErrors = (errors: TextContentError[]) => {
		console.log(errors);
		const mainError = getFormattedMessage("errorAlert");
		openConfirmationPopup({
			text: (
				<div>
					{mainError}
					<br />
					<br />
					{errors.map((tError, i) => {
						if (tError.type === "text") {
							return (
								<>
									{tError.errors.map(error => {
										const text = getFormattedMessage(
											`contentErrors.${error.errorToken}`
										);
										return (
											<div key={i}>
												{getFormattedMessage("text")}:{" "}
												{text}
											</div>
										);
									})}
									<br />
								</>
							);
						}
						return (
							<>
								{tError.errors.map(error => {
									const text = getFormattedMessage(
										`contentErrors.${error.errorToken}`
									);
									return (
										<div key={i}>
											{getFormattedMessage("question")} #
											{tError.index + 1}: {text}
										</div>
									);
								})}
							</>
						);
					})}
				</div>
			),
		});
	};

	private onSave = (
		errors: TextContentError[] | null,
		newTextWithQuestions: IFullTextWithQuestions | undefined
	) => {
		if (errors) {
			this.handleContentErrors(errors);
			return;
		}
		if (!newTextWithQuestions) return;
		if (!newTextWithQuestions || !this.hierarchyMultipleSelectRef.current) {
			return;
		}
		const selectedItems = this.getSelections();
		this.hierarchyMultipleSelectRef.current.saveOptions();
		const textWithQuestions: IFullTextWithQuestions = {
			...newTextWithQuestions,
			text: {
				_id: this.textId as any,
				text: newTextWithQuestions.text.text,
				numberOfQuestions: newTextWithQuestions.questions.length,
			} as IFullTextWithQuestions["text"],
		};
		if (!this.textId) {
			if (!this.props.folderId) {
				throw new Error("folderId is required when adding new text");
			}
			this._QuestionsController
				.addTextQuestions({
					text: textWithQuestions.text,
					questions: textWithQuestions.questions,
					courseId: this.props.courseId,
					...selectedItems,
				})
				.then(data => {
					this.props.onSuccessSave({
						folders: selectedItems.folderIds,
						topics: selectedItems.topicIds,
						taskTypes: selectedItems.taskTypeIds,
						item: {
							type: ItemType.text,
							id: data.text._id,
							name: data.text.text,
						},
					});
				});
			return;
		}
		this._QuestionsController
			.updateTextQuestions({
				text: textWithQuestions.text,
				questions: textWithQuestions.questions.map(e =>
					removeKeys(
						e,
						"authorId",
						"createdAt",
						"updatedAt",
						"domain"
					)
				),
				textId: this.textId,
				courseId: this.props.courseId,
				...selectedItems,
			})
			.then(data => {
				this.props.onSuccessSave({
					folders: selectedItems.folderIds,
					topics: selectedItems.topicIds,
					taskTypes: selectedItems.taskTypeIds,
					item: {
						type: ItemType.text,
						id: this.textId!,
						name: textWithQuestions.text.text,
					},
				});
			});
		return;
	};

	render() {
		if (
			(this.props.textId && !this.textWithQuestions) ||
			!this.state.defaultSelectedTaskTypeIds ||
			!this.state.defaultSelectedTestIds ||
			!this.state.defaultSelectedTopicIds ||
			!this.state.defaultSelectedFolderIds
		) {
			return <FancyLoading />;
		}
		return (
			<TextWithQuestionsEditWrapper
				onSave={this.onSave}
				textWithQuestions={this.textWithQuestions}
				customized={this.getCustomizedProps(getLocale())}
			/>
		);
	}
}

export default TextWithQuestions;
