import { useContext, useState, useRef, useEffect } from "react";
import { ReduxStoreContext } from "../../";
import { IRootState } from "@app/redux";

export const useStore = () => {
	const store = useContext(ReduxStoreContext);
	return store;
};

export const useStoreProperty = <K extends keyof IRootState>(
	propName: K
): IRootState[K] | undefined => {
	const store = useStore();
	const [prop, setProp] = useState(store.getState()[propName]);
	const propRef = useRef(prop);
	propRef.current = prop;

	useEffect(() => {
		return store.subscribe(() => {
			const newProp = store.getState()[propName];
			if (newProp !== propRef.current) {
				propRef.current = newProp;
				setProp(newProp);
			}
		});
	}, [propName, store]);
	return prop;
};
