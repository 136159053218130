import { ITaskType } from "@app/api/task-types/helper-schemas";
import {
	AdminTableContainer,
	AdminTableRow,
} from "@app/components/widgets/admin-table";
import { ITaskTypeHierarchyInstance } from "@app/models/task-type-hierarchy";
import { inject } from "@app/modules";
import { IRootState } from "@app/redux";
import {
	IChildrenInfo,
	IHierarchyInfo,
} from "@app/services/hierarchy-info/interfaces";
import { ObjectId } from "@app/utils/generics";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/AddRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import memoizeOne from "memoize-one";
import * as React from "react";
import { connect } from "react-redux";
import { TaskTypeEditPopup } from "./edit";
import {
	MiddleAligned,
	SubTaskTypeContainer,
	TaskTypeAddButton,
	TaskTypeButtonLabel,
	TaskTypeButtonsContainer,
	TaskTypeDeleteButton,
	TaskTypeEditButton,
	TaskTypeHeader,
} from "./styles/components";
import { FormattedMessage } from "react-intl";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";

interface IOwnProps {
	courseId: ObjectId;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IStateProps & IOwnProps;

interface IState {
	loading: boolean;
	taskTypePopup?:
		| { type: "add"; parentId: ObjectId }
		| { type: "edit"; id: ObjectId };
}

class AllTaskTypes extends React.Component<IProps, IState> {
	state: IState = {
		loading: true,
	};

	courseId = this.props.courseId;

	private _TaskTypeHierarchyModel = inject("TaskTypeHierarchyModel");
	private _TaskTypeHierarchyService = inject("TaskTypeHierarchyService");
	private _TaskTypesController = inject("TaskTypesController");

	getHierarchyInfo = memoizeOne(
		(
			taskTypeHierarchy: ITaskTypeHierarchyInstance | null
		): IHierarchyInfo | undefined => {
			if (!taskTypeHierarchy) return undefined;
			const hierarchyInfo = this._TaskTypeHierarchyService.getHierarchyInfoObjectSync(
				this.props.courseId
			);
			return hierarchyInfo;
		}
	);

	componentDidMount() {
		this._TaskTypesController
			.getHierarchy({ courseId: this.props.courseId }, true)
			.then();
		this._TaskTypesController
			.getAllByCourseId({ courseId: this.props.courseId }, true)
			.then(data => {
				this.setState({
					loading: false,
				});
			});
	}

	onClose = () => {
		this.setState({
			taskTypePopup: undefined,
		});
	};

	onDelete = (_id: ObjectId, originalCourseId: ObjectId) => {
		openConfirmationPopup({
			approveTitle: getFormattedMessage("yes"),
			rejectTitle: getFormattedMessage("no"),
			onApprove: () => {
				return this._TaskTypesController
					.deleteById({ _id, originalCourseId })
					.then(data => this.onClose());
			},
			text: getFormattedMessage("deleteConfirmation"),
			defaultErrorText: getFormattedMessage("errorAlert"),
			displayRejectButtonAsPrimary: true,
		});
	};

	onAddTaskType = (parentId: ObjectId) => {
		this.setState({
			taskTypePopup: {
				type: "add",
				parentId,
			},
		});
	};

	onUpdateTaskType = (id: ObjectId) => {
		this.setState({
			taskTypePopup: {
				type: "edit",
				id,
			},
		});
	};

	render() {
		const hierarchyInfo: IHierarchyInfo =
			this.getHierarchyInfo(
				this._TaskTypeHierarchyModel.findOneByCourseSync(
					this.props.courseId
				)
			) || ({} as IHierarchyInfo);

		return (
			<div className="main">
				<h2 style={{ textAlign: "center" }}>
					<FormattedMessage id="taskTypes" />
				</h2>
				{this.state.taskTypePopup && (
					<TaskTypeEditPopup
						onClose={this.onClose}
						courseId={this.props.courseId}
						id={(this.state.taskTypePopup as { id: ObjectId }).id}
						parentId={
							(this.state.taskTypePopup as { parentId: ObjectId })
								.parentId
						}
					/>
				)}
				<AllTaskTypesTable
					rootTaskTypeId={hierarchyInfo.rootId}
					taskTypeObj={this.props.taskTypes}
					childrenHierarchyInfo={hierarchyInfo.childrenInfo}
					onAddTaskType={this.onAddTaskType}
					onUpdateTaskType={this.onUpdateTaskType}
					onDelete={this.onDelete}
				/>
			</div>
		);
	}
}

interface ITableProps {
	rootTaskTypeId: ObjectId;
	taskTypeObj: IRootState["taskTypes"];
	childrenHierarchyInfo: IChildrenInfo;
	onAddTaskType: (parentId: ObjectId) => void;
	onUpdateTaskType: (_id: ObjectId) => void;
	onDelete: (id: ObjectId, originalCourseId: ObjectId) => void;
}

const AllTaskTypesTable: React.FC<ITableProps> = (props: ITableProps) => (
	<div className={AdminTableContainer}>
		<Table>
			<TableHead>
				<TableRow className={AdminTableRow}>
					<TableCell>
						<RootTaskType
							id={props.rootTaskTypeId}
							name={
								props.taskTypeObj &&
								props.taskTypeObj![props.rootTaskTypeId]
									? props.taskTypeObj[props.rootTaskTypeId]!
											.info.name
									: ""
							}
							onAddTaskType={props.onAddTaskType}
						/>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{!!props.childrenHierarchyInfo &&
					(
						props.childrenHierarchyInfo[props.rootTaskTypeId] || []
					).map(
						taskTypeId =>
							props.taskTypeObj &&
							props.taskTypeObj[taskTypeId] && (
								<TableRow
									key={taskTypeId}
									className={AdminTableRow}
								>
									<TableCell>
										<RecursiveTaskType
											currentTaskType={
												props.taskTypeObj[taskTypeId]!
													.info
											}
											onAddTaskType={props.onAddTaskType}
											onUpdateTaskType={
												props.onUpdateTaskType
											}
											onDelete={props.onDelete}
											depth={0}
											taskTypes={props.taskTypeObj}
											childrenHierarchyInfo={
												props.childrenHierarchyInfo
											}
										/>
									</TableCell>
								</TableRow>
							)
					)}
			</TableBody>
		</Table>
	</div>
);

interface ITaskTypeRecursiveProps {
	currentTaskType: ITaskType;
	onAddTaskType: (id: ObjectId) => void;
	onUpdateTaskType: (_id: ObjectId) => void;
	onDelete: (_id: ObjectId, originalCourseId: ObjectId) => void;
	depth: number;
	taskTypes: IRootState["taskTypes"];
	childrenHierarchyInfo: { [id: string]: string[] };
}

class RecursiveTaskType extends React.PureComponent<ITaskTypeRecursiveProps> {
	render() {
		if (!this.props.currentTaskType) return null;
		return (
			<React.Fragment>
				<TaskType
					id={this.props.currentTaskType._id}
					name={this.props.currentTaskType.name}
					onAddTaskType={this.props.onAddTaskType}
					onUpdateTaskType={this.props.onUpdateTaskType}
					onDelete={this.props.onDelete}
					depth={this.props.depth}
					originalCourseId={
						this.props.currentTaskType.originalCourseId
					}
				/>
				{(
					this.props.childrenHierarchyInfo[
						this.props.currentTaskType._id
					] || []
				).map(
					id =>
						this.props.taskTypes[id] && (
							<RecursiveTaskType
								key={id}
								currentTaskType={this.props.taskTypes[id]!.info}
								onAddTaskType={this.props.onAddTaskType}
								onUpdateTaskType={this.props.onUpdateTaskType}
								onDelete={this.props.onDelete}
								depth={this.props.depth + 20}
								taskTypes={this.props.taskTypes}
								childrenHierarchyInfo={
									this.props.childrenHierarchyInfo
								}
							/>
						)
				)}
			</React.Fragment>
		);
	}
}

interface ITaskTypeProps {
	id: ObjectId;
	name: string;
	originalCourseId: ObjectId;
	onAddTaskType: (id: ObjectId) => void;
	onUpdateTaskType: (id: ObjectId) => void;
	onDelete: (_id: ObjectId, originalCourseId: ObjectId) => void;
	depth: number;
}

const TaskType: React.FC<ITaskTypeProps> = (props: ITaskTypeProps) => (
	<SubTaskTypeContainer marginLeft={props.depth}>
		<TaskTypeHeader>{props.name}</TaskTypeHeader>
		<TaskTypeButtonsContainer>
			<TaskTypeAddButton onClick={() => props.onAddTaskType(props.id)}>
				<TaskTypeButtonLabel>
					<FormattedMessage id="admin:general.addSubTaskType" />
				</TaskTypeButtonLabel>
				<AddIcon className={MiddleAligned} />
			</TaskTypeAddButton>
			<TaskTypeEditButton
				onClick={() => props.onUpdateTaskType(props.id)}
			>
				<TaskTypeButtonLabel>
					<FormattedMessage id="edit" />
				</TaskTypeButtonLabel>
				<EditIcon className={MiddleAligned} />
			</TaskTypeEditButton>
			<TaskTypeDeleteButton
				onClick={() => props.onDelete(props.id, props.originalCourseId)}
			>
				<TaskTypeButtonLabel>
					<FormattedMessage id="delete" />
				</TaskTypeButtonLabel>
				<DeleteIcon className={MiddleAligned} />
			</TaskTypeDeleteButton>
		</TaskTypeButtonsContainer>
		<div style={{ clear: "both" }} />
	</SubTaskTypeContainer>
);

interface IRootTaskTypeProps {
	id: ObjectId;
	name: string;
	onAddTaskType: (id: ObjectId) => void;
}

const RootTaskType: React.FC<IRootTaskTypeProps> = (
	props: IRootTaskTypeProps
) => (
	<SubTaskTypeContainer marginLeft={0} style={{ textAlign: "center" }}>
		<TaskTypeHeader style={{ fontWeight: "bold" }}>
			{props.name}
		</TaskTypeHeader>
		<TaskTypeButtonsContainer onClick={() => props.onAddTaskType(props.id)}>
			<TaskTypeAddButton>
				<TaskTypeButtonLabel>
					<FormattedMessage id="admin:general.addSubTaskType" />
				</TaskTypeButtonLabel>
				<AddIcon className={MiddleAligned} />
			</TaskTypeAddButton>
		</TaskTypeButtonsContainer>
		<div style={{ clear: "both" }} />
	</SubTaskTypeContainer>
);

const mapStateToProps = (state: IRootState) => ({
	taskTypes: state.taskTypes,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	AllTaskTypes
);
