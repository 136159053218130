import { ObjectId } from "@app/utils/generics";
import { inject } from "@app/modules";
import { useFetch } from "./fetch3";

export const useQuestionDifficulty = ({
	questionId,
	courseId,
}: {
	questionId: ObjectId | null;
	courseId: ObjectId | null;
}) => {
	return useFetch(
		() =>
			inject("QuestionsController").getQuestionDifficulty({
				courseId: courseId!,
				questionId: questionId!,
			}),
		[questionId, courseId]
	);
};
