import React, { useState } from "react";
import { ObjectId } from "@app/utils/generics";
import { inject } from "@app/modules";
import { useFetch } from "@app/hooks/fetch3";
import { IFolderSingleItem, ItemType } from "@app/api/folders/helper-schemas";
import { arrayToObject, isNonNullable } from "@app/utils/common";
import { IFullQuestion } from "@tests-core/schemas/questions/helper-schemas";
import { newContent } from "@tests-core/components/questions/contents/new-content";
import { ITestSettingContentId } from "@app/api/tests/helper-schemas";
import { IRGETTestContents } from "@app/api/tests/validators";
import FancyLoading from "@app/components/widgets/fancy-loading";
import { FolderItemsSelectContainer } from "../folder/select";
import { ISelection } from "../folder";
import { addLoader } from "@app/commonJavascript";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import { AllCourses } from "../folder/courses";

export const TestContentList = React.memo<{
	courseId: ObjectId;
	folderId: ObjectId | "null";
	testId: ObjectId;
}>(function TestContentList({ courseId, testId, folderId }) {
	const testItems = useTestItems({
		courseId,
		folderId,
		testId,
	}).doc;

	const [
		contextMenuSelection,
		setContextMenuSelection,
	] = useState<ISelection | null>(null);

	const onItemsCopy = (
		items: { type: ItemType; id: ObjectId }[],
		createNewFolder: boolean,
		isDeepCopy: boolean
	) => {
		setContextMenuSelection({
			viewType: "COPY_ITEMS",
			items,
			createNewFolder,
			isDeepCopy,
		});
	};

	const onFolderSelect = (selected: {
		courseId: ObjectId;
		folderId: ObjectId;
	}) => {
		if (
			!contextMenuSelection ||
			contextMenuSelection.viewType !== "COPY_ITEMS"
		) {
			return;
		}
		const removeLoader = addLoader();
		const FoldersController = inject("FoldersController");
		FoldersController.copyFolderItems({
			source: {
				courseId,
				folderId: folderId === "null" ? null : folderId,
			},
			destination: {
				courseId: selected.courseId,
				folderId: selected.folderId,
				createNewFolder: contextMenuSelection.createNewFolder,
			},
			items: contextMenuSelection.items,
			isDeepCopy: contextMenuSelection.isDeepCopy,
		})
			.then(() => {
				removeLoader();
				setContextMenuSelection(null);
			})
			.catch(e => {
				console.error(e);
				removeLoader();
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			});
	};

	if (!testItems) {
		return <FancyLoading />;
	}

	return (
		<div className="main main2">
			<FolderItemsSelectContainer
				onCopy={onItemsCopy}
				items={testItems}
				folderId={folderId === "null" ? null : folderId}
				courseId={courseId}
				checkAllDefault={true}
			/>
			{contextMenuSelection &&
				contextMenuSelection.viewType === "COPY_ITEMS" && (
					<AllCourses
						currentCourse={courseId}
						currentFolder={folderId}
						onFolderSelect={onFolderSelect}
						onClose={() => setContextMenuSelection(null)}
					/>
				)}
		</div>
	);
});

const useTestItems = ({
	courseId,
	folderId,
	testId,
}: {
	courseId: ObjectId;
	folderId: ObjectId;
	testId: ObjectId;
}) => {
	return useFetch(async (): Promise<IFolderSingleItem[]> => {
		const [content, test] = await Promise.all([
			inject("TestsController").getTestContents({
				courseId,
				folderId,
				testId,
				getAnswers: true,
			}),
			inject("TestsController").getById({
				_id: testId,
			}),
		]);
		if (test.settings && test.settings.contentIds) {
			return testContentToFolderItems({
				contentIds: test.settings.contentIds,
				content,
			});
		}
		return [];
	}, [courseId, folderId, testId]);
};

const testContentToFolderItems = ({
	contentIds,
	content,
}: {
	contentIds: ITestSettingContentId[];
	content: IRGETTestContents;
}): IFolderSingleItem[] => {
	const questionByIds = arrayToObject(content.questions, "_id");
	const textByIds = arrayToObject(content.texts, "_id");
	return contentIds
		.map((item): IFolderSingleItem | null => {
			if (item.type === ItemType.question) {
				const question = questionByIds[item.id];
				if (!question) return null;
				return {
					type: ItemType.question,
					id: item.id,
					name: newContent(
						(question as IFullQuestion).content
					).getShortStat(),
				};
			}
			if (item.type === ItemType.text) {
				const text = textByIds[item.id];
				if (!text) return null;
				return {
					type: ItemType.question,
					id: item.id,
					name: text.text,
				};
			}
			return null;
		})
		.filter(isNonNullable);
};
