import { css } from "emotion";

export const FolderItemsPage = css`
	text-align: center;
`;

export const FolderContainer = css`
	display: inline-block;
	font-size: 16px;
	font-family: BPGNinoMtavruliBOLD;
	text-align: left;
	width: 100%;
	outline: none;
	&:hover {
		background-color: #cde8fc;
	}
`;

export const AddFolderItemsContainer = css`
	text-align: left;
	width: 100%;
	margin: 0 auto;
`;

export const AllFolders = css`
	text-align: center;
	margin: 20px;
`;

export const DraggableCSS = css`
	outline: none;
`;

export const FolderIconCSS = css`
	color: #fed765;
	font-size: 70px !important;
	vertical-align: middle;
	cursor: pointer;
`;

export const AddFolderIconCSS = css`
	font-size: 70px !important;
	vertical-align: middle;
	cursor: pointer;
	outline: none;
	color: darkseagreen;
	vertical-align: middle;
	&:hover {
		color: mediumseagreen;
	}
`;

export const FolderIconSimple = css`
	color: #fed765;
	vertical-align: middle;
`;

export const FileIcon = css`
	width: 50px;
	color: #4587f4;
	cursor: pointer;
	vertical-align: middle;
	padding: 10px 0px;
`;

export const AddFileIcon = css`
	width: 53px;
	color: darkseagreen;
	cursor: pointer;
	vertical-align: middle;
	&:hover {
		color: mediumseagreen;
	}
`;

export const FileIconSimple = css`
	color: #4587f4;
	vertical-align: middle;
`;

export const TaskTypeIconSimple = css`
	color: #9259dc;
	vertical-align: middle;
`;

export const TopicIconSimple = css`
	width: 27px;
	color: #57c828;
	vertical-align: middle;
`;

export const AddCardIcon = css`
	color: darkseagreen;
	cursor: pointer;
	vertical-align: middle;
	font-size: 60px !important;
	&:hover {
		color: mediumseagreen;
	}
`;

export const AddQuestionIcon = css`
	color: darkseagreen;
	cursor: pointer;
	vertical-align: middle;
	font-size: 60px !important;
	&:hover {
		color: mediumseagreen;
	}
`;

export const TestIconSimple = css`
	vertical-align: middle;
	width: 25px;
	color: #4587f4;
`;

export const TestIconStyles = css`
	width: 50px;
	cursor: pointer;
	vertical-align: middle;
	color: #4587f4;
	margin-left: 6px;
	padding: 10px 0px;
`;

export const AddTestIconStyles = css`
	width: 50px;
	cursor: pointer;
	vertical-align: middle;
	color: darkseagreen;
	margin: 0px 7px;
	&:hover {
		color: mediumseagreen;
	}
`;

export const FolderSingleItemNameCSS = css`
	display: inline;
	vertical-align: middle;
	margin-left: 10px;
`;

export const FolderNavigation = css`
	padding: 10px;
	font-family: BPGNinoMtavruliBOLD;
	color: #0f0f49;
	text-align: center;
	> span {
		cursor: pointer;
		&:hover {
			color: #4343bc;
		}
	}
`;

export const currentFolderName = css`
	color: #4343bc !important;
	cursor: auto !important;
`;

export const PopupCSS = css`
	display: table;
	min-width: 300px;
	max-width: 90%;
	background-color: white;
	border-radius: 10px;
	text-align: center;
	margin: 0 auto;
	margin-top: 80px;
	margin-bottom: 80px;
	position: relative;
	font-family: BPGNinoMtavruli;
`;

export const PopupHeader = css`
	height: 40px;
	> h3 {
		padding: 10px 0px;
		text-align: center;
	}
`;

export const Cancel = css`
	float: right;
	color: #c0c0c0;
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	&:hover {
		color: grey;
	}
`;

export const PopupBody = css`
	padding: 10px;
	padding-bottom: 40px;
	> p {
		text-align: left;
	}
`;

export const TestTypesContainer = css`
	width: 400px;
	max-width: 100%;
	margin: 0 auto;
	border: 1px solid grey;
	border-radius: 5px;
	padding: 10px;
	margin-top: 15px;
	margin-bottom: 15px;
	position: relative;
`;

export const TestTypesContainerHeader = css`
	display: inline-block;
	background: white;
	font-family: BPGNinoMtavruli;
	color: grey;
	position: absolute;
	top: -12px;
	left: 21px;
	padding: 5px;
`;

export const OuterCheckboxDiv = css`
	width: 400px;
	margin: 0 auto;
`;

export const FileCSS = css`
	color: grey;
	width: 55px;
	vertical-aign: middle;
	cursor: pointer;
	outline: none;
`;
