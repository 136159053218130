import { containerCSS, statementTextCSS, SaveButtonCSS } from "./custom-styles";
import { css } from "emotion";
import { IQContentEditPassableProps } from "@tests-core/components/questions/contents/edit";
import { IQContentPassableProps } from "@tests-core/components/questions/contents";
import { getFormattedMessage } from "@app/utils/locale";

export const getQuestionsEditCustomizedProps = (
	locale: string
): IQContentEditPassableProps => {
	const explanation = {
		placeholder: getFormattedMessage(
			"admin:qEdit.common.explanation.placeholder"
		),
		title: getFormattedMessage("admin:qEdit.common.explanation.title"),
	};
	return {
		commonProps: {
			commonStyles: {
				saveButton: SaveButtonCSS,
			},
			commonTexts: {
				saveButton: getFormattedMessage("admin:qEdit.common.save"),
				addAnotherQuestion: getFormattedMessage(
					"admin:qEdit.common.addAnotherQuestion"
				),
				contentTypes: {
					selectPlaceholder: getFormattedMessage(
						"admin:qEdit.common.contentTypes.selectPlaceholder"
					),
					MultipleChoice: {
						main: getFormattedMessage(
							"admin:qEdit.common.contentTypes.MultipleChoice.main"
						),
						twoColumns: getFormattedMessage(
							"admin:qEdit.common.contentTypes.MultipleChoice.twoColumns"
						),
						dataSufficiency: getFormattedMessage(
							"admin:qEdit.common.contentTypes.MultipleChoice.dataSufficiency"
						),
					},
					SortItems: {
						main: getFormattedMessage(
							"admin:qEdit.common.contentTypes.SortItems.main"
						),
					},
					FillingBlanks: {
						main: getFormattedMessage(
							"admin:qEdit.common.contentTypes.FillingBlanks.main"
						),
					},
					MultipleContents: {
						main: getFormattedMessage(
							"admin:qEdit.common.contentTypes.MultipleContents.main"
						),
					},
				},
			},
		},
		mcProps: {
			texts: {
				choices: {
					delete: getFormattedMessage("admin:qEdit.common.delete"),
					add: getFormattedMessage("admin:qEdit.mc.choices.add"),
					otherAnswer: getFormattedMessage(
						"admin:qEdit.mc.choices.otherAnswer"
					),
					checkedAnswer: getFormattedMessage(
						"admin:qEdit.mc.choices.checkedAnswer"
					),
				},
				explanation,
				settings: {
					disableShuffle: getFormattedMessage(
						"admin:qEdit.mc.settings.disableShuffle"
					),
					multipleSelect: getFormattedMessage(
						"admin:qEdit.mc.settings.multipleSelect"
					),
				},
				statement: {
					placeholder: getFormattedMessage(
						"admin:qEdit.common.statement"
					),
				},
			},
		},
		siProps: {
			texts: {
				items: {
					add: getFormattedMessage("admin:qEdit.common.add"),
					delete: getFormattedMessage("admin:qEdit.common.delete"),
					placeholder: getFormattedMessage(
						"admin:qEdit.si.items.placeholder"
					),
				},
				explanation,
				statement: {
					placeholder: getFormattedMessage(
						"admin:qEdit.common.statement"
					),
				},
			},
		},
		fbProps: {
			texts: {
				explanation,
				statement: {
					placeholder: getFormattedMessage(
						"admin:qEdit.common.statement"
					),
				},
				nonCheckableItem: {
					Editor: getFormattedMessage(
						"admin:qEdit.fb.nonCheckableItem.Editor"
					),
					NoOne: getFormattedMessage(
						"admin:qEdit.fb.nonCheckableItem.NoOne"
					),
					OneSelf: getFormattedMessage(
						"admin:qEdit.fb.nonCheckableItem.OneSelf"
					),
					whoWillCheck: getFormattedMessage(
						"admin:qEdit.fb.nonCheckableItem.whoWillCheck"
					),
				},
			},
		},
	};
};

const nextButtonCSS = css`
	background: linear-gradient(to top right, #004ddb, #168df8);
	color: white;
	&:hover {
		background: linear-gradient(to top right, #0d3c90, #127bda);
	}
`;

export const getQuestionsCustomizedProps = (
	locale: string,
	isStudentViewer: boolean
): IQContentPassableProps => {
	const viewModeTexts = {
		userAnswer: isStudentViewer
			? getFormattedMessage("student:test.qProps.vModeUserAnswerAsUser")
			: getFormattedMessage(
					"student:test.qProps.vModeUserAnswerAsTeacher"
			  ),
		correctAnswer: getFormattedMessage(
			"student:test.qProps.vModeCorrentAnswer"
		),
	};

	const explanation = getFormattedMessage("student:test.qProps.explanation");

	return {
		mcProps: {
			styles: {
				statement: {
					text: statementTextCSS,
				},
				container: containerCSS,
				choices: {
					checkMark: {
						innerCheckMark: {
							checked: css`
								color: white;
							`,
							checkedAndUnknown: css`
								background: #107cf0;
								border: 1px solid #0355df;
								&:hover {
									background: #0355df;
								}
							`,
							checkedAndCorrect: css`
								background: #17d564;
								border: 1px solid #1cc561;
								&:hover {
									background: #1cc561;
								}
							`,
							incorrect: css`
								background: #e51919;
								border: 1px solid #b41b1b;
								&:hover {
									background: #b41b1b;
								}
							`,
							correct: css`
								color: white;
								background: #17d5cd;
								border: 1px solid #16c0b9;
								&:hover {
									background: #16c0b9;
								}
							`,
						},
					},
				},
			},
			texts: {
				explanation: {
					title: explanation,
				},
			},
		},
		siProps: {
			texts: {
				viewMode: viewModeTexts,
				explanation: {
					title: explanation,
				},
			},
		},
		fbProps: {
			texts: {
				viewMode: viewModeTexts,
				explanation: {
					title: explanation,
				},
				input: {
					placeholder: getFormattedMessage(
						"student:test.qProps.openEndPlaceholder"
					),
				},
				nonCheckableInput: {
					answerInput: {
						placeholder: getFormattedMessage(
							"student:test.qProps.openEndPlaceholder"
						),
					},
					assessment: {
						before: "",
						after: " ქულა",
					},
				},
			},
		},
		mucProps: {
			texts: {
				nextButton: "",
				explanation: {
					title: explanation,
				},
				prevButton: "",
			},
			styles: {
				nextButton: nextButtonCSS,
				prevButton: nextButtonCSS,
			},
		},
	};
};
