import Joi from "@app/utils/joi";
import { AnySchema } from "joi";

export const GalleryMediaSchema = Joi.object({
	type: Joi.string()
		.valid("PHOTO", "VIDEO")
		.required(),
	url: Joi.string().required(),
	title: Joi.string(),
	alt: Joi.string(),
});

export interface IGalleryMedia {
	type: "PHOTO" | "VIDEO";
	url: string;
	title?: string;
	alt?: string;
}

type LooseOptionalKeys<T, K extends string> = Omit<T, K> &
	Partial<{ [key in K & keyof T]: T[key] }>;

export const updateStripKeys = {
	createdAt: Joi.any().strip(),
	updatedAt: Joi.any().strip(),
	author: Joi.any().strip(),
	authorId: Joi.any().strip(),
};

export type ToUpdateKeys<
	T,
	OptionalKeys extends string | never = never,
	OmitKeys extends string = never
> = Omit<
	LooseOptionalKeys<T, OptionalKeys>,
	"createdAt" | "updatedAt" | "author" | "authorId" | OmitKeys
>;

export const insertStripKeys = {
	_id: Joi.forbidden(),
	createdAt: Joi.any().strip(),
	updatedAt: Joi.any().strip(),
	author: Joi.any().strip(),
	authorId: Joi.any().strip(),
};
export type ToInsertKeys<
	T,
	OptionalKeys extends string | never = never,
	OmitKeys extends string = never
> = Omit<
	LooseOptionalKeys<T, OptionalKeys>,
	"_id" | "createdAt" | "updatedAt" | "author" | "authorId" | OmitKeys
>;

export const markKeysOptional = (schema: AnySchema) => schema.optional();
export const markKeysForbidden = (schema: AnySchema) => schema.forbidden();
export const markKeysStripped = (schema: AnySchema) =>
	schema.optional().strip();
