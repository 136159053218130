import * as React from "react";
import * as ReactDOM from "react-dom";
// import * as ReactGA from "react-ga";
import * as serviceWorker from "./serviceWorker";
import App from "@app/app";
import { handleActionOfOtherTab } from "m-model-common";
import reduxThunk, { ThunkMiddleware } from "redux-thunk";
import { appRootReducer } from "@app/redux";
import { _console } from "@app/commonJavascript";
import { applyMiddleware, createStore } from "redux";
import { BrowserRouter, Route } from "react-router-dom";
import { composeWithDevTools } from "redux-devtools-extension";
import { createModules } from "@app/modules";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { GlobalHistory } from "@app/global_history";
import { IRootActions, IRootState } from "@app/redux";
import { loadUser } from "@app/redux/actions/user";
import { Provider } from "react-redux";
import * as ReactGA from "react-ga";
import "@app/components/styles/commonStyles.min.css";
import "polyfills";
import { Requests } from "@app/api";
import { IntlProvider, updateIntl } from "react-intl-redux";
import { loadLocale } from "@app/locale";
import { ParamsContainer } from "@pckgs/component-distributor/params-observer";

const store = createStore(
	appRootReducer,
	{
		intl: {
			locale: localStorage.locale || "ka",
			messages: {},
		},
	},
	composeWithDevTools(
		applyMiddleware(reduxThunk as ThunkMiddleware<IRootState, IRootActions>)
	)
);
createModules({
	store,
	coursesRequest: Requests,
});
export { store };
export const ReduxStoreContext = React.createContext(store);

window.addEventListener("storage", (e: StorageEvent) => {
	handleActionOfOtherTab(e, store.dispatch);
});

if (localStorage.user) {
	try {
		const user = JSON.parse(localStorage.user);
		if (user && Object.keys(user).length > 0) {
			store.dispatch(loadUser(user));
		}
	} catch (e) {
		_console.error(e);
	}
}

ReactGA.initialize("UA-71053074-5");

const updateIntlMessages = (locale: string, messages: any) => {
	store.dispatch(updateIntl({ locale, messages }));
	localStorage.locale = locale;
};

export const loadAndUpdateIntlMessages = async (locale: string) => {
	const { messages } = await loadLocale(locale);
	updateIntlMessages(locale, messages);
};
(window as any).loadAndUpdateIntlMessages = loadAndUpdateIntlMessages;

(async () => {
	const theme = createMuiTheme({
		palette: {
			primary: {
				light: "#63bed9",
				main: "#278ea7",
				dark: "#023e5a",
			},
			secondary: {
				light: "#ffd64a",
				main: "#ffa500",
				dark: "#c67600",
			},
		},
		typography: {
			fontFamily: "BPGNinoMtavruli",
		},
		overrides: {
			MuiButton: {
				root: {
					textTransform: "initial",
				},
			},
		},
	});
	await loadAndUpdateIntlMessages(store.getState().intl.locale);
	ReactDOM.render(
		<MuiThemeProvider theme={theme}>
			<ReduxStoreContext.Provider value={store}>
				<Provider store={store}>
					<IntlProvider {...store.getState().intl}>
						<BrowserRouter>
							<ParamsContainer>
								<GlobalHistory />
								<Route component={App} />
							</ParamsContainer>
						</BrowserRouter>
					</IntlProvider>
				</Provider>
			</ReduxStoreContext.Provider>
		</MuiThemeProvider>,
		document.getElementById("root")
	);
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
