import * as React from "react";
import AdminChat from "./components/admin/chat";
import AdminRoute from "./routes/admin-route";
import AllCardTemplates from "./components/admin/all-card-templates";
import AllCourses from "./components/admin/all-courses";
import AllTestTypes from "./components/admin/all-test-types";
import CardTemplatePage from "./components/admin/cards/card-template-page";
import Course from "./components/admin/course";
import CourseDescriptionClass from "@app/components/course-description-page";
import EditCard from "@tests-core/components/cards";
import Folder from "./components/admin/folder";
import { Guide } from "./components/users/guide/index";
import LoginByTokenPage from "@app/components/login-by-token-page";
import Modelling from "./components/modelling";
import OfflineModelling from "./components/modelling/offline";
import SubTopics from "./components/users/subtopics";
import SubTopicsNew from "./components/users/subtopics-new";
import SubTopicsContent from "./components/users/contents";
import TestTypes from "./components/admin/test-types";
import UserRoute from "./routes/user-route";
import { connect } from "react-redux";
import { History } from "history";
import { IRootState } from "./redux";
import { Route, Switch } from "react-router-dom";
import "moment/locale/ka";
import { CourseEssentialData } from "./components/course-essential-data";
import { UniversalTestLoaderFromUrl } from "./components/users/tests/test-loader-wrapper";
import AdminUserTest from "./components/admin/admin-user-tests";
import Wrapper from "./components/admin/topics";
import { FilterCourseContent } from "./components/admin/filter";
import { AllCourseAppeals } from "./components/admin/appeals";
import { CourseAppeals } from "./components/admin/appeals/by-course";
import { PublicModellingTest } from "./components/modelling/public";
import { TestContentList } from "./components/admin/test";
const RenderSubTopics = props => (
	<SubTopicsContent key={props.match.params.folderId} {...props} />
);

const App = ({
	location,
	isAuthenticated,
	isAdmin,
}: {
	location: History["location"];
	isAuthenticated: boolean;
	isAdmin: boolean;
}) => (
	<React.Fragment>
		<UserRoute
			location={location}
			path="/courses/:courseId"
			exact={false}
			component={CourseEssentialData}
			hideHeader={true}
			hideChat={true}
		/>
		<UserRoute
			location={location}
			path="/admin/courses/:courseId"
			exact={false}
			component={CourseEssentialData}
			hideHeader={true}
			hideChat={true}
		/>
		<UserRoute
			location={location}
			path="/courses/:courseId/description"
			exact={true}
			component={CourseDescriptionClass}
		/>
		{/* <Route location={location} path="/courses/:id/folders/:folderId/final-page" exact={true} component={FinalPage} /> */}
		<UserRoute
			location={location}
			path="/courses/:courseId/guide"
			exact={true}
			component={Guide}
		/>
		<UserRoute
			location={location}
			path="/courses/:courseId/folders/:folderId"
			exact={true}
			component={SubTopics}
		/>
		<UserRoute
			location={location}
			path="/courses/:courseId/folders/:folderId/chapter/:chapter"
			exact={true}
			component={SubTopicsNew}
		/>
		<UserRoute
			location={location}
			path="/courses/:courseId/folders/:folderId/content"
			exact={true}
			component={RenderSubTopics}
		/>
		<UserRoute
			location={location}
			path="/courses/:courseId/folders/:folderId/tests/:testId"
			exact={true}
			component={UniversalTestLoaderFromUrl}
		/>
		<AdminRoute
			location={location}
			path="/admin/test-types/add"
			exact={true}
			component={TestTypes}
		/>
		<AdminRoute
			location={location}
			path="/admin/test-types/:testTypeId/edit"
			exact={true}
			component={TestTypes}
		/>
		<AdminRoute
			location={location}
			path="/admin/test-types"
			exact={true}
			component={AllTestTypes}
		/>
		<Switch>
			<AdminRoute
				location={location}
				path="/admin/courses/add"
				exact={true}
				component={Course}
			/>
			<AdminRoute
				location={location}
				path="/admin/courses/:courseId"
				exact={true}
				component={Folder}
			/>
		</Switch>
		<AdminRoute
			location={location}
			path="/admin/courses/:courseId/filter"
			exact={true}
			component={FilterCourseContent}
		/>
		<AdminRoute
			location={location}
			path="/admin/courses/:courseId/edit"
			exact={true}
			component={Course}
		/>
		<AdminRoute
			location={location}
			path="/admin/courses"
			exact={true}
			component={AllCourses}
		/>
		<AdminRoute
			location={location}
			path="/admin/appeals"
			exact={true}
			component={AllCourseAppeals}
		/>
		<AdminRoute
			location={location}
			path="/admin/appeals/courses/:courseId"
			exact={true}
			component={CourseAppeals}
		/>
		<AdminRoute
			location={location}
			path="/admin/courses/:courseId/folders"
			exact={true}
			component={Folder}
		/>
		<AdminRoute
			location={location}
			path="/admin/courses/:courseId/folders/:folderId"
			exact={true}
			component={Folder}
		/>
		<AdminRoute
			location={location}
			path="/admin/courses/:courseId/folders/:folderId/tests/:testId"
			exact={true}
			component={TestContentList}
		/>
		<AdminRoute
			location={location}
			path="/admin/courses/:courseId/folders/:folderId/cards/add"
			exact={true}
			component={EditCard}
		/>
		<AdminRoute
			location={location}
			path="/admin/courses/:courseId/folders/:folderId/cards/:cardId/edit"
			exact={true}
			component={props => (
				<EditCard key={props.match.params.cardId} {...props} />
			)}
		/>
		<AdminRoute
			location={location}
			path="/admin/courses/:courseId/folders/:folderId/tests/:testId/users-of-test"
			exact={true}
			component={AdminUserTest}
		/>
		<AdminRoute
			location={location}
			path="/admin/courses/:courseId/topics"
			exact={true}
			component={Wrapper}
		/>
		<AdminRoute
			location={location}
			path="/admin/card-templates/add"
			exact={true}
			component={CardTemplatePage}
		/>
		<AdminRoute
			location={location}
			path="/admin/card-templates/:cardTemplateId/edit"
			exact={true}
			component={CardTemplatePage}
		/>
		<AdminRoute
			location={location}
			path="/admin/card-templates"
			exact={true}
			component={AllCardTemplates}
		/>
		<AdminRoute
			location={location}
			path="/admin/courses/:courseId/chat"
			exact={true}
			component={AdminChat}
		/>
		<Route
			location={location}
			path="/loginByToken/:murtskuUserId/:token"
			exact={true}
			component={LoginByTokenPage}
		/>
		<UserRoute
			location={location}
			path="/modelling/:modellingId"
			exact={true}
			component={Modelling}
			hideHeader={true}
		/>
		<Route
			location={location}
			path="/tou-modelling/:modellingId"
			exact={true}
			component={OfflineModelling}
		/>
		<Route
			location={location}
			path="/m/:modellingId"
			exact={true}
			component={PublicModellingTest}
		/>
	</React.Fragment>
);

const mapStateToProps = (state: IRootState) => ({
	isAuthenticated: !!state.user.userId,
	// isAdmin: !!state.user.userData && !!state.user.userData.permissions
	// isStudent: state.user.userType === STUDENT_UTYPE,
	// isTeacher: state.user.userType === TEACHER_UTYPE,
	// isAdmin: state.user.userType === ADMIN_UTYPE,
});

export default connect(mapStateToProps)(App);
