import api from "@app/api";
import { arrayToObject } from "@app/utils/common";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { History } from "history";
import * as React from "react";
import { match } from "react-router";
import {
	AdminTableContainer,
	AdminTableHead,
	AdminTableRow,
	SVG,
} from "../widgets/admin-table";
import FancyLoading from "../widgets/fancy-loading";
import GradeUserTestQuestions, {
	IAOnUserTestInfoSave,
} from "./grade-user-test-questions";
import { IRGETUsersByTest } from "@app/api/tests/validators";
import { inject } from "@app/modules";

interface IProps {
	match: match<{ courseId: string; folderId: string; testId: string }>;
	history: History;
}

interface IState {
	usersTestInfo?: IRGETUsersByTest;
	usernamesById?: {
		[id: number]: { id: number; username: string } | undefined;
	};
	selectedUserTestInfo?: IRGETUsersByTest[number];
}

export default class AdminUserTest extends React.Component<IProps, IState> {
	state: IState = {};
	courseId = this.props.match.params.courseId;
	folderId = this.props.match.params.folderId;
	testId = this.props.match.params.testId;
	_isMounted = false;

	_TestsController = inject("TestsController");

	componentDidMount() {
		this._isMounted = true;
		const userIds: number[] = [];
		this._TestsController
			.getUsersByTest({
				courseId: this.courseId,
				testId: this.testId,
				includeOnlyCurrentCourse: false,
			})
			.then(data1 => {
				for (const obj of data1) {
					userIds.push(obj.userId);
				}
				api.users.getUserNames({ ids: userIds }).then(data2 => {
					this.setState({
						usersTestInfo: data1,
						usernamesById: arrayToObject(data2, "id"),
					});
				});
			});
	}

	onUserSelect = (userTestInfo: IRGETUsersByTest[number]) => {
		this.setState({
			selectedUserTestInfo: userTestInfo,
		});
	};

	onReturnToMainPage = () => {
		this.setState({
			selectedUserTestInfo: undefined,
		});
	};

	onUserTestInfoSave = (newInfo: IAOnUserTestInfoSave) => {
		if (!this.state.usersTestInfo || !this.state.selectedUserTestInfo) {
			return;
		}
		this.setState({
			usersTestInfo: this.state.usersTestInfo.map(e => {
				if (
					e.userId !== this.state.selectedUserTestInfo!.userId ||
					e.attempt !== this.state.selectedUserTestInfo!.attempt
				) {
					return e;
				}
				return {
					...e,
					...newInfo,
				};
			}),
		});
	};

	render() {
		if (!this.state.usersTestInfo || !this.state.usernamesById) {
			return <FancyLoading />;
		}
		return (
			<div className="main">
				{this.state.selectedUserTestInfo ? (
					<GradeUserTestQuestions
						courseId={this.state.selectedUserTestInfo.courseId}
						originalCourseId={this.courseId}
						folderId={this.folderId}
						testId={this.testId}
						userId={this.state.selectedUserTestInfo.userId}
						attempt={this.state.selectedUserTestInfo.attempt}
						onReturnToMainPage={this.onReturnToMainPage}
						onUserTestInfoSave={this.onUserTestInfoSave}
					/>
				) : (
					<AdminUserTestTable
						userTestsInfo={this.state.usersTestInfo.map(el => ({
							userTestInfo: el,
							username:
								(!!this.state.usernamesById![el.userId] &&
									this.state.usernamesById![el.userId]!
										.username) ||
								"",
						}))}
						onUserSelect={this.onUserSelect}
					/>
				)}
			</div>
		);
	}
}

interface IRowInfo {
	userTestInfo: IRGETUsersByTest[number];
	username: string;
}

interface ITableProps {
	userTestsInfo: IRowInfo[];
	onUserSelect: (writingInfo: IRGETUsersByTest[number]) => void;
}

const AdminUserTestTable: React.FC<ITableProps> = props => (
	<Paper className={AdminTableContainer}>
		<Table>
			<TableHead className={AdminTableHead}>
				<TableRow className={AdminTableRow}>
					<TableCell>მომხმარებელი</TableCell>
					<TableCell>მცდლობა No.</TableCell>
					<TableCell>რა ნაწილია შემოწმებული</TableCell>
					<TableCell>ნახვა</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{props.userTestsInfo.map((row, index) => (
					<TableRow key={index} className={AdminTableRow}>
						<TableCell>{row.username}</TableCell>
						<TableCell>{row.userTestInfo.attempt}</TableCell>
						<TableCell>
							{Math.floor(
								row.userTestInfo.assessmentProgress * 100
							)}{" "}
							%
						</TableCell>
						<TableCell>
							<VisibilityIcon
								className={SVG}
								onClick={() =>
									props.onUserSelect(row.userTestInfo)
								}
							/>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</Paper>
);
