import * as React from "react";
import CancelIcon from "@material-ui/icons/CancelRounded";
import {
	Cancel,
	PopupBody,
	PopupCSS,
	PopupHeader,
} from "../admin/folder/styles";
import "./styles/popup.min.css";
import classNames from "classnames";

interface IPopupProps {
	onClose: () => void;
	disableClosingOnOutsideClick?: boolean;
}

class Popup extends React.Component<IPopupProps> {
	_isMounted = false;
	bodyInitialOverflow: string | null = null;

	componentDidMount() {
		this._isMounted = true;
		this.bodyInitialOverflow = document.body.style.overflow!;
		document.body.style.overflow = "hidden";
	}

	componentWillUnmount() {
		this.onClose();
	}

	onClose = () => {
		this._isMounted = false;
		document.body.style.overflow = this.bodyInitialOverflow || "initial";
	};

	private popupBlackClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (this.props.disableClosingOnOutsideClick) return;
		if (e.target === e.currentTarget) this.props.onClose();
	};

	render() {
		return (
			<div className="popupBlackDiv" onClick={this.popupBlackClick}>
				{this.props.children}
			</div>
		);
	}
}

interface IPopupContentProps {
	scrollable?: boolean;
	style?: React.CSSProperties;
	className?: string;
}

export const PopupContent: React.FC<IPopupContentProps> = ({
	scrollable = true,
	style,
	className = "",
	children,
}) => (
	<div
		className={`popup-content ${
			scrollable ? "scrollable" : ""
		} ${className}`}
		style={style}
	>
		{children}
	</div>
);

interface IPopupContentWithCloseProps {
	onClose: () => void;
	style?: React.CSSProperties;
	className?: string;
}

export const PopupContentWithClose: React.FC<IPopupContentWithCloseProps> = props => (
	<div className={classNames(PopupCSS, props.className)} style={props.style}>
		<div className={PopupHeader}>
			<CancelIcon className={Cancel} onClick={props.onClose} />
		</div>
		<div className={PopupBody}>{props.children}</div>
	</div>
);

export default Popup;
