import React, { useCallback } from "react";
import { ObjectId } from "@app/utils/generics";
import {
	IRFolder,
	ItemType,
	IFolderSingleItem,
} from "@app/api/folders/helper-schemas";
import { FolderItemElement } from "./folder-items-container";
import { FolderContainer } from "./styles";
import Checkbox from "@material-ui/core/Checkbox";
import { inject } from "@app/modules";
import FancyLoading from "@app/components/widgets/fancy-loading";
import { FilterOptions } from "./filter-options";
import { intersectWith } from "@app/utils/set";
import Button from "@material-ui/core/Button";
import { AddLabelsButtons } from "../hierarchy-multiple-select/add-labels";

export interface FilterOption {
	allowedTypes: ItemType[];
	topics: ObjectId[];
	taskTypes: ObjectId[];
	createNewFolderWhenCopying: boolean;
	isDeepCopy: boolean;
}

interface Props {
	items: NonNullable<IRFolder["items"]>;
	folderId: ObjectId | null;
	courseId: ObjectId;
	checkAllDefault: boolean;
	onCopy: (
		items: { type: ItemType; id: ObjectId }[],
		createNewFolder: boolean,
		isDeepCopy: boolean
	) => void;
}

interface State {
	selectedItems: Record<ItemType, Record<string, true | undefined>>;
	itemLabels?: Record<
		ItemType,
		Record<
			string,
			| { folders: ObjectId[]; topics: ObjectId[]; taskTypes: ObjectId[] }
			| undefined
		>
	>;
	filterOptions: FilterOption;
	isLoaded: boolean;
}

class FolderItemsSelectContainer extends React.PureComponent<Props, State> {
	state: State = {
		selectedItems: {
			[ItemType.card]: {},
			[ItemType.file]: {},
			[ItemType.folder]: {},
			[ItemType.question]: {},
			[ItemType.test]: {},
			[ItemType.text]: {},
		},
		filterOptions: {
			allowedTypes: [],
			taskTypes: [],
			topics: [],
			createNewFolderWhenCopying: false,
			isDeepCopy: true,
		},
		isLoaded: false,
	};

	private readonly _CoursesController = inject("CoursesController");

	public getSelectedItems = () => {
		const items: { type: ItemType; id: ObjectId }[] = [];
		for (const type in this.state.selectedItems) {
			for (const id in this.state.selectedItems[type]) {
				items.push({
					type: +type as ItemType,
					id,
				});
			}
		}
		return items;
	};

	componentDidMount() {
		if (this.props.checkAllDefault) {
			this.selectAll();
		}
		this._CoursesController
			.getCourseFilteredContent({
				courseId: this.props.courseId,
				offset: 0,
				limit: 1000000,
				folders: this.props.folderId ? [this.props.folderId] : null,
			})
			.then(data => {
				const itemLabels: NonNullable<State["itemLabels"]> = {
					[ItemType.card]: {},
					[ItemType.file]: {},
					[ItemType.folder]: {},
					[ItemType.question]: {},
					[ItemType.test]: {},
					[ItemType.text]: {},
				};
				for (const item of data.items) {
					if (!itemLabels[item.type]) continue;
					itemLabels[item.type][item.id] = item;
				}
				this.setState({ itemLabels }, () => {
					this.loadPreviousSettings().then(() => {
						this.setState({ isLoaded: true });
					});
				});
			});
	}

	private selectAll = () => {
		this.selectItems(this.props.items);
	};

	private loadPreviousSettings = () => {
		return new Promise((resolve, reject) => {
			try {
				const folderSelectOptions =
					localStorage.getItem("folderSelectOptions") || "{}";
				const options = JSON.parse(folderSelectOptions)[
					this.props.courseId
				];
				if (options) {
					this.onFilterOptionsChange(options);
				}
			} catch (e) {
				console.error(e);
			}
			resolve();
		});
	};

	private selectItems = (items: { type: ItemType; id: ObjectId }[]) => {
		this.setState(({ selectedItems }) => {
			const newSel = { ...selectedItems };
			for (const key in newSel) {
				newSel[key] = {};
			}
			for (const item of items) {
				newSel[item.type][item.id] = true;
			}
			return { selectedItems: newSel };
		});
	};

	private onItemClick = (item: IFolderSingleItem) => {
		this.setState(({ selectedItems }) => {
			const newSelection = {
				...selectedItems[item.type],
			};
			if (newSelection[item.id]) {
				delete newSelection[item.id];
			} else {
				newSelection[item.id] = true;
			}
			return {
				selectedItems: {
					...selectedItems,
					[item.type]: newSelection,
				},
			};
		});
	};

	private onFilterOptionsChange = (filterOptions: FilterOption) => {
		const allowedTypes = new Set(filterOptions.allowedTypes);
		const allowedTaskTypes = new Set(filterOptions.taskTypes);
		const allowedTopics = new Set(filterOptions.topics);
		const filteredItems = this.props.items.filter(item => {
			if (allowedTypes.size > 0) {
				if (!allowedTypes.has(item.type)) {
					return false;
				}
			}
			if (allowedTaskTypes.size > 0) {
				const itemTaskTypes = this.state.itemLabels![item.type][
					item.id
				];
				if (
					!itemTaskTypes ||
					intersectWith.call(
						new Set<string>(itemTaskTypes.taskTypes),
						allowedTaskTypes
					).size === 0
				) {
					return false;
				}
			}
			if (allowedTopics.size > 0) {
				const itemTopics = this.state.itemLabels![item.type][item.id];
				if (
					!itemTopics ||
					intersectWith.call(
						new Set<string>(itemTopics.topics),
						allowedTopics
					).size === 0
				) {
					return false;
				}
			}
			return true;
		});
		this.selectItems(filteredItems);
		this.setState(() => ({ filterOptions }));
	};

	handleCopy = () => {
		try {
			const folderSelectOptions = JSON.parse(
				localStorage.getItem("folderSelectOptions") || "{}"
			);
			folderSelectOptions[this.props.courseId] = this.state.filterOptions;
			localStorage.setItem(
				"folderSelectOptions",
				JSON.stringify(folderSelectOptions)
			);
		} catch (e) {
			console.error(e);
		}
		this.props.onCopy(
			this.getSelectedItems(),
			this.state.filterOptions.createNewFolderWhenCopying,
			this.state.filterOptions.isDeepCopy
		);
	};

	allItemsCheckChange = (checkAll: boolean) => {
		const items = this.props.items;
		const selectedItems = { ...this.state.selectedItems };
		for (const key in selectedItems) {
			selectedItems[+key as ItemType] = {};
		}
		if (checkAll) {
			for (const item of items) {
				if (!selectedItems[item.type]) {
					selectedItems[item.type] = {};
				}
				selectedItems[item.type][item.id] = true;
			}
		}
		this.setState({
			selectedItems,
		});
	};

	render() {
		const { items, courseId } = this.props;
		const { itemLabels, filterOptions, isLoaded } = this.state;
		if (!itemLabels || !isLoaded) return <FancyLoading />;
		const numberOfItemsChecked = items.filter(
			item => !!(this.state.selectedItems[item.type] || {})[item.id]
		).length;
		return (
			<div>
				<FilterOptions
					options={filterOptions}
					courseId={courseId}
					onChange={this.onFilterOptionsChange}
				/>
				<Button variant="contained" onClick={this.handleCopy}>
					კოპირება სხვაგან
				</Button>
				<AddLabelsButtons
					getItems={this.getSelectedItems}
					courseId={courseId}
				/>
				<ItemsUnifierCheckbox
					totalCount={items.length}
					checkedCount={numberOfItemsChecked}
					onChange={this.allItemsCheckChange}
				/>
				{items.map(item => (
					<Item
						key={item.id}
						item={item}
						isChecked={
							!!(this.state.selectedItems[item.type] || {})[
								item.id
							]
						}
						onClick={this.onItemClick}
						courseId={courseId}
					/>
				))}
			</div>
		);
	}
}

export const ItemsUnifierCheckbox = React.memo<{
	totalCount: number;
	checkedCount: number;
	onChange: (checkAll: boolean) => void;
}>(function ItemsUnifierCheckbox({ totalCount, checkedCount, onChange }) {
	const areAllChecked = checkedCount === totalCount;
	const areAllUnchecked = checkedCount === 0;
	return (
		<div>
			<Checkbox
				indeterminate={!areAllChecked && !areAllUnchecked}
				checked={areAllChecked}
				onClick={() => {
					if (areAllChecked) {
						onChange(false);
					} else if (areAllUnchecked) {
						onChange(true);
					} else {
						onChange(false);
					}
				}}
			/>
			ყველა
		</div>
	);
});

const Item: React.FC<{
	item: IFolderSingleItem;
	isChecked: boolean;
	onClick: (item: IFolderSingleItem) => void;
	courseId: ObjectId;
}> = React.memo(({ item, isChecked, onClick, courseId }) => {
	const handleClick = useCallback(() => {
		onClick(item);
	}, [item, onClick]);

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "flex-start",
			}}
			onClick={handleClick}
		>
			<div>
				<Checkbox checked={isChecked} />
			</div>
			<div className={FolderContainer}>
				<FolderItemElement
					key={item.id}
					item={item}
					courseId={courseId}
				/>
			</div>
		</div>
	);
});

export { FolderItemsSelectContainer };
