import { useMountingInfo } from "./general";
import { useState, useCallback, useEffect, useMemo, useRef } from "react";

export type IResourceLoadingInfo<
	DOC extends {},
	LoadFunc extends (() => void) | undefined = () => void,
	ErrorType = any
> =
	| {
			isSuccessfullyLoaded: true;
			doc: DOC;
			isIdentificationKnown: true;
			hasFoundError: false;
	  }
	| {
			isSuccessfullyLoaded: false;
			hasFoundError: false;
			isIdentificationKnown: boolean;
			doc?: undefined;
	  }
	| ({
			isSuccessfullyLoaded: false;
			hasFoundError: true;
			isIdentificationKnown: true;
			error: ErrorType;
			doc?: undefined;
	  } & (LoadFunc extends undefined ? {} : { loadAgain: LoadFunc }));

export function getResourceLoadingInfo<
	DOC extends {},
	LoadFunc extends (() => void) | undefined = undefined,
	ErrorType = any
>({
	resource,
	error,
	loadAgain,
	isIdentificationKnown,
}: {
	resource: DOC | null | undefined;
	error: ErrorType;
	loadAgain: LoadFunc;
	isIdentificationKnown: boolean;
}): IResourceLoadingInfo<DOC, LoadFunc, ErrorType> {
	if (resource) {
		return {
			isSuccessfullyLoaded: true,
			isIdentificationKnown: true,
			hasFoundError: false,
			doc: resource,
		};
	}
	if (error) {
		return {
			isSuccessfullyLoaded: false,
			hasFoundError: true,
			error,
			isIdentificationKnown: true,
			...({ loadAgain } as any),
		} as Extract<
			IResourceLoadingInfo<DOC, LoadFunc, ErrorType>,
			{ hasFoundError: true }
		>;
	}
	return {
		isSuccessfullyLoaded: false,
		hasFoundError: false,
		isIdentificationKnown,
	};
}

export function useResourceInfoWithLoading<
	DOC extends {},
	FetchingInfo extends any,
	Fetch extends (args: FetchingInfo) => undefined | Promise<DOC>,
	ErrorType = any
>(args: {
	resource: DOC | null | undefined;
	fetchingArg: FetchingInfo;
	fetch: Fetch;
	isIdentificationKnown: boolean;
	onResourceLoading?: (resource: DOC | null | undefined) => void;
	multiArgs?: false;
	forcefullyFetch?: boolean;
}): IResourceLoadingInfo<DOC, () => void, ErrorType>;
export function useResourceInfoWithLoading<
	DOC extends {},
	FetchingInfo extends readonly any[],
	Fetch extends (...args: FetchingInfo) => undefined | Promise<DOC>,
	ErrorType = any
>(args: {
	resource: DOC | null | undefined;
	fetchingArg: FetchingInfo;
	fetch: Fetch;
	isIdentificationKnown: boolean;
	onResourceLoading?: (resource: DOC | null | undefined) => void;
	multiArgs: true;
	forcefullyFetch?: boolean;
}): IResourceLoadingInfo<DOC, () => void, ErrorType>;
export function useResourceInfoWithLoading<
	DOC extends {},
	FetchingInfo extends any,
	Fetch extends (args: FetchingInfo) => undefined | Promise<DOC>,
	ErrorType = any
>({
	resource,
	fetchingArg,
	fetch,
	isIdentificationKnown,
	onResourceLoading,
	multiArgs,
	forcefullyFetch,
}: {
	resource: DOC | null | undefined;
	fetchingArg: FetchingInfo;
	fetch: Fetch;
	isIdentificationKnown: boolean;
	onResourceLoading?: (resource: DOC | null | undefined) => void;
	multiArgs?: boolean;
	forcefullyFetch?: boolean;
}): IResourceLoadingInfo<DOC, () => void, ErrorType> {
	const [error, setError] = useState<ErrorType | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const mountingInfo = useMountingInfo();

	const argsRef = useRef(fetchingArg);
	argsRef.current = fetchingArg;

	const resourceRef = useRef(resource);
	resourceRef.current = resource;

	const errorRef = useRef(error);
	errorRef.current = error;

	const fetchRef = useRef(fetch);
	fetchRef.current = fetch;

	const resourceLoading = useRef(onResourceLoading);
	resourceLoading.current = onResourceLoading;

	const calledAtLeastOnceRef = useRef(false);

	const resCallingCountRef = useRef(0);

	const getResource = useCallback(() => {
		if (mountingInfo.hasFinishedFirstMountingCycle) {
			if (errorRef.current !== null) setError(null);
			if (resourceLoading.current) resourceLoading.current(null);
		}
		const args = argsRef.current;
		const promise = (!multiArgs
			? fetchRef.current!(args)
			: (fetchRef.current as any)(...((args as any) as any[]))) as
			| Promise<DOC>
			| undefined;
		if (!promise) {
			return;
		}
		setIsLoading(true);
		const callingCount = resCallingCountRef.current;
		promise
			.then((data: DOC) => {
				calledAtLeastOnceRef.current = true;
				if (
					!mountingInfo.isMounted ||
					resCallingCountRef.current !== callingCount
				) {
					return;
				}
				setIsLoading(false);
				if (resourceLoading.current) resourceLoading.current(data);
				return data;
			})
			.catch(e => {
				calledAtLeastOnceRef.current = true;
				if (
					!mountingInfo.isMounted ||
					resCallingCountRef.current !== callingCount
				) {
					return;
				}
				setError(e);
				setIsLoading(false);
				if (resourceLoading.current) resourceLoading.current(undefined);
			});
	}, [
		mountingInfo.hasFinishedFirstMountingCycle,
		mountingInfo.isMounted,
		multiArgs,
	]);

	const getResourceRef = useRef(getResource);
	getResourceRef.current = getResource;

	const memoizedFetchingArgs = useMemo(
		() => JSON.stringify(multiArgs ? [fetchingArg] : fetchingArg),
		[fetchingArg, multiArgs]
	);

	useEffect(() => {
		if (
			isIdentificationKnown &&
			(mountingInfo.hasFinishedFirstMountingCycle ||
				!resourceRef.current ||
				forcefullyFetch)
		) {
			resCallingCountRef.current++;
			calledAtLeastOnceRef.current = false;
			getResourceRef.current();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [memoizedFetchingArgs, isIdentificationKnown, forcefullyFetch]);

	const resourceNotReady = forcefullyFetch && !calledAtLeastOnceRef.current;

	return useMemo(() => {
		return getResourceLoadingInfo({
			resource: isLoading || resourceNotReady ? null : resource,
			error: (isLoading ? null : error) as ErrorType,
			loadAgain: getResource,
			isIdentificationKnown,
		});
	}, [
		isLoading,
		resourceNotReady,
		resource,
		error,
		getResource,
		isIdentificationKnown,
	]);
}
