import * as React from "react";
import chatLogo from "./img/data1.png";
import ClosedChatComponent from "./closed";
import MiniChat from "@chat-app/components/mini-chat";
import {
	addSentMessageInStorage,
	markMessageAsSeenInStorage,
} from "@chat-app/actions/chatrooms";
import { connect } from "react-redux";
import { IMessage, IStateChatroom } from "@chat-app/reducers/chatrooms";
import { ObjectId } from "@app/utils/generics";
import { ToDispatchType } from "@app/redux/common/generics";
import { IChatRootState } from "@chat-app/rootReducer";

interface IOwnProps {
	userId: number;
	userName: string;
	courseId?: ObjectId;
	courseAssistantName: string;
}
type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ToDispatchType<typeof mapDispatchToProps>;

type IProps = IOwnProps & IStateProps & IDispatchProps;

interface IState {
	isChatOpened: boolean;
}

class UserChatMain extends React.Component<IProps, IState> {
	state: IState = {
		isChatOpened: false,
	};

	findChatroom = (
		chatrooms,
		userId: string,
		courseId: string
	): IStateChatroom[string] | null => {
		const chatroomKeys = Object.keys(chatrooms);
		if (chatroomKeys.length <= 0) return null;

		for (let i = 0; i < chatroomKeys.length; i++) {
			if (
				chatrooms[chatroomKeys[i]].participantIds.indexOf(userId) >=
					0 &&
				chatrooms[chatroomKeys[i]].participantIds.indexOf(courseId) >= 0
			) {
				return chatrooms[chatroomKeys[i]];
			}
		}
		return null;
	};

	getUnseenMessages = (
		messages: IMessage[],
		userId: string
	): number | null => {
		let unseenNumber = 0;

		for (let i = 0; i < messages.length; i++) {
			if (messages[i].seenBy.indexOf(userId) < 0) unseenNumber++;
		}

		return unseenNumber > 0 ? unseenNumber : null;
	};

	changeChatState = () =>
		this.setState({ isChatOpened: !this.state.isChatOpened });

	render() {
		if (!this.props.courseId) return null;
		if (!this.props.chatrooms) return null;
		const currentChatroom = this.findChatroom(
			this.props.chatrooms,
			"u" + this.props.userId,
			"c" + this.props.courseId
		);
		const unseenNumber = currentChatroom
			? this.getUnseenMessages(
					currentChatroom.messages,
					"u" + this.props.userId
			  )
			: null;

		return (
			<React.Fragment>
				{!this.state.isChatOpened && (
					<ClosedChatComponent
						onClick={this.changeChatState}
						unseenMessages={unseenNumber}
					/>
				)}
				{this.state.isChatOpened && (
					<MiniChat
						userId={"u" + this.props.userId}
						userName={this.props.userName}
						destinationId={"c" + this.props.courseId}
						destinationName={this.props.courseAssistantName}
						greetingMessage="გამარჯობა, მე დავითი ვარ და ისტორიის გამოცდის ჩაბარებაში დაგეხმარები"
						uniPicture={chatLogo}
						style={{ borderRadius: "7px" }}
						messageStyle={{
							containerStyle: {
								userColor: "#a9a9a9",
								otherColor: "#ff780d",
							},
						}}
						headerStyle={{ borderRadius: "7px 7px 0 0" }}
						textareaStyle={{
							containerStyle: { borderRadius: "0 0 7px 7px" },
							textareaStyle: { borderRadius: "3px" },
						}}
						onClose={this.changeChatState}
					/>
				)}
			</React.Fragment>
		);
	}

	componentDidMount() {
		if (!this.props.courseId) return;
		this.openFirstTime(0);
	}

	openFirstTime = (time: number) => {
		if (time >= 5) return;
		if (!this.props.chatrooms) {
			setTimeout(() => this.openFirstTime(time + 1), 100);
			return;
		}
		const currentChatroom = this.findChatroom(
			this.props.chatrooms,
			"u" + this.props.userId,
			"c" + this.props.courseId
		);

		if (!currentChatroom) this.setState({ isChatOpened: true });
	};
}

const mapStateToProps = (state: IChatRootState) => ({
	chatrooms: state.chatrooms.info,
});

const mapDispatchToProps = {
	sendMessage: addSentMessageInStorage,
	markSeen: markMessageAsSeenInStorage,
};

export default connect<IStateProps, IDispatchProps, IOwnProps>(
	mapStateToProps,
	mapDispatchToProps as any
)(UserChatMain);
