import AdminTable from "@app/components/widgets/admin-table";
import FancyLoading from "@app/components/widgets/fancy-loading";
import { useAppealCounts } from "@app/hooks/appeals";
import { useMyCourses } from "@app/hooks/courses";
import { arrayToObject } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import { css } from "emotion";
import { History } from "history";
import React, { useCallback, useMemo } from "react";

export const AllCourseAppeals: React.FC<{
	history: History;
}> = React.memo(({ history }) => {
	const myCourses = useMyCourses();
	const appealCounts = useAppealCounts();
	const appealCountsByCourseId = useMemo(() => {
		return !appealCounts ? null : arrayToObject(appealCounts, "courseId");
	}, [appealCounts]);

	const gotoCourseAppeal = useCallback(
		(courseId: ObjectId) => {
			history.push(`/admin/appeals/courses/${courseId}`);
		},
		[history]
	);

	const additionalComponents: React.ComponentType<{
		rowId: ObjectId;
		iconClassName: string;
	}> = useMemo(
		() => ({ rowId, iconClassName }) => {
			if (!appealCountsByCourseId) return null;
			const count = appealCountsByCourseId[rowId];
			if (!count) return null;
			return <span className={iconStyle}>{count.count}</span>;
		},
		[appealCountsByCourseId]
	);

	const coursesCount = useMemo(() => {
		if (!myCourses.doc || !appealCountsByCourseId) return null;
		return [...myCourses.doc].sort((a, b) => {
			const count1 = appealCountsByCourseId[a._id]?.count;
			const count2 = appealCountsByCourseId[b._id]?.count;
			if (!count1 && !count2) return 0;
			if (!count1) return 1;
			if (!count2) return -1;
			return count2 - count1;
		});
	}, [myCourses.doc, appealCountsByCourseId]);

	if (!coursesCount) {
		return <FancyLoading />;
	}

	return (
		<div className="main main2">
			<AdminTable
				rows={coursesCount}
				onView={gotoCourseAppeal}
				additionalComponents={additionalComponents}
			/>
		</div>
	);
});

const iconStyle = css`
	display: inline-block;
	background: red;
	color: white;
	padding: 5px;
	border-radius: 20px;
	font-weight: bold;
	height: 35px;
	min-width: 35px;
	text-align: center;
	font-family: sans-serif;
	line-height: 2em;
	vertical-align: middle;
`;
