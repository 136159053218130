import { ObjectId } from "@app/utils/generics";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import DownArrowIcon from "@material-ui/icons/KeyboardArrowDown";
import UpArrowIcon from "@material-ui/icons/KeyboardArrowUp";
import SearchIcon from "@material-ui/icons/Search";
import * as React from "react";
import AutosizeInput from "react-input-autosize";
import { ItemElement, ItemsContainer } from "./folder/contents-sort";
import multipleSelectStyles from "./folder/styles/multiple-select.module.css";

export interface IItemsHierarchy {
	parentInfo: IItemsParentInfo;
	childrenInfo: IItemsChildrenInfo;
}

export interface IItemsParentInfo {
	[itemType: number]: {
		[id: string]: string;
	};
}

export interface IItemsChildrenInfo {
	[id: string]: IMultipleSelectItem<string>[];
}

export interface IMultipleSelectItem<T> {
	id: T;
	name: string;
	type: number;
}

export interface ICheckedItems {
	[itemType: number]: {
		[id: number]: true | undefined;
	};
}

interface IItemsObj {
	[itemType: number]: {
		[id: string]: IMultipleSelectItem<string>;
	};
}

export interface IDefaultItems<
	Idtype extends number | string | symbol = string,
	Type extends number | string | symbol = number
> {
	id: Idtype;
	type: Type;
}

export interface IMSelectWrapperProps {
	items: IMultipleSelectItem<string>[];
	hierarchy: IItemsHierarchy;
	defaultSelectedItems?: IDefaultItems[];
	onSelectedItemsChange: (sortedItems: IDefaultItems[]) => void;
	rootId: ObjectId;
	expandableItemType: number;
	itemTypesToBeStored: { [itemType: number]: true };
	iconsByType?: { [itemType: number]: JSX.Element };
	sort?: boolean;
	onItemsReorder?: (items: IDefaultItems[]) => void;
	hideSelectedItems: boolean;
	singleItemPerLine?: boolean;
	placeholder: string;
	hideSelectedDescendantsFromSearchBar?: boolean;
	disjoinParentFromChildren?: boolean;
}

const checkAncestors = ({
	item,
	isChecked,
	checkedItems,
	hierarchy,
	expandableItemType,
	disjoinParentFromChildren,
}: {
	item: IDefaultItems;
	isChecked: boolean;
	checkedItems: ICheckedItems;
	hierarchy: IItemsHierarchy;
	expandableItemType: number;
	disjoinParentFromChildren?: boolean;
}): {
	newCheckedItems: ICheckedItems;
	affectedParentItems: IDefaultItems[];
} => {
	const newCheckedItems: ICheckedItems = {
		...checkedItems,
	};
	let currentId: string = item.id;
	let itemType = item.type;
	const affectedParentItems: IDefaultItems[] = [];
	if (isChecked) {
		while (currentId) {
			delete (newCheckedItems[itemType] || {})[currentId];
			affectedParentItems.push({ id: currentId, type: itemType });
			if (disjoinParentFromChildren) break;
			currentId = (hierarchy.parentInfo[itemType] || {})[currentId];
			itemType = expandableItemType;
		}
	} else {
		if (!newCheckedItems[item.type]) newCheckedItems[item.type] = {};
		newCheckedItems[item.type][item.id] = true;
		affectedParentItems.push({ id: item.id, type: item.type });
		if (!disjoinParentFromChildren) {
			let parentId: string = (hierarchy.parentInfo[item.type] || {})[
				item.id
			];
			while (parentId) {
				let allChecked = true;
				for (const subItem of hierarchy.childrenInfo[parentId]) {
					if (
						!newCheckedItems[subItem.type] ||
						!newCheckedItems[subItem.type][subItem.id]
					) {
						allChecked = false;
						break;
					}
				}
				if (!allChecked) break;
				if (!newCheckedItems[expandableItemType])
					newCheckedItems[expandableItemType] = {};
				newCheckedItems[expandableItemType][parentId] = true;
				affectedParentItems.push({
					id: parentId,
					type: expandableItemType,
				});

				parentId = (hierarchy.parentInfo[expandableItemType] || {})[
					parentId
				];
			}
		}
	}
	return { newCheckedItems, affectedParentItems };
};

const checkDescendantItems = ({
	itemId,
	isChecked,
	checkedItems,
	sortedItemsArray,
	hierarchy,
	itemTypesToBeStored,
	expandableItemType,
	disjoinParentFromChildren,
}: {
	itemId: string;
	isChecked: boolean;
	checkedItems: ICheckedItems;
	sortedItemsArray: IDefaultItems[];
	hierarchy: IItemsHierarchy;
	itemTypesToBeStored: {
		[itemType: number]: true;
	};
	expandableItemType: number;
	disjoinParentFromChildren?: boolean;
}): {
	newCheckedItems: ICheckedItems;
	newSortedItemsArray: IDefaultItems[];
} => {
	const newCheckedItems = { ...checkedItems };
	const descendantItems: IMSelectWrapperProps["items"] = [
		...(hierarchy.childrenInfo[itemId] || []),
	];
	let newSortedItemsArray: IDefaultItems[] = [...sortedItemsArray];
	if (disjoinParentFromChildren) {
		return { newCheckedItems, newSortedItemsArray };
	}
	if (!descendantItems || !descendantItems.length)
		return {
			newCheckedItems: checkedItems,
			newSortedItemsArray,
		};
	for (const item of descendantItems) {
		if (!isChecked) {
			if (!newCheckedItems[item.type]) newCheckedItems[item.type] = {};
			if (
				!newCheckedItems[item.type][item.id] &&
				itemTypesToBeStored[item.type]
			)
				newSortedItemsArray.push({
					id: item.id,
					type: item.type,
				});
			newCheckedItems[item.type][item.id] = true;
		} else {
			delete (newCheckedItems[item.type] || {})[item.id];
			newSortedItemsArray = newSortedItemsArray.filter(
				el => !(el.id === item.id && el.type === item.type)
			);
		}
		if (item.type === expandableItemType && hierarchy.childrenInfo[item.id])
			descendantItems.push(...hierarchy.childrenInfo[item.id]);
	}
	return { newCheckedItems, newSortedItemsArray };
};

export const DeepMultipleSelectWithSearch: React.FC<IMSelectWrapperProps> = props => {
	const getDefaultCheckedItems = React.useMemo((): {
		items: ICheckedItems;
		newSortedItemsArray: IDefaultItems[];
	} => {
		let items: ICheckedItems = {};
		let newSortedItemsArray: IDefaultItems[] = [];
		for (const item of props.defaultSelectedItems || []) {
			if (!items[item.type]) items[item.type] = {};
			items[item.type][item.id] = true;
			const { newCheckedItems, affectedParentItems } = checkAncestors({
				item,
				isChecked: false,
				checkedItems: items,
				hierarchy: props.hierarchy,
				expandableItemType: props.expandableItemType,
				disjoinParentFromChildren: props.disjoinParentFromChildren,
			});
			items = {
				...items,
				...newCheckedItems,
			};
			if (props.itemTypesToBeStored[props.expandableItemType])
				newSortedItemsArray.push(...affectedParentItems);
			else if (props.itemTypesToBeStored[item.type])
				newSortedItemsArray.push(item);

			if (item.type === props.expandableItemType) {
				const obj = checkDescendantItems({
					itemId: item.id,
					isChecked: false,
					checkedItems: items,
					sortedItemsArray: newSortedItemsArray,
					hierarchy: props.hierarchy,
					itemTypesToBeStored: props.itemTypesToBeStored,
					expandableItemType: props.expandableItemType,
					disjoinParentFromChildren: props.disjoinParentFromChildren,
				});
				items = {
					...items,
					...obj.newCheckedItems,
				};
				newSortedItemsArray = obj.newSortedItemsArray;
			}
		}
		return {
			items,
			newSortedItemsArray,
		};
	}, [
		props.defaultSelectedItems,
		props.disjoinParentFromChildren,
		props.expandableItemType,
		props.hierarchy,
		props.itemTypesToBeStored,
	]);

	const { items, newSortedItemsArray } = getDefaultCheckedItems;
	const [sortedItemsArray, setItemsArray] = React.useState(
		newSortedItemsArray as IDefaultItems[]
	);

	const onItemsReorder = (sortedItems: IDefaultItems[]) => {
		const sortedItemsPure: IDefaultItems[] = sortedItems.map(el => ({
			id: el.id,
			type: el.type,
		}));
		setItemsArray(sortedItemsPure);
		if (props.onItemsReorder) props.onItemsReorder(sortedItemsPure);
	};

	const [searchQuery, setSearchQuery] = React.useState("");
	const [checkedItems, setCheckedItems] = React.useState(items);
	const [isSelectOpen, setIsSelectOpen] = React.useState(false);

	const openSelect = React.useCallback(() => {
		setIsSelectOpen(true);
	}, []);
	const closeSelect = React.useCallback(() => {
		setIsSelectOpen(false);
	}, []);
	const itemsObj = React.useMemo(() => {
		const items: IItemsObj = {};
		for (const item of props.items) {
			if (!items[item.type]) items[item.type] = {};
			items[item.type][item.id] = { ...item };
		}
		return items;
	}, [props.items]);

	const onSelectedItemsChange = props.onSelectedItemsChange;

	const changeCheckedItems = React.useCallback(
		(item: IMultipleSelectItem<string>) => {
			setCheckedItems(checkedItems => {
				const isChecked = !!(checkedItems[item.type] || {})[item.id];
				let newObj: ICheckedItems = {};
				const { newCheckedItems, affectedParentItems } = checkAncestors(
					{
						item,
						isChecked,
						checkedItems,
						hierarchy: props.hierarchy,
						expandableItemType: props.expandableItemType,
						disjoinParentFromChildren:
							props.disjoinParentFromChildren,
					}
				);
				newObj = {
					...checkedItems,
					...newCheckedItems,
				};

				let newSortedItemsArray: IDefaultItems[] = [
					...sortedItemsArray,
				];
				if (props.itemTypesToBeStored[props.expandableItemType]) {
					if (!isChecked)
						newSortedItemsArray.push(...affectedParentItems);
					else {
						for (const parentItem of affectedParentItems) {
							newSortedItemsArray = newSortedItemsArray.filter(
								el =>
									!(
										el.id === parentItem.id &&
										el.type === parentItem.type
									)
							);
						}
					}
				} else if (props.itemTypesToBeStored[item.type]) {
					if (!isChecked) {
						newSortedItemsArray.push({
							id: item.id,
							type: item.type,
						});
					} else {
						newSortedItemsArray = newSortedItemsArray.filter(
							el => !(el.id === item.id && el.type === item.type)
						);
					}
				}

				if (item.type === props.expandableItemType) {
					const obj = checkDescendantItems({
						itemId: item.id,
						isChecked,
						checkedItems: newObj,
						sortedItemsArray: newSortedItemsArray,
						hierarchy: props.hierarchy,
						itemTypesToBeStored: props.itemTypesToBeStored,
						expandableItemType: props.expandableItemType,
						disjoinParentFromChildren:
							props.disjoinParentFromChildren,
					});
					newObj = {
						...newObj,
						...obj.newCheckedItems,
					};
					newSortedItemsArray = obj.newSortedItemsArray;
				}

				setItemsArray(newSortedItemsArray);
				setTimeout(() => onSelectedItemsChange(newSortedItemsArray), 0);
				return newObj;
			});
		},
		[
			onSelectedItemsChange,
			props.disjoinParentFromChildren,
			props.expandableItemType,
			props.hierarchy,
			props.itemTypesToBeStored,
			sortedItemsArray,
		]
	);

	const filteredItemIds = React.useMemo(() => {
		if (!searchQuery) return undefined;
		const searched: ICheckedItems = {};
		for (const item of props.items) {
			if (item.name.indexOf(searchQuery) > -1) {
				let isMyAncesstorChecked = false;
				if ((checkedItems[item.type] || {})[item.id]) {
					continue;
				}

				let currentItemId = item.id;
				while (
					(props.hierarchy.parentInfo[item.type] || {})[currentItemId]
				) {
					if (
						(checkedItems[item.type] || {})[
							props.hierarchy.parentInfo[item.type][currentItemId]
						] === true
					) {
						isMyAncesstorChecked = true;
						break;
					}
					currentItemId =
						props.hierarchy.parentInfo[item.type][currentItemId];
				}

				if (!isMyAncesstorChecked) {
					if (!searched[item.type]) searched[item.type] = {};
					searched[item.type][item.id] = true;
				}
			}
		}
		return searched;
	}, [checkedItems, props.hierarchy.parentInfo, props.items, searchQuery]);

	const defaultOpenedItems = React.useMemo((): { [id: string]: boolean } => {
		const openedItems: { [id: string]: boolean } = {
			[props.rootId]: true,
		};
		for (const item of props.defaultSelectedItems || []) {
			if (item.type === props.expandableItemType)
				openedItems[item.id] = true;
			const parentInfo = props.hierarchy.parentInfo;
			let parentId = (parentInfo[item.type] || {})[item.id];
			while (parentId) {
				if (openedItems[parentId]) break;
				openedItems[parentId] = true;
				parentId = (parentInfo[props.expandableItemType] || {})[
					parentId
				];
			}
		}
		return openedItems;
	}, [
		props.defaultSelectedItems,
		props.expandableItemType,
		props.hierarchy.parentInfo,
		props.rootId,
	]);

	const checkedItemsForSearchBar = React.useMemo((): ICheckedItems => {
		if (!props.hideSelectedDescendantsFromSearchBar) return checkedItems;
		const newCheckedItems = { ...checkedItems };
		for (const itemType in newCheckedItems) {
			if (itemType !== props.expandableItemType + "") continue;
			const newChecked = { ...newCheckedItems[itemType] };
			for (const itemId in newCheckedItems[itemType]) {
				const parentInfo = props.hierarchy.parentInfo;
				const parentId = (parentInfo[itemType] || {})[itemId];
				if (!parentId) continue;
				if (newCheckedItems[itemType][parentId]) {
					delete newChecked[itemId];
				}
			}
			newCheckedItems[itemType] = newChecked;
		}
		return newCheckedItems;
	}, [
		checkedItems,
		props.expandableItemType,
		props.hideSelectedDescendantsFromSearchBar,
		props.hierarchy.parentInfo,
	]);

	const sortedItemsArrayWithoutHiddenElements = React.useMemo((): IDefaultItems[] => {
		const newArr: IDefaultItems[] = [];
		for (const item of sortedItemsArray) {
			if (
				!checkedItemsForSearchBar[item.type] ||
				!checkedItemsForSearchBar[item.type][item.id]
			) {
				continue;
			}
			newArr.push(item);
		}
		return newArr;
	}, [checkedItemsForSearchBar, sortedItemsArray]);

	return (
		<ClickAwayListener onClickAway={closeSelect}>
			<div className={multipleSelectStyles.multipleSelectContainer}>
				<div className={multipleSelectStyles.multipleSelectSearchBar}>
					<div
						className={multipleSelectStyles.searchItems}
						onClick={openSelect}
					>
						<SearchItems
							hierarchy={props.hierarchy}
							items={itemsObj}
							onChangeChekedItems={changeCheckedItems}
							searchQuery={searchQuery}
							setSearchQuery={setSearchQuery}
							expandableItemType={props.expandableItemType}
							itemTypesToBeStored={props.itemTypesToBeStored}
							iconsByType={props.iconsByType}
							sort={props.sort}
							onItemsReorder={onItemsReorder}
							sortedItemsArray={
								sortedItemsArrayWithoutHiddenElements
							}
							placeholder={props.placeholder}
							singleItemPerLine={props.singleItemPerLine}
						/>
					</div>
					<div className={multipleSelectStyles.searchIcon}>
						<SearchIcon />
					</div>
				</div>
				{isSelectOpen && (
					<div className={multipleSelectStyles.allContent}>
						<MultipleSelect
							hierarchy={props.hierarchy}
							items={props.items}
							checkedItems={checkedItems}
							onChangeChekedItems={changeCheckedItems}
							searchedObj={filteredItemIds}
							searchQuery={searchQuery}
							rootFolderId={props.rootId}
							defaultOpenedItems={defaultOpenedItems}
							expandableItemType={props.expandableItemType}
							iconsByType={props.iconsByType}
							hideSelectedItems={props.hideSelectedItems}
						/>
					</div>
				)}
			</div>
		</ClickAwayListener>
	);
};
interface ISearchItemsProps {
	hierarchy: IItemsHierarchy;
	items: IItemsObj;
	onChangeChekedItems: (item: IMultipleSelectItem<string>) => void;
	searchQuery: string;
	setSearchQuery: (searchQuery: string) => void;
	expandableItemType: number;
	itemTypesToBeStored: { [itemType: number]: true };
	iconsByType?: { [itemType: number]: JSX.Element };
	sort?: boolean;
	onItemsReorder?: (items: IDefaultItems[]) => void;
	sortedItemsArray: IDefaultItems[];
	placeholder: string;
	singleItemPerLine?: boolean;
}
const SearchItems: React.FC<ISearchItemsProps> = props => {
	const elements: any[] = [];

	const inputRef = React.useRef<HTMLInputElement>(null);
	const inputRefFunc = React.useCallback((e: HTMLInputElement | null) => {
		(inputRef as any).current = e;
	}, []);

	let index = -1;

	const checkedItemObjs: IMultipleSelectItem<string>[] = [];

	for (const item of props.sortedItemsArray) {
		if (!props.itemTypesToBeStored[item.type]) continue;
		const itemObj: IMultipleSelectItem<string> = (props.items[item.type] ||
			{})[item.id];
		if (!itemObj) continue;
		index++;
		checkedItemObjs.push(itemObj);
		elements.push(
			<ItemElement
				key={index}
				item={itemObj}
				onCheckedItemsChange={props.onChangeChekedItems}
				iconsByType={props.iconsByType}
				onWholeLine={props.singleItemPerLine}
			/>
		);
	}

	return (
		<div
			onClick={e => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			}}
		>
			{props.sort && props.onItemsReorder ? (
				<ItemsContainer
					items={checkedItemObjs}
					onItemsReorder={props.onItemsReorder}
					onCheckedItemsChange={props.onChangeChekedItems}
					iconsByType={props.iconsByType}
					singleItemPerLine={props.singleItemPerLine}
				/>
			) : (
				elements
			)}
			{props.sort && <br />}
			<AutosizeInput
				inputRef={inputRefFunc}
				inputClassName={multipleSelectStyles.input}
				value={props.searchQuery}
				onChange={e => props.setSearchQuery(e.target.value)}
				// onKeyDown={onKeyDown}
				placeholder={props.placeholder}
			/>
		</div>
	);
};

const isTopParents = (
	hierarchy: IItemsHierarchy,
	item: IMultipleSelectItem<string>
): boolean => {
	return !(hierarchy.parentInfo[item.type] || {})[item.id];
};

interface IMultipleSelectProps {
	hierarchy: IItemsHierarchy;
	items: IMultipleSelectItem<string>[];
	checkedItems: ICheckedItems;
	onChangeChekedItems: (item: IMultipleSelectItem<string>) => void;
	searchedObj?: ICheckedItems;
	searchQuery: string;
	rootFolderId: ObjectId;
	defaultOpenedItems: { [id: string]: boolean };
	expandableItemType: number;
	iconsByType?: { [itemType: number]: JSX.Element };
	hideSelectedItems: boolean;
}
interface ItemsObj {
	[itemId: string]: IMultipleSelectItem<string>;
}

const MultipleSelect: React.FC<IMultipleSelectProps> = props => {
	const [openedItems, setOpenedItems] = React.useState(
		props.defaultOpenedItems
	);

	const changeOpenItems = React.useCallback(
		(item: IMultipleSelectItem<string>) => {
			setOpenedItems(openedItems => ({
				...openedItems,
				[item.id]: !openedItems[item.id],
			}));
		},
		[]
	);
	const itemsArray: any[] = [];
	for (let i = 0; i < props.items.length; i++) {
		const item = props.items[i];
		let displayItem = false;
		if (!props.searchedObj) {
			displayItem = item && isTopParents(props.hierarchy, item);
		} else {
			displayItem = !!(props.searchedObj[item.type] || {})[item.id];
		}
		if (displayItem) {
			itemsArray.push(
				<div key={i} className={multipleSelectStyles.topParent}>
					<SingleItem
						hierarchy={props.hierarchy}
						item={item}
						openedItems={openedItems}
						onItemOpenChange={changeOpenItems}
						onChangeChekedItems={props.onChangeChekedItems}
						checkedItems={props.checkedItems}
						searchQuery={props.searchQuery}
						expandableItemType={props.expandableItemType}
						iconsByType={props.iconsByType}
						hideSelectedItems={props.hideSelectedItems}
					/>
				</div>
			);
		}
	}

	if (itemsArray.length === 0) {
		return (
			<div style={{ width: "100%", textAlign: "center", color: "#777" }}>
				{props.searchQuery ? "არაფერი მოიძებნა" : "ყველაფერი არჩეულია"}
			</div>
		);
	}
	return <div style={{ width: "100%" }}>{itemsArray}</div>;
};

interface ITopSingleItemProps {
	hierarchy: IItemsHierarchy;
	item: IMultipleSelectItem<string>;
	openedItems: { [id: string]: boolean };
	onItemOpenChange: (item: IMultipleSelectItem<string>) => void;
	checkedItems: ICheckedItems;
	onChangeChekedItems: (item: IMultipleSelectItem<string>) => void;
	searchQuery: string;
	expandableItemType: number;
	iconsByType?: { [itemType: number]: JSX.Element };
	hideSelectedItems: boolean;
}

const SingleItem: React.FC<ITopSingleItemProps> = props => {
	const { onItemOpenChange, onChangeChekedItems } = props;
	const onChangeOpen = React.useCallback(() => {
		onItemOpenChange(props.item);
	}, [onItemOpenChange, props.item]);
	const onChangeCheck = React.useCallback(() => {
		onChangeChekedItems(props.item);
	}, [onChangeChekedItems, props.item]);
	const children =
		props.item.type === props.expandableItemType
			? props.hierarchy.childrenInfo[props.item.id]
			: undefined;
	const isOpened =
		props.item.type === props.expandableItemType &&
		!!props.openedItems[props.item.id];
	const isChecked = !!(props.checkedItems[props.item.type] || {})[
		props.item.id
	];
	if (props.hideSelectedItems && isChecked) {
		return null;
	}
	const classes = [multipleSelectStyles.checkBox];
	if (isChecked) {
		classes.push(multipleSelectStyles.isChecked);
	}

	return (
		<div>
			<div>
				<div className={multipleSelectStyles.parentAndBoxes}>
					<div
						onClick={onChangeCheck}
						className={classes.join(" ")}
						tabIndex={-1}
					/>
					{children &&
						children.length > 0 &&
						(isOpened ? (
							<UpArrowIcon
								onClick={onChangeOpen}
								className={multipleSelectStyles.openBox}
							/>
						) : (
							<DownArrowIcon
								onClick={onChangeOpen}
								className={multipleSelectStyles.openBox}
							/>
						))}
					<div className={multipleSelectStyles.parentsName}>
						{!!props.iconsByType &&
							props.iconsByType[props.item.type]}
						<ItemName
							searchQuery={props.searchQuery}
							name={props.item.name}
						/>
					</div>
				</div>
			</div>
			<div
				style={{ paddingLeft: 40 }}
				className={multipleSelectStyles.childrenName}
			>
				{!!children &&
					!!isOpened &&
					children.map((child, index) => (
						<SingleItem {...props} key={index} item={child} />
					))}
			</div>
		</div>
	);
};
interface IItemNameProps {
	name: string;
	searchQuery: string;
}
const ItemName: React.FC<IItemNameProps> = props => {
	const searchedIndex = props.name.indexOf(props.searchQuery);
	return (
		<div style={{ paddingTop: 5, marginLeft: 8 }}>
			<div style={{ display: "inline" }}>
				{props.name.slice(0, searchedIndex)}
			</div>
			<div style={{ backgroundColor: "yellow", display: "inline" }}>
				{props.name.slice(
					searchedIndex,
					searchedIndex + props.searchQuery.length
				)}
			</div>
			<div style={{ display: "inline" }}>
				{props.name.slice(searchedIndex + props.searchQuery.length)}
			</div>
		</div>
	);
};
