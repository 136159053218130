import { ITaskType } from "@app/api/task-types/helper-schemas";
import { IAPOSTCreateTaskType } from "@app/api/task-types/validators";
import Popup, { PopupContent } from "@app/components/widgets/Popup";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import TextField from "@material-ui/core/TextField";
import Photo from "@material-ui/icons/Collections";
import React from "react";
import { PrimaryButton } from "../../styles/buttons";
import FancyLoading from "../../widgets/fancy-loading";
import GalleryPopup from "../gallery";
import { InputStyle } from "./styles/components";
import { removeKeys } from "@app/utils/common";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormattedMessage } from "react-intl";
import { getFormattedMessage } from "@app/utils/locale";

interface IProps {
	courseId: string;
	id?: ObjectId;
	parentId?: ObjectId;
	onSuccess?: () => void;
}

interface IState {
	isGalleryOpened: boolean;
	taskType?: IAPOSTCreateTaskType | ITaskType;
	isLoading: boolean;
}

class TaskTypeEdit extends React.PureComponent<IProps, IState> {
	state: IState = {
		isGalleryOpened: false,
		isLoading: true,
	};

	private _TaskTypesController = inject("TaskTypesController");

	componentDidMount() {
		if (this.props.id) {
			this._TaskTypesController
				.getById(
					{
						_id: this.props.id,
						originalCourseId: this.props.courseId,
					},
					true
				)
				.then(taskType => {
					this.setState({ taskType, isLoading: false });
				});
		} else {
			this.setState({
				taskType: {
					name: "",
					originalCourseId: this.props.courseId,
				},
				isLoading: false,
			});
		}
	}

	openGallery = () => this.setState({ isGalleryOpened: true });
	closeGallery = () => this.setState({ isGalleryOpened: false });

	handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			taskType: {
				...this.state.taskType!,
				name: e.target.value,
			},
		});
	};

	handlePhotoChange = (photoPath: string) => {
		this.setState({
			taskType: {
				...this.state.taskType!,
				photo: photoPath,
			},
			isGalleryOpened: false,
		});
	};

	onSave = () => {
		this.setState({ isLoading: true });
		if (!this.props.id) {
			this._TaskTypesController
				.add({
					...this.state.taskType,
					parentId: this.props.parentId,
				} as IAPOSTCreateTaskType)
				.then(data => {
					if (this.props.onSuccess) this.props.onSuccess();
				})
				.catch(e => {
					console.error(e);
					alert("დაფიქსირდა შეცდომა");
					this.setState({ isLoading: false });
				});
		} else {
			this._TaskTypesController
				.update(removeKeys(this.state.taskType as ITaskType, "author"))
				.then(data => {
					if (this.props.onSuccess) this.props.onSuccess();
				})
				.catch(() => {
					alert("დაფიქსირდა შეცდომა");
					this.setState({ isLoading: false });
				});
		}
	};

	handleConsistsOfMultipleQuestionsChange = (e, checked: boolean) => {
		this.setState({
			taskType: {
				...this.state.taskType!,
				consistsOfMultipleQuestions: checked,
				approximateNumOfQuestionsPerText: !checked
					? null
					: this.state.taskType!.approximateNumOfQuestionsPerText,
			},
		});
	};

	handleApproximateNumOfQuestionsPerTextChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const value = +e.target.value || 1;
		this.setState({
			taskType: {
				...this.state.taskType!,
				consistsOfMultipleQuestions: true,
				approximateNumOfQuestionsPerText: value,
			},
		});
	};

	render() {
		const { taskType } = this.state;
		if (!taskType || this.state.isLoading) {
			return <FancyLoading />;
		}
		return (
			<>
				<div>
					<TextField
						label={getFormattedMessage("inputs.name")}
						defaultValue={taskType.name}
						placeholder={getFormattedMessage("inputs.name")}
						margin="normal"
						variant="outlined"
						className={InputStyle}
						onChange={this.handleNameChange}
					/>
					<PrimaryButton
						onClick={this.openGallery}
						style={{ verticalAlign: "middle" }}
					>
						<FormattedMessage id="admin:general.addPhoto" />
					</PrimaryButton>

					{taskType.photo && (
						<Photo style={{ verticalAlign: "middle" }} />
					)}
					<FormControlLabel
						control={
							<Checkbox
								checked={!!taskType.consistsOfMultipleQuestions}
								onChange={
									this.handleConsistsOfMultipleQuestionsChange
								}
							/>
						}
						label={getFormattedMessage(
							"admin:general.consistsOfMultipleQuestions"
						)}
					/>
					{taskType.consistsOfMultipleQuestions && (
						<TextField
							label={getFormattedMessage(
								"admin:general.approximateNumOfQuestionsPerText"
							)}
							defaultValue={
								taskType.approximateNumOfQuestionsPerText || ""
							}
							type="number"
							onChange={
								this
									.handleApproximateNumOfQuestionsPerTextChange
							}
							margin="dense"
							variant="outlined"
							className={InputStyle}
						/>
					)}

					<PrimaryButton
						onClick={this.onSave}
						style={{ display: "block" }}
					>
						<FormattedMessage id="save" />
					</PrimaryButton>
				</div>
				{this.state.isGalleryOpened && (
					<GalleryPopup
						courseId={this.props.courseId}
						onClose={this.closeGallery}
						onUpload={this.handlePhotoChange}
					/>
				)}
			</>
		);
	}
}

export const TaskTypeEditPopup: React.FC<Omit<IProps, "onSuccess"> & {
	onClose: () => void;
}> = props => {
	return (
		<Popup onClose={props.onClose}>
			<PopupContent>
				<TaskTypeEdit {...props} onSuccess={props.onClose} />
			</PopupContent>
		</Popup>
	);
};
