import * as React from "react";
import Header from "@app/components/Header";
import LoginPopup from "@app/components/login-popup";
import UserChatMain from "@app/components/users/chat";
import { chatStore } from "@chat-app/socket-functions";
import { connect, Provider } from "react-redux";
import { History, Path } from "history";
import { IRootState } from "@app/redux";
import { Redirect, Route } from "react-router-dom";
import { paramsToKey } from "./admin-route";
import { ParamsObserver } from "@pckgs/component-distributor/params-observer";

interface IWonProps {
	component: any;
	location: History["location"];
	path: Path;
	exact: boolean;
	excludedParamsForKey?: string | string[];
	hideHeader?: boolean;
	hideChat?: boolean;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IWonProps;

const UserRoute = ({
	component: Component,
	isAuthenticated,
	location,
	user,
	excludedParamsForKey,
	hideHeader,
	hideChat,
	...rest
}: IProps) => {
	return (
		<Route
			location={location}
			{...rest}
			render={props =>
				user.userData ? (
					<div
						key={paramsToKey(
							props.match.params,
							excludedParamsForKey
						)}
					>
						<ParamsObserver params={props.match.params} />
						<div className="user-page">
							{!hideHeader && <Header {...rest} {...props} />}
							<div className="user-page-without-header">
								<Component
									{...rest}
									{...props}
									{...props.match.params}
									userData={user.userData}
								/>
							</div>
						</div>
						{!hideChat && user.userData.murtskuId && (
							<Provider store={chatStore}>
								<UserChatMain
									userId={user.userData.murtskuId}
									userName={
										user.userData.username ||
										user.userData.firstname +
											" " +
											user.userData.lastname
									}
									courseId={props.match.params.courseId}
									courseAssistantName="ყოვლისმცოდნე დავითი"
								/>
							</Provider>
						)}
					</div>
				) : !isAuthenticated ? (
					<LoginPopup onClose={succ => window.location.reload()} />
				) : (
					<Redirect to="/" />
				)
			}
		/>
	);
};

const mapStateToProps = (state: IRootState) => ({
	isAuthenticated: !!state.user.userId,
	user: state.user,
});

export default connect<IStateProps, null, IWonProps>(mapStateToProps)(
	UserRoute
);
