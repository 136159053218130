import React, { useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import { ItemType } from "@app/api/folders/helper-schemas";
import { ObjectId } from "@app/utils/generics";
import { useBoolean } from "@app/hooks/general";
import { HierarchyItemType } from "@app/services/hierarchy-info/interfaces";
import Popup, { PopupContentWithClose } from "@app/components/widgets/Popup";
import { HierarchyMultipleSelect, getDefaultHierarchySelect } from ".";
import { PrimaryButton } from "@app/components/styles/buttons";
import { IDefaultItems } from "../deep-multiple-select-wth-search";
import { inject } from "@app/modules";
import { addLoader } from "@app/commonJavascript";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";

export const AddLabelsButtons = React.memo<{
	getItems: () => { type: ItemType; id: ObjectId }[];
	courseId: ObjectId;
}>(function AddLabelsButtons({ getItems, courseId }) {
	const {
		value: isLabelsPopupVisible,
		setTrue: showLabels,
		setFalse: hideLabels,
	} = useBoolean();
	const [type, setType] = useState<"add" | "remove">("add");
	const handleSubmit = (
		labelsByHierarchy: Record<HierarchyItemType, IDefaultItems[]>
	) => {
		const taskTypes = labelsByHierarchy[HierarchyItemType.taskType].map(
			e => e.id
		);
		const topics = labelsByHierarchy[HierarchyItemType.topic].map(
			e => e.id
		);
		if (!taskTypes.length && !topics.length) {
			hideLabels();
			return;
		}
		const items = getItems();
		const questions = items
			.filter(item => item.type === ItemType.question)
			.map(e => e.id);
		const texts = items
			.filter(item => item.type === ItemType.text)
			.map(e => e.id);
		const cards = items
			.filter(item => item.type === ItemType.card)
			.map(e => e.id);
		const promises: Promise<any>[] = [];
		const removeLoader = addLoader();
		const TaskTypesController = inject("TaskTypesController");
		const taskTypeFn =
			type === "add"
				? TaskTypesController.addItems
				: TaskTypesController.removeItems;
		for (const taskType of taskTypes) {
			promises.push(
				taskTypeFn({
					courseId,
					labelId: taskType,
					cards,
					questions,
					texts,
				})
			);
		}
		const TopicsController = inject("TopicsController");
		const topicFn =
			type === "add"
				? TopicsController.addItems
				: TopicsController.removeItems;
		for (const topic of topics) {
			promises.push(
				topicFn({
					courseId,
					labelId: topic,
					cards,
					questions,
					texts,
				})
			);
		}
		Promise.all(promises)
			.then(() => {
				removeLoader();
				hideLabels();
			})
			.catch(e => {
				console.error(e);
				removeLoader();
				openConfirmationPopup({
					text: "დაფიქსირდა შეცდომა. მიმართეთ თემურს",
				});
			});
	};
	return (
		<React.Fragment>
			<Button
				onClick={() => {
					showLabels();
					setType("add");
				}}
			>
				თემების/სავარჯიშოს ტიპების დამატება
			</Button>
			<Button
				onClick={() => {
					showLabels();
					setType("remove");
				}}
			>
				თ/ს ამოკლება
			</Button>
			{isLabelsPopupVisible && (
				<LabelsPopup
					courseId={courseId}
					hierarchiesToShow={[
						HierarchyItemType.taskType,
						HierarchyItemType.topic,
					]}
					onClose={hideLabels}
					onSubmit={handleSubmit}
					title={type === "add" ? "დამატება" : "ამოკლება"}
				/>
			)}
		</React.Fragment>
	);
});

export const LabelsPopup = React.memo<{
	courseId: ObjectId;
	hierarchiesToShow: HierarchyItemType[];
	onClose: () => void;
	onSubmit: (
		labelsByHierarchy: Record<HierarchyItemType, IDefaultItems[]>
	) => void;
	title?: string | JSX.Element;
}>(({ courseId, hierarchiesToShow, onClose, onSubmit, title }) => {
	const hierarchyMultipleSelectRef = useRef<HierarchyMultipleSelect>(null);
	const handleSubmit = () => {
		if (!hierarchyMultipleSelectRef.current) return;
		const selectedLabels = getDefaultHierarchySelect();
		for (const type of hierarchiesToShow) {
			const items = hierarchyMultipleSelectRef.current.getSelectedItemsByHierarchyType(
				type
			);
			selectedLabels[type] = items;
		}
		onSubmit(selectedLabels);
	};
	return (
		<Popup onClose={onClose}>
			<PopupContentWithClose onClose={onClose}>
				{title && <h2>{title}</h2>}
				<HierarchyMultipleSelect
					ref={hierarchyMultipleSelectRef}
					courseId={courseId}
					hierarchiesToShow={hierarchiesToShow}
					itemTypesToBeStored={{
						[HierarchyItemType.folder]: {
							[ItemType.folder]: true,
						},
					}}
					multipleSelectProps={{
						[HierarchyItemType.topic]: {
							disjoinParentFromChildren: true,
						},
						[HierarchyItemType.taskType]: {
							disjoinParentFromChildren: true,
						},
						[HierarchyItemType.folder]: {
							disjoinParentFromChildren: true,
						},
					}}
				/>
				<PrimaryButton onClick={handleSubmit}>დასტური</PrimaryButton>
			</PopupContentWithClose>
		</Popup>
	);
});
LabelsPopup.displayName = "LabelsPopup";
