import * as React from "react";
import CenturyDate from "./century";
import MilleniumDate from "./millenium";
import MillionDate from "./million";
import Select from "react-select";
import YearDate from "./year";
import { AnyComponent } from "../../../../utils/generics";
import {
	ICenturyDate,
	IDateProperty,
	IMilleniumDate,
	IMillionDate,
	IYearDate,
} from "../../../../schemas/cards/templates";

interface IInputForDate {
	val: IDateProperty;
	onChange: (val: IDateProperty) => void;
}

export default class InputForDate extends React.Component<IInputForDate> {
	dateOptions = [
		{ value: 1, label: "Million" },
		{ value: 2, label: "Millenium" },
		{ value: 3, label: "Century" },
		{ value: 4, label: "Year" },
	];

	getTypeOfDateValue = (date: IDateProperty) => {
		if ((date as IMillionDate).million !== undefined) {
			return {
				value: 1,
				label: "Million",
			};
		}
		if ((date as IMilleniumDate).millenium !== undefined) {
			return {
				value: 2,
				label: "Millenium",
			};
		}
		if ((date as ICenturyDate).century !== undefined) {
			return {
				value: 3,
				label: "century",
			};
		}
		if ((date as IYearDate).year !== undefined) {
			return {
				value: 4,
				label: "year",
			};
		}
		throw new Error("incorrect date object");
	};

	getTypeOfDate = (
		date: IDateProperty
	): AnyComponent<{
		date: IDateProperty;
		onChange: (newDate: IDateProperty) => void;
	}> => {
		const dateValue = this.getTypeOfDateValue(date).value;
		switch (dateValue) {
			case 1:
				return MillionDate;
			case 2:
				return MilleniumDate;
			case 3:
				return CenturyDate;
			case 4:
				return YearDate;
			default:
				throw new Error("incorrect date object");
		}
	};

	valueToMainKey = (num: number) => {
		switch (num) {
			case 1:
				return "million";
			case 2:
				return "millenium";
			case 3:
				return "century";
			case 4:
				return "year";
			default:
				throw new Error("incorrect date num");
		}
	};

	onDateTypeChange = (selectedDateType: { value: number; label: string }) => {
		const oldSelection = this.getTypeOfDateValue(this.props.val);
		if (oldSelection.value === 2 && selectedDateType.value === 3) {
			// millenium -> century
			this.props.onChange({
				century: (this.props.val as IMilleniumDate).millenium,
				half: (this.props.val as IMilleniumDate).half,
			});
			return;
		}
		if (oldSelection.value === 3 && selectedDateType.value === 2) {
			// century -> millenium
			this.props.onChange({
				millenium: (this.props.val as ICenturyDate).century,
				half: (this.props.val as ICenturyDate).half,
			});
			return;
		}
		const myKey = this.valueToMainKey(oldSelection.value);
		const newKey = this.valueToMainKey(selectedDateType.value);
		this.props.onChange({
			[newKey]: (this.props.val as any)[myKey],
		} as IDateProperty);
	};

	render() {
		const Comp = this.getTypeOfDate(this.props.val);

		return (
			<div
				style={{
					display: "inline-block",
					border: "2px solid #ccc",
					padding: 5,
					margin: 5,
					borderRadius: 4,
					verticalAlign: "top",
				}}
			>
				<Select
					value={this.getTypeOfDateValue(this.props.val)}
					options={this.dateOptions}
					onChange={this.onDateTypeChange}
				/>
				<Comp
					date={this.props.val as any}
					onChange={this.props.onChange}
				/>
			</div>
		);
	}
}
