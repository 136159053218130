import * as React from "react";
import CardTemplate from ".";
import { getUniqueId } from "../../../utils/common";
import { ICardTemplate } from "../../../schemas/cards/templates";

interface IProps {
	template?: ICardTemplate;
	onSave: (data: ReturnType<CardTemplate["getData"]>) => void;
}

class CardTemplateWrapper extends React.PureComponent<IProps> {
	tempRef: React.RefObject<CardTemplate> = React.createRef();

	generateUniqueIds = (numOfIds?: number) => {
		if (this.tempRef.current) {
			const component = this.tempRef.current;
			return getUniqueId(component.getCurrentIds(), numOfIds);
		}
		return getUniqueId([], numOfIds);
	};

	onSave = () => {
		if (!this.tempRef.current) return;
		this.props.onSave(this.tempRef.current.getData());
	};

	render() {
		return (
			<div>
				<CardTemplate
					defaultTemplate={this.props.template}
					ref={this.tempRef}
					generateUniqueIds={this.generateUniqueIds}
				/>
				<button onClick={this.onSave}>Save</button>
			</div>
		);
	}
}

export default CardTemplateWrapper;
