import * as React from "react";
import Close from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import { ILabel } from "../../../schemas/cards/templates";

interface ILabelProps {
	label: ILabel;
	onChange: (newProperty: ILabel) => void;
	onDelete: (id: number) => void;
}

class Label extends React.PureComponent<ILabelProps> {
	onDelete = () => {
		this.props.onDelete(this.props.label.id);
	};

	onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onChange({
			...this.props.label,
			name: e.target.value,
		});
	};

	render() {
		return (
			<div
				style={{
					position: "relative",
					width: 210,
					display: "inline-block",
					verticalAlign: "middle",
				}}
			>
				<TextField
					value={this.props.label.name}
					onChange={this.onChange}
				/>
				<Close
					onClick={this.onDelete}
					style={{
						cursor: "pointer",
						position: "absolute",
						right: 5,
						top: 5,
						color: "red",
					}}
				/>
			</div>
		);
	}
}

export default Label;
