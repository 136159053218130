import * as React from "react";
import AdminChatComponent from "./component";
import { chatStore } from "@chat-app/socket-functions";
import { connect, Provider } from "react-redux";
import { IRootState } from "@app/redux";
import { match } from "react-router";
import { ObjectId } from "@app/utils/generics";

interface IOwnProps {
	match: match<{ courseId: ObjectId }>;
}
type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps;

class AdminChat extends React.Component<IProps> {
	render() {
		return (
			<Provider store={chatStore}>
				<AdminChatComponent
					courseId={this.props.match.params.courseId}
				/>
			</Provider>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	userId: state.user.userData!.murtskuId,
});

export default connect<IStateProps, any, IOwnProps>(mapStateToProps)(AdminChat);
