import api from "@app/api";
import dotenv from "dotenv";
import { initializeConnection } from "@chat-app/socket-functions";
dotenv.config();

const socketURL =
	process.env.REACT_APP_CHAT_WEBSOCKET_URL || "http://127.0.0.1:8087";
const socketPath = process.env.REACT_APP_CHAT_WEBSOCKET_PATH;

export const handleExpiration = () => {
	api.users
		.getChatToken()
		.then(token => {
			socketConnection(token);
		})
		.catch(err => {
			console.log(err);
		});
};

export const handleValidationError = () => {
	// TODO: Handle invalid token
};

const isLocalhost = Boolean(
	window.location.hostname === "localhost" ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === "[::1]" ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
		)
);

const socketIsDisabled = isLocalhost;

export const socketConnection = token => {
	if (socketIsDisabled) {
		return;
	}
	try {
		initializeConnection({
			socketServer: socketURL,
			userToken: token,
			options: {
				onTokenExpired: handleExpiration,
				onInvalidToken: handleValidationError,
				path: socketPath,
			},
		});
	} catch (e) {
		console.log(e);
	}
};
