import {
	FileItemTypes,
	IFileSingleItem,
	IFileWithFullContent,
} from "@app/api/files/helper-schemas";
import CancelIcon from "@material-ui/icons/CancelRounded";
import { css } from "emotion";
import React from "react";
import { FileText } from "./text";
import {
	FileQuestionsContainer,
	FileCardsContainer,
} from "./questions-and-cards";
import { ContentAddArg } from ".";
import { FileTabs } from "./tabs";

interface FileItemProps {
	item: IFileSingleItem;
	index: number;
	onChange: (item: IFileSingleItem, index: number) => void;
	onDelete: (index: number) => void;
	itemNames: IFileWithFullContent["itemNames"];
	onGalleryOpen: (onSuccess: (path: string) => void) => void;
	onContentAddOpen: (arg: ContentAddArg) => void;
}

export class FileSingleItem extends React.PureComponent<FileItemProps> {
	handleDelete = () => {
		this.props.onDelete(this.props.index);
	};

	handleItemChange = (item: IFileSingleItem) => {
		this.props.onChange(item, this.props.index);
	};

	render() {
		const { item, itemNames } = this.props;

		return (
			<div className={ItemContainer}>
				<div>
					{item.type === FileItemTypes.text && (
						<FileText
							item={item}
							onChange={this.handleItemChange}
							onGalleryOpen={this.props.onGalleryOpen}
						/>
					)}
					{item.type === FileItemTypes.questions && (
						<FileQuestionsContainer
							item={item}
							onChange={this.handleItemChange}
							itemNames={itemNames}
							onContentAddOpen={this.props.onContentAddOpen}
						/>
					)}
					{item.type === FileItemTypes.cards && (
						<FileCardsContainer
							item={item}
							onChange={this.handleItemChange}
							itemNames={itemNames}
							onContentAddOpen={this.props.onContentAddOpen}
						/>
					)}
					{item.type === FileItemTypes.tabs && (
						<FileTabs
							item={item}
							onChange={this.handleItemChange}
							onGalleryOpen={this.props.onGalleryOpen}
							onContentAddOpen={this.props.onContentAddOpen}
							itemNames={itemNames}
						/>
					)}
				</div>
				<CancelIcon
					className={CancelIconStyles}
					onClick={this.handleDelete}
				/>
			</div>
		);
	}
}

const ItemContainer = css`
	position: relative;
	margin-bottom: 40px;
`;

const CancelIconStyles = css`
	position: absolute;
	right: -11px;
	top: -11px;
	color: #c0c0c0;
	cursor: pointer;
	&:hover {
		color: grey;
	}
`;
