import Joi from "@app/utils/joi";
import { ObjectId, IAnyObj } from "@app/utils/generics";

export const MainAdminPermissionsSchema = Joi.object({
	isAdmin: Joi.boolean()
		.valid(true)
		.required(),
	isMainAdmin: Joi.boolean()
		.valid(true)
		.required(),
});
export interface IMainAdminPermissions {
	isAdmin: true;
	isMainAdmin: true;
}

export const CourseAdminPermissionsSchema = Joi.object({
	isAdmin: Joi.boolean()
		.valid(true)
		.required(),
	isMainAdmin: Joi.boolean().valid(false),
	courseIds: Joi.array()
		.items(Joi.objectId())
		.required(),
});
export interface ICourseAdminPermissions {
	isAdmin: true;
	courseIds: ObjectId[];
	isMainAdmin?: false;
}

export const StudentPermissionsSchema = Joi.object({
	isAdmin: Joi.boolean().valid(false),
	isMainAdmin: Joi.boolean().valid(false),
	availableCourses: Joi.array()
		.items(Joi.objectId())
		.required(),
}).allow(null);
export type IStudentPermissions =
	| ({
			availableCourses: ObjectId[];
	  } & {
			isAdmin?: false;
			isMainAdmin?: false;
	  })
	| null;

export const UserPermissionsSchema = Joi.alternatives([
	MainAdminPermissionsSchema,
	CourseAdminPermissionsSchema,
	StudentPermissionsSchema,
]);
export type IUserPermissions =
	| IMainAdminPermissions
	| ICourseAdminPermissions
	| IStudentPermissions
	| null;

export const AdminPermissionsSchema = Joi.alternatives([
	MainAdminPermissionsSchema,
	CourseAdminPermissionsSchema,
]);
export type IAdminPermissions = IMainAdminPermissions | ICourseAdminPermissions;

export const RUserSchema = Joi.object({
	id: Joi.number()
		.integer()
		.required(),
	murtskuId: Joi.number()
		.integer()
		.allow(null)
		.required(),
	mobile: Joi.string()
		.allow(null)
		.required(),
	mail: Joi.string()
		.allow(null)
		.allow(""),
	username: Joi.string(),
	firstname: Joi.string().allow(null),
	lastname: Joi.string().allow(null),
	permissions: UserPermissionsSchema.allow(null),
});
export interface IRUser {
	id: number;
	murtskuId: number | null;
	mobile: string | null;
	mail?: string | null;
	username?: string;
	firstname?: string | null;
	lastname?: string | null;
	permissions?: IUserPermissions | null;
}

export const UserEssentialsSchema = Joi.object({
	userData: RUserSchema.required(),
	extraInfo: Joi.object(),
});
export interface IUserEssentials {
	userData: IRUser;
	extraInfo?: IAnyObj;
}
