import React from "react";

const TopicIcon = props => (
	<svg viewBox="0 0 32 32" {...props}>
		<path
			d="M15 8h-4v21h15V8h-.505A1.494 1.494 0 0124 6.5c0-.828.677-1.5 1.495-1.5H26V4H8.493A2.49 2.49 0 006 6.5V26c0 1.657 1.343 3 2.994 3H10V8H8.493A1.496 1.496 0 017 6.5C7 5.666 7.669 5 8.493 5H23.5s-.5.737-.5 1.474C23 7.21 23.5 8 23.5 8H21v12l-3-3-3 3V8zm1 0v9.6l2-2 2 2V8h-4z"
			fill="currentColor"
		/>
	</svg>
);

export default TopicIcon;
