import { Appeal } from "@app/models/appeal";
import { inject } from "@app/modules";
import { IRequest } from "../requests";
import {
	IAPOSTAppeal,
	IRPOSTAppeal,
	RPOSTAppealSchema,
	IRGETAppealCounts,
	RGETAppealCountsSchema,
	IAGETAppealsByCourse,
	IRGETAppealsByCourse,
	RGETAppealsByCourseSchema,
	IADELETEAppeal,
	IADELETEManyAppeals,
} from "./validators";

export class AppealsController {
	private readonly Request: IRequest;

	private readonly _AppealModel = inject("AppealModel");

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTAppeal): Promise<Appeal> =>
		this.Request.send("POST", "/api/appeals", args, null, {
			responseSchema: RPOSTAppealSchema,
		}).then((data: IRPOSTAppeal) => {
			return this._AppealModel.loadOneSync(data);
		});

	getAppealCounts = async (): Promise<IRGETAppealCounts> =>
		this.Request.send("GET", "/api/appeals", undefined, null, {
			responseSchema: RGETAppealCountsSchema,
		});

	getCourseAppeals = async (
		args: IAGETAppealsByCourse
	): Promise<IRGETAppealsByCourse> =>
		this.Request.send("GET", "/api/appeals/courses/:courseId", args, null, {
			responseSchema: RGETAppealsByCourseSchema,
		});

	delete = async (args: IADELETEAppeal): Promise<void> =>
		this.Request.send("DELETE", "/api/appeals/:_id", args).then(() => {
			this._AppealModel.deleteByIdSync(args._id);
		});

	deleteMany = async (args: IADELETEManyAppeals): Promise<void> =>
		this.Request.send("DELETE", "/api/appeals", args).then(() => {
			this._AppealModel.deleteManyByIdsSync(args.ids);
		});
}
