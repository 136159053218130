import * as React from "react";
import memoizeOne from "memoize-one";
import Select from "react-select";
import { ICardTemplate } from "../../../schemas/cards/templates";

interface ICardMainTemplate {
	_id: ICardTemplate["_id"];
	name: ICardTemplate["name"];
}

interface IChooseCardTemplateProps {
	templates: ICardMainTemplate[];
	selectedId?: ICardMainTemplate["_id"];
	onChange: (_id: ICardMainTemplate["_id"]) => void;
}

export default class ChooseCardTemplate extends React.PureComponent<
	IChooseCardTemplateProps
> {
	getOptions = memoizeOne(
		(templates: IChooseCardTemplateProps["templates"]) => {
			return templates.map(t => ({
				value: t._id,
				label: t.name,
			}));
		}
	);

	onChange = (select: { value: any; label: any }) => {
		this.props.onChange(select.value);
	};
	render() {
		const options = this.getOptions(this.props.templates);
		const selectedOption =
			this.props.selectedId === undefined
				? undefined
				: options.find(e => e.value === this.props.selectedId);
		return (
			<div>
				<Select
					value={selectedOption}
					onChange={this.onChange}
					options={options}
					placeholder={"Choose template"}
				/>
			</div>
		);
	}
}
