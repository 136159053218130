import * as React from "react";
import * as ReactDOM from "react-dom";
import LoginPopup from "@app/components/login-popup";
import { Provider } from "react-redux";
import { store } from "index";

const successCallbacks: Function[] = [];
const rejectionCallbacks: Function[] = [];

export function requireLoginForActionPromise() {
	return new Promise((resolve, reject) => {
		requireLoginForAction(resolve, reject);
	});
}

export default function requireLoginForAction(
	callback: Function,
	rejectionCallback?: Function
) {
	if (localStorage.credentials && localStorage.user) {
		// already logged in
		callback();
	} else {
		successCallbacks.push(callback);
		if (rejectionCallback) rejectionCallbacks.push(rejectionCallback);
		if (successCallbacks.length > 1) {
			// login popup is already displayed
			return;
		}
		const theParent = document.getElementById("playground");
		const popupContainer = document.createElement("div");
		theParent!.appendChild(popupContainer);
		ReactDOM.render(
			<Provider store={store}>
				<LoginPopup
					onClose={loggedInSuccessfully => {
						popupContainer.remove();
						ReactDOM.unmountComponentAtNode(popupContainer);
						if (!loggedInSuccessfully) rejectCallbacks();
					}}
					onSuccess={resolveCallbacks}
				/>
			</Provider>,
			popupContainer
		);
	}
}

function resolveCallbacks() {
	cleanArray(rejectionCallbacks);
	successCallbacks.forEach((callback, i) => {
		delete successCallbacks[i];
		callback();
	});
	cleanArray(successCallbacks);
}
function rejectCallbacks() {
	cleanArray(successCallbacks);
	rejectionCallbacks.forEach((callback, i) => {
		delete rejectionCallbacks[i];
		callback();
	});
	cleanArray(rejectionCallbacks);
}

function cleanArray(arr: any[]) {
	while (arr.length) arr.pop();
}
