/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-restricted-globals */
import * as React from "react";
import AdminTable from "../widgets/admin-table";
import { History } from "history";
import { ObjectId } from "@app/utils/generics";
import { PrimaryButton } from "../styles/buttons";
import { inject } from "@app/modules";
import { Course } from "@app/models/course";
import { FormattedMessage } from "react-intl";
import FancyLoading from "../widgets/fancy-loading";
import { IRUser } from "@app/api/users/helper-schemas";
import Chat from "@material-ui/icons/Chat";
import FilterIcon from "@material-ui/icons/FilterList";
import LineIcon from "@material-ui/icons/LineStyle";
import { ConnectWithHooks } from "@app/hooks/connect-with-hooks";
import { useMyCourses } from "@app/hooks/courses";
import { IResourceLoadingInfo } from "@app/hooks/fetch";

interface IProps {
	history: History;
	userData: IRUser;
	courses: IResourceLoadingInfo<Course[]>;
}

class AllCourses extends React.Component<IProps> {
	_isMounted = false;

	_CoursesController = inject("CoursesController");

	componentDidMount() {
		this._isMounted = true;
	}

	addCourse = () => this.props.history.push(`/admin/courses/add`);

	redirectTo = (id: ObjectId) =>
		this.props.history.push(`/admin/courses/${id}/edit`);

	redirectToChat = (id: ObjectId) =>
		this.props.history.push(`/admin/courses/${id}/chat`);

	onDelete = (id: ObjectId) => {
		if (confirm("Are you sure?") && confirm("Are you really sure?")) {
			this._CoursesController.deleteById({ _id: id }).then();
		}
	};

	redirectToFilter = (id: ObjectId) => {
		this.props.history.push(`/admin/courses/${id}/filter`);
	};

	redirectToRootFolder = (_id: ObjectId) => {
		const course = this.props.courses.doc?.find(e => e._id === _id);
		if (!course) return;
		const rootFolderId = course.rootFolder;
		this.props.history.push(
			`/admin/courses/${_id}/folders/${rootFolderId}`
		);
	};

	redirectToTopicsPage = (courseId: ObjectId) => {
		this.props.history.push(`/admin/courses/${courseId}/topics`);
	};

	additionalComponents: React.ComponentType<{
		rowId: ObjectId;
		iconClassName: string;
	}> = ({ rowId, iconClassName }) => {
		return (
			<>
				<Chat
					className={iconClassName}
					onClick={() => this.redirectToChat(rowId)}
				/>
				<FilterIcon
					className={iconClassName}
					onClick={() => this.redirectToFilter(rowId)}
				/>
				<LineIcon
					className={iconClassName}
					onClick={() => this.redirectToTopicsPage(rowId)}
				/>
			</>
		);
	};

	render() {
		if (!this.props.courses.doc) {
			return <FancyLoading />;
		}

		const courses = this.props.courses.doc;
		return (
			<div className="main">
				<AdminTable
					rows={courses}
					redirectTo={this.redirectTo}
					onDelete={this.onDelete}
					onView={this.redirectToRootFolder}
					additionalComponents={this.additionalComponents}
				/>
				<PrimaryButton onClick={this.addCourse}>
					<FormattedMessage id="admin:general.createCourse" />
				</PrimaryButton>
			</div>
		);
	}
}

export default ConnectWithHooks(AllCourses, {
	courses: () => useMyCourses(),
});
