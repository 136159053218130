/* eslint-disable no-loop-func */
import { MError } from "../../utils/errors";
import {
	HierarchyItemType,
	IParentInfoModel,
	IParentInfoInstance,
	IItemInstance,
	IParentInfo,
	IClonnableItemModel,
} from "./interfaces";
import HierarchyInfoService from "./base";
import { ObjectId } from "@app/utils/generics";
import { ICourseModel } from "@app/models/course";

export interface IUpdatedIds {
	[originalId: string]: ObjectId | undefined;
}

export default class ClonnableHierarchyInfoService extends HierarchyInfoService {
	private readonly clonnableItemModel: IClonnableItemModel;

	constructor(
		parentInfoModel: IParentInfoModel,
		itemModel: IClonnableItemModel,
		courseModel: ICourseModel,
		itemType: HierarchyItemType
	) {
		super(parentInfoModel, itemModel, courseModel, itemType);
		this.clonnableItemModel = itemModel;
	}

	public setItemParentSync(
		courseId: ObjectId,
		itemId: ObjectId,
		parentId: ObjectId,
		hierarchyOfThisItem: IParentInfo
	): void {
		const oldHierarchyInfo = this.getHierarchyInfoObjectSync(courseId);

		const item = this.itemModel.findByIdSync(itemId);
		if (!item) {
			throw new MError(404, `item with id "${itemId}" not found`);
		} else if (itemId === oldHierarchyInfo.rootId) {
			throw new Error("root item cannot be a subfolder");
		}
		const oldParentId = oldHierarchyInfo.parentInfo[itemId];

		let updatedIds: IUpdatedIds = {};
		if (this.needsCloningSync(courseId, itemId, item as IItemInstance)) {
			const newParentUpdatedIds = this.cloneHierarchySync(
				courseId,
				parentId
			);
			if (oldParentId) {
				const updatedOldParentId =
					newParentUpdatedIds[oldParentId] || oldParentId;
				if (this.needsCloningSync(courseId, updatedOldParentId)) {
					const oldParentUpdatedIds = this.cloneHierarchySync(
						courseId,
						updatedOldParentId
					);
					updatedIds = {
						...newParentUpdatedIds,
						...oldParentUpdatedIds,
					};
				}
			}
		}

		// get updated ids of possibly cloned items
		const updatedItemId = updatedIds[itemId] || itemId;
		const updatedParentId = updatedIds[parentId] || parentId;

		// update course hierarchy info document
		const doc = this.getCourseInfoDocSync(courseId);
		doc.parentInfo = {
			...doc.parentInfo,
			...hierarchyOfThisItem,
			[updatedItemId]: updatedParentId,
		};
		doc.saveSync();
	}

	public onItemDeleteSync(courseId: ObjectId, itemId: ObjectId): void {
		const currentHierarchyInfo = this.getHierarchyInfoObjectSync(courseId);

		const item = this.itemModel.findByIdSync(itemId);
		if (!item) {
			throw new MError(404, `item with id "${itemId}" not found`);
		} else if (itemId === currentHierarchyInfo.rootId) {
			throw new Error("cannot delete root item");
		}
		const stringifiedItemId = itemId;
		// const parentId = currentHierarchyInfo.parentInfo[stringifiedItemId];
		const currentChildrenIds =
			currentHierarchyInfo.childrenInfo[stringifiedItemId];

		let updatedIds: IUpdatedIds = {};
		const needsCloning = this.needsCloningSync(
			courseId,
			itemId,
			item as IItemInstance
		);
		if (needsCloning && item.originalCourseId !== courseId) {
			// clone all items from current parent to the top
			const itemToCloneUntil =
				currentHierarchyInfo.parentInfo[stringifiedItemId];
			updatedIds = this.cloneHierarchySync(courseId, itemToCloneUntil);
		}

		const doc = this.getCourseInfoDocSync(courseId);
		// const updatedParentId = updatedIds[parentId] || parentId;

		// children of the item will be removed
		currentChildrenIds.forEach(id => {
			const newId = updatedIds[id] || id;
			delete doc.parentInfo[newId];
		});
		delete doc.parentInfo[stringifiedItemId];
		doc.parentInfo = { ...doc.parentInfo };
		doc.saveSync();
	}

	public needsCloningSync(
		courseId: ObjectId,
		itemId: ObjectId,
		item?: IItemInstance
	): boolean {
		return false;
	}

	public filterItemsToCloneSync(
		courseId: ObjectId,
		itemIds: ObjectId[]
	): ObjectId[] {
		return [];
	}

	public cloneHierarchySync(
		courseId: ObjectId,
		bottomItemId: ObjectId,
		doc?: IParentInfoInstance
	): IUpdatedIds {
		if (!doc) {
			doc = this.getCourseInfoDocSync(courseId);
		} else if (doc.courseId !== courseId) {
			throw new Error(
				`invalid course parent info document for course "${courseId}"`
			);
		}
		if (doc.rootId !== bottomItemId && !doc.parentInfo[bottomItemId]) {
			throw new Error(
				`item with id "${bottomItemId}" does not belong to course "${courseId}"`
			);
		}

		// ids of items to clone
		const ids = [
			bottomItemId,
			...this.getAncestorIdsSync(courseId, bottomItemId, doc.parentInfo),
		];
		return this.createItemClonesSync(courseId, ids, doc);
	}

	public createItemClonesSync(
		courseId: ObjectId,
		itemIds: ObjectId[],
		doc?: IParentInfoInstance
	) {
		return {} as IUpdatedIds;
	}

	public cloneHierarchyForSeveralItemsSync(
		courseId: ObjectId,
		itemIds: ObjectId[],
		doc?: IParentInfoInstance
	): IUpdatedIds {
		if (!doc) {
			doc = this.getCourseInfoDocSync(courseId);
		} else if (doc.courseId !== courseId) {
			throw new Error(
				`invalid course parent info document for course "${courseId}"`
			);
		}

		const allAncestors = this.getMultipleItemAncestorIdsSync(
			courseId,
			itemIds,
			doc.parentInfo
		);
		return this.createItemClonesSync(courseId, allAncestors, doc);
	}
}
