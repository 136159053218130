import { css } from "emotion";

export const SingleSelect = css`
	width: 400px;
	max-width: 95%;
	color: grey;
	font-size: 16px;
	font-family: BPGNinoMtavruli;
	display: inline-block;
	padding: 10px 0px;
	z-index: 2;
`;

export const smallSelect = css`
	width: 25%;
	border: 1px solid black;
	border-radius: 5px;
	background-color: white;
	color: black;
	font-family: BGPNinoMtavruli;
	display: inline-block;
	margin: 10px;
	padding: 10px 5px;
	font-size: 16px;
`;
