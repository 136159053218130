import styled from "react-emotion";
import ArrowIcon from "@material-ui/icons/ArrowBackRounded";
import * as React from "react";

export const PrimaryButton = styled("button")(
	{
		minWidth: 200,
		height: 40,
		margin: "20px 0px",
		fontSize: 18,
		fontFamily: "BPGNinoMtavruli",
		color: "white",
		backgroundColor: "#0077dd",
		borderRadius: 7,
		border: "none",
		outline: "none",
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "#0d62ab",
		},
	},
	(props: { isFaded?: boolean }) => {
		if (props.isFaded) return { opacity: 0.2 };
		return;
	}
);

interface IPButtonWArrowProps {
	onClick: () => any;
}

export const PrimaryButtonWithArrow: React.FC<IPButtonWArrowProps> = (
	props: IPButtonWArrowProps
) => (
	<PrimaryButton onClick={props.onClick}>
		<ArrowIcon style={{ verticalAlign: "middle", marginRight: 10 }} />
		<div
			style={{
				display: "inline-block",
				verticalAlign: "middle",
			}}
		>
			უკან დაბრუნება
		</div>
	</PrimaryButton>
);
