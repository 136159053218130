import { ItemType } from "@app/api/folders/helper-schemas";
import { IRTestType } from "@app/api/test-types/helper-schemas";
import { IRTest } from "@app/api/tests/helper-schemas";
import { reactSelectStyles } from "@app/commonJavascript";
import { PrimaryButton } from "@app/components/styles/buttons";
import { InputStyle } from "@app/components/styles/inputs";
import { IRootState } from "@app/redux";
import { HierarchyItemType } from "@app/services/hierarchy-info/interfaces";
import { ObjectId } from "@app/utils/generics";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import memoizeOne from "memoize-one";
import * as React from "react";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import Select from "react-select";
import { inject } from "../../../../modules";
import FancyLoading from "../../../widgets/fancy-loading";
import Popup, { PopupContentWithClose } from "../../../widgets/Popup";
import { HierarchyMultipleSelect } from "../../hierarchy-multiple-select";
import TestTypeSettings from "../test-type-settings";
import { getFormattedMessage } from "@app/utils/locale";
import { FormattedMessage } from "react-intl";
import { removeKeys } from "@app/utils/common";

export interface IAllowedItemTypes {
	[type: number]: boolean;
}

interface IOwnProps {
	onClose: () => void;
	folderId: ObjectId;
	courseId: ObjectId;
	testId?: ObjectId;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IStateProps & IOwnProps;

interface ISelectedItem {
	id: string;
	type: ItemType.question | ItemType.card | ItemType.text;
}

interface IState {
	defaultSelectedItems?: ISelectedItem[];
	test?: IRTest;
	hasAtLeastOneSelectedItems: boolean;
}

class AdminTestPopup extends React.PureComponent<IProps, IState> {
	private id = this.props.testId;
	private _isMounted = false;

	private getTestTypeOptions = memoizeOne(
		(testTypes: IRootState["testTypes"]) => {
			const arr = [{ value: "", label: "არც ერთი" }];
			arr.push(
				...Object.keys(testTypes).map(id => ({
					value: testTypes[id]!.info._id,
					label: testTypes[id]!.info.name,
				}))
			);
			return arr;
		}
	);

	private _TestTypesController = inject("TestTypesController");
	private _TestsController = inject("TestsController");

	state: IState = {
		test: !this.props.testId
			? ({
					name: "",
					settings: {},
			  } as IRTest)
			: undefined,
		hasAtLeastOneSelectedItems: false,
	};

	private hierarchyMultipleSelectRef = React.createRef<
		HierarchyMultipleSelect
	>();

	private getSelections = (): ISelectedItem[] | undefined => {
		const ref = this.hierarchyMultipleSelectRef.current;
		if (!ref) return undefined;
		const selectedContent = ref.getSelectedItemsByHierarchyType(
			HierarchyItemType.folder
		);
		return selectedContent;
	};

	componentDidMount() {
		this._isMounted = true;
		this._TestTypesController.getAll().then(() => {
			if (this.id) {
				this._TestsController.getById({ _id: this.id }).then(test => {
					const selectedItems: ISelectedItem[] = [];
					if (test.settings && test.settings.contentIds) {
						selectedItems.push(...test.settings.contentIds);
					}
					this.setState({
						test: test,
						defaultSelectedItems: selectedItems,
						hasAtLeastOneSelectedItems: selectedItems.length > 0,
					});
				});
			}
		});
	}

	private onSave = () => {
		if (!this.state.test) return;
		const { author, ...test } = this.state.test;
		let { settings } = test;
		const contentIds = this.getSelections();
		if (!contentIds) return;
		settings = getObjectWithoutUndefinedValues({
			...removeKeys(
				settings || ({} as any),
				"contentIds",
				"isContentSorted"
			),
			contentIds: contentIds.length > 0 ? contentIds : undefined,
			isContentSorted:
				contentIds.length > 0 && settings
					? settings.isContentSorted
					: undefined,
		});
		const args = {
			...test,
			settings,
			testTypeId: test.testTypeId || undefined,
			courseId: this.props.courseId,
			folderId: this.props.folderId,
		};
		this.hierarchyMultipleSelectRef.current!.saveOptions();
		if (!this.props.testId) {
			this._TestsController.add(args).then(data => this.props.onClose());
		} else {
			this._TestsController
				.update(args)
				.then(data => this.props.onClose());
		}
	};

	private handleTestTypeChange = (sel: {
		value: ObjectId;
		label: string;
	}) => {
		if (!this.state.test) return;
		this.setState({
			test: {
				...this.state.test,
				testTypeId: sel.value,
				testTypeSettings:
					sel.value === ""
						? this.state.test.testTypeSettings
						: undefined,
			},
		});
	};

	private onTestTypeSettingsChange = (settings: IRTestType["settings"]) => {
		this.setState(({ test }) => {
			if (!test) return null;
			return {
				test: {
					...test,
					testTypeSettings: settings,
				},
			};
		});
	};

	private handleInputChange = (field: string) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		if (!this.state.test) return;
		this.setState({
			test: {
				...this.state.test,
				[field]: e.target.value,
			},
		});
	};

	private handleIsSortedChange = () => {
		if (
			!this.state.test ||
			!this.state.test.settings ||
			!(this.getSelections() || []).length
		) {
			return;
		}
		this.setState({
			test: {
				...this.state.test,
				settings: {
					...this.state.test.settings,
					isContentSorted: !this.state.test.settings.isContentSorted,
				},
			},
		});
	};

	private selectHierarchyItems = React.memo((props: { sort: boolean }) => {
		return (
			<HierarchyMultipleSelect
				ref={this.hierarchyMultipleSelectRef}
				courseId={this.props.courseId}
				hierarchiesToShow={[HierarchyItemType.folder]}
				itemTypesToBeStored={{
					[HierarchyItemType.folder]: {
						[ItemType.question]: true,
						[ItemType.card]: true,
						[ItemType.text]: true,
					},
				}}
				placeholders={{
					[HierarchyItemType.folder]: getFormattedMessage(
						"admin:testType.chooseContent"
					),
				}}
				defaultSelections={{
					[HierarchyItemType.folder]:
						this.state.defaultSelectedItems || [],
				}}
				multipleSelectProps={{
					[HierarchyItemType.folder]: {
						sort: props.sort,
						singleItemPerLine: true,
					},
				}}
				onChange={selection => {
					const selectedItems = selection[HierarchyItemType.folder];
					const hasAtLeastOneSelectedItems = selectedItems.length > 0;
					if (
						this.state.hasAtLeastOneSelectedItems !==
						hasAtLeastOneSelectedItems
					) {
						this.setState({
							hasAtLeastOneSelectedItems,
						});
					}
				}}
			/>
		);
	});

	render() {
		if (!this.state.test) {
			return (
				<Popup onClose={this.props.onClose}>
					<PopupContentWithClose onClose={this.props.onClose}>
						<FancyLoading />
					</PopupContentWithClose>
				</Popup>
			);
		}
		const options = this.getTestTypeOptions(this.props.testTypes);
		const selectedTestTypeOption = this.state.test.testTypeId
			? options.find(o => o.value === this.state.test!.testTypeId)
			: undefined;

		const isContentSorted = !!(
			this.state.test.settings && this.state.test.settings.isContentSorted
		);

		const SelectHierarchyItems = this.selectHierarchyItems;

		return (
			<Popup onClose={this.props.onClose}>
				<PopupContentWithClose onClose={this.props.onClose}>
					<div>
						<TextField
							label={getFormattedMessage("inputs.name")}
							value={this.state.test.name}
							onChange={this.handleInputChange("name")}
							margin="dense"
							variant="outlined"
							className={InputStyle}
						/>
					</div>
					<div>
						<TextField
							label={getFormattedMessage("inputs.description")}
							value={this.state.test.description || ""}
							onChange={this.handleInputChange("description")}
							margin="dense"
							variant="outlined"
							className={InputStyle}
						/>
					</div>
					<Select
						options={options}
						styles={reactSelectStyles}
						value={selectedTestTypeOption}
						onChange={this.handleTestTypeChange}
						placeholder={getFormattedMessage(
							"admin:general.chooseTestType"
						)}
					/>
					{!this.state.test.testTypeId && (
						<TestTypeSettings
							defaultSettings={this.state.test.testTypeSettings}
							onChange={this.onTestTypeSettingsChange}
						/>
					)}
					<br />
					<SelectHierarchyItems sort={isContentSorted} />
					{this.state.hasAtLeastOneSelectedItems && (
						<FormControlLabel
							control={
								<Checkbox
									checked={isContentSorted}
									onChange={this.handleIsSortedChange}
								/>
							}
							label={getFormattedMessage(
								"admin:testType.isContentSorted"
							)}
						/>
					)}
					<br />
					<PrimaryButton onClick={this.onSave}>
						<FormattedMessage id="save" />
					</PrimaryButton>
				</PopupContentWithClose>
			</Popup>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	testTypes: state.testTypes,
	tests: state.tests,
	folders: state.folders,
	courses: state.courses,
});

const getObjectWithoutUndefinedValues = <T extends {}>(obj: T): T => {
	const newObj = {} as T;
	for (const key in obj) {
		if (obj[key] !== undefined) {
			newObj[key] = obj[key];
		}
	}
	return newObj;
};

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	AdminTestPopup
);
