import React from "react";
import { IRGETCourseFilteredContent } from "@app/api/courses/validators";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditIcon from "@material-ui/icons/Edit";
import Portal from "@material-ui/core/Portal";
import { useBoolean } from "@app/hooks/general";
import QuestionPopup from "../questions/popup";
import TextPopup from "../questions/text-popup";
import { ObjectId } from "@app/utils/generics";
import { ItemType } from "@app/api/folders/helper-schemas";
import { inject } from "@app/modules";
import { useModelDocsByIds } from "m-model-react";
import TaskTypeIcon from "@material-ui/icons/AssignmentRounded";
import TopicIcon from "../folder/styles/img/topic";
import FolderIcon from "@material-ui/icons/Folder";
import {
	FolderIconSimple,
	TaskTypeIconSimple,
	TopicIconSimple,
} from "../folder/styles";
import styles from "./styles/filter.module.css";
import { FormattedMessage } from "react-intl";
import { successfulSaveArg } from "../types";

export const FilterResults: React.FC<{
	items: IRGETCourseFilteredContent["items"];
	courseId: ObjectId;
	onSuccessSave: (args: successfulSaveArg) => void;
}> = React.memo(({ items, courseId, onSuccessSave }) => {
	const container = React.useRef<HTMLDivElement>(null);
	return (
		<div>
			<div ref={container} />
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							<FormattedMessage id="admin:general.itemName" />
						</TableCell>
						<TableCell>
							<FormattedMessage id="admin:general.itemLabels" />
						</TableCell>
						<TableCell>
							<FormattedMessage id="admin:general.itemOptions" />
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map(item => {
						return (
							<TableItem
								key={item.type + "-" + item.id}
								item={item}
								popupContainerRef={container}
								courseId={courseId}
								onSuccessSave={onSuccessSave}
							/>
						);
					})}
				</TableBody>
			</Table>
		</div>
	);
});

const TableItem: React.FC<{
	courseId: ObjectId;
	item: IRGETCourseFilteredContent["items"][number];
	popupContainerRef: React.RefObject<HTMLDivElement>;
	onSuccessSave: (args: successfulSaveArg) => void;
}> = React.memo(({ courseId, item, popupContainerRef, onSuccessSave }) => {
	const {
		value: isEditPopupVisible,
		setTrue: showEditPopup,
		setFalse: hideEditPopup,
	} = useBoolean();
	return (
		<>
			<TableRow>
				<TableCell>{item.name}</TableCell>
				<TableCell>
					{item.folders && <FolderNames ids={item.folders} />}
					{item.topics && <TopicNames ids={item.topics} />}
					{item.taskTypes && <TaskTypeNames ids={item.taskTypes} />}
				</TableCell>
				<TableCell>
					<EditIcon
						onClick={showEditPopup}
						className={styles.pointer}
					/>
				</TableCell>
			</TableRow>
			{isEditPopupVisible && (
				<Portal container={popupContainerRef.current}>
					{item.type === ItemType.question && (
						<QuestionPopup
							onClose={hideEditPopup}
							courseId={courseId}
							id={item.id}
							onSuccessSave={onSuccessSave}
						/>
					)}
					{item.type === ItemType.text && (
						<TextPopup
							onClose={hideEditPopup}
							courseId={courseId}
							textId={item.id}
							onSuccessSave={onSuccessSave}
						/>
					)}
				</Portal>
			)}
		</>
	);
});

const FolderNames: React.FC<{ ids: ObjectId[] }> = React.memo(({ ids }) => {
	const folders = useModelDocsByIds(inject("FolderModel"), ids);
	if (!folders || folders.length === 0) return null;
	return (
		<div>
			<FolderIcon className={FolderIconSimple} />
			{folders.map(e => (
				<span key={e._id} className={styles.folderLabel}>
					{e.name}
				</span>
			))}
		</div>
	);
});

const TaskTypeNames: React.FC<{ ids: ObjectId[] }> = React.memo(({ ids }) => {
	const taskTypes = useModelDocsByIds(inject("TaskTypeModel"), ids);
	if (!taskTypes || taskTypes.length === 0) return null;
	return (
		<div>
			<TaskTypeIcon className={TaskTypeIconSimple} />
			{taskTypes.map(e => (
				<span key={e._id} className={styles.taskTypeLabel}>
					{e.name}
				</span>
			))}
		</div>
	);
});

const TopicNames: React.FC<{ ids: ObjectId[] }> = React.memo(({ ids }) => {
	const topics = useModelDocsByIds(inject("TopicModel"), ids);
	if (!topics || topics.length === 0) return null;
	return (
		<div>
			<TopicIcon className={TopicIconSimple} />
			{topics.map(e => (
				<span key={e._id} className={styles.topicLabel}>
					{e.name}
				</span>
			))}
		</div>
	);
});
