import { IFolderItem, ItemType } from "@app/api/folders/helper-schemas";
import { openConfirmationPopup } from "@app/components/widgets/confirmation-popup";
import { useCourse } from "@app/hooks/courses";
import { useFolder } from "@app/hooks/folders";
import {
	FilteredFolderItem,
	itemTypeFilterFn,
	useItemOnClick,
} from "@app/hooks/item";
import { useItemProgress } from "@app/hooks/user-folder-progress";
import { useCoursesUserId } from "@app/hooks/users";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";
import classNames from "classnames";
import { History } from "history";
import React from "react";
import { CourseBackground } from "../background";
import { SponsorLogo } from "../sponsor-logo";
import styles from "./universal.module.scss";

const displayingItemTypes = [ItemType.folder, ItemType.test] as const;

export const UniversalGuide = React.memo<{
	courseId: string;
	history: History;
}>(function UniversalGuide({ courseId }) {
	const userId = useCoursesUserId()!;
	const course = useCourse(courseId);
	const rootFolderId = course.doc?.rootFolder;
	const rootFolder = useFolder({ courseId, folderId: rootFolderId ?? null });

	return (
		<div style={{ textAlign: "center" }}>
			<CourseBackground />
			<SponsorLogo />
			<div className={styles.grid}>
				{rootFolder.isSuccessfullyLoaded &&
					rootFolder.doc.items
						?.filter(itemTypeFilterFn(...displayingItemTypes))
						.map((item, index) => (
							<ItemContainer
								key={item.id}
								item={item as IFolderItem}
								courseId={courseId}
								userId={userId}
								parentFolderId={rootFolderId!}
								index={index}
							/>
						))}
			</div>
		</div>
	);
});

const ItemContainer = React.memo<{
	item: FilteredFolderItem<typeof displayingItemTypes[number]>;
	courseId: ObjectId;
	userId: number;
	parentFolderId: ObjectId;
	index: number;
}>(function ItemContainer({ item, courseId, userId, parentFolderId, index }) {
	const progressDoc = useItemProgress({
		courseId,
		coursesUserId: userId,
		itemId: item.id,
		itemType: item.type,
		parentFolderId,
	});
	const folder = useFolder({
		courseId,
		folderId: item.type === ItemType.folder ? item.id : null,
	});
	const progress = !progressDoc ? 0 : progressDoc.progress;

	const onItemCLick = useItemOnClick({
		courseId,
		parentFolderId,
		item,
	});

	const hasStarted = progress > 0;
	const hasFinished = progress >= 1;

	const onClick = () => {
		if (isLocked) {
			openConfirmationPopup({
				text: "თავი დაბლოკილია",
			});
			return;
		}
		onItemCLick();
	};

	const isLocked = inject("UserFolderProgressService").isItemLockedSync({
		courseId,
		itemId: item.id,
		itemType: item.type,
		folderId: parentFolderId,
		userId,
	});

	if (folder.isIdentificationKnown && !folder.doc) return null;

	return (
		<Item
			title={`თავი ${index + 1}`}
			description={item.name}
			image={folder.doc?.photo}
			isLocked={isLocked}
			hasFinished={hasFinished}
			onClick={onClick}
		/>
	);
});

export const Item = React.memo<{
	title: string;
	description: string;
	image?: string;
	isLocked: boolean;
	hasFinished: boolean;
	onClick: () => void;
}>(function Item({
	title,
	description,
	image,
	isLocked,
	hasFinished,
	onClick,
}) {
	return (
		<div className={styles.item} onClick={onClick}>
			<div className={styles.title}>{title}</div>
			<div className={styles.photoBox}>
				<div
					className={classNames(
						styles.photo,
						isLocked
							? styles.locked
							: hasFinished
							? ""
							: styles.current
					)}
				>
					<img alt="" src={image || "/img/default-lesson.svg"} />
				</div>
			</div>
			<div className={styles.description}>{description}</div>
		</div>
	);
});
