import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";

export interface IQuestionStatsCoefficients {
	a: number;
	b: number;
	c: number;
}

export interface IAnswersByGroup {
	numCorrect: number;
	numTotal: number;
}
///

export const QuestionDifficultyStatsSchema = Joi.object({
	_id: Joi.objectId().required(),
	questionId: Joi.objectId().required(),
	coefficients: Joi.object({
		a: Joi.number().required(),
		b: Joi.number().required(),
		c: Joi.number().required(),
	}).required(),
	answersByGroups: Joi.array()
		.items(
			Joi.object({
				numCorrect: Joi.number().required(),
				numTotal: Joi.number().required(),
			})
		)
		.required(),
	createdAt: Joi.date().required(),
	updatedAt: Joi.date().required(),
});

export interface IQuestionDifficultyStats {
	_id: ObjectId;
	questionId: ObjectId;
	coefficients: IQuestionStatsCoefficients;
	answersByGroups: IAnswersByGroup[];
	createdAt: Date;
	updatedAt: Date;
}
