import * as React from "react";
import styled from "@emotion/styled";
import Close from "@material-ui/icons/Close";

const Container = styled.div`
	width: 100%;
	border-radius: ${(props: any) =>
		props.borderRadius ? props.borderRadius : "20px 20px 0 0"};
	background: ${(props: any) =>
		// tslint:disable-next-line:no-nested-template-literals
		`linear-gradient(to right, ${
			props.color1 ? props.color1 : "#ff780d"
		}, ${props.color2 ? props.color2 : "#ffa013"})`};
	color: white;
	padding: 10px 15px;
	z:index: 99p;
	text-align: left;
`;

const Title = styled.div`
	display: inline-block;
	width: calc(100% - 35px);
	vertical-align: middle;
`;

const CloseContainer = styled.div`
	display: inline-block;
	width: 35px;
	text-align: right;
	vertical-align: middle;

	svg {
		cursor: pointer;
		vertical-align: middle;
	}
`;

export interface IHeaderStyleProps {
	borderRadius?: string;
	color1?: string;
	color2?: string;
}

interface IOwnProps {
	chatroomName: string;
	onClose: () => void;
	style?: IHeaderStyleProps;
}

type IProps = IOwnProps;

class MiniChatHeader extends React.Component<IProps> {
	render() {
		const { borderRadius, color1, color2 } = this.props.style
			? this.props.style
			: { borderRadius: undefined, color1: undefined, color2: undefined };

		return (
			<Container
				borderRadius={borderRadius}
				color1={color1}
				color2={color2}
			>
				<Title>{this.props.chatroomName}</Title>
				<CloseContainer>
					<Close onClick={this.props.onClose} />
				</CloseContainer>
			</Container>
		);
	}
}

export default MiniChatHeader;
