import classnames from "classnames";
import * as React from "react";
import memoizeOne from "memoize-one";
import { AnyComponent } from "../../../utils/generics";
import { EditorText } from "../../../utils";
import {
	IContentEditorProps,
	ContentError,
	ContentErrorToken,
} from "./interfaces";
import {
	IEditableStat,
	ItemEdit,
	itemEditClassName,
	itemEditContainerClassName,
} from "./common/items-edit";
import { IEditCommonProps } from "./edit";
import { IItemTextProps } from "./common/stats";
import { IStatement } from "../../../schemas/questions/contnets/common-schemas";
import { IText } from "../../../schemas/texts/helper-schemas";

interface IStatementPlaceholders {
	statement: string;
}

type ITexts = IStatementPlaceholders;

interface ITextObj {
	text: {
		text: IText["text"];
	};
}

export type IProps = Partial<IEditCommonProps> &
	IContentEditorProps & { texts: IStatementPlaceholders } & ITextObj & {
		toHTML: (editorState: EditorText) => string;
		toEditorState: (html: string) => EditorText;
	};

interface IState {
	stat: IStatement;
}

class TextEdit extends React.PureComponent<IProps, IState> {
	static defaultProps: Partial<IProps> = {
		texts: {
			statement: "Enter Text",
		} as ITexts,
	};

	// tslint:disable-next-line:max-line-length

	getClassNames = memoizeOne((pClassNames: any) => {
		const classNames = { ...(pClassNames || {}) };
		classNames.container = classnames(
			itemEditContainerClassName,
			classNames.container
		);
		classNames.text = classnames(itemEditClassName, classNames.text);
		return classNames;
	});

	getComponents = memoizeOne(() => {
		const components = {} as {
			text: AnyComponent<IItemTextProps>;
		};
		components.text = (props: IItemTextProps) => (
			<ItemEdit
				{...props}
				onChange={this.onTextChange}
				placeholder={this.props.texts.statement}
				galleryComponent={this.props.galleryComponent}
			/>
		);
		return components;
	});

	constructor(props: IProps) {
		super(props);
		this.state = {
			stat: {
				id: 1,
				text: this.props.toEditorState(props.text.text || ""),
			},
		};
	}

	getData = () => {
		return {
			text: this.props.toHTML(this.state.stat.text),
		};
	};

	getErrors = (): ContentError[] => {
		const data = this.getData();
		const errors: ContentError[] = [];
		if (!data.text.trim()) {
			errors.push({
				engMessage: "Empty statement",
				errorToken: ContentErrorToken.emptyStatement,
			});
		}
		return errors;
	};

	onTextChange = (stat: IEditableStat) => {
		this.setState({
			stat,
		});
	};

	render() {
		// const classNames = this.getClassNames({});
		const components = this.getComponents();
		return (
			<div className={itemEditContainerClassName}>
				<components.text stat={this.state.stat} />
			</div>
		);
	}
}

export default TextEdit;
