import * as React from "react";
import Popup, { PopupContent } from "@app/components/widgets/Popup";
import styled from "@emotion/styled";
import Folder from "@material-ui/icons/Folder";
import { IRFolder, ItemType } from "@app/api/folders/helper-schemas";
import { inject } from "@app/modules";
import { ObjectId } from "@app/utils/generics";

interface IProps {
	courseId: ObjectId;
	onClose: () => void;
	onFolderChange: (newFolder: ObjectId) => void;
	itemType: ItemType;
	itemId: ObjectId;
}

interface IState {
	folder: null | IFoldersWithChildren;
}

interface IFoldersWithChildren {
	folder: IRFolder;
	children: IFoldersWithChildren[];
}

const Container = styled.div`
	width: calc(100vw - 20px);
	max-width: 800px;
	background-color: white;
	border-radius: 15px;
	padding: 15px;
`;

class AllFolders extends React.Component<IProps> {
	state: IState = {
		folder: null,
	};

	_CoursesController = inject("CoursesController");
	_FolderHierarchyService = inject("FolderHierarchyService");

	render() {
		return (
			<Popup onClose={this.props.onClose}>
				<PopupContent>
					<Container>
						{this.state.folder && (
							<DisplayFolder
								folderId={this.state.folder.folder._id}
								folderName={this.state.folder.folder.name}
								childrenFolders={this.state.folder.children}
								depth={0}
								onClick={this.props.onFolderChange}
								itemId={this.props.itemId}
								itemType={this.props.itemType}
							/>
						)}
					</Container>
				</PopupContent>
			</Popup>
		);
	}

	findFolderChildren = (
		folderObject: IFoldersWithChildren,
		allFolders: IFoldersWithChildren[]
	) => {
		const folderId = folderObject.folder._id;

		for (let i = 0; i < allFolders.length; i++) {
			const parentId = this._FolderHierarchyService.getParentIdSync(
				this.props.courseId,
				allFolders[i].folder._id
			);
			if (parentId === folderId)
				folderObject.children.push(allFolders[i]);
		}

		if (folderObject.children.length > 0) {
			for (let i = 0; i < folderObject.children.length; i++) {
				this.findFolderChildren(folderObject.children[i], allFolders);
			}
		}
	};

	componentDidMount() {
		this._CoursesController
			.getAllFolders({ courseId: this.props.courseId })
			.then(data => {
				const { allFolders } = data;

				const foldersWithChildren: IFoldersWithChildren[] = [];
				for (let i = 0; i < allFolders.length; i++) {
					foldersWithChildren.push({
						folder: allFolders[i],
						children: [],
					});
				}

				const rootFolder = foldersWithChildren.find(
					folder => folder.folder._id === data.rootFolderId
				);
				if (!rootFolder) return;

				this.findFolderChildren(rootFolder, foldersWithChildren);

				this.setState({ folder: rootFolder });
			})
			.catch(err => {
				console.log(err);
			});
	}
}

const DisplayFolderContainer = styled.div`
	background-color: white;
	padding: 2px 1px;
	cursor: pointer;
	margin: 0 0 0 ${(props: any) => props.depth * 15}px;

	svg {
		vertical-align: middle;
		margin-right: 10px;
	}

	p {
		display: inline;
		vertical-align: middle;
		margin: 0;
	}

	&:hover {
		background-color: rgba(0, 0, 255, 0.3);
	}
`;

interface IDisplayFolderProps {
	folderId: ObjectId;
	folderName: string;
	childrenFolders: IFoldersWithChildren[];
	depth: number;
	onClick: (folderId: ObjectId) => void;
	itemType: ItemType;
	itemId: ObjectId;
}

const DisplayFolder: React.SFC<IDisplayFolderProps> = props => (
	<React.Fragment>
		<DisplayFolderContainer
			depth={props.depth}
			onClick={() => props.onClick(props.folderId)}
		>
			<Folder />
			<p>{props.folderName}</p>
		</DisplayFolderContainer>
		{props.childrenFolders.map(children => {
			if (
				props.itemType === ItemType.folder &&
				children.folder._id === props.itemId
			) {
				// no need to display myself and my descendants
				return null;
			}
			return (
				<DisplayFolder
					key={children.folder._id}
					folderId={children.folder._id}
					folderName={children.folder.name}
					childrenFolders={children.children}
					depth={props.depth + 1}
					onClick={props.onClick}
					itemType={props.itemType}
					itemId={props.itemId}
				/>
			);
		})}
	</React.Fragment>
);

export default AllFolders;
