import React, { useMemo, useCallback, useRef } from "react";
import { FilterOption } from "./select";
import Select from "react-select";
import { useLocale } from "@app/hooks/intl";
import { ItemType } from "@app/api/folders/helper-schemas";
import { getFormattedMessage } from "@app/utils/locale";
import {
	useDynamicRef,
	useValueChangeWithRef,
	useFuncCall,
} from "@app/hooks/general";
import { HierarchyMultipleSelect } from "../hierarchy-multiple-select";
import { ObjectId } from "@app/utils/generics";
import { HierarchyItemType } from "@app/services/hierarchy-info/interfaces";
import { IDefaultItems } from "../deep-multiple-select-wth-search";
import { CheckboxWithLabel } from "@app/components/widgets/input";

export const FilterOptions: React.FC<{
	options: FilterOption;
	courseId: ObjectId;
	onChange: (newOptions: FilterOption) => void;
}> = ({ options, courseId, onChange }) => {
	const locale = useLocale();

	const hierarchyMultipleSelectRef = useRef<HierarchyMultipleSelect>(null);

	const optionsRef = useDynamicRef(options);
	const optionsChange = useValueChangeWithRef(onChange, optionsRef);

	const itemTypeOptions = useMemo((): {
		value: ItemType;
		label: string;
	}[] => {
		return [
			{
				value: ItemType.question,
				label: getFormattedMessage("questions"),
			},
			{ value: ItemType.text, label: getFormattedMessage("texts") },
			{ value: ItemType.file, label: getFormattedMessage("files") },
			{ value: ItemType.folder, label: getFormattedMessage("folders") },
			{ value: ItemType.test, label: getFormattedMessage("tests") },
			{ value: ItemType.card, label: getFormattedMessage("cards") },
		];
	}, []);
	const handleItemTypesSelectChange = useCallback(
		(
			sel: {
				value: ItemType;
				label: string;
			}[]
		) => {
			onChange({
				...optionsRef.current,
				allowedTypes: sel.map(e => e.value),
			});
		},
		[onChange, optionsRef]
	);

	const handleCreateNewFolderChange = useFuncCall(
		optionsChange,
		"createNewFolderWhenCopying" as const
	);

	const handleDeepCopyChange = useFuncCall(
		optionsChange,
		"isDeepCopy" as const
	);

	const allowedTypes = useMemo(() => {
		return itemTypeOptions.filter(
			e => options.allowedTypes.indexOf(e.value) > -1
		);
	}, [itemTypeOptions, options.allowedTypes]);

	const onLabelsChange = useCallback(
		(
			value: Record<HierarchyItemType, IDefaultItems[]>,
			isInitialCall: boolean
		) => {
			onChange({
				...optionsRef.current,
				topics: value[HierarchyItemType.topic].map(e => e.id),
				taskTypes: value[HierarchyItemType.taskType].map(e => e.id),
			});
		},
		[onChange, optionsRef]
	);

	return (
		<div>
			<Select
				options={itemTypeOptions}
				value={allowedTypes}
				onChange={handleItemTypesSelectChange}
				isMulti={true}
				placeholder="item types"
			/>
			<HierarchyMultipleSelect
				ref={hierarchyMultipleSelectRef}
				onChange={onLabelsChange}
				courseId={courseId}
				hierarchiesToShow={[
					HierarchyItemType.taskType,
					HierarchyItemType.topic,
				]}
				itemTypesToBeStored={{
					[HierarchyItemType.folder]: {},
				}}
				placeholders={{
					[HierarchyItemType.folder]: getFormattedMessage("folders"),
				}}
				alwaysHideLoadLastOptionsButton={true}
				defaultSelections={{
					[HierarchyItemType.topic]: options.topics.map(id => ({
						id,
						type: -1,
					})),
					[HierarchyItemType.taskType]: options.taskTypes.map(id => ({
						id,
						type: -1,
					})),
				}}
				loadLastOptionsAutomatically={false}
			/>
			<CheckboxWithLabel
				value={options.createNewFolderWhenCopying}
				label={getFormattedMessage(
					"admin:general.createFolderWhenCopying"
				)}
				onChange={handleCreateNewFolderChange}
			/>
			<CheckboxWithLabel
				value={options.isDeepCopy}
				label={getFormattedMessage("admin:general.isDeepCopy")}
				onChange={handleDeepCopyChange}
			/>
		</div>
	);
};
