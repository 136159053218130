import React, { useCallback } from "react";
import {
	IFileQuestions,
	IFileWithFullContent,
	IFileCards,
	FileItemTypes,
} from "@app/api/files/helper-schemas";
import { PrimaryButton } from "@app/components/styles/buttons";
import { FormattedMessage } from "react-intl";
import QuestionIcon from "@material-ui/icons/HelpOutline";
import styled from "@emotion/styled";
import { css } from "emotion";
import CardIcon from "@material-ui/icons/CreditCard";
import { ContentAddArg } from ".";
import { useDynamicRef } from "@app/hooks/general";

export const FileQuestionsContainer: React.FC<{
	item: IFileQuestions;
	onChange: (item: IFileQuestions) => void;
	itemNames: IFileWithFullContent["itemNames"];
	onContentAddOpen: (arg: ContentAddArg) => void;
}> = React.memo(({ item, itemNames, onChange, onContentAddOpen }) => {
	const questionNames = itemNames.questions || {};
	const itemRef = useDynamicRef(item);

	console.log(item);

	const handleContentAdd = useCallback(() => {
		onContentAddOpen({
			defaultIds: itemRef.current.ids.map(questionId => ({ questionId })),
			handleConfirm: newIds =>
				onChange({
					...itemRef.current,
					ids: newIds,
				}),
			type: FileItemTypes.questions,
		});
	}, [itemRef, onChange, onContentAddOpen]);

	return (
		<ItemsGroupBox>
			{item.ids.map(id => (
				<div key={id}>
					<QuestionIcon className={ItemIconCSS} />
					{questionNames[id]}
				</div>
			))}
			<PrimaryButton
				style={{
					display: "block",
					margin: "0 auto",
					marginBottom: 20,
				}}
				onClick={handleContentAdd}
			>
				<FormattedMessage id="admin:general.addChangeQuestions" />
			</PrimaryButton>
		</ItemsGroupBox>
	);
});

export const FileCardsContainer: React.FC<{
	item: IFileCards;
	onChange: (item: IFileCards) => void;
	itemNames: IFileWithFullContent["itemNames"];
	onContentAddOpen: (arg: ContentAddArg) => void;
}> = React.memo(({ item, onChange, itemNames, onContentAddOpen }) => {
	const cardNames = itemNames.cards || {};
	const itemRef = useDynamicRef(item);

	const handleContentAdd = useCallback(() => {
		onContentAddOpen({
			defaultIds: itemRef.current.ids.map(cardId => ({ cardId })),
			handleConfirm: newIds =>
				onChange({
					...itemRef.current,
					ids: newIds,
				}),
			type: FileItemTypes.cards,
		});
	}, [itemRef, onChange, onContentAddOpen]);

	return (
		<ItemsGroupBox>
			{item.ids.map(id => (
				<div key={id}>
					<CardIcon className={ItemIconCSS} />
					{cardNames[id]}
				</div>
			))}
			<PrimaryButton
				style={{
					display: "block",
					margin: "0 auto",
					marginBottom: 20,
				}}
				onClick={handleContentAdd}
			>
				<FormattedMessage id="admin:general.addChangeCards" />
			</PrimaryButton>
		</ItemsGroupBox>
	);
});

export const ItemsGroupBox = styled.div`
	margin: 0 auto;
	padding: 10px;
	border: 1px solid black;
	border-radius: 10px;
	margin: 20px 0px;
	text-align: left;
	padding: 10px 20px;
`;

export const ItemIconCSS = css`
	margin-right: 10px;
`;
