import { IUserTestQuestionInfo } from "@app/api/tests/helper-schemas";
import { jsonDateParser } from "@app/utils/common";

export const userAnswersLocalStorageKey = "modellingUserAnswers";

interface SavedInfo {
	answers: IUserTestQuestionInfo[];
	modellingId: number;
}

export const locallySaveModellingUserAnswers = ({
	answers,
	modellingId,
}: SavedInfo) => {
	const key = userAnswersLocalStorageKey + "---" + modellingId;
	const savedInfo: SavedInfo = { answers, modellingId };
	localStorage.setItem(key, JSON.stringify(savedInfo));
};

export const getLocalModellingUserAnswers = (
	modellingId: number
): IUserTestQuestionInfo[] | null => {
	const key = userAnswersLocalStorageKey + "---" + modellingId;
	const storageValue = localStorage.getItem(key);
	if (!storageValue) return null;
	try {
		const rawValue = JSON.parse(storageValue, jsonDateParser) as SavedInfo;
		if (rawValue.modellingId !== modellingId) return null;
		return rawValue.answers;
	} catch (e) {
		return null;
	}
};

export const hasStartedModelling = (modellingId: number) => {
	const key = userAnswersLocalStorageKey + "---" + modellingId;
	const storageValue = localStorage.getItem(key);
	return !!storageValue;
};
