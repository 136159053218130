import * as React from "react";
import { connect } from "react-redux";
import { TestLoader } from "./test-loader";
import { IRootState } from "@app/redux";
import { match } from "react-router";
import { ObjectId } from "@app/utils/generics";
import { History } from "history";

type IStateProps = ReturnType<typeof mapStateToProps>;

interface IOwnProps {
	match: match<{ courseId: ObjectId; testId: ObjectId; folderId: ObjectId }>;
	history: History;
}

type ILaaProps = IStateProps & IOwnProps;

const UniversalTestLoaderFromUrl: React.FC<ILaaProps> = props => {
	return (
		<TestLoader
			courseId={props.match.params.courseId}
			testId={props.match.params.testId}
			folderId={props.match.params.folderId}
			userId={props.userData!.id}
			history={props.history}
		/>
	);
};
const mapStateToProps = (state: IRootState) => ({
	userData: state.user.userData,
});

const connected = connect<IStateProps, null, IOwnProps>(
	mapStateToProps,
	null
)(UniversalTestLoaderFromUrl);

export { connected as UniversalTestLoaderFromUrl };
