import * as React from "react";
import chatLogo from "./img/data1.png";
import styled from "@emotion/styled";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	width: calc(100vw - 20px);
	max-width: 400px;
	height: 60px;
	bottom: 10px;
	right: 10px;
	border-radius: 5px;
	background-color: white;
	box-shadow: 0 0 10px 1px;
	padding: 5px;
	cursor: pointer;

	* {
		vertical-align: middle;
	}

	img {
		height: 100%;
		margin-right: 10px;
	}

	div {
		display: inline-block;

		p {
			display: inline;
		}
	}
`;

const Unseen = styled.div`
	background-color: red;
	width: 25px;
	height: 25px;
	font-size: 15px;
	border-radius: 50%;
	line-height: 25px;
	color: white;
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(-50%, -50%);
`;

interface IProps {
	unseenMessages: number | null;
	onClick: () => void;
}

const ClosedChatComponent: React.SFC<IProps> = props => (
	<Container onClick={props.onClick}>
		<div style={{ position: "relative" }}>
			{props.unseenMessages !== null && (
				<Unseen>
					{props.unseenMessages <= 9 ? props.unseenMessages : "9+"}
				</Unseen>
			)}
			<div>
				<img src={chatLogo} />
				<p>ყოვლისმცოდნე დავითი</p>
			</div>
			<KeyboardArrowUp />
		</div>
	</Container>
);

export default ClosedChatComponent;
