import { ItemType } from "@app/api/folders/helper-schemas";
import * as React from "react";
import { ContextMenu, MenuItem, connectMenu } from "react-contextmenu";
import { FormattedMessage } from "react-intl";
import "./styles/context-menu.css";

export interface ITriggerAttributes {
	itemType: ItemType;
}

interface IMyMenuProps {
	onSelect: Function;
	trigger: null | ITriggerAttributes;
}

export enum MenuAction {
	view,
	edit,
	relocate,
	delete,
	vewUsersOfByTest,
}

const MyMenu: React.FC<IMyMenuProps> = (props: IMyMenuProps) => {
	const itemType = props.trigger ? props.trigger.itemType : null;
	return (
		<ContextMenu id="multi">
			{(itemType === ItemType.folder || itemType === ItemType.file) && (
				<MenuItem
					onClick={props.onSelect}
					data={{ action: MenuAction.view }}
				>
					<FormattedMessage id="admin:general.options.open" />
				</MenuItem>
			)}
			<MenuItem
				onClick={props.onSelect}
				data={{ action: MenuAction.edit }}
			>
				<FormattedMessage id="admin:general.options.edit" />
			</MenuItem>
			<MenuItem
				onClick={props.onSelect}
				data={{ action: MenuAction.relocate }}
			>
				<FormattedMessage id="admin:general.options.relocate" />
			</MenuItem>
			<MenuItem
				onClick={props.onSelect}
				data={{ action: MenuAction.delete }}
			>
				<FormattedMessage id="admin:general.options.delete" />
			</MenuItem>
			{itemType === ItemType.test && (
				<MenuItem
					onClick={props.onSelect}
					data={{ action: MenuAction.vewUsersOfByTest }}
				>
					<FormattedMessage id="admin:general.options.assessStudents" />
				</MenuItem>
			)}
		</ContextMenu>
	);
};

const ConnectedMenu = connectMenu("multi")(MyMenu);

export { ConnectedMenu as MyMenu };
