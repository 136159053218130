import { IRootState } from "@app/redux";
import { removeKeys } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import EditCard from "@tests-core/components/cards";
import CardWrapper from "@tests-core/components/cards/wrapper";
import { ICard } from "@tests-core/schemas/cards";
import memoizeOne from "memoize-one";
import * as React from "react";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import Popup, { PopupContentWithClose } from "../../widgets/Popup";
import { inject } from "@app/modules";
import { IStateCardTemplates } from "@app/models/card-template";

interface IOwnProps {
	onClose: () => void;
	folderId: ObjectId;
	courseId: ObjectId;
	id?: ObjectId;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IStateProps & IOwnProps;

class CardPopup extends React.PureComponent<IProps> {
	card?: ICard;
	id = this.props.id;
	_isMounted = false;
	getTemplateArray = memoizeOne((templates: IStateCardTemplates) =>
		Object.keys(templates).map(key => templates[key]!.info)
	);

	_CardTemplatesController = inject("CardTemplatesController");
	_CardsController = inject("CardsController");

	componentDidMount() {
		this._isMounted = true;
		this._CardTemplatesController.getAll().then();
		if (this.id) {
			if (!this.props.cardTemplates[this.id]) {
				this._CardsController
					.getById(
						{
							_id: this.id,
							courseId: this.props.courseId,
						},
						true
					)
					.then(card => {
						this.card = card;
						this.forceUpdate();
					});
			} else {
				this.card = this.props.cards[this.id]!.info;
				this.forceUpdate();
			}
		}
	}

	onSave = (newCard: ReturnType<EditCard["getData"]>) => {
		if (!newCard || !this.props.folderId) return;
		if (!this.id) {
			this._CardsController
				.add({
					folderId: this.props.folderId,
					courseId: this.props.courseId,
					card: newCard,
				})
				.then(data => this.props.onClose());
			return;
		}
		const removedKeysCard = removeKeys(newCard as ICard, "authorId");
		this._CardsController
			.update({
				...removedKeysCard,
				folderId: this.props.folderId,
				courseId: this.props.courseId,
			})
			.then(this.props.onClose);
		return;
	};

	render() {
		return (
			<Popup onClose={this.props.onClose}>
				<PopupContentWithClose onClose={this.props.onClose}>
					{(!this.id || !!this.props.cards[this.id]) && (
						<CardWrapper
							templates={this.getTemplateArray(
								this.props.cardTemplates
							)}
							defaultCard={
								this.id
									? this.props.cards[this.id]!.info
									: undefined
							}
							onSave={this.onSave}
						/>
					)}
				</PopupContentWithClose>
			</Popup>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	cards: state.cards,
	cardTemplates: state.cardTemplates,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	CardPopup
);
