import * as React from "react";
import { IMillionDate } from "../../../../schemas/cards/templates";

interface IMillionDateProps {
	date: IMillionDate;
	onChange: (newDate: IMillionDate) => void;
}

export default class MillionDate extends React.PureComponent<
	IMillionDateProps
> {
	onMillionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onChange({
			...this.props.date,
			million: +e.target.value,
		});
	};

	render() {
		return (
			<div style={{ margin: "3px 0px" }}>
				<div style={{ display: "inline-block", minWidth: 50 }}>
					million
				</div>
				<input
					defaultValue={"" + this.props.date.million}
					type="text"
					onChange={this.onMillionChange}
				/>
			</div>
		);
	}
}
