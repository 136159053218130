import * as React from "react";
import Select from "react-select";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CheckBoxContainer } from "@app/components/styles/checkbox";
import { InputStyle } from "@app/components/styles/inputs";
import {
	IRTestType,
	TestTypeAlgorithms,
	TestTypeAnswersShowTime,
} from "@app/api/test-types/helper-schemas";
import { SingleSelect } from "@app/components/styles/selects";
import { reactSelectStyles } from "@app/commonJavascript";
import { NotUndefined } from "@app/utils/generics";
import TextField from "@material-ui/core/TextField";
import { getFormattedMessage } from "@app/utils/locale";

interface IProps {
	defaultSettings?: IRTestType["settings"];
	onChange: (newSettings: IRTestType["settings"]) => void;
}

interface IState {
	settings: IRTestType["settings"];
}

type FilterKeys<Base, Condition> = {
	[Key in keyof Base]: Base[Key] extends Condition ? Key : never;
}[keyof Base];

type testSettingBooleanKeys = NotUndefined<
	FilterKeys<IRTestType["settings"], boolean | undefined>
>;

class TestTypeSettings extends React.PureComponent<IProps, IState> {
	private algorithms: { [key: string]: string } = {
		[TestTypeAlgorithms.catAndSpacedRepetition]: getFormattedMessage(
			"admin:testType.algos.catAndSpacedRepetition"
		),
		[TestTypeAlgorithms.spacedRepetition]: getFormattedMessage(
			"admin:testType.algos.spacedRepetition"
		),
	};
	private selectAlgorithmOptions = Object.keys(this.algorithms).map(el => ({
		value: el,
		label: this.algorithms[el],
	}));

	private showAnswersTimeGeo: { [key: string]: string } = {
		[TestTypeAnswersShowTime.immediately]: getFormattedMessage(
			"admin:testType.answersAt.immediately"
		),
		[TestTypeAnswersShowTime.afterFinish]: getFormattedMessage(
			"admin:testType.answersAt.afterFinish"
		),
		[TestTypeAnswersShowTime.afterDeadline]: getFormattedMessage(
			"admin:testType.answersAt.afterDeadline"
		),
	};
	private showAnswersTimeOptions = Object.keys(this.showAnswersTimeGeo).map(
		el => ({
			value: el,
			label: this.showAnswersTimeGeo[el],
		})
	);

	state = {} as IState;

	constructor(props: IProps) {
		super(props);
		if (props.defaultSettings) {
			this.state.settings = props.defaultSettings;
		} else {
			this.state.settings = {
				algorithm: undefined,
				showAnswersAt: TestTypeAnswersShowTime.immediately,
				maxNumOfWritingTests: null,
				checkInBackEnd: false,
				allowSwitchingToSubmittedQuestions: true,
				allowSwitchingToUnsubmittedQuestions: false,
				showStatsAfterEnd: false,
				submitAnswerAfterAnswering: true,
			};
		}
	}

	componentDidMount() {
		if (!this.props.defaultSettings) {
			this.callOnChange();
		}
	}

	callOnChange = () => {
		this.props.onChange(this.state.settings);
	};

	private handleCheckboxChange = (
		field: testSettingBooleanKeys,
		checked: boolean
	) => {
		this.setState(
			({ settings }) => ({
				settings: {
					...settings,
					[field as string]: checked,
				},
			}),
			this.callOnChange
		);
	};

	handleAlgorithmChange = selectedOption => {
		this.setState(
			({ settings }) => ({
				settings: {
					...settings,
					algorithm: selectedOption
						? selectedOption.value
						: undefined,
				},
			}),
			this.callOnChange
		);
	};

	private handleShowAnswerChange = selectedOption => {
		this.setState(
			({ settings }) => ({
				settings: {
					...settings,
					showAnswersAt: selectedOption.value,
				},
			}),
			this.callOnChange
		);
	};

	private handleMaxNumOfWritingTestsChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const value = +e.target.value || 1;
		this.setState(
			({ settings }) => ({
				settings: {
					...settings,
					maxNumOfWritingTests: value,
				},
			}),
			this.callOnChange
		);
	};

	private handleMaxNumOfWritingTestsCheckboxChange = (
		e,
		checked: boolean
	) => {
		this.setState(
			({ settings }) => ({
				settings: {
					...settings,
					maxNumOfWritingTests: checked ? null : 1,
				},
			}),
			this.callOnChange
		);
	};

	private handleMaxNumOfQuestionsChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const value = e.target.value as string;
		this.setState(
			({ settings }) => ({
				settings: {
					...settings,
					maxNumOfQuestions: value === "" ? undefined : +value,
				},
			}),
			this.callOnChange
		);
	};

	render() {
		const { settings } = this.state;
		return (
			<React.Fragment>
				<div className={CheckBoxContainer}>
					<FormControlLabel
						control={
							<Checkbox
								checked={!!settings.checkInBackEnd}
								onChange={(e, checked) =>
									this.handleCheckboxChange(
										"checkInBackEnd",
										checked
									)
								}
							/>
						}
						label={getFormattedMessage(
							"admin:testType.checkInBackEnd"
						)}
					/>
				</div>
				<div className={CheckBoxContainer}>
					<FormControlLabel
						control={
							<Checkbox
								checked={!!settings.showStatsAfterEnd}
								onChange={(e, checked) =>
									this.handleCheckboxChange(
										"showStatsAfterEnd",
										checked
									)
								}
							/>
						}
						label={getFormattedMessage(
							"admin:testType.showStatsAfterEnd"
						)}
					/>
				</div>
				<div className={CheckBoxContainer}>
					<FormControlLabel
						control={
							<Checkbox
								checked={!!settings.submitAnswerAfterAnswering}
								onChange={(e, checked) =>
									this.handleCheckboxChange(
										"submitAnswerAfterAnswering",
										checked
									)
								}
							/>
						}
						label={getFormattedMessage(
							"admin:testType.submitAnswerAfterAnswering"
						)}
					/>
				</div>
				<div className={CheckBoxContainer}>
					<FormControlLabel
						control={
							<Checkbox
								checked={
									!!settings.allowSwitchingToSubmittedQuestions
								}
								onChange={(e, checked) =>
									this.handleCheckboxChange(
										"allowSwitchingToSubmittedQuestions",
										checked
									)
								}
							/>
						}
						label={getFormattedMessage(
							"admin:testType.allowSwitchingToSubmittedQuestions"
						)}
					/>
				</div>
				<div className={CheckBoxContainer}>
					<FormControlLabel
						control={
							<Checkbox
								checked={
									!!settings.allowSwitchingToUnsubmittedQuestions
								}
								onChange={(e, checked) =>
									this.handleCheckboxChange(
										"allowSwitchingToUnsubmittedQuestions",
										checked
									)
								}
							/>
						}
						label={getFormattedMessage(
							"admin:testType.allowSwitchingToUnsubmittedQuestions"
						)}
					/>
				</div>

				<div className={CheckBoxContainer}>
					<FormControlLabel
						control={
							<Checkbox
								checked={settings.maxNumOfWritingTests === null}
								onChange={
									this
										.handleMaxNumOfWritingTestsCheckboxChange
								}
							/>
						}
						label={getFormattedMessage(
							"admin:testType.canBeWrittenInfinitely"
						)}
					/>
				</div>

				{settings.maxNumOfWritingTests !== null && (
					<div>
						<TextField
							label={getFormattedMessage(
								"admin:testType.maxNumOfWritingTests"
							)}
							defaultValue={settings.maxNumOfWritingTests}
							type="number"
							onChange={this.handleMaxNumOfWritingTestsChange}
							margin="dense"
							variant="outlined"
							className={InputStyle}
						/>
					</div>
				)}
				<div>
					<TextField
						label={getFormattedMessage(
							"admin:testType.maxNumOfQuestions"
						)}
						value={
							settings.maxNumOfQuestions !== undefined
								? settings.maxNumOfQuestions
								: ""
						}
						type="number"
						onChange={this.handleMaxNumOfQuestionsChange}
						margin="dense"
						variant="outlined"
						className={InputStyle}
					/>
				</div>
				<div>
					<Select
						value={
							settings.algorithm
								? {
										value: settings.algorithm,
										label: this.algorithms[
											settings.algorithm
										],
								  }
								: undefined
						}
						isClearable={true}
						styles={reactSelectStyles}
						onChange={this.handleAlgorithmChange}
						options={this.selectAlgorithmOptions}
						placeholder={getFormattedMessage(
							"admin:testType.algorithm"
						)}
						className={SingleSelect}
					/>
				</div>
				<div>
					<Select
						value={{
							value: settings.showAnswersAt,
							label: this.showAnswersTimeGeo[
								settings.showAnswersAt
							],
						}}
						styles={reactSelectStyles}
						onChange={this.handleShowAnswerChange}
						options={this.showAnswersTimeOptions}
						placeholder={getFormattedMessage("Show answers")}
						className={SingleSelect}
					/>
				</div>
			</React.Fragment>
		);
	}
}

export default TestTypeSettings;
