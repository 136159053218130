import { ObjectId } from "@app/utils/generics";
import React, { useRef, useCallback, useState } from "react";
import { FilterLabelOptions, ILabelRefVal } from "./labels";
import { useModelDocById } from "m-model-react";
import { inject } from "@app/modules";
import { SwitchWithLabel } from "@app/components/widgets/input";
import { getFormattedMessage } from "@app/utils/locale";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import {
	IAGETCourseFilteredContent,
	IRGETCourseFilteredContent,
} from "@app/api/courses/validators";
import { Paginator } from "@app/components/widgets/paginator";
import {
	addLoader,
	getHTMLElementCoords,
	animateWindowScroll,
} from "@app/commonJavascript";
import { FilterResults } from "./table";
import { successfulSaveArg } from "../types";

export const FilterCourseContent: React.FC<{ courseId: ObjectId }> = props => {
	const [filterResults, setFilterResults] = useState<
		IRGETCourseFilteredContent
	>();
	const [currentPage, setCurrentPage] = useState(0);

	const currentPageRef = useRef(currentPage);
	currentPageRef.current = currentPage;

	const numOfItemsPerPage = 50;
	const optionsRef = useRef<HandleFilter>();
	const dataContainderRef = useRef<HTMLDivElement>(null);

	const getFilterResults = useCallback(
		(options: HandleFilter, page: number) => {
			const oldPage = currentPageRef.current;
			const CoursesController = inject("CoursesController");
			const removeLoader = addLoader();
			CoursesController.getCourseFilteredContent({
				...options,
				courseId: props.courseId,
				limit: numOfItemsPerPage,
				offset: page * numOfItemsPerPage,
			})
				.then(data => {
					setFilterResults(data);
					if (dataContainderRef.current) {
						if (oldPage !== page) {
							const { top } = getHTMLElementCoords(
								dataContainderRef.current
							);
							animateWindowScroll(top - 120, 200);
							setCurrentPage(page);
						}
					}
					removeLoader();
				})
				.catch(() => {
					removeLoader();
					if (oldPage !== page) setCurrentPage(oldPage);
				});
		},
		[props.courseId]
	);

	const handleFilter = useCallback(
		(options: HandleFilter) => {
			optionsRef.current = options;
			getFilterResults(options, 0);
		},
		[getFilterResults]
	);

	const onPageChange = useCallback(
		(newPageIndex: number) => {
			setCurrentPage(newPageIndex);
			if (!optionsRef.current) return;
			getFilterResults(optionsRef.current, newPageIndex);
		},
		[getFilterResults]
	);

	const numOfPages = filterResults
		? Math.ceil(filterResults.totalCount / numOfItemsPerPage)
		: 0;

	const onSuccessSave = useCallback((args: successfulSaveArg) => {
		setFilterResults(results => {
			if (!results) return results;
			return {
				...results,
				items: results.items.map(e =>
					e.type !== args.item.type || e.id !== args.item.id
						? e
						: {
								...e,
								folders: args.folders || e.folders,
								topics: args.topics || e.topics,
								taskTypes: args.taskTypes || e.taskTypes,
								name: args.item.name,
						  }
				),
			};
		});
	}, []);

	const paginator = (
		<Paginator
			numOfPages={numOfPages}
			pageIndex={currentPage}
			onPageChange={onPageChange}
		/>
	);

	return (
		<div className="main">
			<FilterBar courseId={props.courseId} onFilter={handleFilter} />
			{numOfPages > 1 && paginator}
			{filterResults && (
				<div style={{ textAlign: "center" }}>
					{filterResults.totalCount}
				</div>
			)}
			{filterResults && (
				<div ref={dataContainderRef}>
					<FilterResults
						items={filterResults.items}
						courseId={props.courseId}
						onSuccessSave={onSuccessSave}
					/>
				</div>
			)}
			{numOfPages > 1 && paginator}
		</div>
	);
};

type HandleFilter = Pick<
	IAGETCourseFilteredContent,
	"folders" | "topics" | "taskTypes" | "randomSeed"
>;

export const FilterBar: React.FC<{
	courseId: ObjectId;
	onFilter: (options: HandleFilter) => void;
}> = props => {
	const { onFilter, courseId } = props;
	const hRef = useRef<ILabelRefVal>(null);

	const LastOptionModel = inject("LastOptionModel");
	const doc = useModelDocById(LastOptionModel, props.courseId);
	const randomVal = (doc && doc.filterShuffleKey) || 0;

	const handleRandomChange = (shuffle: boolean) => {
		const LastOptionModel = inject("LastOptionModel");
		let value = 0;
		if (shuffle) {
			while (value === 0) value = Math.floor(Math.random() * 1e7);
		}
		LastOptionModel.setValue(props.courseId, "filterShuffleKey", value);
	};

	const handleFilter = useCallback(() => {
		if (!hRef.current) return;
		const selectedHierarchyItems = hRef.current.getSelectedItems(true);
		onFilter({
			folders: selectedHierarchyItems.folders,
			taskTypes: selectedHierarchyItems.taskTypes,
			topics: selectedHierarchyItems.topics,
			randomSeed: randomVal === 0 ? null : randomVal,
		});
	}, [onFilter, randomVal]);

	if (!props.courseId) return null;
	return (
		<div>
			<FilterLabelOptions
				courseId={courseId}
				ref={hRef}
				onInitialCall={handleFilter}
			/>
			<Randomness value={randomVal} onChange={handleRandomChange} />
			<Button variant="contained" onClick={handleFilter}>
				<FormattedMessage id="admin:general.filter" />
			</Button>
		</div>
	);
};

const Randomness: React.FC<{
	onChange: (shuffle: boolean) => void;
	value: number;
}> = props => {
	return (
		<div style={{ margin: 20 }}>
			<SwitchWithLabel
				label={getFormattedMessage("admin:general.shuffle")}
				value={props.value !== 0}
				onChange={props.onChange}
			/>
		</div>
	);
};
