import styled from "react-emotion";

export const MainButton = styled("button")(
	{
		height: 40,
		border: "none",
		borderRadius: 20,
		color: "white",
		background: "linear-gradient(to top right, #004ddb, #168df8)",
		fontSize: 18,
		fontFamily: "FiraGO",
		cursor: "pointer",
		outline: "none",
		padding: "0px 40px",
		marginTop: 30,
		"&:hover": {
			// backgroundColor: "#11429b"
		},
	},
	(props: { isFaded?: boolean }) => {
		if (props.isFaded) return { opacity: 0.2 };
		return;
	}
);
