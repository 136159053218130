import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
	IFinishPageProps,
	ITestFinishArgs,
} from "@tests-core/components/tests";
import testStyles from "@app/components/users/tests/style/new-styles.module.css";
import { ITestNavigationProps } from "@tests-core/components/tests/navigation";
import { IRGETTestContents } from "@app/api/tests/validators";
import { IUserTestQuestionInfo, IRTest } from "@app/api/tests/helper-schemas";
import { openConfirmationPopup } from "../widgets/confirmation-popup";
import { getFormattedMessage } from "@app/utils/locale";
import {
	ITestTypeSettings,
	TestTypeAnswersShowTime,
} from "@app/api/test-types/helper-schemas";
import { UniversalTestWrapper2 } from "../users/tests/wrapper-2";
import { tryPromiseMultipleTimes } from "@app/utils/promises";
import { IRGETGetPublicModelling } from "@app/api/modeling/validators";
import { inject } from "@app/modules";
import FancyLoading from "../widgets/fancy-loading";
import triggerEvent from "@app/utils/events";
import { match } from "react-router-dom";
import { PrimaryButton } from "../styles/buttons";
import Helmet from "react-helmet";
import { useBoolean } from "@app/hooks/general";
import { IModelingTest } from "@app/api/modeling/helper-schemas";
import { newContent } from "@tests-core/components/questions/contents/new-content";
import { IFullQuestion } from "@tests-core/schemas/questions/helper-schemas";

export const PublicModellingTest: React.FC<{
	match: match<{ modellingId: string }>;
}> = ({ match }) => {
	const { value: hasStarted, setTrue: start } = useBoolean();
	const modellingId = match.params.modellingId;
	const [modellingInfo, setModellingInfo] = useState<
		IRGETGetPublicModelling
	>();

	useEffect(() => {
		const controller = inject("ModelingController");
		controller
			.getPublic({ testId: +modellingId })
			.then(setModellingInfo)
			.catch(() => {
				openConfirmationPopup({
					text: getFormattedMessage("errorAlert"),
				});
			});
	}, [modellingId]);

	const onFinish = useCallback(() => {
		triggerEvent(
			{
				category: "Test",
				action: "Modelling test",
				label: "finish",
			},
			{
				modellingId: +modellingId,
			}
		);
	}, [modellingId]);

	const handleStart = useCallback(() => {
		triggerEvent(
			{
				category: "Test",
				action: "Modelling test",
				label: "start",
			},
			{
				modellingId: +modellingId,
			}
		);
		start();
	}, [modellingId, start]);

	const helmet = (
		<Helmet>
			<title>მოდელირებები</title>
		</Helmet>
	);

	if (!modellingInfo) {
		return (
			<React.Fragment>
				{helmet}
				<FancyLoading />
			</React.Fragment>
		);
	}

	if (!hasStarted) {
		return (
			<React.Fragment>
				{helmet}
				<StartPage
					modelling={modellingInfo.modelling}
					onStart={handleStart}
					test={modellingInfo.test}
				/>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			{helmet}
			<Quiz
				content={{
					questions: modellingInfo.questions,
					texts: modellingInfo.texts,
					cards: modellingInfo.cards,
				}}
				test={modellingInfo.test}
				onFinish={onFinish}
			/>
		</React.Fragment>
	);
};

interface IProps {
	content: IRGETTestContents;
	test: IRTest;
	onFinish: (args: ITestFinishArgs) => void;
}

interface IState {
	isFinished: boolean;
}

class Quiz extends React.PureComponent<IProps, IState> {
	defaultUserAnswers: IUserTestQuestionInfo[] = [];
	content = this.props.content;

	state = {
		isFinished: false,
	};

	save = async (args: ITestFinishArgs) => {
		this.setState({
			isFinished: true,
		});
		this.props.onFinish(args);
	};

	handleTestError = (e: any) => {
		openConfirmationPopup({
			text: getFormattedMessage("student:test.errorWhileSaving"),
		});
		throw e;
	};

	onFinish = async (args: ITestFinishArgs) => {
		return tryPromiseMultipleTimes({
			getPromise: () => this.save(args),
			maxTrials: 6,
			delayBetweenTrials: 100,
		}).catch(this.handleTestError);
	};
	onSave = async (args: ITestFinishArgs) => {
		return tryPromiseMultipleTimes({
			getPromise: () => this.save(args),
			maxTrials: 6,
			delayBetweenTrials: 100,
		}).catch(this.handleTestError);
	};

	onGotoNext = () => {
		//
	};
	redirectoToFolder = () => {
		//
	};

	testTypeSettings: ITestTypeSettings = {
		allowSwitchingToSubmittedQuestions: true,
		allowSwitchingToUnsubmittedQuestions: true,
		checkInBackEnd: false,
		maxNumOfWritingTests: 1,
		showAnswersAt: TestTypeAnswersShowTime.afterFinish,
		submitAnswerAfterAnswering: false,
	};

	preFinishHook = (): Promise<{ finish: boolean }> => {
		return new Promise((resolve, reject) => {
			openConfirmationPopup({
				text: "ნამდვილად გსურთ მოდელირების დასრულება?",
				approveTitle: getFormattedMessage("yes"),
				rejectTitle: getFormattedMessage("no"),
				onApprove: () => {
					resolve({ finish: true });
				},
				onReject: () => {
					resolve({ finish: false });
				},
			});
		});
	};

	WrapperRef = React.createRef<UniversalTestWrapper2>();

	forceFullyFinish = async () => {
		const test = this.WrapperRef.current?.testComponentRef.current;
		if (!test) return;
		test.safeFinish().catch(() => {
			//
		});
	};

	render() {
		return (
			<div className="rest-container-of-page">
				<TouAd />
				<UniversalTestWrapper2
					ref={this.WrapperRef}
					test={this.props.test}
					testTypeSettings={this.testTypeSettings}
					content={this.content}
					defaultUserAnswers={this.defaultUserAnswers}
					onFinish={this.onFinish}
					onSave={this.onSave}
					onGotoNext={this.onGotoNext}
					currentAttempt={1}
					FinishPageComponent={FinishPageComponent}
					testNavigationProps={{
						styles: testDesign,
						showFinishPageIcon: true,
					}}
					preFinishHook={this.preFinishHook}
				/>
				<div
					className="main"
					style={{
						padding: "0 20px",
						marginTop: "-20px",
					}}
				>
					{!this.state.isFinished && (
						<PrimaryButton
							onClick={this.forceFullyFinish}
							style={{ backgroundColor: "#a375ff" }}
						>
							დასრულება
						</PrimaryButton>
					)}
				</div>
			</div>
		);
	}
}

const FinishPageComponent: React.FC<IFinishPageProps> = props => {
	const maxScore = useMemo(() => {
		try {
			let sum = 0;
			for (const q of props.questions) {
				sum += newContent((q as IFullQuestion).content).getMaxCredit();
			}
			return sum;
		} catch (e) {
			console.warn(e);
		}
		return 0;
	}, [props.questions]);
	console.log("maxScore", maxScore);

	const correctAnswers = props.info.userAnswers.filter(
		e =>
			e.credit === e.maxCredit &&
			typeof e.maxCredit === "number" &&
			e.maxCredit > 0
	).length;
	const totalNumber = props.info.userAnswers.filter(
		e => typeof e.maxCredit === "number" && e.maxCredit > 0
	).length;
	const score = props.info.totalCredit;

	return (
		<div>
			<div
				style={{
					fontFamily: "Roboto Geo Caps",
					textAlign: "center",
					color: "#5273e6",
					fontSize: 22,
				}}
			>
				<span
					style={{ fontSize: 27, marginBottom: 10, display: "block" }}
				>
					შენ დააგროვე {score} ქულა.
				</span>
				<br />
				<span style={{ fontSize: 20, marginBottom: 10 }}>
					{" "}
					მიზნამდე ახლოა! ყველაფერი, რაც მიზნის მისაღწევად გჭირდება,
					მხოლოდ შენს ხელშია! დაუკოპირე ლინკი შენს მეგობრებს და
					დაეხმარე მათაც გაიარონ მოდელირების ტესტი:{" "}
					<a
						href="https://murtsku.com/tou-modelireba.php"
						target="__blank"
					>
						https://murtsku.com/tou-modelireba.php
					</a>{" "}
					ღია უნივერსიტეტში შენთვის სუპერ შესაძლებლობები გვაქვს!
					შეკითხვები შეგიძლია პირდაპირ ღია უნივერსიტეტის მარკეტინგის
					ხელმძღვანელს დაუსვა: 577044999 დავით გვეტაძე
				</span>
			</div>
		</div>
	);
};

export const testDesign: NonNullable<ITestNavigationProps["styles"]> = {
	item: {
		container: testStyles.itemContainer,
		isFinishPage: testStyles.finishItem,
		isStartPage: testStyles.startItem,
		isSelected: testStyles.isSelected,
		containsGradableItemsByEditor: testStyles.containsGradableItemsByEditor,
		isAccessible: testStyles.isAccessible,
		isNotAccessible: testStyles.isNotAccessible,
		hasAnswered: testStyles.hasAnswered,
		hasAnsweredFully: testStyles.hasAnsweredFully,
		hasAnsweredCorreclty: testStyles.hasAnsweredCorreclty,
		hasAnsweredPartiallyCorreclty: testStyles.hasAnsweredPartiallyCorreclty,
		hasAnsweredIncorreclty: testStyles.hasAnsweredIncorreclty,
	},
	OuterContainer: testStyles.outerContainer,
	ContainerWithScrollbar: testStyles.containerWithScrollbar,
};

const StartPage: React.FC<{
	modelling: IModelingTest;
	test: IRTest;
	onStart: () => void;
}> = ({ modelling, test, onStart }) => {
	const mainText = (
		<>
			მიზნამდე ახლოა! ყველაფერი, რაც მიზნის მისაღწევად გჭირდება, მხოლოდ
			შენს ხელშია!
			<br />
			<br />
			დაუკოპირე ლინკი შენს მეგობრებს და დაეხმარე მათაც გაიარონ მოდელირების
			ტესტი:{" "}
			<a href="https://murtsku.com/tou-modelireba.php" target="__blank">
				https://murtsku.com/tou-modelireba.php
			</a>
			<br />
			ღია უნივერსიტეტს შენი შესაძლებლობების სჯერა!
			<br />
		</>
	);
	const body = useMemo(() => {
		if (modelling.subject_id === 8) {
			return (
				<>
					გთხოვთ, გაითვალისწინოთ, რომ ონლაინ მოდელირების დროს
					შეუძლებელია წერილისა და არგუმენტირებული ესეს შემოწმება, რის
					გამოც მოდელირების მაქსიმალური ქულა შეადგენს 58-ს.
				</>
			);
		}
		if (modelling.subject_id === 7) {
			return (
				<>
					გთხოვთ, გაითვალისწინოთ, რომ ONLINE მოდელირების დროს
					შეუძლებელია ამოცანების ამოხსნის გზის შეფასება, რის გამოც
					თითოეული საკითხის მაქსიმალური ქულა შეადგენს 1-ს, ხოლო
					მოდელირების ჯამური ქულა არის 40.
				</>
			);
		}
		if (modelling.subject_id === 17) {
			return (
				<>
					გთხოვ, გაითვალისწინე, რომ ონლაინ მოდელირების დროს
					არგუმენტაციის სავარჯიშოს შემოწმება შეუძლებელია, რის გამოც
					ტესტი წარმოდგენილია არასრულად.
					<br />
					შესაბამისად, მოდელირების ჯამური ქულა შეადგენს არა 60-ს,
					არამედ 55 ქულას.
				</>
			);
		}
		return null;
	}, [modelling.subject_id]);
	return (
		<div style={{ textAlign: "center" }} className="main">
			<TouAd />
			<h1>{test.name}</h1>
			{mainText}
			<br />
			{body}
			<br />
			<br />
			თბილისის ღია უნივერსიტეტი გისურვებს წარმატებას!
			<br />
			<PrimaryButton onClick={onStart}>დაწყება</PrimaryButton>
		</div>
	);
};

const TouAd = () => {
	return (
		<div className="main" style={{ textAlign: "center", marginTop: 20 }}>
			<img
				src="https://murtsku.com/css/img/tou/logo-085.jpg"
				style={{
					display: "block",
					margin: "0px auto",
					maxWidth: "90%",
					width: 443,
				}}
				alt="logo"
			/>
		</div>
	);
};
