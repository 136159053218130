import { AppealSchema, IAppeal } from "./helper-schemas";
import { insertStripKeys, ToInsertKeys } from "../helper-schemas";
import Joi from "@app/utils/joi";
import { ObjectId } from "@app/utils/generics";
import { itemTypes, ItemType } from "../folders/helper-schemas";

export const APOSTAppealSchema = AppealSchema.keys(insertStripKeys).keys({
	ip: Joi.any().strip(),
});
export type IAPOSTAppeal = ToInsertKeys<IAppeal, never, "ip">;

export const RPOSTAppealSchema = AppealSchema;
export type IRPOSTAppeal = IAppeal;

///

export const RGETAppealCountsSchema = Joi.array().items(
	Joi.object({
		courseId: Joi.objectId().required(),
		count: Joi.number().required(),
	})
);

export type IRGETAppealCounts = { courseId: ObjectId; count: number }[];

///

export const AGETAppealsByCourseSchema = Joi.object({
	courseId: Joi.objectId().required(),
});

export interface IAGETAppealsByCourse {
	courseId: ObjectId;
}

export const RGETAppealsByCourseSchema = Joi.array().items(
	Joi.object({
		itemId: Joi.objectId(),
		itemType: Joi.number()
			.valid(...itemTypes)
			.required(),
		name: Joi.string()
			.allow("")
			.required(),
		appeals: Joi.array()
			.items(AppealSchema)
			.required(),
	})
);

export type IRGETAppealsByCourse = {
	itemId: ObjectId;
	itemType: ItemType;
	name: string;
	appeals: IAppeal[];
}[];

///

export const ADELETEAppealSchema = Joi.object({
	_id: Joi.objectId().required(),
});

export interface IADELETEAppeal {
	_id: ObjectId;
}

///

export const ADELETEManyAppealsSchema = Joi.object({
	ids: Joi.array().items(Joi.objectId().required()),
});

export interface IADELETEManyAppeals {
	ids: ObjectId[];
}
