import { cardsReducer } from "@app/models/card";
import { cardTemplatesReducer } from "@app/models/card-template";
import { coursesReducer } from "@app/models/course";
import { filesReducer } from "@app/models/file";
import { foldersReducer } from "@app/models/folder";
import { folderHierarchiesReducer } from "@app/models/folder-hierarchy";
import { lastOptionsReducer } from "@app/models/last-option";
import { taskTypesReducer } from "@app/models/task-type";
import { taskTypeHierarchiesReducer } from "@app/models/task-type-hierarchy";
import { testsReducer } from "@app/models/test";
import { testTypesReducer } from "@app/models/test-type";
import { topicsReducer } from "@app/models/topic";
import { topicHierarchiesReducer } from "@app/models/topic-hierarchy";
import { userFolderLevelsReducer } from "@app/models/user-folder-level";
import { userFolderProgressesReducer } from "@app/models/user-folder-progress";
import { userTaskTypeLevelsReducer } from "@app/models/user-task-type-level";
import { userTopicLevelsReducer } from "@app/models/user-topic-level";
import { UserActions } from "@app/redux/actions/user";
import { IntlAction, intlReducer } from "react-intl-redux";
import { combineReducers } from "redux";
import user from "./reducers/user";
import { appealsReducer } from "@app/models/appeal";

export const appRootReducer = combineReducers({
	intl: intlReducer,
	user,
	courses: coursesReducer,
	folders: foldersReducer,
	testTypes: testTypesReducer,
	tests: testsReducer,
	files: filesReducer,
	cardTemplates: cardTemplatesReducer,
	cards: cardsReducer,
	userFolderProgresses: userFolderProgressesReducer,
	folderHierarchies: folderHierarchiesReducer,
	topics: topicsReducer,
	topicHierarchies: topicHierarchiesReducer,
	taskTypes: taskTypesReducer,
	taskTypeHierarchies: taskTypeHierarchiesReducer,
	userFolderLevels: userFolderLevelsReducer,
	userTaskTypeLevels: userTaskTypeLevelsReducer,
	userTopicLevels: userTopicLevelsReducer,
	lastOptions: lastOptionsReducer,
	appeals: appealsReducer,
});

export type IRootState = ReturnType<typeof appRootReducer>;

export type IRootActions = IntlAction | UserActions;
