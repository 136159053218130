import * as React from "react";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import Close from "@material-ui/icons/Close";
import GeneralInput, { getComponentForType, getEmptyValue } from "./inputs";
import { arrayTypeToSingle, isPropertyTypeArray } from "../../utils/template";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { ICardProperty, IEntry } from "../../schemas/cards";
import {
	ICardTemplate,
	ICardTemplateProperty,
} from "../../schemas/cards/templates";
import { IIdGenerators } from "../questions/contents/interfaces";
import { draggableContainerClassname, draggableLabel } from "./index";

interface ICardRowProps {
	property: ICardTemplateProperty;
	template: ICardTemplate;
	entries?: IEntry | IEntry[];
	onChange: (newProp: ICardProperty) => void;
	onAdd?: (newProp: ICardProperty) => void;
	derivedFromArray?: boolean;
	generateUniqueIds: IIdGenerators["generateUniqueIds"];
}

export default class CardRow extends React.PureComponent<ICardRowProps> {
	onAddIconClick = () => {
		if (!Array.isArray(this.props.entries)) {
			return;
		}
		const singleType = arrayTypeToSingle(this.props.property.type);
		this.props.onChange({
			id: this.props.property.id,
			entries: [
				...this.props.entries!,
				{
					id: this.props.generateUniqueIds()[0],
					val: getEmptyValue(singleType),
				},
			] as IEntry[],
		});
	};

	onLabelDelete = labelId => {
		if (!this.props.entries) {
			return;
		}
		if (Array.isArray(this.props.entries)) {
			return;
		} // TODO: support arrays too
		this.props.onChange({
			id: this.props.property.id,
			entries: {
				...this.props.entries!,
				labels: this.props.entries.labels!.filter(e => e !== labelId),
			},
		});
	};

	fillWithEmptyEntry = () => {
		if (this.props.derivedFromArray || !this.props.onAdd) return;
		if (!isPropertyTypeArray(this.props.property.type)) {
			this.props.onAdd({
				id: this.props.property.id,
				entries: {
					id: this.props.generateUniqueIds()[0],
					val: getEmptyValue(this.props.property.type),
				},
			});
		} else {
			const singleType = arrayTypeToSingle(this.props.property.type);
			this.props.onAdd({
				id: this.props.property.id,
				entries: [
					{
						id: this.props.generateUniqueIds()[0],
						val: getEmptyValue(singleType),
					},
				],
			});
			// TODO:
		}
	};

	propArrayChange = (newProp: ICardProperty) => {
		this.props.onChange({
			id: this.props.property.id,
			entries: (this.props.entries as IEntry[]).map(entry =>
				entry.id === (newProp.entries as IEntry).id
					? (newProp.entries as IEntry)
					: entry
			),
		});
	};
	render() {
		const { entries } = this.props;
		if (entries === undefined) {
			setTimeout(this.fillWithEmptyEntry, 0);
			return null; // TODO: get empty value
		}
		if (isPropertyTypeArray(this.props.property.type)) {
			return (
				<div>
					<div style={{ marginRight: 10 }}>
						{this.props.property.name}
					</div>
					{(entries as IEntry[]).map((e, index) => (
						<CardRow
							key={e.id}
							property={{
								...this.props.property,
								type: arrayTypeToSingle(
									this.props.property.type
								),
							}}
							template={this.props.template}
							entries={e}
							onChange={this.propArrayChange}
							derivedFromArray={true}
							generateUniqueIds={this.props.generateUniqueIds}
						/>
					))}
					<AddIcon onClick={this.onAddIconClick} />
				</div>
			);
		}
		const entry = entries as IEntry;
		return (
			<div style={{ display: "flex" }}>
				{!this.props.derivedFromArray && (
					<div style={{ marginRight: 10 }}>
						{this.props.property.name}
					</div>
				)}
				<div>
					<GeneralInput
						entry={entry}
						onChange={this.props.onChange}
						type={this.props.property.type}
						propId={this.props.property.id}
						component={
							getComponentForType(this.props.property.type) as any
						}
					/>
					<Droppable
						droppableId={
							"entryLabels" +
							entry.id +
							":propertyId" +
							this.props.property.id
						}
						direction={"horizontal"}
					>
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								style={{ paddingBottom: 10 }}
							>
								{entry.labels &&
									entry.labels!.map((labelId, index) => {
										const label = this.props.template.labels.find(
											e => e.id === labelId
										);
										if (!label) {
											return null;
										}
										return (
											<Draggable
												key={labelId}
												draggableId={
													"entry" +
													entry.id +
													":propertyId" +
													this.props.property.id +
													":labelId" +
													labelId
												}
												index={index}
											>
												{(provided2, snapshot2) => (
													<div
														ref={provided2.innerRef}
														{...provided2.draggableProps}
														{...provided2.dragHandleProps}
														className={
															draggableContainerClassname
														}
													>
														<div
															className={
																draggableLabel
															}
															style={{
																display:
																	"relative",
															}}
														>
															{label.name}
															<div
																style={{
																	cursor:
																		"pointer",
																	display:
																		"inline-block",
																	verticalAlign:
																		"middle",
																}}
																onClick={() =>
																	this.onLabelDelete(
																		labelId
																	)
																}
															>
																<Close />
															</div>
														</div>
													</div>
												)}
											</Draggable>
										);
									})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</div>
			</div>
		);
	}
}
