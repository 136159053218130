import { arrayToObject } from "@app/utils/common";
import * as React from "react";
import FancyLoading from "../widgets/fancy-loading";
import { ObjectId } from "@app/utils/generics";
import { IUserTestQuestionInfo } from "@app/api/tests/helper-schemas";
import {
	IFullQuestion,
	IQuestionItemsAssessment,
} from "@tests-core/schemas/questions/helper-schemas";
import QuestionContentTestMode from "@tests-core/components/questions/contents";
import { PrimaryButtonWithArrow, PrimaryButton } from "../styles/buttons";
import { getQuestionsCustomizedProps } from "@app/components/admin/questions/custom-props";
import { mergeComponentObjects } from "@tests-core/utils";
import memoizeOne from "memoize-one";
import { IQContentPassableProps } from "@tests-core/components/questions/contents";
import { getGradableItemsByEditor } from "@tests-core/components/questions/contents/grading";
import { IFBNonCheckableInputAssessmentProps } from "@tests-core/components/questions/contents/filling-blanks";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { css } from "emotion";
import { IRGETUsersByTest } from "@app/api/tests/validators";
import { Omit } from "@app/utils/generics";
import { inject } from "@app/modules";
import { getLocale } from "@app/hooks/intl";
import { FormattedMessage } from "react-intl";

export type IAOnUserTestInfoSave = Omit<
	IRGETUsersByTest[number],
	"userId" | "startedAt" | "attempt"
>;

interface IProps {
	courseId: ObjectId;
	folderId: ObjectId;
	testId: ObjectId;
	userId: number;
	attempt: number;
	originalCourseId: ObjectId;
	onReturnToMainPage: () => void;
	onUserTestInfoSave: (newInfo: IAOnUserTestInfoSave) => void;
}

interface IState {
	userAnswers: { [id: string]: IUserTestQuestionInfo | undefined };
	gradableTestQuestions: IFullQuestion[];
	allTestQuestions: IFullQuestion[];
	showAllQuestions: boolean;
	loading: boolean;
	adminCredits: {
		[qId: string]:
			| {
					numGradableItems: number;
					gradedItems?: {
						[itemId: number]: number | undefined;
					};
			  }
			| undefined;
	};
}

const nonChecableInputContainerCSS = css`
	border: 2px solid #14a2f5;
	margin: 5px;
	padding: 3px;
`;

const nonCheckableInputContainerCheckedCSS = css`
	border: 2px solid #00dcff;
`;

class GradeUserTestQuestions extends React.Component<IProps, IState> {
	state: IState = {
		userAnswers: {},
		gradableTestQuestions: [],
		allTestQuestions: [],
		showAllQuestions: false,
		loading: true,
		adminCredits: {},
	};
	_isMounted = false;

	_TestsController = inject("TestsController");
	_QuestionsController = inject("QuestionsController");

	componentDidMount() {
		this._isMounted = true;
		const {
			courseId,
			originalCourseId,
			folderId,
			testId,
			userId,
			attempt,
		} = this.props;
		this._TestsController
			.getUserTestForAdmin({
				courseId,
				folderId,
				testId,
				userId,
				attempt,
			})
			.then(data1 => {
				if (data1) {
					const qIds: ObjectId[] = data1.questions.map(q => q.id);
					this._QuestionsController
						.getBulk({
							ids: qIds,
							courseId: originalCourseId,
							getAnswers: true,
						})
						.then(data2 => {
							const filteredQuestions: IFullQuestion[] = [];
							const adminCreditsInitialObj: IState["adminCredits"] = {};
							for (const q of data2 as IFullQuestion[]) {
								const gradableItems = getGradableItemsByEditor(
									q.content
								);
								if (gradableItems.length > 0) {
									filteredQuestions.push(q);
									adminCreditsInitialObj[q._id] = {
										numGradableItems: gradableItems.length,
									};
								}
							}
							if (data1.editorAssessments) {
								const gradedQuestions =
									data1.editorAssessments.questions;
								for (const qId of Object.keys(
									gradedQuestions
								)) {
									const gradedItems: {
										[itemId: number]: number;
									} = {};
									for (const itemId of Object.keys(
										gradedQuestions[qId]!.items
									)) {
										gradedItems[itemId] = gradedQuestions[
											qId
										]!.items[itemId].credit;
									}
									adminCreditsInitialObj[qId] = {
										...adminCreditsInitialObj[qId]!,
										gradedItems,
									};
								}
							}
							this.setState({
								userAnswers: arrayToObject(
									data1.questions,
									"id"
								),
								allTestQuestions: data2 as IFullQuestion[],
								gradableTestQuestions: filteredQuestions as IFullQuestion[],
								adminCredits: adminCreditsInitialObj,
								loading: false,
							});
						});
				}
			});
	}

	onAdminCreditChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		qId: string,
		itemId: number
	) => {
		this.setState({
			adminCredits: {
				...this.state.adminCredits,
				[qId]: {
					...this.state.adminCredits[qId]!,
					gradedItems: {
						...this.state.adminCredits[qId]!.gradedItems!,
						[itemId]: +e.target.value,
					},
				},
			},
		});
	};

	onSaveAdminCredits = () => {
		const credits = this.state.adminCredits;
		let totalAssessmentProgress = 0;
		const allGradableQuestionIds: string[] = Object.keys(credits);
		for (const questionId of allGradableQuestionIds) {
			const gradableQuestion = credits[questionId]!;
			if (!gradableQuestion.gradedItems) continue;
			const gradedItemIds = Object.keys(gradableQuestion.gradedItems);
			const assessmentProgress =
				gradedItemIds.length / gradableQuestion.numGradableItems;
			totalAssessmentProgress += assessmentProgress;
		}
		this._TestsController
			.saveAdminCredits({
				courseId: this.props.courseId,
				folderId: this.props.folderId,
				testId: this.props.testId,
				userId: this.props.userId,
				attempt: this.props.attempt,
				credits: this.state.adminCredits,
			})
			.then(() => {
				this.props.onUserTestInfoSave({
					assessmentProgress:
						totalAssessmentProgress / allGradableQuestionIds.length,
					courseId: this.props.courseId,
				});
				this.props.onReturnToMainPage();
			});
	};

	onShowAllQuestionsClick = () => {
		this.setState({ showAllQuestions: !this.state.showAllQuestions });
	};

	defaultTestProps = {
		fbProps: {
			components: {
				nonCheckable: {
					inner: {
						Assessment: props => {
							return (
								<GradeComponent
									{...props}
									onAdminCreditChange={
										this.onAdminCreditChange
									}
								/>
							);
						},
					},
				},
			},
			styles: {
				nonCheckableInput: {
					Container: nonChecableInputContainerCSS,
					CheckedContainer: nonCheckableInputContainerCheckedCSS,
				},
			},
		},
	} as IQContentPassableProps;

	getMergedProps = memoizeOne((locale: string) => {
		return mergeComponentObjects(
			this.defaultTestProps,
			getQuestionsCustomizedProps(locale, false)
		);
	});

	toLaa = (items: { [itemId: number]: number | undefined } = {}) => {
		const assessmentItems: IQuestionItemsAssessment = {};
		for (const itemId in items) {
			assessmentItems[itemId] = {
				credit: items[itemId] || 0,
			};
		}
		return assessmentItems;
	};

	changeAnswer = () => {
		//
	};

	render() {
		if (this.state.loading) return <FancyLoading />;
		const mergedCustomProps = this.getMergedProps(getLocale());
		let questions;
		let text;
		if (this.state.showAllQuestions) {
			questions = this.state.allTestQuestions;
			text = questions.length + " კითხვა";
		} else {
			questions = this.state.gradableTestQuestions;
			text =
				questions.length +
				" კითხვა " +
				this.state.allTestQuestions.length +
				" - დან";
		}

		const seeAllQuestions = (
			<FormControlLabel
				control={
					<Checkbox
						checked={this.state.showAllQuestions}
						onChange={this.onShowAllQuestionsClick}
					/>
				}
				label={"ყველა კითხვის ნახვა"}
			/>
		);
		return (
			<div className="main">
				<PrimaryButtonWithArrow
					onClick={this.props.onReturnToMainPage}
				/>
				<h3>{text}</h3>
				{seeAllQuestions}
				<br />
				{questions.map(question => {
					const gradedAssessments =
						this.state.adminCredits[question._id] &&
						this.state.adminCredits[question._id]!.gradedItems;
					return (
						<div
							key={question._id}
							className={QuestionContainerCSS}
						>
							<QuestionContext.Provider value={question._id}>
								<QuestionContentTestMode
									content={question.content}
									onUserAnswerChange={this.changeAnswer}
									displayAnswer={true}
									userAnswer={
										(
											this.state.userAnswers[
												question._id
											] || ({} as any)
										).userAnswer
									}
									customized={mergedCustomProps}
									displayItemAssessments={true}
									itemsAssessments={this.toLaa(
										gradedAssessments
									)}
								/>
							</QuestionContext.Provider>
						</div>
					);
				})}
				{seeAllQuestions}
				<br />
				<PrimaryButton onClick={this.onSaveAdminCredits}>
					<FormattedMessage id="save" />
				</PrimaryButton>
				<br />
				<PrimaryButtonWithArrow
					onClick={this.props.onReturnToMainPage}
				/>
			</div>
		);
	}
}
const QuestionContext = React.createContext("");

const QuestionContainerCSS = css`
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 5px;
	margin: 20px 0;
	font-family: FiraGo;
`;

interface IGradeComponentProps extends IFBNonCheckableInputAssessmentProps {
	onAdminCreditChange: (
		e: React.ChangeEvent<HTMLInputElement>,
		questionId: ObjectId,
		itemId: number
	) => void;
}

const GradeComponent: React.FC<IGradeComponentProps> = props => {
	const questionId = React.useContext(QuestionContext);
	return (
		<input
			type="number"
			defaultValue={
				props.itemAssessment &&
				(props.itemAssessment.credit === undefined
					? undefined
					: "" + props.itemAssessment.credit)
			}
			placeholder={"ქულა"}
			onChange={e => {
				props.onAdminCreditChange(e, questionId, props.itemId);
			}}
		/>
	);
};

export default GradeUserTestQuestions;
