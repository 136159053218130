import { ObjectId } from "@app/utils/generics";
import React from "react";
import { inject } from "@app/modules";
import FancyLoading from "@app/components/widgets/fancy-loading";
import { ITestTypeSettings } from "@app/api/test-types/helper-schemas";
import {
	IRTest,
	IEditorAssessments,
	IUserTestQuestionInfo,
} from "@app/api/tests/helper-schemas";
import { UniversalTestWrapper } from "./test-wrapper";
import { History } from "history";
import { ItemType } from "@app/api/folders/helper-schemas";
import { ITestFinishArgs } from "@tests-core/components/tests";
import { IRGETTestContents } from "@app/api/tests/validators";
import { addLoader } from "@app/commonJavascript";

interface IProps {
	courseId: ObjectId;
	testId: ObjectId;
	folderId: ObjectId;
	onGotoNext?: (testId: ObjectId, folderId: ObjectId) => void;
	userId: number;
	history: History;
}

interface IState {
	isLoading: boolean;
	userQuestionAnswers?: IUserTestQuestionInfo[];
	editorAssessments?: IEditorAssessments;
	attemptNumber?: number;
	info?: {
		test: IRTest;
		testTypeSettings: ITestTypeSettings;
		content: IRGETTestContents;
	};
}

export class TestLoader extends React.PureComponent<IProps, IState> {
	_FoldersController = inject("FoldersController");
	_TestsController = inject("TestsController");
	_UserFolderProgressService = inject("UserFolderProgressService");
	_CourseFetchingController = inject("CourseFetchingController");

	state: IState = {
		isLoading: true,
	};

	_isMounted = false;

	removeLoader?: () => void;

	componentDidMount() {
		this._isMounted = true;
		Promise.all([
			this._FoldersController.getHierarchy({
				courseId: this.props.courseId,
			}),
			this._CourseFetchingController.loadWholeCourseSkeleton({
				courseId: this.props.courseId,
			}),
		]).then(() => {
			this.loadTest();
		});
	}

	componentWillUnmount() {
		this._isMounted = false;
		if (this.removeLoader) {
			this.removeLoader();
		}
	}

	loadTest = () => {
		return Promise.all([
			this._TestsController
				.loadTest({
					testId: this.props.testId,
					loadContent: true,
					folderId: this.props.folderId,
					courseId: this.props.courseId,
					getAnswers: true,
				})
				.then(data => {
					if (!this._isMounted) return;
					let questionsArray = data.content.questions;
					let cardsArray = data.content.cards;
					if (data.test.settings && data.test.settings.contentIds) {
						const contentIds = data.test.settings!.contentIds;
						const questionIdToIndex: Record<string, number> = {};
						const cardIdToIndex: Record<string, number> = {};
						let index = -1;
						for (const content of contentIds) {
							index++;
							if (content.type === ItemType.question) {
								questionIdToIndex[content.id] = index;
							} else if (content.type === ItemType.card) {
								cardIdToIndex[content.id] = index;
							}
						}
						questionsArray = [...questionsArray].sort((q1, q2) => {
							return (
								questionIdToIndex[q1._id] -
								questionIdToIndex[q2._id]
							);
						});
						cardsArray = [...cardsArray].sort((c1, c2) => {
							return (
								cardIdToIndex[c1._id] - cardIdToIndex[c2._id]
							);
						});
					}
					// TODO: filter contentIds that have not been found
					this.setState({
						info: {
							test: data.test,
							testTypeSettings: data.testTypeSettings,
							content: {
								...data.content,
								questions: questionsArray,
								cards: cardsArray,
							},
						},
					});
				}),
			this.getAttemptNumber().then(attemptNumber => {
				if (!this._isMounted) return;
				this.setState({
					attemptNumber,
				});
				return this._TestsController
					.getUserTest({
						courseId: this.props.courseId,
						folderId: this.props.folderId,
						testId: this.props.testId,
						attempt: attemptNumber,
					})
					.then(data => {
						if (!this._isMounted) return;
						if (data) {
							this.setState({
								userQuestionAnswers: data.questions,
								editorAssessments: data.editorAssessments,
							});
						} else {
							this.setState({
								userQuestionAnswers: [],
								editorAssessments: undefined,
							});
						}
					});
			}),
		]).then(() => {
			if (!this._isMounted) return;
			this.setState({
				isLoading: false,
			});
		});
	};

	getAttemptNumber = async () => {
		const attemptNumber = this._UserFolderProgressService.getAttemptNumberSync(
			{
				courseId: this.props.courseId,
				folderId: this.props.folderId,
				itemId: this.props.testId,
				itemType: ItemType.test,
				userId: this.props.userId,
			}
		);
		return attemptNumber;
	};

	saveTest = async (args: ITestFinishArgs) => {
		if (!args.hasChangedAtLeastOneAnswer) return;
		const isMounted = this._isMounted;
		let removeLoader: () => void;
		if (isMounted) {
			removeLoader = addLoader();
			this.removeLoader = removeLoader;
		}
		return this._TestsController
			.finish({
				courseId: this.props.courseId,
				folderId: this.props.folderId,
				testId: this.props.testId,
				questions: args.userAnswers
					.filter(
						e => e.userAnswer !== null && e.userAnswer !== undefined
					)
					.map((e): IUserTestQuestionInfo & {
						maxCredit: number;
					} => ({
						id: e.questionId,
						credit: e.credit || 0,
						maxCredit: e.maxCredit!,
						numAttempts: 1,
						userAnswer: e.userAnswer!,
						answeredAt: e.dates.lastAnsweredAt || new Date(),
					})),
				score: args.unassessedCredit,
				progress: Math.min(
					1,
					Math.max(
						0,
						args.numOfKnownUniqueQuestions === null
							? args.numOfAnsweredUniqueQuestions /
									args.questions.length
							: args.numOfAnsweredUniqueQuestions /
									args.numOfKnownUniqueQuestions
					)
				),
				attempt: this.state.attemptNumber!,
			})
			.then(() => {
				if (removeLoader) removeLoader();
			})
			.catch(e => {
				console.log(e);
				alert("დაფიქსირდა შეცდომა..");
				if (removeLoader) removeLoader();
			});
	};

	onFinish = (args: ITestFinishArgs) => {
		return this.saveTest(args);
	};
	onSave = (args: ITestFinishArgs) => {
		return this.saveTest(args);
	};

	onGotoNext = () => {
		if (this.props.onGotoNext) {
			this.props.onGotoNext(this.props.testId, this.props.folderId);
		} else {
			this.props.history.push(`/courses/${this.props.courseId}/guide`);
		}
	};

	redirectoToFolder = () => {
		this.props.history.push(
			`/courses/${this.props.courseId}/folders/${this.props.folderId}`
		);
	};

	onResetTestProgress = () => {
		this.setState({
			isLoading: true,
		});
		this._FoldersController
			.resetItemProgress({
				courseId: this.props.courseId,
				parentFolderId: this.props.folderId,
				itemId: this.props.testId,
				itemType: ItemType.test,
			})
			.then(() => {
				if (!this._isMounted) return;
				this.setState({
					userQuestionAnswers: undefined,
					editorAssessments: undefined,
					attemptNumber: undefined,
					isLoading: true,
				});
				return this.loadTest();
			})
			.then(() => {
				if (this._isMounted) {
					this.setState({
						isLoading: false,
					});
				}
			})
			.catch(e => {
				if (this._isMounted) {
					this.setState({
						isLoading: false,
					});
				}
				console.log(e);
				alert("უფს.. დაფიქსირდა შეცდომა");
			});
	};

	onResetFolders = (progressResetFolderIds: ObjectId[]) => {
		this._FoldersController
			.resetProgress({
				folderIds: progressResetFolderIds,
				courseId: this.props.courseId,
			})
			.then(() => {
				if (!this._isMounted) return;
				this.redirectoToFolder();
			});
	};

	render() {
		if (
			this.state.isLoading ||
			!this.state.info ||
			!this.state.userQuestionAnswers ||
			this.state.attemptNumber === undefined
		) {
			return <FancyLoading />;
		}

		const { info } = this.state;

		return (
			<div>
				<UniversalTestWrapper
					key={`${info.test._id}-${this.state.attemptNumber}`}
					test={info.test}
					testTypeSettings={info.testTypeSettings}
					content={info.content}
					defaultUserAnswers={this.state.userQuestionAnswers}
					onFinish={this.onFinish}
					onSave={this.onSave}
					onGotoNext={this.onGotoNext}
					onResetTestProgress={this.onResetTestProgress}
					onResetFolders={this.onResetFolders}
					redirectoToFolder={this.redirectoToFolder}
					courseId={this.props.courseId}
					folderId={this.props.folderId}
					currentAttempt={this.state.attemptNumber}
					editorAssessments={this.state.editorAssessments}
				/>
			</div>
		);
	}
}
