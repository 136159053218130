import * as React from "react";

interface IInputForNumber {
	val: number;
	onChange: (val: number) => void;
}

const InputForNumber: React.FC<IInputForNumber> = props => {
	return (
		<input
			defaultValue={props.val + ""}
			type="number"
			onChange={e => props.onChange(+e.target.value)}
		/>
	);
};

export default InputForNumber;
