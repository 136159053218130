import React from "react";

export const ConnectWithHooks = <P extends {}, K extends keyof P>(
	Component: React.ComponentClass<P>,
	hooks: ToFunctionValues<P, K>
) => {
	type Props = Subtract<P, GetReturnTypes<typeof hooks>>;
	const useConnectedComponent = React.forwardRef<
		React.ComponentClass<Props>,
		Props
	>((props: Props, ref) => {
		const values: any = {};
		for (const key in hooks) {
			const hook = hooks[key];
			if (!hook) continue;
			values[key] = (hook as any)(props);
		}
		return <Component {...props} {...values} />;
	});
	return React.memo(useConnectedComponent);
};

type GetReturnTypes<A extends {}> = {
	[key in keyof A]: A[key] extends (...args: any[]) => any
		? ReturnType<A[key]>
		: never;
};

type ToFunctionValues<Origin extends {}, K extends keyof Origin> = {
	[key in K]: (props: Origin) => Origin[key];
};

type Subtract<A extends {}, B extends {}> = {
	[key in Exclude<keyof A, keyof B>]: A[key];
};
