import { TimerProps } from "@tests-core/components/tests";
import { displayTime } from "@tests-core/components/tests/timer";
import { css } from "emotion";
import React from "react";

export const TestTimer = React.memo<TimerProps>(function TestTimer({ timeMs }) {
	if (timeMs < 0) return null;
	return (
		<div className={timerStyles}>
			{timeMs <= 0 ? (
				"დრო ამოწურულია"
			) : (
				<>
					დარჩენილი დრო: <span>{displayTime(timeMs)}</span>
				</>
			)}
		</div>
	);
});

const timerStyles = css`
	font-family: FiraGo;
	text-align: right;
	margin-bottom: 20px;
	& > span {
		font-family: Roboto;
		font-weight: bold;
		color: #5273e6;
	}
`;
