import * as React from "react";
import memoizeOne from "memoize-one";
import styled, { css } from "react-emotion";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import { IRootState } from "@app/redux";
import { ItemType } from "@app/api/folders/helper-schemas";
import { MainButton } from "../styles/styledComponents";
import { ObjectId } from "@app/utils/generics";
import { IFinishPageProps } from "@tests-core/components/tests";
import { ITestTypeSettings } from "@app/api/test-types/helper-schemas";
import { inject } from "@app/modules";
import { IStateFolders } from "@app/models/folder";

interface IOwnProps extends IFinishPageProps {
	courseId: ObjectId;
	folderId: ObjectId;
	testTypeSettings: ITestTypeSettings;
	redirectoToFolder: () => void;
	onResetTestProgress: () => void;
	onGotoNext: () => void;
	onResetFolders: (progressResetFolderIds: ObjectId[]) => void;
	currentAttempt: number;
}
type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IOwnProps & IStateProps;

interface IQuestionsFolders {
	[questionId: string]: ObjectId[] | undefined; // folderIds
}

interface IState {
	progressResetFolderIds: ObjectId[];
	isLoading: boolean;
}

class FeedbackPage extends React.Component<IProps, IState> {
	state: IState = {
		progressResetFolderIds: [],
		isLoading: false,
	} as IState;

	getTestFeedback = memoizeOne(
		(
			questions: IProps["questions"],
			userAnswersInfo: IProps["info"]["userAnswers"]
		) => {
			let numCorrectAnswers = 0;
			const mistakes: {
				[folderId: string]: IMistakeProps["mistake"] | undefined;
			} = {};
			this.searchFolderOfQuestionRecursively(this.props.folderId);
			for (let i = 0; i < userAnswersInfo.length; i++) {
				const ans = userAnswersInfo[i];
				let creditShare: number | undefined;
				if (ans.credit !== undefined && ans.maxCredit !== undefined) {
					creditShare = ans.credit / ans.maxCredit;
				}
				if (creditShare === 1) {
					numCorrectAnswers++;
				} else {
					const questionId = ans.questionId;
					const topicFolderIds = this.questionsFolders[questionId];
					if (!topicFolderIds) continue;
					for (let j = 0; j < topicFolderIds.length; ++j) {
						const topicFolderId = topicFolderIds[j];
						if (!mistakes[topicFolderId]) {
							mistakes[topicFolderId] = {
								folderId: topicFolderId,
								quantity: 1,
							};
						} else {
							mistakes[topicFolderId] = {
								...mistakes[topicFolderId]!,
								quantity: mistakes[topicFolderId]!.quantity + 1,
							};
						}
					}
				}
			}
			const res: {
				numCorrectAnswers: number;
				mistakes: IMistakeProps["mistake"][];
			} = {
				numCorrectAnswers,
				mistakes: Object.keys(mistakes).map(t => mistakes[t]!),
			};
			return res;
		}
	);

	questionsFolders: IQuestionsFolders = {};

	getSortedMistakes = memoizeOne((mistakes: IMistakeProps["mistake"][]) => {
		return [...mistakes].sort((a, b) => {
			return b.quantity - a.quantity;
		});
	});

	searchFolderOfQuestionRecursively = (folderId: ObjectId) => {
		if (!this.props.folders[folderId]) return;
		const currentFolderItems = this.props.folders[folderId]!.info.items;
		if (currentFolderItems) {
			for (let i = 0; i < currentFolderItems.length; i++) {
				if (currentFolderItems[i].type === ItemType.question) {
					const qId = currentFolderItems[i].id;
					if (!this.questionsFolders[qId]) {
						this.questionsFolders[qId] = [folderId];
					} else if (
						this.questionsFolders[qId]!.indexOf(folderId) === -1
					) {
						this.questionsFolders[qId]!.push(folderId);
					}
				}
				if (currentFolderItems[i].type === ItemType.folder) {
					this.searchFolderOfQuestionRecursively(
						currentFolderItems[i].id
					);
				}
			}
		}
	};

	onProgressResetStateChange = (folderId: ObjectId) => {
		if (this.state.progressResetFolderIds.indexOf(folderId) < 0) {
			this.setState({
				progressResetFolderIds: [
					...this.state.progressResetFolderIds,
					folderId,
				],
			});
		} else {
			this.setState({
				progressResetFolderIds: this.state.progressResetFolderIds.filter(
					id => id !== folderId
				),
			});
		}
	};

	onResetFolders = () => {
		if (this.state.progressResetFolderIds.length === 0) {
			alert("დასარესეტებლად მონიშნეთ მინიმუმ ერთი თემა");
			return;
		}
		this.props.onResetFolders(this.state.progressResetFolderIds);
	};

	_CoursesController = inject("CoursesController");

	componentDidMount() {
		if (!this.props.folders || Object.keys(this.props.folders).length < 1) {
			this._CoursesController.getAllFolders({
				courseId: this.props.courseId,
			});
		}
	}

	render() {
		const testFeedback = this.getTestFeedback(
			this.props.questions,
			this.props.info.userAnswers
		);
		const mistakes = this.getSortedMistakes(testFeedback.mistakes);
		const hasMistake: boolean = testFeedback.mistakes.some(
			e => e.quantity > 0
		);

		const maxNumOfWritingTests =
			typeof this.props.testTypeSettings.maxNumOfWritingTests !== "number"
				? Infinity
				: this.props.testTypeSettings.maxNumOfWritingTests;
		return (
			<div style={{ fontFamily: "BPGNinoMtavruli", textAlign: "center" }}>
				<HeadingStyle>
					თქვენ სწორად უპასუხეთ {testFeedback.numCorrectAnswers}{" "}
					კითხვას {this.props.info.userAnswers.length}-დან. კითხვების
					სწორი პასუხების სანახავად დააჭირეთ კითხვის ნომერს.
				</HeadingStyle>
				{hasMistake && (
					<HeadingStyle>
						გთხოვთ, თავიდან გაიაროთ თემები, რომლებშიც შეცდომები
						დაუშვით
					</HeadingStyle>
				)}
				<div className={MistakeAndWriteText} />
				{mistakes.map((mistake, index) => (
					<Mistake
						key={index}
						folders={this.props.folders}
						mistake={mistake}
						index={index}
						progressResetFolderIds={
							this.state.progressResetFolderIds
						}
						onProgressResetStateChange={
							this.onProgressResetStateChange
						}
					/>
				))}
				{hasMistake && (
					<MainButton onClick={this.onResetFolders}>
						მონიშნული თემების დარესეტება
					</MainButton>
				)}
				{maxNumOfWritingTests > this.props.currentAttempt && (
					<>
						<br />
						<br />
						<br />
						<div>გსურთ ამავე ტესტის ახლიდან დაწყება?</div>
						<MainButton
							onClick={this.props.onResetTestProgress}
							style={{ marginTop: 5 }}
						>
							ტესტის თავიდან დაწერა
						</MainButton>
					</>
				)}
				<br />
				<br />
				<MainButton onClick={this.props.onGotoNext}>
					განაგრძეთ კურსი
				</MainButton>
			</div>
		);
	}
}

interface IMistakeProps {
	folders: IStateFolders;
	mistake: { folderId: ObjectId; quantity: number };
	index: number;
	progressResetFolderIds: ObjectId[];
	onProgressResetStateChange: (folderId: ObjectId) => void;
}

const Mistake: React.FC<IMistakeProps> = props => {
	return (
		<div className={MistakeContainerStyle}>
			<MistakeStyle>
				{props.index === 0 && (
					<div
						style={{
							color: "#fd2f2f",
							position: "absolute",
							right: 10,
							top: -25,
						}}
					>
						შეცდომა
					</div>
				)}
				<MistakeTestStyle>
					{props.folders[props.mistake.folderId] &&
						props.folders[props.mistake.folderId]!.info.name}
				</MistakeTestStyle>
				<RedCircle>{props.mistake.quantity}</RedCircle>
			</MistakeStyle>
			<Reset>
				<FormControlLabel
					control={
						<Checkbox
							checked={
								props.progressResetFolderIds.indexOf(
									props.mistake.folderId
								) >= 0
							}
							onChange={() =>
								props.onProgressResetStateChange(
									props.mistake.folderId
								)
							}
						/>
					}
					label={"პროგრესის დარესეტება"}
				/>
			</Reset>
		</div>
	);
};

const HeadingStyle = styled("div")({
	maxWidth: "80%",
	margin: "30px auto",
	fontWeight: "bold",
	fontSize: 18,
	textAlign: "center",
});

const MistakeContainerStyle = css`
	maxwidth: 100%;
	height: 60px;
	display: flex;
	margin: 20px 100px;

	@media (max-width: 700px) {
		margin: 20px 10px;
	}
`;

const MistakeStyle = styled("div")({
	border: "1px solid #8c959f",
	borderRadius: 33,
	flex: 9,
	position: "relative",
	display: "flex",
});

const RedCircle = styled("div")({
	width: 40,
	height: 40,
	background: "#fd2f2f",
	color: "white",
	borderRadius: 50,
	position: "absolute",
	right: 10,
	top: 9,
	padding: 10,
	textAlign: "center",
	fontSize: 18,
	fontWeight: "bold",
});

const MistakeTestStyle = styled("div")({
	padding: "10px 55px 10px 20px",
	display: "flex",
	alignSelf: "center",
});

const Reset = styled("div")({
	flex: 1,
	position: "relative",
	padding: "0px 10px",
	display: "flex",
});

const MistakeAndWriteText = css`
	margin: 0px 100px;

	@media (max-width: 700px) {
		margin: 20px a0px;
	}
`;

const mapStateToProps = (state: IRootState) => ({
	folders: state.folders,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	FeedbackPage
);
