import { IRUser } from "@app/api/users/helper-schemas";
import {
	LOAD_USER_INFO,
	USER_AUTHORIZATION_SUCCESS,
	USER_LOG_OUT,
} from "@app/redux/action-types";
import { UserActions } from "@app/redux/actions/user";
import {
	defaultSpecialItemNameOfOtherTabsActions,
	defaultSpecialActionKeyOfOtherTabsActions,
} from "m-model-common";

function getNewUserState(
	state: IStateUser = {},
	action: UserActions
): IStateUser {
	switch (action.type) {
		case USER_AUTHORIZATION_SUCCESS:
			return {
				...state,
				loggedIn: true,
				userId: action.userData.id,
				userData: action.userData,
			};

		case LOAD_USER_INFO:
			return {
				...state,
				...action.user,
			} as IStateUser;
		case USER_LOG_OUT:
			return {};
		default:
			return state;
	}
}

export default function user(
	state: IStateUser = {},
	action: UserActions
): IStateUser {
	const newState = getNewUserState(state, action);

	if (
		newState !== state &&
		typeof (action as any)[defaultSpecialActionKeyOfOtherTabsActions] ===
			"undefined"
	) {
		localStorage.setItem("user", JSON.stringify(newState));
		localStorage.setItem(
			defaultSpecialItemNameOfOtherTabsActions,
			JSON.stringify({
				...action,
				[defaultSpecialActionKeyOfOtherTabsActions]: Math.random(),
			})
		);
	}
	return newState;
}

export interface IUnauthentificatedUser {
	loggedIn?: false;
	userId?: undefined;
	userData?: undefined;
}

export interface IAuthentificatedUser {
	loggedIn: true;
	userId: number;
	userData: IRUser;
}

export type IStateUser = IUnauthentificatedUser | IAuthentificatedUser;
