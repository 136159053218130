import * as React from "react";
import AdminTable from "../widgets/admin-table";
import memoizeOne from "memoize-one";
import { connect } from "react-redux";
import { History } from "history";
import { IRootState } from "@app/redux";
import { ObjectId } from "@app/utils/generics";
import { PrimaryButton } from "../styles/buttons";
import { inject } from "@app/modules";
import { IStateTestTypes } from "@app/models/test-type";
import { FormattedMessage } from "react-intl";

interface IOwnProps {
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;
type IProps = IStateProps & IOwnProps;

class AllTestTypes extends React.Component<IProps> {
	_isMounted: boolean = false;

	getSortedTestTypes = memoizeOne((testTypes: IStateTestTypes) => {
		return Object.keys(testTypes).map(_id => testTypes[_id]!.info);
	});

	_TestTypesController = inject("TestTypesController");

	componentDidMount() {
		this._isMounted = true;
		this._TestTypesController.getAll().then();
	}

	addTestType = () => this.props.history.push(`/admin/test-types/add`);
	redirectTo = (id: ObjectId) =>
		this.props.history.push(`/admin/test-types/${id}/edit`);
	onDelete = (id: ObjectId) => {
		this._TestTypesController.deleteById({ _id: id }).then();
	};

	render() {
		if (!this.props.testTypes) return null;
		return (
			<div className="main">
				<AdminTable
					rows={this.getSortedTestTypes(this.props.testTypes)}
					redirectTo={this.redirectTo}
					onDelete={this.onDelete}
				/>
				<PrimaryButton onClick={this.addTestType}>
					<FormattedMessage id="admin:general.addTestType" />
				</PrimaryButton>
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	testTypes: state.testTypes,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(
	AllTestTypes
);
