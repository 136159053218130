import { MError } from "../../utils/errors";
import {
	HierarchyItemType,
	IChildrenInfo,
	IHierarchyInfo,
	IParentInfoModel,
	IItemModel,
	IParentInfoInstance,
	IParentInfo,
} from "./interfaces";
import { ICourseModel } from "@app/models/course";
import { ObjectId } from "@app/utils/generics";
import {
	IMultipleSelectItem,
	IItemsHierarchy,
} from "@app/components/admin/deep-multiple-select-wth-search";
import { arrayToObject } from "@tests-core/utils/common";

export default class HierarchyInfoService {
	private static getRootIdFieldNameByItemType(
		itemType: HierarchyItemType
	): string {
		if (itemType === HierarchyItemType.folder) {
			return "rootFolder";
		}
		throw new Error("invalid hierarchy item type " + itemType);
	}

	protected readonly courseModel: ICourseModel;
	protected readonly parentInfoModel: IParentInfoModel;
	protected readonly itemModel: IItemModel;
	protected readonly itemType: HierarchyItemType;

	constructor(
		parentInfoModel: IParentInfoModel,
		itemModel: IItemModel,
		courseModel: ICourseModel,
		itemType: HierarchyItemType
	) {
		this.parentInfoModel = parentInfoModel;
		this.itemModel = itemModel;
		this.itemType = itemType;
		this.courseModel = courseModel;
	}

	public getCourseInfoDocSync(courseId: ObjectId) {
		const courseInfoDocument = this.parentInfoModel.findOneByCourseSync(
			courseId
		);
		if (!courseInfoDocument) {
			throw new MError(
				404,
				`document for course with id "${courseId}" not found`
			);
		}
		return courseInfoDocument;
	}

	public getRootIdSync(courseId: ObjectId) {
		const courseInfoDocument = this.getCourseInfoDocSync(courseId);
		return courseInfoDocument.rootId;
	}

	public getParentIdSync(
		courseId: ObjectId,
		itemId: ObjectId,
		parentInfo?: IParentInfo
	): ObjectId | undefined {
		if (!parentInfo) {
			const doc = this.getCourseInfoDocSync(courseId);
			parentInfo = doc.parentInfo;
		}
		return parentInfo![itemId];
	}

	public getHierarchyInfoObjectSync(
		courseId: ObjectId,
		doc?: IParentInfoInstance
	): IHierarchyInfo {
		if (!doc) {
			doc = this.getCourseInfoDocSync(courseId);
		} else if (doc.courseId !== courseId) {
			throw new Error(
				`invalid course parent info document for course "${courseId}"`
			);
		}
		return {
			parentInfo: doc.parentInfo,
			childrenInfo: this.getChildrenInfoObjectSync(
				courseId,
				doc.parentInfo
			),
			rootId: doc.rootId,
		};
	}

	public getParentInfoObjectSync(courseId: ObjectId) {
		const courseInfoDocument = this.parentInfoModel.findOneByCourseSync(
			courseId
		);
		if (!courseInfoDocument) {
			throw new MError(
				404,
				`document for course with id "${courseId}" not found`
			);
		}
		return courseInfoDocument.parentInfo;
	}

	public getChildrenInfoObjectSync(
		courseId: ObjectId,
		parentInfoObject?: IParentInfo
	) {
		if (!parentInfoObject) {
			parentInfoObject = this.getParentInfoObjectSync(courseId);
		}
		const childrenInfoObject: IChildrenInfo = {};

		for (const childId in parentInfoObject) {
			if (parentInfoObject.hasOwnProperty(childId)) {
				const parentId = parentInfoObject[childId];
				const strigifiedParentId = parentId;
				const childObjectId: ObjectId = childId;

				if (childrenInfoObject[strigifiedParentId]) {
					childrenInfoObject[strigifiedParentId].push(childObjectId);
				} else {
					childrenInfoObject[strigifiedParentId] = [childObjectId];
				}
				if (!childrenInfoObject[childObjectId]) {
					childrenInfoObject[childObjectId] = [];
				}
			}
		}
		return childrenInfoObject;
	}

	public getAncestorIdsSync(
		courseId: ObjectId,
		itemId: ObjectId,
		parentInfo?: IParentInfo
	): ObjectId[] {
		if (!parentInfo) {
			const doc = this.getCourseInfoDocSync(courseId);
			parentInfo = doc.parentInfo;
		}
		let currentId = itemId;
		const parentIds: ObjectId[] = [];
		while (parentInfo![currentId]) {
			parentIds.push(parentInfo![currentId]);
			currentId = parentInfo![currentId];
		}
		return parentIds;
	}

	public getMultipleItemAncestorIdsSync(
		courseId: ObjectId,
		itemIds: ObjectId[],
		parentInfo?: IParentInfo
	): ObjectId[] {
		if (!parentInfo) {
			parentInfo = this.getParentInfoObjectSync(courseId);
		}

		const promises: ObjectId[][] = [];
		for (let i = 0; i < itemIds.length; i++) {
			const itemId = itemIds[i];
			const promise = this.getAncestorIdsSync(
				courseId,
				itemId,
				parentInfo
			);
			promises.push(promise);
		}

		const unfilteredIds: ObjectId[] = [];
		promises.forEach(e => unfilteredIds.push(...e));
		return this.getUniqueObjectIds(unfilteredIds);
	}

	public getDescendantIdsSync(
		courseId: ObjectId,
		itemId: ObjectId,
		depth = Infinity,
		hierarchy?: IHierarchyInfo
	): ObjectId[] {
		if (!hierarchy) {
			hierarchy = this.getHierarchyInfoObjectSync(courseId);
		}
		const foundIds: ObjectId[] = [];

		let currentDepth = 1;
		const idsAtCurrentDepth: ObjectId[] = [
			...(hierarchy.childrenInfo[itemId] || []),
		];
		while (idsAtCurrentDepth.length > 0 && currentDepth <= depth) {
			// check every item at current depth
			const numItems = idsAtCurrentDepth.length;
			const childrenIds: ObjectId[] = [];

			for (let i = 0; i < numItems; i++) {
				const folderId = idsAtCurrentDepth.shift()!;
				foundIds.push(folderId);
				childrenIds.push(...(hierarchy.childrenInfo[folderId] || []));
			}

			idsAtCurrentDepth.push(...childrenIds);
			currentDepth++;
		}
		return foundIds;
	}

	public getDescendantIdsOfManyItemsSync({
		courseId,
		itemIds,
		depth = Infinity,
		hierarchy,
		includeSelves = false,
	}: {
		courseId: ObjectId;
		itemIds: ObjectId[];
		depth?: number;
		hierarchy?: IHierarchyInfo;
		includeSelves?: boolean;
	}): ObjectId[] {
		if (!hierarchy) {
			hierarchy = this.getHierarchyInfoObjectSync(courseId);
		}

		const descendantItems = new Set<string>();
		const subDescendantsArray: ObjectId[][] = [];
		for (const itemId of itemIds) {
			if (includeSelves) {
				descendantItems.add(itemId);
			}
			subDescendantsArray.push(
				this.getDescendantIdsSync(courseId, itemId, depth, hierarchy)
			);
		}
		for (const subDescendants of subDescendantsArray) {
			for (const subItemId of subDescendants) {
				descendantItems.add(subItemId);
			}
		}
		return [...descendantItems];
	}

	public getAllItemIdsSync(
		courseId: ObjectId,
		hierarchy?: IHierarchyInfo
	): ObjectId[] {
		hierarchy = hierarchy
			? hierarchy
			: this.getHierarchyInfoObjectSync(courseId);
		const itemIds: (string | ObjectId)[] = Object.keys(
			hierarchy.parentInfo
		);
		itemIds.push(hierarchy.rootId);
		return itemIds;
	}

	public getAllItemIdsSetSync(
		courseId: ObjectId,
		hierarchy?: IHierarchyInfo
	): Set<string> {
		return new Set(this.getAllItemIdsSync(courseId, hierarchy));
	}

	public getLeafIdsSync(
		courseId: ObjectId,
		doc?: IParentInfoInstance
	): Set<string> {
		if (!doc) {
			doc = this.getCourseInfoDocSync(courseId);
		} else if (doc.courseId !== courseId) {
			throw new Error(
				`invalid course parent info document for course "${courseId}"`
			);
		}
		const leaves = new Set<string>();
		const childrenInfo = this.getChildrenInfoObjectSync(
			courseId,
			doc.parentInfo
		);
		for (const itemId in doc.parentInfo) {
			if (!childrenInfo[itemId] || childrenInfo[itemId].length === 0) {
				leaves.add(itemId);
			}
		}
		if (leaves.size === 0) leaves.add(doc.rootId);
		return leaves;
	}

	public setItemParentSync(
		courseId: ObjectId,
		itemId: ObjectId,
		parentId: ObjectId,
		hierarchyOfThisItem: IParentInfo
	): void {
		const oldHierarchyInfo = this.getHierarchyInfoObjectSync(courseId);

		const item = this.itemModel.findByIdSync(itemId);
		if (!item) {
			throw new MError(404, `item with id "${itemId}" not found`);
		} else if (itemId === oldHierarchyInfo.rootId) {
			throw new Error("root item cannot be a subfolder");
		}

		// update course hierarchy info document
		const doc = this.getCourseInfoDocSync(courseId);
		doc.parentInfo = {
			...doc.parentInfo,
			...hierarchyOfThisItem,
			[itemId]: parentId,
		};
		doc.saveSync();
	}

	public onItemDeleteSync(courseId: ObjectId, itemId: ObjectId): void {
		const currentHierarchyInfo = this.getHierarchyInfoObjectSync(courseId);

		const item = this.itemModel.findByIdSync(itemId);
		if (!item) {
			throw new MError(404, `item with id "${itemId}" not found`);
		} else if (itemId === currentHierarchyInfo.rootId) {
			throw new Error("cannot delete root item");
		}
		const stringifiedItemId = itemId;
		// const parentId = currentHierarchyInfo.parentInfo[stringifiedItemId];
		const childrenIds =
			currentHierarchyInfo.childrenInfo[stringifiedItemId];

		const doc = this.getCourseInfoDocSync(courseId);
		// const updatedParentId = updatedIds[parentId] || parentId;

		// children of the item will be removed
		for (const id of childrenIds) {
			delete doc.parentInfo[id];
		}
		delete doc.parentInfo[stringifiedItemId];
		doc.parentInfo = { ...doc.parentInfo };
		doc.saveSync();
	}

	private getUniqueObjectIds(ids: ObjectId[]): ObjectId[] {
		const set: Set<string> = new Set<string>();
		ids.forEach(id => set.add(id));
		return [...set];
	}

	public isItemInHierarchy(
		courseId: ObjectId,
		itemId: ObjectId,
		hierarchy?: IHierarchyInfo
	): boolean {
		hierarchy = hierarchy
			? hierarchy
			: this.getHierarchyInfoObjectSync(courseId);
		return (
			hierarchy.rootId === itemId ||
			hierarchy.parentInfo.hasOwnProperty(itemId)
		);
	}

	public getAllItemsAndHierarchyObj(
		courseId: ObjectId,
		itemType: number
	): {
		items: IMultipleSelectItem<string>[];
		itemsHierarchy: IItemsHierarchy;
		rootId: string;
	} {
		const hierarchyInfo = this.getHierarchyInfoObjectSync(courseId);
		const allItemIds = [
			...this.getDescendantIdsSync(
				courseId,
				hierarchyInfo.rootId,
				Infinity,
				hierarchyInfo
			),
			hierarchyInfo.rootId,
		];
		const items = arrayToObject(
			this.itemModel.findManyByIdsSync(allItemIds),
			"_id"
		);
		const parentInfo: IItemsHierarchy["parentInfo"] = { [itemType]: {} };
		const childrenInfo: IItemsHierarchy["childrenInfo"] = {};
		for (const id of Object.keys(hierarchyInfo.parentInfo)) {
			parentInfo[itemType][id] = hierarchyInfo.parentInfo[id];
		}
		for (const id of Object.keys(hierarchyInfo.childrenInfo)) {
			childrenInfo[id] = hierarchyInfo.childrenInfo[id].map(itemId => {
				let name = "";
				if (items[itemId]) name = items[itemId]!.name;
				return {
					id: itemId,
					name,
					type: itemType,
				};
			});
		}

		const returnItems: IMultipleSelectItem<string>[] = [];
		for (const id of Object.keys(items)) {
			returnItems.push({
				id,
				name: items[id]!.name,
				type: itemType,
			});
		}
		return {
			items: returnItems,
			itemsHierarchy: {
				parentInfo,
				childrenInfo,
			},
			rootId: hierarchyInfo.rootId,
		};
	}
}
