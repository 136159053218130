import { ICourse } from "@app/api/courses/helper-schemas";
import { IRFolder, IUserFolderProgress } from "@app/api/folders/helper-schemas";
import { Folder } from "@app/models/folder";
import { inject } from "@app/modules";
import { arrayToObject } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";
import { useModelDocById } from "m-model-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IResourceLoadingInfo, useResourceInfoWithLoading } from "./fetch";

interface UseFolderInfoArgs {
	folderId: ObjectId | null;
	courseId: ObjectId | null;
}
export function useFolder({
	folderId,
	courseId,
}: UseFolderInfoArgs): IResourceLoadingInfo<Folder> {
	const folder = useModelDocById(inject("FolderModel"), folderId);
	const fetchResource = useCallback((info: UseFolderInfoArgs) => {
		const FoldersController = inject("FoldersController");
		if (!info.folderId || !info.courseId) return;
		return FoldersController.getById({
			_id: info.folderId,
			courseId: info.courseId,
		});
	}, []);
	const resourceInfo = useResourceInfoWithLoading({
		resource: folder,
		fetchingArg: useMemo(() => ({ folderId, courseId }), [
			courseId,
			folderId,
		]),
		fetch: fetchResource,
		isIdentificationKnown: courseId !== null && folderId !== null,
	});
	return resourceInfo;
}

export type WholeFolderInfo = {
	folders: Record<string, IRFolder | undefined>; // { [folderId: string]: IRFolder | undefined }
	folderProgresses: Record<string, IUserFolderProgress | undefined>; // { [folderId: string]: IUserFolderProgress | undefined }
	course: ICourse;
};

export function useWholeCourseSkeleton(args: {
	courseId: ObjectId | null;
	folderId?: ObjectId;
	depth?: number;
}): WholeFolderInfo | null {
	const [data, setData] = useState<WholeFolderInfo | null>(null);

	useEffect(() => {
		setData(null);
		if (!args.courseId) return;
		let isCancelled = false;
		const CourseFetchingController = inject("CourseFetchingController");
		CourseFetchingController.loadWholeCourseSkeleton({
			courseId: args.courseId,
			folderId: args.folderId,
			depth: args.depth,
		}).then(data => {
			if (isCancelled) return;
			setData({
				folders: arrayToObject(data.folders, "_id"),
				folderProgresses: data.folderProgresses,
				course: data.course,
			});
		});
		return () => {
			isCancelled = true;
		};
	}, [args.courseId, args.depth, args.folderId]);

	return data;
}
