import * as React from "react";

const SvgFile = props => (
	<svg viewBox="0 0 247 247" {...props}>
		<path
			d="M171.333 48c-4.143 0-7.833-3.357-7.833-7.5V0H41.333C37.19 0 33.5 3.357 33.5 7.5v231c0 4.133 3.51 7.737 7.644 7.75l165.167.75h.015c1.993 0 3.731-1.037 5.136-2.438 1.412-1.407 2.039-3.568 2.039-5.562V48h-42.168zm.5 133h-99c-4.143 0-7.5-3.357-7.5-7.5s3.357-7.5 7.5-7.5h99c4.143 0 7.5 3.357 7.5 7.5s-3.357 7.5-7.5 7.5zm0-33h-99c-4.143 0-7.5-3.357-7.5-7.5s3.357-7.5 7.5-7.5h99c4.143 0 7.5 3.357 7.5 7.5s-3.357 7.5-7.5 7.5zm0-33h-99c-4.143 0-7.5-3.357-7.5-7.5s3.357-7.5 7.5-7.5h99c4.143 0 7.5 3.357 7.5 7.5s-3.357 7.5-7.5 7.5zm0-31h-99c-4.143 0-7.5-3.357-7.5-7.5s3.357-7.5 7.5-7.5h99c4.143 0 7.5 3.357 7.5 7.5s-3.357 7.5-7.5 7.5zm38.934-51H178.5V2.383L210.767 33z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgFile;
