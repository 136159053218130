import { css } from "emotion";
import React from "react";
import { TimerProps } from ".";

export const DefaultTestTimer = React.memo<TimerProps>(
	function DefaultTestTimer({ timeMs }) {
		return (
			<div className={timerStyles}>
				დარჩენილი დრო: <span>{displayTime(Math.max(0, timeMs))}</span>
			</div>
		);
	}
);

const timerStyles = css`
	text-align: right;
	margin-bottom: 20px;
	& > span {
		font-weight: bold;
	}
`;

export const displayTime = (timeMs: number): string => {
	const hours = Math.floor((timeMs / (1000 * 60 * 60)) % 24);
	const minutes = Math.floor((timeMs / 1000 / 60) % 60);
	const seconds = Math.floor((timeMs / 1000) % 60);
	const timeArr: string[] = [];
	if (hours > 0) {
		timeArr.push(padZeros(hours));
	}
	timeArr.push(padZeros(minutes));
	timeArr.push(padZeros(seconds));
	return timeArr.join(" : ");
};

const padZeros = (num: number) => {
	const str = num + "";
	if (str.length < 2) return "0" + num;
	return str;
};
