export function groupArray<
	T extends unknown,
	K extends string | number | null | undefined
>(arr: T[], elementToKey: (el: T) => K): T[][];
export function groupArray<
	T extends unknown,
	K extends string | number | null | undefined,
	R extends any
>(
	arr: T[],
	elementToKey: (el: T) => K,
	groupElements: (elements: T[], key: K) => R
): R[];
export function groupArray<
	T extends unknown,
	K extends string | number | null | undefined
>(
	arr: T[],
	elementToKey: (el: T) => K,
	groupElements: (elements: T[], key: K) => any = e => e
): any[] {
	const keyToElements: Record<any, T[] | undefined> = {};
	for (const elem of arr) {
		const key = elementToKey(elem) as any;
		if (key !== undefined && key !== null) {
			if (!keyToElements[key]) keyToElements[key] = [];
			keyToElements[key]!.push(elem);
		}
	}
	const usedKeys = new Set<any>();
	const finalArr: any[] = [];
	for (const elem of arr) {
		const key = elementToKey(elem) as any;
		if (key !== undefined && key !== null) {
			if (usedKeys.has(key)) continue;
			usedKeys.add(key);
			const elements = keyToElements[key] || [elem];
			finalArr.push(groupElements(elements, key));
		} else {
			finalArr.push(groupElements([elem], key));
		}
	}
	return finalArr;
}

export const flatten = <T extends Array<unknown> = any>(
	arr: T,
	unique?: "unique"
): T extends Array<infer R> ? R : never => {
	const flattened = (arr as any).reduce(
		(acc, val) =>
			Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val),
		[]
	);
	if (unique) {
		return [...new Set(flattened)] as any;
	}
	return flattened;
};
