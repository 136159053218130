import * as React from "react";
import { _console } from "@app/commonJavascript";
import { connect } from "react-redux";
import { History } from "history";
import { ILoginByToken, loginByToken } from "@app/redux/actions/user";
import { match } from "react-router";
import { clearAllKeysExcept } from "@app/utils/storage";
import { store } from "../../index";
import { userAnswersLocalStorageKey } from "./modelling/utils";

interface IOwnProps {
	history: History;
	match: match<{ murtskuUserId: string; token: string }>;
}

type IStateProps = null;

interface IDispatchProps {
	loginByToken: ILoginByToken;
}

interface IErrorType {
	mobile?: boolean;
	password?: boolean;
}

interface IState {
	mobile: string;
	password: string;
	signLoading: boolean;
	errors: IErrorType;
}

type IProps = IOwnProps & IDispatchProps;

class LoginByTokenPage extends React.Component<IProps> {
	_isMounted = false;
	state = {
		mobile: "",
		password: "",
		signLoading: false,
		errors: {},
	} as IState;
	constructor(props: IProps) {
		super(props);
		localStorage.removeItem("credentials");
	}
	componentDidMount() {
		this._isMounted = true;
		let murtskuId;
		if (localStorage.getItem("user")) {
			const user = store.getState().user;
			if (user.loggedIn) murtskuId = user.userData.murtskuId;
		}
		// NOTE: DO NOT clear the items that users need to continue test progress
		if (
			Number(murtskuId) === Number(this.props.match.params.murtskuUserId)
		) {
			clearAllKeysExcept(localStorage, [
				key => key.indexOf(userAnswersLocalStorageKey) > -1,
			]);
		} else {
			localStorage.clear();
		}
		this.onLoginClick();
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	onLoginClick = () => {
		this.props
			.loginByToken({
				murtskuUserId: +this.props.match.params.murtskuUserId,
				token: this.props.match.params.token,
			})
			.then(() => {
				const urlParams = new URLSearchParams(window.location.search);
				const redirectTo = urlParams.get("redirect") || "";
				this.props.history.replace("/" + redirectTo);
			})
			.catch(err => {
				_console.error(err);
			});
	};
	render() {
		return <div>დაელოდეთ...</div>;
	}
}

export default connect<IStateProps, IDispatchProps, IOwnProps>(null, ({
	loginByToken,
} as any) as IDispatchProps)(LoginByTokenPage);
