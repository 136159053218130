import * as React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { CourseVisibility, ICourse } from "@app/api/courses/helper-schemas";
import { History } from "history";
import { InputStyle } from "../styles/inputs";
import { IRootState } from "@app/redux";
import { match } from "react-router";
import { PrimaryButton } from "../styles/buttons";
import CourseReminder, { ReminderButton } from "./course-reminder";
import { SingleSelect } from "../styles/selects";
import TextField from "@material-ui/core/TextField";
import { inject } from "@app/modules";
import { getFormattedMessage } from "@app/utils/locale";
import { FormattedMessage } from "react-intl";
import { CheckboxWithLabel } from "../widgets/input";

interface IOwnProps {
	match: match<{ courseId?: string }>;
	history: History;
}

type IStateProps = ReturnType<typeof mapStateToProps>;

type IProps = IStateProps & IOwnProps;

interface IState {
	course?: ICourse;
	isReminderMenuOpened: boolean;
}

class Course extends React.Component<IProps, IState> {
	state: IState = {
		isReminderMenuOpened: false,
	};
	id =
		this.props.match.params.courseId === "add"
			? undefined
			: this.props.match.params.courseId;
	_isMounted = false;
	visibilityFiltersArray = [
		CourseVisibility.public,
		CourseVisibility.private,
		CourseVisibility.unlisted,
	];
	visibilityFilters = {
		[CourseVisibility.public]: getFormattedMessage("public"),
		[CourseVisibility.private]: getFormattedMessage("private"),
		[CourseVisibility.unlisted]: getFormattedMessage("unlisted"),
	};
	selectedVisibilityOptions = this.visibilityFiltersArray.map(el => ({
		value: el,
		label: this.visibilityFilters[el],
	}));

	_CoursesController = inject("CoursesController");

	componentDidMount() {
		this._isMounted = true;
		if (this.id) {
			if (!this.props.courses[this.id]) {
				this._CoursesController
					.getById({ _id: this.id })
					.then(course => {
						this.setState({
							course,
						});
					});
			} else {
				this.setState({
					course: this.props.courses[this.id]!.info,
				});
			}
		} else {
			const course = {
				name: "",
				price: 0,
				visibility: CourseVisibility.public,
			} as ICourse;
			this.setState({
				course,
			});
		}
	}

	handleInputChange = (field: string) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		let value = e.target.value as string | number;
		if (field === "price") {
			value = +value;
		}
		this.setState({
			course: {
				...this.state.course!,
				[field]: value,
			},
		});
	};

	handleSelectChange = selectedOption => {
		this.setState({
			course: {
				...this.state.course!,
				visibility: selectedOption.value,
			},
		});
	};

	save = () => {
		const {
			name,
			description,
			price,
			visibility,
			isExtendable,
		} = this.state.course!;
		if (!this.id) {
			this._CoursesController
				.add({ name, description, price, visibility, isExtendable })
				.then(
					data => this.props.history.replace(`/admin/courses`) // TODO: redirect to another page
				);
			return;
		}
		this._CoursesController
			.update({
				_id: this.id,
				name,
				description,
				price,
				visibility,
				isExtendable,
			})
			.then(() => this.props.history.push(`/admin/courses`));
	};

	handleIsExtendableChange = (checked: boolean) => {
		this.setState({
			course: { ...this.state.course!, isExtendable: checked },
		});
	};

	openReminderMenu = () =>
		this.setState({
			isReminderMenuOpened: !this.state.isReminderMenuOpened,
		});

	render() {
		if (!this.state.course) return null;
		return (
			<div className="main">
				{this.state.isReminderMenuOpened && this.id && (
					<CourseReminder
						courseId={this.id}
						onClose={this.openReminderMenu}
					/>
				)}
				<div>
					<TextField
						label={getFormattedMessage("inputs.name")}
						value={this.state.course.name}
						onChange={this.handleInputChange("name")}
						margin="dense"
						variant="outlined"
						className={InputStyle}
					/>
				</div>
				<div>
					<TextField
						label={getFormattedMessage("inputs.description")}
						value={this.state.course.description || ""}
						onChange={this.handleInputChange("description")}
						margin="dense"
						variant="outlined"
						className={InputStyle}
					/>
				</div>
				<div>
					<TextField
						label={getFormattedMessage("price")}
						defaultValue={this.state.course.price}
						onChange={this.handleInputChange("price")}
						margin="normal"
						variant="outlined"
						type="number"
						className={InputStyle}
					/>
				</div>
				<div>
					<Select
						value={{
							value: this.state.course.visibility!,
							label: this.visibilityFilters[
								this.state.course.visibility!
							],
						}}
						onChange={this.handleSelectChange}
						options={this.selectedVisibilityOptions}
						placeholder={"საჯაროობა"}
						className={SingleSelect}
					/>
				</div>
				<div>
					<CheckboxWithLabel
						value={!!this.state.course.isExtendable}
						onChange={this.handleIsExtendableChange}
						label="შესაძლებელი იყოს, სხვა კურსები დაშენდეს ამაზე"
					/>
				</div>
				{!!this.id && (
					<ReminderButton onClick={this.openReminderMenu}>
						<FormattedMessage id="admin:general.courseReminder" />
					</ReminderButton>
				)}
				<PrimaryButton onClick={this.save}>
					<FormattedMessage id="save" />
				</PrimaryButton>
				<br />
			</div>
		);
	}
}

const mapStateToProps = (state: IRootState) => ({
	courses: state.courses,
});

export default connect<IStateProps, null, IOwnProps>(mapStateToProps)(Course);
