import * as React from "react";
import { IFinishPageProps } from "../../../../@tests-core/components/tests";
import { IRTest } from "@app/api/tests/helper-schemas";
import {
	ITestTypeSettings,
	TestTypeAnswersShowTime,
} from "@app/api/test-types/helper-schemas";
import { MainButton } from "../styles/styledComponents";

interface IStandardFinishComponentProps extends IFinishPageProps {
	onGotoNext: () => void;
	onResetTestProgress: () => void;
	test: IRTest;
	testTypeSettings: ITestTypeSettings;
	currentAttempt: number;
}

export const StandardFinishComponent: React.FC<
	IStandardFinishComponentProps
> = props => {
	const maxNumOfWritingTests =
		typeof props.testTypeSettings.maxNumOfWritingTests !== "number"
			? Infinity
			: props.testTypeSettings.maxNumOfWritingTests;
	if (
		props.testTypeSettings.showAnswersAt ===
		TestTypeAnswersShowTime.afterFinish
	) {
		return (
			<div
				style={{
					padding: 30,
					textAlign: "center",
					fontSize: 20,
					fontFamily: "FiraGo",
				}}
			>
				თქვენ წარმატებით დაასრულეთ ტესტი.
				<br />
				<MainButton
					onClick={() =>
						props.onPageSelect({
							type: "question",
							index: 0,
						})
					}
					style={{
						background:
							"linear-gradient(to top right, #04a160, #0cda55)",
					}}
				>
					კითხვების გადახედვა
				</MainButton>
				<br />
				<MainButton onClick={props.onGotoNext}>შემდეგი</MainButton>
				{maxNumOfWritingTests > props.currentAttempt && (
					<>
						<br />
						<br />
						<div>გსურთ ტესტის ახლიდან დაწყება?</div>
						<MainButton
							onClick={props.onResetTestProgress}
							style={{ marginTop: 10 }}
						>
							თავიდან დაწერა
						</MainButton>
					</>
				)}
			</div>
		);
	}
	return (
		<div
			style={{
				padding: 30,
				textAlign: "center",
				fontSize: 20,
				fontFamily: "FiraGo",
			}}
		>
			თქვენ წარმატებით დაასრულეთ ტესტი. კითხვების გადახედვისთვის დააჭირეთ
			შესაბამისი კითხვის ნომერს.
			<br />
			<MainButton onClick={props.onGotoNext}>შემდეგი</MainButton> <br />
			{maxNumOfWritingTests > props.currentAttempt && (
				<>
					<br />
					გსურთ ტესტის ახლიდან დაწყება? <br />{" "}
					<MainButton onClick={props.onResetTestProgress}>
						თავიდან დაწერა
					</MainButton>
				</>
			)}
		</div>
	);
};

export const ModellingFinishComponent: React.FC<
	IStandardFinishComponentProps
> = props => {
	const maxNumOfWritingTests =
		typeof props.testTypeSettings.maxNumOfWritingTests !== "number"
			? Infinity
			: props.testTypeSettings.maxNumOfWritingTests;
	return (
		<div
			style={{
				padding: 30,
				textAlign: "center",
				fontSize: 20,
				fontFamily: "FiraGo",
			}}
		>
			{props.info.hasAssessableQuestion && !props.info.isFullyAssessed
				? "მოდელირება დასრულებულია. ყოვლისმცოდნე დავითი შენს ტესტს შეასწორებს 3 სამუშაო დღის ვადაში."
				: `თქვენ დააგროვეთ ${Math.floor(props.info.totalCredit)} ქულა`}
			<br />
			<MainButton
				onClick={() =>
					props.onPageSelect({
						type: "question",
						index: 0,
					})
				}
				style={{
					background:
						"linear-gradient(to top right, #04a160, #0cda55)",
				}}
			>
				კითხვების გადახედვა
			</MainButton>
			{maxNumOfWritingTests > props.currentAttempt && (
				<>
					<br />
					<br />
					<div>გსურთ ტესტის ახლიდან დაწყება?</div>
					<MainButton
						onClick={props.onResetTestProgress}
						style={{ marginTop: 10 }}
					>
						თავიდან დაწერა
					</MainButton>
				</>
			)}
		</div>
	);
};
