import { IFileSingleItem, FileItemTypes } from "@app/api/files/helper-schemas";
import { generateFakeObjectId } from "@app/utils/common";

export const getDefaultItemByType = (type: FileItemTypes): IFileSingleItem => {
	if (type === FileItemTypes.text) {
		return {
			type: FileItemTypes.text,
			text: "",
		};
	}
	if (type === FileItemTypes.questions) {
		return {
			type: FileItemTypes.questions,
			ids: [],
		};
	}
	if (type === FileItemTypes.cards) {
		return {
			type: FileItemTypes.cards,
			ids: [],
		};
	}
	if (type === FileItemTypes.tabs) {
		return {
			type: FileItemTypes.tabs,
			items: [
				{
					_id: generateFakeObjectId(),
					title: "",
					item: getDefaultItemByType(FileItemTypes.text),
				},
			],
		};
	}
	throw new Error("type not supported");
};
