import pillar from "../../styles/img/newimgs/pillar.png";
import styled, { css } from "react-emotion";

export const Container = styled("div")({
	backgroundColor: "#152958",
	width: "100%",
});

export const RedBorder = styled("div")({
	width: 800,
	maxWidth: "90%",
	margin: "0 auto",
	// border: "red 2px solid"
});

export const MainTestContainer = css`
	font-family: FiraGO;
	width: 100%;
	padding: 30px;
	border-radius: 15px;
	background: #152958;
	border: 2px solid white;
	color: white;
	cursor: pointer;
	font-size: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0;
	box-shadow: rgba(255, 255, 255, 0.5) 0 0 7px,
		rgba(255, 255, 255, 0.5) 0 0 7px inset;
	transition: 0.3s;
	&:hover {
		box-shadow: white 0 0 10px, white 0 0 10px inset;
	}
`;

export const LockContainerCSS = css`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	& > svg {
		font-size: 90px;
		left: 50%;
		position: relative;
		transform: translate(-50%) translate(2px, 4px) rotateX(52deg)
			rotateZ(-41deg);
		color: #cecece;
		transition: 0.3s;
	}
`;

export const CheckContainerCSS = css`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	& > svg {
		font-size: 90px;
		left: 50%;
		position: relative;
		transform: translate(-50%) translate(2px, 7px) rotateX(52deg)
			rotateZ(0deg);
		color: #0fba1c;
		transition: 0.3s;
	}
`;

const lockSelector = `&:hover .${LockContainerCSS} svg`;
const checkMarkSelector = `&:hover .${CheckContainerCSS} svg`;

export const EachPillar = styled("div")(
	{
		width: 200,
		height: 325,
		marginTop: 70,
		cursor: "pointer",
		position: "relative",
		paddingTop: 108,
		[lockSelector]: {
			color: "#a3a3a3",
			transform:
				"translate(-50%) translate(3px,-1px) rotateX(52deg) rotateZ(-41deg)",
			filter:
				"drop-shadow(#767676 -2px 2px) drop-shadow(#767676 -2px 2px) drop-shadow(#767676 -2px 2px)",
		},
		[checkMarkSelector]: {
			color: "#0fba1c",
			transform:
				"translate(-50%) translate(3px,2px) rotateX(52deg) rotateZ(0deg)",
			filter:
				"drop-shadow(#12a31c 0px 2px) drop-shadow(#12a31c 0px 2px) drop-shadow(#12a31c 0px 2px)",
		},
	},
	(props: { isRight: boolean; isLocked: boolean }) => {
		if (props.isRight) {
			if (props.isLocked) {
				return {
					transition: "0.3s",
					marginLeft: "auto",
					marginRight: 0,
				};
			}
			return {
				marginLeft: "auto",
				marginRight: 0,
			};
		}
		return {
			transition: "0.3s",
		};
	}
);

export const PillarImg = styled("div")({
	width: 200,
	height: 300,
	background: `url(${pillar})`,
	backgroundSize: "contain",
	backgroundRepeat: "no-repeat",
	position: "relative",
});

export const WhiteSpace = styled("div")({
	width: 180,
	position: "absolute",
	background: "white",
	margin: 10,
	padding: 5,
	borderRadius: 3,
	fontFamily: "FiraGO",
	transform: "translate(0, -100%)",
	top: 90,
});

export const PillarImage = styled("div")({}, (props: { url: string }) => {
	if (props.url) {
		return {
			width: 130,
			height: 100,
			background: `url(${props.url})`,
			// background: "#ec81bd",
			margin: "5px auto",
			padding: 5,
			borderRadius: 3,
			fontFamily: "FiraGO",
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
		};
	}
	return {};
});

export const PathForLeft = styled("div")({
	position: "absolute",
	// borderTop: "4px dashed white",
	width: 657,
	height: 3,
	transform: "rotate(33deg)",
	marginTop: 200,
	marginLeft: 99,
	background: "linear-gradient(to right, #fff 50%, #ffffff00 50%)",
	backgroundSize: 25,
});

export const PathForRight = styled("div")({
	marginTop: 191,
	marginLeft: 102,
	position: "absolute",
	// borderTop: "4px dashed white",
	width: 600,
	height: 3,
	transform: "rotate(-33deg)",
	background: "linear-gradient(to right, #fff 50%, #ffffff00 50%)",
	backgroundSize: 25,
});

export const PathDownward = styled("div")({
	margin: "0 102px",
	position: "absolute",
	marginTop: 54,
	height: 270,
	width: 3,
	background: "linear-gradient(to bottom, #fff 50%, #ffffff00 50%)",
	backgroundSize: "100% 25px",
});

export const NameWithoutPhoto = styled("div")({
	padding: 10,
	textAlign: "center",
	fontSize: 18,
	wordBreak: "break-word",
});

export const CanGoToFolder = css`
	cursor: pointer;
	margin-top: 10px;
	:hover {
		text-decoration: underline;
	}
`;
