import React from "react";
import { ObjectId } from "@app/utils/generics";
import { ItemType } from "@app/api/folders/helper-schemas";
import FolderPopup from "./folder-popup";
import FileContentPopup from "./file-content-popup";
import QuestionPopup from "../../questions/popup";
import TextPopup from "../../questions/text-popup";
import { ISelection } from "..";
import { FilePopup } from "./file";
import AdminTestPopup from "./test-popup";
import CardPopup from "../../cards/popup";

export const ItemPopups: React.FC<{
	selection: ISelection;
	onClose: () => void;
	folderId: ObjectId;
	courseId: ObjectId;
}> = ({ selection, onClose, folderId, courseId }) => {
	if (
		selection.viewType === "COPY_ITEMS" ||
		selection.viewType === "RELOCATE"
	) {
		return null;
	}
	return (
		<>
			{selection.type === ItemType.folder && (
				<>
					{selection.viewType === "Add" && (
						<FolderPopup
							parentFolderId={folderId}
							onClose={onClose}
							courseId={courseId}
						/>
					)}
					{selection.viewType === "Edit" && (
						<FolderPopup
							onClose={onClose}
							courseId={courseId}
							folderId={selection.id}
						/>
					)}
				</>
			)}
			{selection.type === ItemType.file && (
				<>
					{selection.viewType === "Add" && (
						<FilePopup
							onClose={onClose}
							folderId={folderId}
							courseId={courseId}
						/>
					)}
					{selection.viewType === "Edit" && (
						<FilePopup
							onClose={onClose}
							folderId={folderId}
							fileId={selection.id}
							courseId={courseId}
						/>
					)}
					{selection.viewType === "View" && (
						<FileContentPopup
							onClose={onClose}
							fileId={selection.id}
							folderId={folderId}
							courseId={courseId}
						/>
					)}
				</>
			)}

			{selection.type === ItemType.card &&
				(selection.viewType === "Add" ||
					selection.viewType === "Edit") && (
					<CardPopup
						onClose={onClose}
						folderId={folderId}
						courseId={courseId}
						id={selection.id}
					/>
				)}

			{selection.type === ItemType.question &&
				(selection.viewType === "Add" ||
					selection.viewType === "Edit") && (
					<QuestionPopup
						onClose={onClose}
						folderId={folderId}
						courseId={courseId}
						id={selection.id}
					/>
				)}
			{selection.type === ItemType.text &&
				(selection.viewType === "Add" ||
					selection.viewType === "Edit") && (
					<TextPopup
						onClose={onClose}
						folderId={folderId}
						courseId={courseId}
						textId={selection.id}
					/>
				)}

			{selection.type === ItemType.test &&
				(selection.viewType === "Add" ||
					selection.viewType === "Edit") && (
					<AdminTestPopup
						onClose={onClose}
						folderId={folderId}
						courseId={courseId}
						testId={selection.id}
					/>
				)}
		</>
	);
};
