import { inject } from "@app/modules";
import { ItemType } from "../folders/helper-schemas";
import { IRequest } from "../requests";
import {
	APOSTCreateQuestionSchema,
	APUTQuestionSchema,
	IADELETEQuestion,
	IAGETNamesByIds,
	IAGETQuestion,
	IAPOSTCreateQuestion,
	IAPUTQuestion,
	IRGETNamesByIds,
	IRGETQuestion,
	IRPOSTCreateQuestion,
	IRPUTQuestion,
	RGETNamesByIdsSchema,
	RGETQuestionSchema,
	RPOSTCreateQuestionSchema,
	RPUTQuestionSchema,
	IAGETManyByIds,
	IRGETManyByIds,
} from "./validators";
import {
	IAGETTextWithQuestions,
	IRGETTextWithQuestions,
	IAPOSTCreateTextWithQuestions,
	IRPOSTCreateTextWithQuestions,
	IAPUTCreateTextWithQuestions,
	IRPUTCreateTextWithQuestions,
	IADELETEQuestionText,
} from "./texts/validators";
import {
	IAGETQuestionDifficulty,
	IRGETQuestionDifficulty,
	IAGETMultipleQuestionDifficulties,
	IRGETMultipleQuestionDifficulties,
	RGETQuestionDifficultySchema,
	RGETMultipleQuestionDifficultiesSchema,
} from "./stats/validators";
import { IQuestionDifficultyStats } from "./stats/helper-schemas";
import { PromisesKeeperAPI, getManyResources } from "../promises-keeper";
import { ObjectId } from "@app/utils/generics";

export class QuestionsController {
	private readonly Request: IRequest;

	private readonly _FolderItemsService = inject("FolderItemsService");
	private readonly _TestItemsService = inject("TestItemsService");

	private difficultiesPromises = new PromisesKeeperAPI<
		ObjectId,
		IQuestionDifficultyStats,
		{ courseId: ObjectId }
	>(
		getManyResources(
			docs =>
				this.getMultipleQuestionDifficulties({
					courseId: docs[0].extraInfo!.courseId,
					questionIds: docs.map(e => e.id),
				}),
			(data, id) => data.find(e => e.questionId === id)
		),
		15
	);

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = (args: IAPOSTCreateQuestion): Promise<IRPOSTCreateQuestion> =>
		this.Request.send("POST", "/api/questions", args, null, {
			responseSchema: RPOSTCreateQuestionSchema,
			requestSchema: APOSTCreateQuestionSchema,
		}).then((data: IRPOSTCreateQuestion) => {
			if (args.folderIds) {
				this._FolderItemsService.setItemParentsInCourseSync({
					newParentFolderIds: args.folderIds,
					item: {
						type: ItemType.question,
						id: data._id,
						name: data.shortStat,
					},
					courseId: args.courseId,
					isNewlyCreatedItem: true,
				});
			}

			if (args.testIds) {
				this._TestItemsService.setItemTestsInCourseSync({
					courseId: args.courseId,
					itemId: data._id,
					itemType: ItemType.question,
					testIds: args.testIds,
				});
			}
			return data;
		});

	update = (args: IAPUTQuestion): Promise<IRPUTQuestion> =>
		this.Request.send("PUT", "/api/questions/:_id", args, null, {
			requestSchema: APUTQuestionSchema,
			responseSchema: RPUTQuestionSchema,
		}).then((data: IRPUTQuestion) => {
			this._FolderItemsService.updateItemSync({
				courseId: args.courseId,
				item: {
					id: args.question._id,
					type: ItemType.question,
					name: data.shortStat,
				},
			});
			if (args.folderIds) {
				this._FolderItemsService.setItemParentsInCourseSync({
					newParentFolderIds: args.folderIds,
					item: {
						id: args.question._id,
						type: ItemType.question,
						name: data.shortStat,
					},
					courseId: args.courseId,
					isNewlyCreatedItem: false,
				});
			}

			if (args.testIds) {
				this._TestItemsService.setItemTestsInCourseSync({
					courseId: args.courseId,
					itemId: data._id,
					itemType: ItemType.question,
					testIds: args.testIds,
				});
			}
			return data;
		});

	getById = (args: IAGETQuestion): Promise<IRGETQuestion> =>
		this.Request.send("GET", "/api/questions/:_id", args, null, {
			responseSchema: RGETQuestionSchema,
		}).then((data: IRGETQuestion) => {
			return data;
		});

	getBulk = (args: IAGETManyByIds): Promise<IRGETManyByIds> =>
		this.Request.send("POST", "/api/questions/get-bulk", args);

	getNames = (args: IAGETNamesByIds): Promise<IRGETNamesByIds> =>
		this.Request.send("POST", "/api/questions/get-names", args, null, {
			responseSchema: RGETNamesByIdsSchema,
		}).then((data: IRGETNamesByIds) => {
			return data;
		});

	deleteById = (args: IADELETEQuestion): Promise<void> =>
		this.Request.send("DELETE", "/api/questions/:_id", args).then(
			(data: void) => {
				if (args.eraseQuestionFromDatabase) {
					this._FolderItemsService.deleteItemSync({
						itemId: args._id,
						type: ItemType.question,
						courseId: args.courseId,
						deleteOnlyInParentFolder: false,
					});
				} else {
					if (!args.folderId) {
						throw new Error("folderId was required");
					}
					this._FolderItemsService.deleteItemSync({
						itemId: args._id,
						type: ItemType.question,
						courseId: args.courseId,
						parentFolderId: args.folderId,
						deleteOnlyInParentFolder: true,
					});
				}
				return data;
			}
		);

	getTextQuestions = (
		args: IAGETTextWithQuestions
	): Promise<IRGETTextWithQuestions> =>
		this.Request.send("GET", "/api/questions/texts/:textId", args);

	addTextQuestions = (
		args: IAPOSTCreateTextWithQuestions
	): Promise<IRPOSTCreateTextWithQuestions> =>
		this.Request.send("POST", "/api/questions/texts/", args).then(
			(data: IRPOSTCreateTextWithQuestions) => {
				const textId = data.text._id;
				const textContent: string = data.text.text;
				if (args.folderIds) {
					this._FolderItemsService.setItemParentsInCourseSync({
						courseId: args.courseId,
						newParentFolderIds: args.folderIds,
						item: {
							type: ItemType.text,
							id: textId,
							name: textContent,
						},
						isNewlyCreatedItem: true,
					});
				}

				if (args.testIds) {
					this._TestItemsService.setItemTestsInCourseSync({
						courseId: args.courseId,
						itemId: textId,
						itemType: ItemType.text,
						testIds: args.testIds,
					});
				}
				return data;
			}
		);

	updateTextQuestions = (
		args: IAPUTCreateTextWithQuestions
	): Promise<IRPUTCreateTextWithQuestions> =>
		this.Request.send("PUT", "/api/questions/texts/:textId", args).then(
			(data: IRPUTCreateTextWithQuestions) => {
				this._FolderItemsService.updateItemSync({
					item: {
						type: ItemType.text,
						id: args.textId,
						name: args.text.text,
					},
					courseId: args.courseId,
					upsert: true,
				});
				if (args.folderIds) {
					this._FolderItemsService.setItemParentsInCourseSync({
						courseId: args.courseId,
						newParentFolderIds: args.folderIds,
						item: {
							type: ItemType.text,
							id: args.textId,
							name: args.text.text,
						},
						isNewlyCreatedItem: false,
					});
				}
				if (args.testIds) {
					this._TestItemsService.setItemTestsInCourseSync({
						courseId: args.courseId,
						itemId: args.textId,
						itemType: ItemType.text,
						testIds: args.testIds,
					});
				}
				return data;
			}
		);

	deleteText = (args: IADELETEQuestionText): Promise<void> =>
		this.Request.send("DELETE", "/api/questions/texts/:textId", args).then(
			(data: void) => {
				if (args.eraseQuestionFromDatabase) {
					this._FolderItemsService.deleteItemSync({
						courseId: args.courseId,
						itemId: args.textId,
						type: ItemType.text,
					});
				} else {
					this._FolderItemsService.deleteItemSync({
						courseId: args.courseId,
						itemId: args.textId,
						type: ItemType.text,
						deleteOnlyInParentFolder: true,
						parentFolderId: args.folderId,
					});
				}
				return data;
			}
		);

	getQuestionDifficulty = (
		args: IAGETQuestionDifficulty
	): Promise<IRGETQuestionDifficulty> =>
		this.difficultiesPromises.getOrSetPromise(
			args.questionId,
			() =>
				this.Request.send(
					"GET",
					"/api/questions/:questionId/difficulty",
					args,
					null,
					{
						responseSchema: RGETQuestionDifficultySchema,
					}
				),
			{ courseId: args.courseId }
		);

	getMultipleQuestionDifficulties = (
		args: IAGETMultipleQuestionDifficulties
	): Promise<IRGETMultipleQuestionDifficulties> =>
		this.Request.send(
			"POST",
			"/api/questions/get-difficulty-of-multiuiple-questions",
			args,
			null,
			{
				responseSchema: RGETMultipleQuestionDifficultiesSchema,
			}
		);
}
