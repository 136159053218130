import * as React from "react";
import CardRow from "./row";
import memoizeOne from "memoize-one";
import styled, { css } from "react-emotion";
import {
	DragDropContext,
	Draggable,
	Droppable,
	DropResult,
} from "react-beautiful-dnd";
import { IIdGenerators } from "../questions/contents/interfaces";
import { isPropertyTypeArray } from "../../utils/template";
import { NotUndefined, OptionalKeys } from "../../utils/generics";
import { reorder } from "../../utils";
import { ICard, ICardProperty, IEntry } from "../../schemas/cards";
import {
	ICardTemplate,
	ICardTemplateProperty,
} from "../../schemas/cards/templates";

interface IProps {
	defaultTemplate: ICardTemplate;
	defaultCard?: OptionalKeys<ICard, "_id" | "authorId">;
	generateUniqueIds: IIdGenerators["generateUniqueIds"];
}

interface IState {
	template: ICardTemplate;
	card: NotUndefined<IProps["defaultCard"]>;
}

// WARNING: this component may have bugs if another template will be passed
class EditCard extends React.PureComponent<IProps, IState> {
	state: IState = {
		card: this.props.defaultCard || {
			name: "",
			properties: [],
			templateId: this.props.defaultTemplate._id,
		},
		template: this.props.defaultTemplate,
	};

	cardPropToString = memoizeOne((cardProp: ICardProperty): string => {
		if (!Array.isArray(cardProp.entries)) {
			if (!Array.isArray(cardProp.entries.val)) {
				return cardProp.entries.val.toString();
			}
			return (cardProp.entries.val[0] || "").toString();
		}
		if (cardProp.entries.length === 0) return "";
		if (!Array.isArray(cardProp.entries[0].val)) {
			return cardProp.entries[0].val.toString();
		}
		return (cardProp.entries[0].val[0] || "").toString();
	});

	getPrimaryProperty = memoizeOne((properties: ICardTemplateProperty[]) => {
		return properties.find(property => property.isPrimary === true);
	});

	getData = () => {
		const card = { ...this.state.card };
		card.name = this.getName() || "";
		return card;
	};

	getCurrentIds = (): number[] => {
		// card property ids
		const ids: number[] = [];
		if (this.state.card) {
			this.state.card.properties.forEach(cardProp => {
				if (Array.isArray(cardProp.entries)) {
					cardProp.entries.forEach(entry => {
						ids.push(entry.id);
					});
				} else {
					ids.push(cardProp.entries.id);
				}
			});
		}
		return ids;
	};

	onChange = (newProp: ICardProperty) => {
		if (this.state.card === undefined) return;
		this.setState(({ card }) => ({
			card: {
				...card,
				properties: card.properties.map(each =>
					each.id !== newProp.id ? each : newProp
				),
			},
		}));
	};

	onCardPropAdd = (newProp: ICardProperty) => {
		if (this.state.card === undefined) return;
		this.setState(({ card }) => ({
			card: {
				...card,
				properties: [...card.properties, newProp],
			},
		}));
	};

	onDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		if (!this.state.card) return;
		if (!this.state.template) return;

		const destId = result.destination.droppableId;

		if (result.source.droppableId === "labels") {
			const labelId = this.state.template.labels[result.source.index].id;
			if (result.destination.droppableId.startsWith("entryLabels")) {
				const entryId = parseInt(
					destId.substring("entryLabels".length, destId.indexOf(":")),
					10
				);
				const propId = parseInt(
					destId.substring(
						destId.indexOf("propertyId") + "proppertyId".length - 1
					),
					10
				);
				const property = this.getTemplatePropertyById(propId);
				if (!property) return;
				this.setState({
					card: {
						...this.state.card,
						properties: this.state.card.properties.map(cardProp => {
							if (cardProp.id !== propId) return cardProp;
							if (isPropertyTypeArray(property.type)) {
								return {
									...cardProp,
									entries: (cardProp.entries as IEntry[]).map(
										entry => {
											if (entry.id !== entryId)
												return entry;
											return this.addLabelToEntry(
												entry,
												labelId,
												result.destination!.index
											);
										}
									),
								};
							}
							if ((cardProp.entries as IEntry).id !== entryId)
								return cardProp;
							return {
								...cardProp,
								entries: this.addLabelToEntry(
									cardProp.entries as IEntry,
									labelId,
									result.destination!.index
								),
							};
						}),
					},
				});
			}
		}

		const startId = result.source.droppableId;

		if (
			result.source.droppableId.startsWith("entryLabels") &&
			result.destination.droppableId.startsWith("entryLabels")
		) {
			const startEntryId = parseInt(
				startId.substring("entryLabels".length, startId.indexOf(":")),
				10
			);
			const startPropId = parseInt(
				startId.substring(
					startId.indexOf("propertyId") + "proppertyId".length - 1
				),
				10
			);

			const endEntryId = parseInt(
				destId.substring("entryLabels".length, destId.indexOf(":")),
				10
			);
			const endPropId = parseInt(
				destId.substring(
					destId.indexOf("propertyId") + "proppertyId".length - 1
				),
				10
			);

			const startProperty = this.getTemplatePropertyById(startPropId);
			if (!startProperty) return;
			const endProperty = this.getTemplatePropertyById(endPropId);
			if (!endProperty) return;

			const myLabelId = parseInt(
				result.draggableId.substring(
					result.draggableId.indexOf("labelId") + "labelId".length
				),
				10
			);

			this.setState({
				card: {
					...this.state.card,
					properties: this.state.card.properties.map(cardProp => {
						if (
							cardProp.id !== startPropId &&
							cardProp.id !== endPropId
						)
							return cardProp;
						const myPropType =
							cardProp.id === startPropId
								? startProperty.type
								: endProperty.type;
						if (isPropertyTypeArray(myPropType)) {
							return {
								...cardProp,
								entries: (cardProp.entries as IEntry[]).map(
									entry => {
										if (
											startEntryId !== entry.id &&
											endEntryId !== entry.id
										)
											return entry;
										return {
											...entry,
											labels: this.handleLabelReorder(
												entry.labels,
												myLabelId,
												result.source.index,
												result.destination!.index,
												this.detectAction(
													entry.id,
													startEntryId,
													endEntryId
												)
											),
										};
									}
								),
							};
						}
						const entry = cardProp.entries as IEntry;
						if (
							startEntryId !== entry.id &&
							endEntryId !== entry.id
						)
							return cardProp;
						return {
							...cardProp,
							entries: {
								...entry,
								labels: this.handleLabelReorder(
									entry.labels,
									myLabelId,
									result.source.index,
									result.destination!.index,
									this.detectAction(
										entry.id,
										startEntryId,
										endEntryId
									)
								),
							},
						};
					}),
				},
			});
		}
	};

	detectAction = (
		myEntryId: number,
		startEntryId: number,
		endEntryId: number
	): "insert" | "remove" | "reorder" => {
		if (myEntryId !== startEntryId && myEntryId !== endEntryId) {
			throw new Error(
				"myEntryId must be either startEntryId or endEntryId"
			);
		}
		if (startEntryId === endEntryId) return "reorder";
		if (startEntryId === myEntryId) return "remove";
		return "insert";
	};

	handleLabelReorder = (
		labels: number[] | undefined,
		labelId: number,
		startIndex: number,
		endIndex: number,
		action: "insert" | "remove" | "reorder"
	) => {
		if (action === "reorder") {
			return reorder(labels || [], startIndex, endIndex);
		}
		if (action === "insert") {
			if (labels === undefined) return [labelId];
			return [
				...labels.slice(0, endIndex),
				labelId,
				...labels.slice(endIndex),
			];
		}
		if (labels === undefined) return undefined;
		const newLabels = labels.filter((x, i) => i !== startIndex);
		if (newLabels.length === 0) return undefined;
		return newLabels;
	};

	addLabelToEntry = (
		entry: IEntry,
		labelId: number,
		destinationIndex: number
	): IEntry => {
		if (entry.labels === undefined) {
			return {
				...entry,
				labels: [labelId],
			};
		}
		if (entry.labels.indexOf(labelId) > -1) return entry;
		return {
			...entry,
			labels: [
				...entry.labels.slice(0, destinationIndex),
				labelId,
				...entry.labels.slice(destinationIndex),
			],
		};
	};

	getTemplatePropertyById(propId: number): undefined | ICardTemplateProperty {
		if (!this.state.template) return undefined;
		return this.state.template.properties.find(e => e.id === propId);
	}

	getName = () => {
		const t = this.getPrimaryProperty(this.state.template!.properties);
		if (!t) return null;
		const cardProp = this.state.card!.properties.find(
			prop => prop.id === t.id
		);
		if (!cardProp) return null;
		return this.cardPropToString(cardProp);
	};

	render() {
		if (!this.state.card) return null;
		if (!this.state.template) return null;
		return (
			<Container>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<CardLabels labels={this.state.template.labels} />

					<div style={{ display: "flex", height: 50 }}>
						<div style={{ flex: 1 }}>ბარათი</div>
						<div style={{ flex: 1 }}>{this.getName()}</div>
					</div>

					{this.state.template.properties.map(prop => {
						const cardProp = this.state.card!.properties.find(
							e => e.id === prop.id
						);
						return (
							<div key={prop.id} style={{ minHeight: 30 }}>
								<CardRow
									property={prop}
									onChange={this.onChange}
									onAdd={this.onCardPropAdd}
									entries={
										cardProp &&
										(cardProp.entries as IEntry[])
									}
									template={this.state.template!}
									generateUniqueIds={
										this.props.generateUniqueIds
									}
								/>
							</div>
						);
					})}
				</DragDropContext>
			</Container>
		);
	}
}

interface ICardLabelsProps {
	labels: any;
}

const CardLabels: React.FC<ICardLabelsProps> = React.memo(props => (
	<Droppable droppableId="labels" direction="horizontal">
		{(provided, snapshot) => (
			<div ref={provided.innerRef}>
				<div>
					{props.labels.map((label, i) => (
						<Draggable
							isDragDisabled={false}
							key={label.id}
							draggableId={"label" + label.id}
							index={i}
						>
							{(provided2, snapshot2) => (
								<div
									ref={provided2.innerRef}
									{...provided2.draggableProps}
									{...provided2.dragHandleProps}
									className={draggableContainerClassname}
								>
									<div className={draggableLabel}>
										{label.name}
									</div>
								</div>
							)}
						</Draggable>
					))}
				</div>
			</div>
		)}
	</Droppable>
));

export const draggableContainerClassname = css`
	display: inline-block;
`;

export const draggableLabel = css({
	display: "inline-block",
	margin: 5,
	padding: 5,
	border: "1px solid #ccc",
	borderRadius: 4,
});

const Container = styled("div")({
	width: 600,
	maxWidth: "100%",
	border: "#ccc solid 1px",
	borderRadius: 5,
	padding: 10,
});

export default EditCard;
