import React from "react";
import { ReactComponent as UniversalSponsorLogo } from "../styles/img/universal-sponsor-logo.svg";
import { designDistributor } from "./distributor";

export const SponsorLogo = designDistributor({
	universal: React.memo(({ style }: { style?: React.CSSProperties }) => (
		<UniversalSponsorLogo
			style={{
				color: "white",
				display: "block",
				margin: "20px auto",
				...style,
			}}
		/>
	)),
	_default: () => null,
});
