import React, { useCallback } from "react";
import {
	IFileTabs,
	IFileWithFullContent,
	FileItemTypes,
	IFileSingleItem,
} from "@app/api/files/helper-schemas";
import { ContentAddArg } from ".";
import { css } from "emotion";
import { getDefaultItemByType } from "./defaults";
import { FileSingleItem } from "./single-item";
import AutosizeInput from "react-input-autosize";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import classNames from "classnames";
import { generateFakeObjectId } from "@app/utils/common";
import { ObjectId } from "@app/utils/generics";

interface FileTabsProps {
	item: IFileTabs;
	onChange: (item: IFileTabs) => void;
	itemNames: IFileWithFullContent["itemNames"];
	onGalleryOpen: (onSuccess: (path: string) => void) => void;
	onContentAddOpen: (arg: ContentAddArg) => void;
}

interface FileTabsState {
	selectedTabIndex: number;
}

export class FileTabs extends React.Component<FileTabsProps, FileTabsState> {
	state: FileTabsState = {
		selectedTabIndex: 0,
	};

	handleTabNameChange = (newName: string, _id: ObjectId) => {
		this.props.onChange({
			...this.props.item,
			items: this.props.item.items.map(tab =>
				tab._id !== _id ? tab : { ...tab, title: newName }
			),
		});
	};

	handleTabItemChange = (item: IFileSingleItem, index: number) => {
		this.props.onChange({
			...this.props.item,
			items: this.props.item.items.map((tab, i) =>
				i !== index ? tab : { ...tab, item: item }
			),
		});
	};

	handleDelete = (_id: ObjectId) => {
		const length = this.props.item.items.length;
		this.props.onChange({
			...this.props.item,
			items: this.props.item.items.filter(item => item._id !== _id),
		});
		this.setState(({ selectedTabIndex }) => ({
			selectedTabIndex:
				selectedTabIndex >= length - 1
					? selectedTabIndex - 1
					: selectedTabIndex,
		}));
	};

	handleDeleteByIndex = (index: number) => {
		const length = this.props.item.items.length;
		this.props.onChange({
			...this.props.item,
			items: this.props.item.items.filter((_, i) => i !== index),
		});
		this.setState(({ selectedTabIndex }) => ({
			selectedTabIndex:
				selectedTabIndex >= length - 1
					? selectedTabIndex - 1
					: selectedTabIndex,
		}));
	};

	handleAdd = () => {
		const lastItem = this.props.item.items[
			this.props.item.items.length - 1
		];
		const length = this.props.item.items.length;
		this.props.onChange({
			...this.props.item,
			items: [
				...this.props.item.items,
				{
					_id: generateFakeObjectId(),
					title: "",
					item: getDefaultItemByType(
						lastItem ? lastItem.item.type : FileItemTypes.text
					),
				},
			],
		});
		this.setState({
			selectedTabIndex: length,
		});
	};

	handleTabSelect = (_id: ObjectId) => {
		const selectedTabIndex = this.props.item.items.findIndex(
			item => item._id === _id
		);
		this.setState({ selectedTabIndex });
	};

	render() {
		const { item } = this.props;
		const { selectedTabIndex } = this.state;
		const selectedTab = item.items[selectedTabIndex];
		return (
			<div className={tabsContainerCSS}>
				<TabsHeader
					items={item.items}
					onChange={this.handleTabNameChange}
					onDelete={this.handleDelete}
					onAdd={this.handleAdd}
					onTabSelect={this.handleTabSelect}
					selectedTabIndex={selectedTabIndex}
				/>
				{selectedTab && (
					<FileSingleItem
						key={selectedTab._id}
						item={selectedTab.item}
						index={selectedTabIndex}
						onDelete={this.handleDeleteByIndex}
						onChange={this.handleTabItemChange}
						itemNames={this.props.itemNames}
						onGalleryOpen={this.props.onGalleryOpen}
						onContentAddOpen={this.props.onContentAddOpen}
					/>
				)}
			</div>
		);
	}
}

const TabsHeader: React.FC<{
	items: IFileTabs["items"];
	onChange: (newName: string, _id: ObjectId) => void;
	onDelete: (_id: ObjectId) => void;
	onAdd: () => void;
	onTabSelect: (_id: ObjectId) => void;
	selectedTabIndex: number;
}> = ({ items, onChange, onDelete, onAdd, onTabSelect, selectedTabIndex }) => {
	return (
		<div className={tabHeaderContainer}>
			{items.map(({ title, _id }, i) => {
				return (
					<Tab
						key={_id}
						_id={_id}
						title={title}
						onChange={onChange}
						onDelete={onDelete}
						onClick={onTabSelect}
						isSelected={selectedTabIndex === i}
					/>
				);
			})}
			<AddIcon onClick={onAdd} />
		</div>
	);
};

const Tab: React.FC<{
	title: string;
	_id: ObjectId;
	onChange: (newName: string, _id: ObjectId) => void;
	onDelete: (_id: ObjectId) => void;
	onClick: (_id: ObjectId) => void;
	isSelected: boolean;
}> = ({ title, _id, onChange, onDelete, onClick, isSelected }) => {
	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			onChange(e.target.value, _id);
		},
		[_id, onChange]
	);

	const handleDelete = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			onDelete(_id);
		},
		[_id, onDelete]
	);

	const handleClick = useCallback(() => {
		onClick(_id);
	}, [_id, onClick]);

	return (
		<div
			onClick={handleClick}
			className={classNames(singleTabCSS, isSelected && selectedTabCSS)}
		>
			<AutosizeInput
				value={title}
				onChange={handleChange}
				inputClassName={tabInputStyles}
				autoFocus={isSelected}
				placeholder="type name..."
			/>
			<CloseIcon onClick={handleDelete} />
		</div>
	);
};

const tabsContainerCSS = css`
	border: 1px solid #ccc;
	padding: 10px;
`;

const tabHeaderContainer = css`
	display: flex;
	max-width: 80vw;
	overflow-x: auto;
	align-items: center;
`;

const singleTabCSS = css`
	display: flex;
	align-items: center;
	justify-content: center;
	background: #eee;
	padding: 4px 6px;
	margin: 3px;
	border: 1px solid #ccc;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
`;
const selectedTabCSS = css`
	background: #a6d6ff;
	border-color: #0077dd;
`;
const tabInputStyles = css`
	background: transparent;
	padding: 3px;
	border: none;
	outline: none;
`;
