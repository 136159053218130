import { ItemType } from "@app/api/folders/helper-schemas";
import { inject } from "@app/modules";
import {
	currentFolderName,
	FolderNavigation,
	FolderContainer,
	FolderSingleItemNameCSS,
	AddCardIcon,
	AddFileIcon,
	AddFolderIconCSS,
	AddFolderItemsContainer,
	AddQuestionIcon,
	AddTestIconStyles,
} from "./styles";
import React, { useMemo } from "react";
import { ObjectId } from "@app/utils/generics";
import { useFolder } from "@app/hooks/folders";
import BackIcon from "@material-ui/icons/Reply";
import CreateNewFolder from "@material-ui/icons/CreateNewFolder";
import CardIcon from "@material-ui/icons/CreditCard";
import QuestionIcon from "@material-ui/icons/HelpOutline";
import SvgAddFile from "./styles/img/add-file";
import TestIcon from "./styles/img/test";

interface IFoldersNavigationProps {
	currentFolderId: ObjectId;
	onItemSelect: (type: ItemType, id: ObjectId) => void;
	courseId: ObjectId;
}

export const FoldersNavigation: React.FC<IFoldersNavigationProps> = React.memo(
	props => {
		const FolderHierarchyService = inject("FolderHierarchyService");
		const parentFolders = useMemo(() => {
			const parentFolderIds = FolderHierarchyService.getAncestorIdsSync(
				props.courseId,
				props.currentFolderId
			);
			return [...parentFolderIds].reverse();
		}, [FolderHierarchyService, props.courseId, props.currentFolderId]);

		const currentFolder = useFolder({
			folderId: props.currentFolderId,
			courseId: props.courseId,
		});

		if (!currentFolder.isSuccessfullyLoaded) return null;
		return (
			<div className={FolderNavigation}>
				{parentFolders.map(id => (
					<FolderName
						key={id}
						onClick={props.onItemSelect}
						folderId={id}
						courseId={props.courseId}
					/>
				))}
				<span className={currentFolderName}>
					{currentFolder.doc.name}
				</span>
			</div>
		);
	}
);

const FolderName: React.FC<{
	folderId: ObjectId;
	courseId: ObjectId;
	onClick: (type: ItemType, id: ObjectId) => void;
}> = ({ folderId, courseId, onClick }) => {
	const folder = useFolder({
		folderId,
		courseId,
	});
	return (
		<span onClick={() => onClick(ItemType.folder, folderId)}>
			{(folder.isSuccessfullyLoaded ? folder.doc.name : "") + " > "}
		</span>
	);
};

export const UpFolderIcon: React.FC<{ onClick: () => void }> = ({
	onClick,
}) => (
	<div
		className={FolderContainer}
		style={{ cursor: "pointer" }}
		onClick={onClick}
	>
		<div className={FolderSingleItemNameCSS}>
			<BackIcon />
		</div>
	</div>
);

export const BottomNavigation: React.FC<{
	onAddPopupOpen: (type: ItemType) => () => void;
}> = React.memo(({ onAddPopupOpen }) => {
	return (
		<div className={AddFolderItemsContainer}>
			<CreateNewFolder
				className={AddFolderIconCSS}
				onClick={onAddPopupOpen(ItemType.folder)}
			/>
			<SvgAddFile
				className={AddFileIcon}
				onClick={onAddPopupOpen(ItemType.file)}
			/>
			<CardIcon
				className={AddCardIcon}
				onClick={onAddPopupOpen(ItemType.card)}
			/>
			<QuestionIcon
				className={AddQuestionIcon}
				onClick={onAddPopupOpen(ItemType.question)}
			/>
			<TestIcon
				className={AddTestIconStyles}
				onClick={onAddPopupOpen(ItemType.test)}
			/>
		</div>
	);
});
