import { inject } from "@app/modules";
import { IRequest } from "../requests";
import { TopicHierarchySchema } from "./helper-schemas";
import {
	IADELETETopic,
	IAGETALLCourseTopics,
	IAGETTopic,
	IAGETTopicHierarchy,
	IAGETTopicLevels,
	IAPOSTCreateTopic,
	IAPUTTopic,
	IRGETAllTopics,
	IRGETTopic,
	IRGETTopicHierarchy,
	IRGETTopicLevels,
	IRPOSTCreateTopic,
	RGETAllTopicsSchema,
	RGETTopicLevelsSchema,
	RGETTopicSchema,
	RPOSTCreateTopicSchema,
	IAGETTopicsByQuestion,
	IRGETTopicsByQuestion,
	IAPUTAddItemsToTopic,
	IRPUTAddItemsToTopic,
	IAPUTRemoveItemsFromTopic,
	IRPUTRemoveItemsFromTopic,
} from "./validators";
import { generateFakeObjectId } from "@app/utils/common";
import { PromisesKeeperAPI } from "../promises-keeper";
import { ObjectId } from "@app/utils/generics";
import { Topic } from "@app/models/topic";
import { TopicHierarchy } from "@app/models/topic-hierarchy";
import { UserTopicLevel } from "@app/models/user-topic-level";

export class TopicsController {
	private readonly Request: IRequest;

	private readonly _TopicModel = inject("TopicModel");
	private readonly _TopicHierarchyModel = inject("TopicHierarchyModel");
	private readonly _UserTopicLevelModel = inject("UserTopicLevelModel");
	private readonly _TopicHierarchyService = inject("TopicHierarchyService");

	private topicPromises = new PromisesKeeperAPI<ObjectId, Topic>();
	private userTopicLevelPromises = new PromisesKeeperAPI<
		ObjectId,
		UserTopicLevel
	>();

	private readonly assertAndGetCoursesUser = inject(
		"assertAndGetCoursesUser"
	);

	constructor(request: IRequest) {
		this.Request = request;
	}

	add = async (args: IAPOSTCreateTopic): Promise<Topic> =>
		this.Request.send("POST", "/api/topics", args, null, {
			responseSchema: RPOSTCreateTopicSchema,
		}).then((data: IRPOSTCreateTopic) => {
			const topic = this._TopicModel.loadOneSync(data); // topic must be loaded first
			if (args.parentId) {
				this._TopicHierarchyService.setItemParentSync(
					args.originalCourseId,
					data._id,
					args.parentId
				);
			}
			return topic;
		});

	update = async (args: IAPUTTopic): Promise<Topic | null> =>
		this.Request.send("PUT", "/api/topics/:_id", args).then(() =>
			this._TopicModel.updateOneSync({ _id: args._id }, args)
		);

	getAllByCourseId = async (
		args: IAGETALLCourseTopics,
		loadFresh = false
	): Promise<Topic[]> => {
		if (!loadFresh) {
			const topicHierarchy = this._TopicHierarchyModel.findOneByCourseSync(
				args.courseId
			);
			if (topicHierarchy) {
				const topicIds = this._TopicHierarchyService.getAllItemIdsSync(
					args.courseId
				);
				const topics = this._TopicModel.findManyByIdsSync(topicIds);
				if (topicIds.length === topics.length) {
					return topics;
				}
			}
		}
		return this.Request.send(
			"GET",
			"/api/topics/by-course-id",
			args,
			null,
			{
				responseSchema: RGETAllTopicsSchema,
			}
		).then((data: IRGETAllTopics) => {
			this._TopicModel.meta.updateLoadTime(args.courseId);
			return this._TopicModel.loadManySync(data);
		});
	};

	getByItem = async (
		args: IAGETTopicsByQuestion
	): Promise<IRGETTopicsByQuestion> =>
		this.Request.send("GET", "/api/topics/by-content", args).then(
			(data: IRGETTopicsByQuestion) => data
		);

	getById = async (args: IAGETTopic, loadFresh?: boolean): Promise<Topic> => {
		if (!loadFresh) {
			const topic = this._TopicModel.findByIdSync(args._id);
			if (topic) return topic;
		}
		return this.topicPromises.getOrSetPromise(args._id, () =>
			this.Request.send("GET", "/api/topics/:_id", args, null, {
				responseSchema: RGETTopicSchema,
			}).then((data: IRGETTopic) => {
				return this._TopicModel.loadOneSync(data);
			})
		);
	};

	getHierarchy = async (
		args: IAGETTopicHierarchy,
		loadFresh?: boolean
	): Promise<TopicHierarchy> => {
		if (!loadFresh) {
			const hierarchy = this._TopicHierarchyModel.findOneByCourseSync(
				args.courseId
			);
			if (hierarchy) return hierarchy;
		}
		return this.Request.send("GET", "/api/topics/hierarchy", args, null, {
			responseSchema: TopicHierarchySchema,
		}).then((data: IRGETTopicHierarchy) => {
			return this._TopicHierarchyModel.loadOneSync(data);
		});
	};

	getLevels = async (
		args: IAGETTopicLevels,
		loadFresh?: boolean,
		forceLoadingIfNeedsRecalculation?: boolean
	): Promise<UserTopicLevel> => {
		const user = this.assertAndGetCoursesUser();
		const userId = user.id;
		if (!loadFresh) {
			const levelsDoc = this._UserTopicLevelModel.findOneSync({
				userId,
				courseId: args.courseId,
			});
			if (levelsDoc) {
				if (!levelsDoc.needsRecalculation) return levelsDoc;
				if (forceLoadingIfNeedsRecalculation) {
					return this.getLevels(args, true);
				}
				this.getLevels(args, true).then();
				return levelsDoc;
			}
		}
		return this.userTopicLevelPromises.getOrSetPromise(
			`${userId}-${args.courseId}`,
			() =>
				this.Request.send(
					"GET",
					"/api/topics/levels",
					args,
					undefined,
					{
						responseSchema: RGETTopicLevelsSchema,
					}
				)
					.then((data: IRGETTopicLevels) => {
						return this._UserTopicLevelModel.loadOneSync(data);
					})
					.catch(e => {
						if (!e.response || e.response.status !== 404) {
							throw e;
						}
						const emptyData: IRGETTopicLevels = {
							_id: generateFakeObjectId(),
							userId,
							courseId: args.courseId,
							levelsByTopicId: {},
						};
						return this._UserTopicLevelModel.loadOneSync(emptyData);
					})
		);
	};

	deleteById = async (args: IADELETETopic): Promise<void> =>
		this.Request.send("DELETE", "/api/topics/:_id", args).then(() => {
			this._TopicHierarchyService.onItemDeleteSync(
				args.originalCourseId,
				args._id
			);
		});

	addItems = (args: IAPUTAddItemsToTopic): Promise<IRPUTAddItemsToTopic> =>
		this.Request.send("PUT", "/api/topics/:labelId/add-items", args);

	removeItems = (
		args: IAPUTRemoveItemsFromTopic
	): Promise<IRPUTRemoveItemsFromTopic> =>
		this.Request.send("PUT", "/api/topics/:labelId/remove-items", args);
}
