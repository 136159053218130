import {
	FileItemTypes,
	IFile,
	IFileSingleItem,
	IFileWithFullContent,
} from "@app/api/files/helper-schemas";
import AddIcon from "@material-ui/icons/AddCircleRounded";
import RemoveIcon from "@material-ui/icons/RemoveCircleRounded";
import { css } from "emotion";
import React from "react";
import Select from "react-select";
import { getDefaultItemByType } from "./defaults";
import { FileSingleItem } from "./single-item";
import { ContentAddArg } from ".";

interface FileItemsProps {
	items: IFile["items"];
	onChange: (items: IFile["items"]) => void;
	itemNames: IFileWithFullContent["itemNames"];
	onGalleryOpen: (onSuccess: (path: string) => void) => void;
	onContentAddOpen: (arg: ContentAddArg) => void;
}

interface FileItemsState {
	isAddingNewItem: boolean;
}

export class FileItems extends React.PureComponent<
	FileItemsProps,
	FileItemsState
> {
	state: FileItemsState = {
		isAddingNewItem: false,
	};

	options = [
		{ value: FileItemTypes.text, label: "Text" },
		{ value: FileItemTypes.tabs, label: "Tabs" },
		{ value: FileItemTypes.cards, label: "Cards" },
		{ value: FileItemTypes.questions, label: "Questions" },
	];

	itemChange = (item: IFileSingleItem, index: number) => {
		this.props.onChange(
			this.props.items.map((e, i) => (i !== index ? e : item))
		);
	};

	onItemDelete = (index: number) => {
		this.props.onChange(this.props.items.filter((e, i) => i !== index));
	};

	switchAddingItemState = () => {
		this.setState(({ isAddingNewItem }) => ({
			isAddingNewItem: !isAddingNewItem,
		}));
	};

	handleNewFileItemTypeChange = (
		sel: {
			value: FileItemTypes;
			label: string;
		} | null
	) => {
		this.setState({
			isAddingNewItem: false,
		});
		if (!sel) return;
		this.props.onChange([
			...this.props.items!,
			getDefaultItemByType(sel.value),
		]);
	};

	render() {
		const { items } = this.props;
		return (
			<div>
				{items.map((item, i) => {
					return (
						<FileSingleItem
							key={i}
							item={item}
							index={i}
							onChange={this.itemChange}
							onDelete={this.onItemDelete}
							itemNames={this.props.itemNames}
							onGalleryOpen={this.props.onGalleryOpen}
							onContentAddOpen={this.props.onContentAddOpen}
						/>
					);
				})}

				{this.state.isAddingNewItem && (
					<Select
						options={this.options}
						onChange={this.handleNewFileItemTypeChange}
						placeholder="Select File Item Type"
						autoFocus={true}
						defaultMenuIsOpen={true}
					/>
				)}
				{!this.state.isAddingNewItem ? (
					<AddIcon
						className={AddIconStyles}
						onClick={this.switchAddingItemState}
					/>
				) : (
					<RemoveIcon
						className={AddIconStyles}
						onClick={this.switchAddingItemState}
					/>
				)}
			</div>
		);
	}
}

const AddIconStyles = css`
	font-size: 32px !important;
	color: #c0c0c0;
	cursor: pointer;
	&:hover {
		color: grey;
	}
`;
