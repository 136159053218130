import * as React from "react";
import CloseIcon from "@material-ui/icons/Close";
import QuestionEditwrapper from "./edit-wrapper";
import TextEdit from "./edit-text";
import { fromHTMLToState, fromStateToHTML } from "../../editor/html";
import {
	IFullTextWithQuestions,
	IText,
} from "../../../schemas/texts/helper-schemas";
import { IQContentEditPassableProps } from "./edit";
import { IQuestion } from "../../../schemas/questions/helper-schemas";
import { v4 as uuid } from "uuid";
import { Omit } from "../../../utils/generics";
import { ContentError } from "./interfaces";

export type TextContentError =
	| {
			type: "item";
			index: number;
			errors: ContentError[];
	  }
	| {
			type: "text";
			errors: ContentError[];
	  };

interface IProps {
	textWithQuestions?: IFullTextWithQuestions;
	onSave: (
		errors: TextContentError[] | null,
		data: IFullTextWithQuestions
	) => void;
	customized?: IQContentEditPassableProps;
}

interface IState {
	textRef: React.RefObject<TextEdit>;
	questions: (IFullTextWithQuestions["questions"][number] | null)[];
	questionRefs: React.RefObject<QuestionEditwrapper>[];
	questionUUIDS: string[];
}

class TextWithQuestionsEditWrapper extends React.PureComponent<IProps, IState> {
	state: IState = {
		textRef: React.createRef<TextEdit>(),
		questions: this.props.textWithQuestions
			? this.props.textWithQuestions.questions
			: [null],
		questionRefs: (this.props.textWithQuestions
			? this.props.textWithQuestions.questions.map(React.createRef)
			: [React.createRef()]) as IState["questionRefs"],
		// tslint:disable-next-line:no-unnecessary-callback-wrapper
		questionUUIDS: this.props.textWithQuestions
			? this.props.textWithQuestions.questions.map(() => uuid())
			: [uuid()],
	};

	defaultText = {
		text: "",
	};

	getData = () => {
		const questions = this.getQuestionsArrayByRef().filter(
			q => !!q
		) as IQuestion[];
		if (!questions.length) return undefined;
		const text = this.getTextByRef();
		if (!text) return undefined;
		return {
			questions,
			text: ({
				...text,
				questions: questions.map(q => q._id),
				numberOfQuestions: questions.length,
			} as Omit<
				IText,
				("_id" | "authorId") | ("createdAt" | "updatedAt")
			>) as IText,
		} as IFullTextWithQuestions;
	};

	onSave = () => {
		const data = this.getData();
		if (!data) return;
		const errors = this.getErrorsArrayByRef();
		this.props.onSave(errors, data);
	};

	getTextByRef = () => {
		if (!this.state.textRef.current) return null;
		const text = this.state.textRef.current.getData();
		if (!text) return null;
		return text;
	};

	getQuestionsArrayByRef = () => {
		return this.state.questionRefs.map(qRef => {
			if (!qRef.current) return null;
			return qRef.current.getData() || null;
		});
	};

	getErrorsArrayByRef = (): TextContentError[] | null => {
		const accumulatedErrors = this.state.questionRefs
			.map((qRef, index): TextContentError | null => {
				if (!qRef.current) return null;
				const errors = qRef.current.getErrors();
				if (!errors) return null;
				return { index, errors, type: "item" };
			})
			.filter(e => !!e) as TextContentError[];
		if (this.state.textRef.current) {
			const textErrors = this.state.textRef.current.getErrors();
			if (textErrors.length) {
				accumulatedErrors.unshift({
					type: "text",
					errors: textErrors,
				});
			}
		}
		if (accumulatedErrors.length === 0) return null;
		return accumulatedErrors;
	};

	onAddQuestion = () => {
		const questions = this.getQuestionsArrayByRef();
		this.setState({
			questions: [...questions, null],
			questionRefs: [...this.state.questionRefs, React.createRef()],
			questionUUIDS: [...this.state.questionUUIDS, uuid()],
		});
	};

	getLastQuestionType = (questions: IState["questions"]) => {
		for (let i = questions.length - 1; i >= 0; --i) {
			if (questions[i] !== null && questions[i]!.content.type) {
				return questions[i]!.content.type;
			}
		}
		return null;
	};

	onQuestionDelete = (index: number) => {
		const questions = this.getQuestionsArrayByRef();
		this.setState({
			questions: questions.filter((q, i) => i !== index),
			questionRefs: this.state.questionRefs.filter((q, i) => i !== index),
			questionUUIDS: this.state.questionUUIDS.filter(
				(q, i) => i !== index
			),
		});
	};

	render() {
		const lastQuestionType = this.getLastQuestionType(this.state.questions);
		const commonStyles =
			this.props.customized &&
			this.props.customized.commonProps &&
			this.props.customized.commonProps.commonStyles;
		const commonTexts =
			this.props.customized &&
			this.props.customized.commonProps &&
			this.props.customized.commonProps.commonTexts;
		const commonComponetns =
			this.props.customized &&
			this.props.customized.commonProps &&
			this.props.customized.commonProps.commonComponents;
		return (
			<div style={{ textAlign: "left" }}>
				<TextEdit
					ref={this.state.textRef}
					text={
						(this.props.textWithQuestions &&
							this.props.textWithQuestions.text) ||
						this.defaultText
					}
					toHTML={fromStateToHTML as (e: any) => string}
					toEditorState={fromHTMLToState as (h: string) => any}
					{...(this.props.customized &&
						this.props.customized.commonProps)}
				/>
				{this.state.questions.map((q, index) => {
					// tslint:disable-next-line:no-unused-expression
					return (
						<div
							key={this.state.questionUUIDS[index]}
							style={{ margin: "20px 0" }}
						>
							<CloseIcon
								onClick={() => this.onQuestionDelete(index)}
							/>
							<QuestionEditwrapper
								defaultContentType={lastQuestionType}
								defaultQuestion={q || undefined}
								customized={this.props.customized}
								ref={this.state.questionRefs[index]}
							/>
						</div>
					);
				})}
				<div>
					<button
						onClick={this.onAddQuestion}
						className={
							commonStyles && commonStyles.addAnotherQuestion
						}
					>
						{(commonTexts && commonTexts.addAnotherQuestion) ||
							"Add question"}
					</button>
				</div>
				{commonComponetns &&
					commonComponetns.beforeSaveButtonOfText && (
						<commonComponetns.beforeSaveButtonOfText />
					)}
				<button
					onClick={this.onSave}
					className={commonStyles && commonStyles.saveButton}
				>
					{(commonTexts && commonTexts.saveButton) || "Save"}
				</button>
			</div>
		);
	}
}

export default TextWithQuestionsEditWrapper;
