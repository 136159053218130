import React, { useRef, useState, useCallback } from "react";
import {
	HierarchyMultipleSelect,
	IHierarchyMultipleSelectDefaultSelections,
} from "../hierarchy-multiple-select";
import { HierarchyItemType } from "@app/services/hierarchy-info/interfaces";
import { ItemType } from "@app/api/folders/helper-schemas";
import { getFormattedMessage } from "@app/utils/locale";
import { ObjectId } from "@app/utils/generics";
import {
	IMSelectWrapperProps,
	DeepMultipleSelectWithSearch,
} from "../deep-multiple-select-wth-search";
import { SwitchWithLabel } from "@app/components/widgets/input";
import styles from "./styles/filter.module.css";
import { IAGETCourseFilteredContent } from "@app/api/courses/validators";

type SelectedItems = Pick<
	IAGETCourseFilteredContent,
	"folders" | "topics" | "taskTypes"
>;

export interface ILabelRefVal {
	getSelectedItems: (saveSelections: true) => SelectedItems;
}

export const FilterLabelOptions = React.forwardRef<
	ILabelRefVal,
	{
		courseId: ObjectId;
		defaultSelections?: IHierarchyMultipleSelectDefaultSelections;
		onInitialCall?: () => void;
	}
>((props, ref) => {
	const { onInitialCall, courseId, defaultSelections } = props;
	const hierarchyMultipleSelectRef = useRef<HierarchyMultipleSelect>(null);
	const [hiddenHierarchyTypes, setHiddenHierarchyTypes] = useState(
		{} as {
			[h in HierarchyItemType]?: boolean;
		}
	);
	const commonProps: Partial<IMSelectWrapperProps> = {
		hideSelectedItems: false,
		hideSelectedDescendantsFromSearchBar: true,
	};
	const foldersProps: PartialMCProps = {
		...commonProps,
		isHiddenChecked: !!hiddenHierarchyTypes[HierarchyItemType.folder],
		onVisibilityChange: useCallback((checked: boolean) => {
			setHiddenHierarchyTypes(c => ({
				...c,
				[HierarchyItemType.folder]: checked,
			}));
		}, []),
		checkboxLabel: getFormattedMessage("admin:general.withoutFolders"),
	};
	const topicsProps: PartialMCProps = {
		...commonProps,
		isHiddenChecked: !!hiddenHierarchyTypes[HierarchyItemType.topic],
		onVisibilityChange: useCallback((checked: boolean) => {
			setHiddenHierarchyTypes(c => ({
				...c,
				[HierarchyItemType.topic]: checked,
			}));
		}, []),
		checkboxLabel: getFormattedMessage("admin:general.withoutTopics"),
	};
	const taskTypesProps: PartialMCProps = {
		...commonProps,
		isHiddenChecked: !!hiddenHierarchyTypes[HierarchyItemType.taskType],
		onVisibilityChange: useCallback((checked: boolean) => {
			setHiddenHierarchyTypes(c => ({
				...c,
				[HierarchyItemType.taskType]: checked,
			}));
		}, []),
		checkboxLabel: getFormattedMessage("admin:general.withoutTaskTypes"),
	};

	const getSelectedItems = (saveSelections: boolean): SelectedItems => {
		if (!hierarchyMultipleSelectRef.current) {
			return { folders: null, taskTypes: null, topics: null };
		}
		const convertEmptyArrToNull = <T extends any[]>(arr: T): T | null => {
			if (arr.length === 0) return null;
			return arr;
		};
		const folders = hiddenHierarchyTypes[HierarchyItemType.folder]
			? "none"
			: convertEmptyArrToNull(
					hierarchyMultipleSelectRef.current.getSelectedItemIdsByHierarchyType(
						HierarchyItemType.folder
					)
			  );
		const taskTypes = hiddenHierarchyTypes[HierarchyItemType.taskType]
			? "none"
			: convertEmptyArrToNull(
					hierarchyMultipleSelectRef.current.getSelectedItemIdsByHierarchyType(
						HierarchyItemType.taskType
					)
			  );
		const topics = hiddenHierarchyTypes[HierarchyItemType.topic]
			? "none"
			: convertEmptyArrToNull(
					hierarchyMultipleSelectRef.current.getSelectedItemIdsByHierarchyType(
						HierarchyItemType.topic
					)
			  );
		if (saveSelections) {
			hierarchyMultipleSelectRef.current.saveOptions();
		}
		return { folders, taskTypes, topics };
	};

	assignRefValue(ref, { getSelectedItems });

	const onChange = useCallback(
		(value: any, isInitialCall: boolean) => {
			if (!isInitialCall) return;
			if (onInitialCall) onInitialCall();
		},
		[onInitialCall]
	);

	return (
		<div>
			<HierarchyMultipleSelect
				ref={hierarchyMultipleSelectRef}
				onChange={onChange}
				courseId={courseId}
				hierarchiesToShow={[
					HierarchyItemType.folder,
					HierarchyItemType.topic,
					HierarchyItemType.taskType,
				]}
				itemTypesToBeStored={{
					[HierarchyItemType.folder]: {
						[ItemType.folder]: true,
					},
				}}
				placeholders={{
					[HierarchyItemType.folder]: getFormattedMessage("folders"),
				}}
				defaultSelections={defaultSelections}
				loadLastOptionsAutomatically={true}
				multipleSelectProps={{
					[HierarchyItemType.folder]: foldersProps,
					[HierarchyItemType.topic]: topicsProps,
					[HierarchyItemType.taskType]: taskTypesProps,
				}}
				multipleSelectComponent={MultipleSelect}
			/>
		</div>
	);
});

type MCAdditionalProps = {
	isHiddenChecked: boolean;
	onVisibilityChange: (checked: boolean) => void;
	checkboxLabel: string;
};

type PartialMCProps = Partial<IMSelectWrapperProps> & MCAdditionalProps;

const MultipleSelect: React.FC<IMSelectWrapperProps & MCAdditionalProps> = ({
	isHiddenChecked,
	onVisibilityChange,
	checkboxLabel,
	...restProps
}) => {
	return (
		<div className={styles.searchContainer}>
			<div>
				<SwitchWithLabel
					label={!isHiddenChecked ? "" : checkboxLabel}
					value={!isHiddenChecked}
					onChange={checked => onVisibilityChange(!checked)}
				/>
			</div>
			<div>
				{!isHiddenChecked && (
					<DeepMultipleSelectWithSearch {...restProps} />
				)}
			</div>
		</div>
	);
};

const assignRefValue = <T extends {}>(ref: React.Ref<T>, val: T) => {
	if (typeof ref === "function") {
		ref(val);
	} else if (ref) {
		(ref as React.MutableRefObject<T>).current = val;
	}
};
